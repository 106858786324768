/**
 * Enum for the different platforms that can be used to access the application.
 * @export
 * @enum {string}
 * - Safari
 * - SafariMobile
 * - Chrome
 * - ChromeMobile
 * - Other
 * - OtherMobile
 */
export enum Platform {
  Safari = "Safari",
  SafariMobile = "SafariMobile",

  Chrome = "Chrome",
  ChromeMobile = "ChromeMobile",

  Mozilla = "Mozilla",
  MozillaMobile = "MozillaMobile",

  Other = "Other",
  OtherMobile = "OtherMobile",
}
