import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable, timeout} from "rxjs";
import {User} from "../models/user";
import {AuthenticationService} from "../_auth/authentication.service";
import {UserJwt} from "../models/admin_owner/user/user-jwt";
import {DashboardStats} from "../models/stats/dashboard-stats";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class GeneralHttpService {
  private _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders,) {
    this._baseUrl = baseUrl;

  }

  online() {
    return this.http.get(this._baseUrl + ServerEndpoints.Inventory_General_OnlineCheck)
  }

  login(name: string, password: string): Observable<User> {
    return this.http.post<User>(this._baseUrl + "/auth/User/Login", {
      "Name": name,
      "Password": password
    });
  }

  logout(jwt: string): Observable<any> {
    return this.http.post(this._baseUrl + "/auth/User/Logout?jwt=" + jwt, {});
  }


  getDashboardStats(): Observable<DashboardStats> {
    return this.http.get<DashboardStats>(this._baseUrl + ServerEndpoints.Inventory_General_GetDashboardStats);
  }
}
