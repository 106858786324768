import {AfterViewInit, Component} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-help-integration',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-integration.component.html',
  styleUrls: ['./help-integration.component.scss']
})
export class HelpIntegrationComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${document.location.origin}/static/help-integration.js`;
    document.body.appendChild(script);
  }
}
