import {EmployeeEventResponseDto} from "../../../models/event/employee-event-response-dto";
import {LocationNl} from "../../../models/location-nl";

export class ShiftLocation {
  location: LocationNl;
  responses: EmployeeEventResponseDto[] = [];

  constructor(location: LocationNl) {
    this.location = location;
  }
}
