import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {PrinterDto} from "../_models/printer-dto";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class PrinterHttpService {
  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  add(name: string, connectionString: string): Observable<PrinterDto> {
    return this.httpClient.put<PrinterDto>(`${this.baseUrl}${ServerEndpoints.Device_Printer_Add}`, {
      name: name,
      connectionString: connectionString
    })
  }

  get(id: string): Observable<PrinterDto> {
    return this.httpClient.get<PrinterDto>(`${this.baseUrl}${ServerEndpoints.Device_Printer_Get}/${id}`)
  }

  list(): Observable<PrinterDto[]> {
    return this.httpClient.get<PrinterDto[]>(`${this.baseUrl}${ServerEndpoints.Device_Printer_List}`)
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}${ServerEndpoints.Device_Printer_Delete}/${id}`)
  }

  changeName(id: string, name: string): Observable<PrinterDto> {
    return this.httpClient.post<PrinterDto>(`${this.baseUrl}${ServerEndpoints.Device_Printer_ChangeName}/${id}`, {
      name: name
    })
  }

  setVariable(id: string, variable: string, value: string): Observable<PrinterDto> {
    return this.httpClient.post<PrinterDto>(`${this.baseUrl}${ServerEndpoints.Device_Printer_SetVariable}/${id}`, {
      name: variable,
      value: value
    })
  }
}
