import {EnvironmentSettingType} from "./environment-setting-type";

export class EnvironmentSetting {
  id: string = "";
  value: string = "";
  type: EnvironmentSettingType = EnvironmentSettingType.None;
  updated: boolean = false;
  empty: boolean = false;

  constructor(empty: boolean = false) {
    this.empty = empty;
  }
}
