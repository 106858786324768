import {BatteryState} from "./battery-state";


export class DeviceInformation {
  deviceId: string = "";

  model: string = "";
  manufacturer: string = "";
  osVersion: string = "";
  platform: string = "";
  batteryLevel: number = 0;
  batteryState: BatteryState = BatteryState.Unknown;
  appVersion: string = "";
  appBuild: string = "";
  modified: Date = new Date();
}
