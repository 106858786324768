import {Component, OnInit} from '@angular/core';
import {CompanyHttpService} from "../x-http-requests/company-http.service";
import {CategoryDto} from "../../models/category-dto";
import {CompanyDto} from "../../models/admin_owner/company/company-dto";
import {ActivatedRoute, Router} from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import {Routes} from "../../enums/routes";
import {IntegrationProvider} from "../../models/integrations/integration-provider";
import {ShardDto} from "../../models/admin_owner/shard/shard-dto";
import {AlertLevel} from "../../enums/alert-level";
import {FiskalyHttpService} from "../../_modules/owner/_services/fiskaly-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class OwnerCompanyComponent implements OnInit {
  companies?: CompanyDto[];
  filtered_companies?: CompanyDto[];
  searchText: string = "";
  error?: HttpErrorResponse;
  name: string = "";

  constructor(private companyHttpService: CompanyHttpService,
              private route: ActivatedRoute, private router: Router,
              private fiskalyHttpService: FiskalyHttpService,
              private gas: GlobalAlertService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  redirectToSettings(id: string) {

    this.router.navigate([Routes.OwnerCompanySettings], {queryParams: {id: id}});
  }

  AddCompany(): void {
    this.companyHttpService.add(this.name).subscribe(x => {
      if (this.companies == undefined) this.companies = [];
      this.error = undefined;
      this.companies.splice(0, 0, x);
      this.FilterItem(this.searchText);
      this.name = "";
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  RemoveCompany(company: CompanyDto): void {
    let confirmation = confirm("Are you sure you want to delete the following company:\n" + company.name + "\n" + company.id);
    if (!confirmation) return;
    this.companyHttpService.delete(company.id).subscribe(x => {
      this.error = undefined;
      this.companies!.splice(this.companies!.indexOf(company), 1);
      this.FilterItem(this.searchText);
    }, error => {
      this.error = error;
      console.error(error);
    });
  }

  AssignCopy() {
    this.filtered_companies = Object.assign([], this.companies);
  }

  FilterItem(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    } else {
      this.filtered_companies = Object.assign([], this.companies?.filter(company =>
        company.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ))
    }
  }

  LoadData() {
    this.companyHttpService.list().subscribe(x => {
      this.companies = x;
      this.AssignCopy();
    }, error => console.error(error));
  }

  ngOnInit(): void {
    this.LoadData();
  }

  hasIntegrationReference(company: CompanyDto, provider: IntegrationProvider): boolean {
    return company.integrationReferences.some(x => x.integrationProvider == provider && x.externalId != undefined);
  }

  fiskalyRegister(company: CompanyDto) {
    this.fiskalyHttpService.upsertCompany(company.id)
      .withLoadingIndicator()
      .subscribe({
        next: c => {
          this.gas.createAndShow("Success", "Fiskaly registration successful", AlertLevel.success);
          this.companies?.splice(this.companies?.indexOf(company), 1, c);
          this.FilterItem(this.searchText);
        },
        error: err => {
          console.error(err);
          this.gas.createAndShow("Error", "Fiskaly registration failed", AlertLevel.error);
        }
      })
  }

  protected readonly IntegrationProvider = IntegrationProvider;
}
