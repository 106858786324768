import {Component} from '@angular/core';
import {Routes} from "../../../enums/routes";
import {PermissionService} from "../../../_auth/permission.service";
import {LocationRoutes} from "../../location/_models/_enums/location-routes";

@Component({
  selector: 'app-navbar-devices',
  templateUrl: './navbar-location.component.html',
  styleUrls: ['./navbar-location.component.scss']
})
export class NavbarLocationComponent {
  constructor(public PermissionService: PermissionService) {
  }

  protected readonly Routes = Routes;
  protected readonly LocationRoutes = LocationRoutes;
}
