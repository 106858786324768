/**
 * Enum for pop-ups
 * @export
 * @enum {number}
 *
 * {@link None} - No pop-up
 * {@link EditItem} - Edit item pop-up
 * {@link EditSourceDetails} - Edit source details pop-up
 */
export enum PopUps {
  None = 0,
  EditItem = 1,
  EditSourceDetails = 2,
}
