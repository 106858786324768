import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "./user.service";
import {AuthenticationService} from "./authentication.service";
import {Roles} from "./x-enums/roles";
import {Routes} from "../enums/routes";
import {UrlHelper} from "../helpers/url-helper";
import {KeyboardService} from "../_services/keyboard.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private keyboardService: KeyboardService
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.keyboardService.isOptionPressed) {
      window.open(state.url, '_blank');
      this.keyboardService.isOptionPressed = false;
      return false;
    }

    const user = this.authenticationService.userValue;
    if (user) {
      const { roles } = route.data;
      const { deactivated } = route.data;
      if (deactivated && deactivated == true) {
        if (user.permissions.includes(Roles.Employee)) {
          this.authenticationService.logout();
        } else {
          this.router.navigate(['/']);
        }
        return false;
      }

      if (
        (roles && !roles.includes(Roles.Employee) && user.permissions.includes(Roles.Employee)) ||
        (!roles && user.permissions.includes(Roles.Employee))
      ) {
        this.router.navigate(['/'+Routes.EmployeePanel]);
        return false;
      }
      if ( roles && roles.includes(Roles.Employee) && !user.permissions.includes(Roles.Employee)) {
        this.router.navigate(['/']);
        return false;
      }

      if ( roles && roles.includes(Roles.Owner) && user.permissions.includes(Roles.Owner)) return true;
      if ( roles && !roles.includes(Roles.Owner) && user.permissions.includes(Roles.Admin)) return true;
      if ( roles && !roles.every((x: string) => user.permissions.includes(x))) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/login'], {queryParams: {returnUrl:state.url}});
    return false;
  }

}
