import {ShardNl} from "../../../models/admin_owner/shard/shard-nl";
import {HelpType} from "./help-type";
import {AuthUnit} from "./auth-unit";

export class ShardActiveMapper {
  shard: ShardNl;
  active: boolean = false;

  constructor(shard: ShardNl) {
    this.shard = shard;
  }
}

export class HelpTypeActiveMapper {
  type: HelpType;
  active: boolean = false;

  constructor(type: HelpType) {
    this.type = type;
  }
}

export class TelegramChannelConfiguration {
  channel: AuthUnit;
  shards: ShardActiveMapper[] = [];
  types: HelpTypeActiveMapper[] = [
    new HelpTypeActiveMapper(HelpType.Cvd),
    new HelpTypeActiveMapper(HelpType.Security),
    new HelpTypeActiveMapper(HelpType.Runner),
    new HelpTypeActiveMapper(HelpType.Change),
    new HelpTypeActiveMapper(HelpType.Technical),
    new HelpTypeActiveMapper(HelpType.TSE)
  ];
  interval: number = 15;
  statusUpdateEnabled: boolean = false;

  constructor(channel: AuthUnit, shards: ShardNl[]) {
    this.channel = channel;
    shards.forEach(shard => {
      this.shards.push(new ShardActiveMapper(shard));
    })
  }
  MapFetch(fetch: TelegramMessageSetter) {
    Object.keys(fetch.helpTypeActiveMapper).forEach(key => {
      let field = this.types.find(x => x.type == parseInt(key));
      if (field != undefined) field.active = true;
    })
    Object.keys(fetch.shardActiveMapper).forEach(key => {
      let field = this.shards.find(x => x.shard.id == key);
      if (field != undefined) field.active = true;
    })
    this.interval = fetch.interval;
    this.statusUpdateEnabled = fetch.statusUpdateEnabled;
  }
}

export class TelegramMessageSetter {
  shardActiveMapper: any = {};
  helpTypeActiveMapper: any = {};
  statusUpdateEnabled: boolean = false;
  interval: number = 15;
}
