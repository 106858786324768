import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../_auth/permission.service";
import {ModelBase} from "../../models/model-base";
import {SettingsPageComponent} from "../../x-components/settings/settings-page/settings-page.component";
import {SettingsInputElementModel} from "../../models/settings-input-element-model";
import {CompositionHttpService} from "../../http-requests/composition-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {CompositionDto} from "../../models/composition-dto";
import {Routes} from "../../enums/routes";
import {AlertLevel} from "../../enums/alert-level";
import {ObservableQueueElement} from "../../models/observable-queue-element";
import {concatMap, finalize, from} from "rxjs";
import {HttpPropertyChangeStatus} from "../../enums/http-property-change-status";
import {CompositionTypeDto} from "../../models/composition-type/composition-type-dto";
import {CompositionTypeHttpService} from "../../http-requests/composition-type-http.service";
import {VATRate, VATRateHelper} from "../../models/common/vat-rate";
import {Tuple} from "../../models/_generic/tuple";
import {EventDto} from "../../models/event/event-dto";
import {BusinessCaseTypeHelper} from "../../models/common/business-case-type";

@Component({
  selector: 'app-compostion-type-settings',
  templateUrl: '../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./compostion-type-settings.component.scss']
})
export class CompostionTypeSettingsComponent extends SettingsPageComponent {

  public redirectRoute: string = Routes.CompositionTypes;

  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newCompositionTypeName: new SettingsInputElementModel(),
    newVat: new SettingsInputElementModel(),
    newBusinessCaseType: new SettingsInputElementModel()
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public compositionTypeHttpService: CompositionTypeHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newCompositionTypeName.propertyTitle = "Name";
    this.settingsInputElementModel.newCompositionTypeName.requiredPermission = PermissionService.Inventory_CompositionType_ChangeName();


    this.settingsInputElementModel.newVat.propertyTitle = "MwSt. in %";
    this.settingsInputElementModel.newVat.inputType = "dropdown";
    // set items
    VATRateHelper.GetRates.forEach(rate => {
      this.settingsInputElementModel.newVat.items.push(new Tuple<any, any>(rate, (VATRateHelper.GetValue(rate) * 100).toFixed(2)))
    })
    this.settingsInputElementModel.newVat.requiredPermission = PermissionService.Inventory_CompositionType_SetValue();

    this.settingsInputElementModel.newBusinessCaseType.propertyTitle = "Geschäftsvorfall";
    this.settingsInputElementModel.newBusinessCaseType.inputType = "dropdown";
    // set items
    BusinessCaseTypeHelper.GetBusinessCases.forEach(bCase => {
      this.settingsInputElementModel.newBusinessCaseType.items.push(new Tuple<any, any>(bCase, BusinessCaseTypeHelper.GetName(bCase)));
    })
    this.settingsInputElementModel.newBusinessCaseType.requiredPermission = PermissionService.Inventory_CompositionType_SetValue();


    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_CompositionType_Delete());
  }

  public model?: CompositionTypeDto;

  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.compositionTypeHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Artikel-Kategorie gelöscht", "Objekt Kategorie wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };


  @Input() loadInputModel: Function = (id: string) => {
    this.compositionTypeHttpService.get(id).subscribe((model: CompositionTypeDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompositionTypeName.originalPropertyValue = this.settingsInputElementModel.newCompositionTypeName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newVat.originalPropertyValue = this.settingsInputElementModel.newVat.changeableProperties.newPropertyValue = this.model?.vatRate;
      this.settingsInputElementModel.newBusinessCaseType.originalPropertyValue = this.settingsInputElementModel.newBusinessCaseType.changeableProperties.newPropertyValue = this.model?.businessCaseType;
      this.updateElements()
    });
  };

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveValue("VATRate", this.settingsInputElementModel.newVat))
    this.requiresChanges.push(this.saveValue("BusinessCaseType", this.settingsInputElementModel.newBusinessCaseType))
    // this.requiresChanges.push(this.saveVat())

    if (!this.changeRequestRequired()) return;


    this.executeQueueWrapper();

  };

  saveVat(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newVat.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newVat.changeableProperties.newPropertyValue === this.settingsInputElementModel.newVat.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionTypeHttpService.changeVat(this.model.id, this.settingsInputElementModel.newVat.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompositionTypeDto) => {
      this.model = model;
      this.settingsInputElementModel.newVat.originalPropertyValue = this.settingsInputElementModel.newVat.changeableProperties.newPropertyValue = this.model?.vatRate;
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der MwSt.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveValue(name: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionTypeHttpService.setValue(this.model.id, name, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompositionTypeDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Eigenschaft.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }


  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionTypeName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionTypeName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCompositionTypeName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionTypeHttpService.changeName(this.model.id, this.settingsInputElementModel.newCompositionTypeName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompositionTypeDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompositionTypeName.originalPropertyValue = this.settingsInputElementModel.newCompositionTypeName.changeableProperties.newPropertyValue = this.model?.name
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

}
