import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {LegalAccountState} from "../../legal/_models/_enums/legal-account-state";
import {ServerEndpoints} from "../../../server.endpoints";
import {ShardDto} from "../../../models/admin_owner/shard/shard-dto";
import {CompanyDto} from "../../../models/admin_owner/company/company-dto";

@Injectable({
  providedIn: 'root'
})
export class FiskalyHttpService {
  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  isConfigured(): Observable<LegalAccountState> {
    return this.httpClient.get<LegalAccountState>(`${this.baseUrl}${ServerEndpoints.Management_Fiskaly_IsConfigured}`);
  }

  setCredentials(key: string, secret: string): Observable<LegalAccountState> {
    return this.httpClient.put<LegalAccountState>(`${this.baseUrl}${ServerEndpoints.Management_Fiskaly_SetCredentials}`, {
      name: key,
      password: secret
    })
  }

  upsertShard(id: string): Observable<ShardDto> {
    return this.httpClient.post<ShardDto>(`${this.baseUrl}${ServerEndpoints.Management_Fiskaly_UpsertShard}/${id}`, {});
  }

  upsertCompany(id: string): Observable<CompanyDto> {
    return this.httpClient.post<CompanyDto>(`${this.baseUrl}${ServerEndpoints.Management_Fiskaly_UpsertCompany}/${id}`, {});
  }
}
