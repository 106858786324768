import {IModelBase} from "../i-model-base";

export class ReplaceDtoElementModel<T> {
  public OldElement: T | undefined;
  public NewElement: T | undefined;

  constructor(oldElement: T | undefined=undefined, newElement: T | undefined =undefined) {
    this.OldElement = oldElement;
    this.NewElement = newElement;
  }
}
