<div *ngIf="visible && (useRelativeY || useRelativeX)" class="w-full" [style.height.px]="padding"></div>
<div #popup class="absolute w-64 transition-all bg-white shadow-lg rounded-lg z-10" id="eaPopup"
     [style.top.px]="positionY"
     [style.left.px]="positionX"
     [ngClass]="{'scale-0': !visible}">
  <div *ngIf="side == Side.Right"
       class="absolute -z-10 h-[20px] w-[20px] top-[20px] -left-[10px] rotate-45 bg-white shadow-lg"></div>
  <div *ngIf="side == Side.Left"
       class="absolute -z-10 h-[20px] w-[20px] top-[20px] -right-[10px] rotate-45 bg-white shadow-lg"></div>
  <div class="flex flex-col w-full p-2">
    <span *ngIf="!EmployeeAttributeService.Entities || !employee">Fehler beim Laden aufgetreten.</span>
    <span *ngIf="EmployeeAttributeService.Entities && EmployeeAttributeService.Entities.length == 0 && employee">Keine Attribute gefunden.</span>
    <div class="flex-col w-full" *ngIf="EmployeeAttributeService.Entities && employee">
      <div class="flex">
        <span class="text-sm font-bold">Attribute</span>
        <a class="ml-auto text-sm select-none text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
           id="eaEdit" (click)="editMode = true"
           *ngIf="!editMode && employee && PermissionService.CheckPermission(PermissionService.Company_EmployeeAttribute_SetEmployeeAttributes())">Bearbeiten</a>
        <a class="ml-auto text-sm select-none text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
           id="eaDisEdit" (click)="editMode = false"
           *ngIf="editMode && employee && PermissionService.CheckPermission(PermissionService.Company_EmployeeAttribute_SetEmployeeAttributes())">Fertig</a>
      </div>
      <div class="mt-1">
        <div class="inline-flex w-full" *ngFor="let employeeAttribute of EmployeeAttributeService.Entities">
          <input class="mr-2 cursor-pointer disabled:pointer-events-none" [disabled]="!editMode"
                 (ngModelChange)="setEmployeeAttributes([employeeAttribute.id], $event)"
                 [id]="prefix + '_' + employeeAttribute.id" [name]="employeeAttribute.id" type="checkbox"
                 [ngModel]="containsAttribute(employeeAttribute.id) && employee">
          <label class="select-none cursor-pointer text-ellipsis truncate pr-1"
                 [ngClass]="{'pointer-events-none': !editMode}"
                 [for]="prefix + '_' + employeeAttribute.id">{{ employeeAttribute.name }}
            <span class="font-light" *ngIf="employeeAttribute.shortName != ''">({{ employeeAttribute.shortName }}
              )</span>
          </label>
          <div class="ml-auto flex"
               *ngIf="PermissionService.CheckPermission(PermissionService.Company_EmployeeAttribute_Get())">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-gear-wide-connected text-gray-500 hover:text-gray-700 cursor-pointer place-self-center"
                 viewBox="0 0 16 16"
                 (click)="redirectToSettings(employeeAttribute.id)">
              <path
                d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
            </svg>
          </div>
        </div>
        <form class="flex relative w-full mt-1"
              *ngIf="editMode && PermissionService.CheckPermission(PermissionService.Company_EmployeeAttribute_Add())">
          <input name="editNewName" [(ngModel)]="editNewName" type="text"
                 class="w-full border-2 border-gray-300 rounded-lg px-2 py-1 text-sm" placeholder="Neues Attribut">
          <button type="submit" (click)="addEmployeeAttribute($event)"
                  class="absolute place-self-center right-1 text-green-600 hover:text-green-800">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus"
                 viewBox="0 0 16 16">
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
