import {Injectable} from '@angular/core';
import {DtoBaseService} from "../../../../_services/dto-services/dto-base.service";
import {EmployeeAttributeDto} from "../../x-models/employee-attribute-dto";
import {EmployeeAttributeHttpService} from "../../../x-components/x-services/employee-attribute-http.service";
import {Observable} from 'rxjs';
import {DtoServices} from 'src/app/_services/dto-services/_enums/dto-services';
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {DtoSignalRService} from "../../../../_services/dto-services/dto-signal-r.service";

@Injectable({
  providedIn: 'root'
})
export class EmployeeAttributeService extends DtoBaseService<EmployeeAttributeDto, EmployeeAttributeHttpService> {
  public Identifier: DtoServices = DtoServices.EmploymentAttributeService;
  FetchAll(all: boolean): Observable<EmployeeAttributeDto[]> {
      return this.HttpService.list();
  }
  FetchById(id: string, all: boolean): Observable<EmployeeAttributeDto> {
    return this.HttpService.get(id);
  }
  Add(payload: any): Observable<EmployeeAttributeDto> {
    return this.HttpService.add(payload.Name, payload["EmployeeIds"] as string[]);
  }
  GetId(entity: EmployeeAttributeDto): string {
    return entity.id;
  }

  constructor(employeeAttributeHttpService: EmployeeAttributeHttpService,
              globalAlertService: GlobalAlertService,
              dtoSignalRService: DtoSignalRService) {
    super(employeeAttributeHttpService, globalAlertService, dtoSignalRService);
    this.Initialize();
  }
}
