import {Component, ElementRef, ViewChild} from '@angular/core';
import {ItemBase} from "../item-base";

@Component({
  selector: 'app-navbar-item-small',
  templateUrl: './navbar-item-small.component.html',
  styleUrls: ['./navbar-item-small.component.scss']
})
export class NavbarItemSmallComponent extends ItemBase {
  @ViewChild('item') item!: ElementRef;
}
