import {CookieType} from "./x-enums/cookie-type";

export class CookieBase {
  id: CookieType = CookieType.None;
  enabled: boolean = false;
  constructor(id: CookieType, enabled: boolean = false) {
    this.id = id;
    this.enabled = enabled;
  }
}

export class CookieSetting extends CookieBase {
  name: string = "";
  description: string = "";
  forced: boolean = false;
  constructor(id: CookieType, name: string, description: string, enabled: boolean = false) {
    super(id, enabled);
    this.name = name;
    this.description = description;
  }
}
