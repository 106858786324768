import {Component, Inject, OnInit} from '@angular/core';
import {CategoryHttpService} from "../http-requests/category-http.service";
import {CategoryDto} from "../models/category-dto";
import {HttpErrorResponse} from "@angular/common/http";
import {PermissionService} from "../_auth/permission.service";
import {Routes} from "../enums/routes";
import {Router} from "@angular/router";
import {GlobalAlertService} from "../_services/global-alert.service";
import {AlertLevel} from "../enums/alert-level";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  public categories?: CategoryDto[] = undefined;
  public newCategoryName: string = "";
  public error?: HttpErrorResponse;
  public inverse: boolean = false;
  public field: string = "";

  constructor(private categoryHttpService: CategoryHttpService, @Inject('BASE_URL') baseUrl: string,
              public PermissionService: PermissionService, private router: Router,
              private globalAlertService: GlobalAlertService) {

  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.CategorySettings], {queryParams: {id: id}});
  }


  AddCategory(): void {
    this.categoryHttpService.add(this.newCategoryName).subscribe(x => {
      if (this.categories == undefined) this.categories = []
      this.categories.splice(0, 0, x);
      this.globalAlertService.createAlertBannerModel("Erfolgreich hinzugefügt", `Die Kategorie ${x.name} wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
      //this.categories.push(x);
      this.newCategoryName = "";
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Hinzufügen der Kategorie ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  RemoveCategory(category: CategoryDto): void {
    let confirmation = confirm("Folgende Kategorie wirklich löschen:\n" + category.name + "\n" + category.id);
    if (!confirmation) return;
    this.categoryHttpService.delete(category.id).subscribe(x => {
      this.categories!.forEach((x, i) => {
        if (x == category) {
          this.categories!.splice(i, 1);
          return;
        }
      })
      this.globalAlertService.createAlertBannerModel("Erfolgreich gelöscht", `Die Kategorie ${category.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Die Kategorie ${category.name} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SortBy(field: string) {
    if (this.categories == undefined) this.categories = []
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "name":
        this.categories.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
        break;
      case "created":
        this.categories.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
    }
    if (this.inverse) this.categories.reverse();
  }

  ngOnInit(): void {
    this.categoryHttpService.list().subscribe(result => {
      this.categories = result;
    }, error => console.error(error));
  }

  t_error() {
    throw new Error("Sentry Test Error");
  }
}
