import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss']
})
export class AutomationComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }
}
