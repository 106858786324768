import {Component, OnInit} from '@angular/core';
import {PermissionService} from "../../../_auth/permission.service";
import {EmployeeHttpService} from "../x-http-requests/employee-http.service";
import {EmployeeDto} from "../x-models/employee-dto";
import {HttpErrorResponse} from "@angular/common/http";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";
import {ActivatedRoute, Router} from "@angular/router";
import {DownloadService} from "../../x-components/x-services/download.service";
import {DownloadInformation} from "../../x-components/x-models/download-information";
import {PasswordService} from "../../../_services/password.service";
import {PlatformScannerService} from "../../../_services/platform-scanner.service";
import {Platform} from "../../../enums/platform";
import {SocialTypes} from "../../../x-models/x-enums/social-types";
import {EmploymentRelationshipDto} from "../x-models/employment-relationship-dto";
import {EventDto} from "../../../models/event/event-dto";
import {EmployeeService} from "../_services/_dto-services/employee.service";
import {EmploymentRelationshipService} from "../_services/_dto-services/employment-relationship.service";
import {Sorting} from "../../../x-models/x-enums/sorting";
import {Sortable} from "../../../_abstracts/sortable";
import {ServerEndpoints} from "../../../server.endpoints";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends Sortable implements OnInit {
  constructor(public PermissionService: PermissionService,
              private employeeHttpService: EmployeeHttpService,
              protected EmployeeService: EmployeeService,
              protected EmploymentRelationshipService: EmploymentRelationshipService,
              private globalAlertService: GlobalAlertService,
              private router: Router,
              private downloadService: DownloadService,
              private passwordService: PasswordService,
              public platformScannerServices: PlatformScannerService,
              private route: ActivatedRoute) {
    super();
    this.Inverse = false;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.queryParams.subscribe(params => {
      this.relationshipId = params.employmentRelationship ?? "";
    })

    this.EmployeeService.EntitiesChanged.subscribe({
      next: x => {
        this.Filter(this.filter_text);
        if (this.popupEmployee != undefined) {
          this.popupEmployee = this.EmployeeService.GetById(this.popupEmployee.id);
        }
      }
    })

    this.EmploymentRelationshipService.EntitiesChanged.subscribe({
      next: value => {
        if (this.relationshipId != "") {
          this.relationship = this.EmploymentRelationshipService.GetById(this.relationshipId);
          this.Filter(this.filter_text)
        }
      }
    })
  }

  ngOnInit(): void {
    // this.EmployeeService.LoadData();
  }

  error?: HttpErrorResponse;
  //employees: EmployeeDto[] = [];
  filtered_employees: EmployeeDto[] = [];
  filter_text: string = "";

  employee_firstname: string = "";
  employee_lastname: string = "";
  employee_email: string = "";
  employee_email_reset: boolean = true;

  downloadInformation: DownloadInformation = new DownloadInformation();

  IsMobile(): boolean {
    let platform = this.platformScannerServices.getPlatform();
    return platform == Platform.ChromeMobile || platform == Platform.SafariMobile || platform == Platform.OtherMobile;
  }

  OrderBy(sortedBy: Sorting, inverse: boolean) {
    this.SortedBy = sortedBy;
    this.Inverse = inverse;

    this.filtered_employees = this.filtered_employees.sort((a, b) => {
      switch (this.SortedBy) {
        case Sorting.Name: {
          return this.GetNameSorting(a, b);
        }
        case Sorting.Date: {
          return this.GetLastShiftSorting(a, b, this.Inverse);
        }
        default: {
          return this.GetNameSorting(a, b);
        }
      }
    })

    if (inverse) this.filtered_employees = this.filtered_employees.reverse();
  }

  GetNameSorting(a: EmployeeDto, b: EmployeeDto, factor: number = 1): number {
    if (this.GetFullName(a, true) < this.GetFullName(b, true)) return -1 * factor;
    if (this.GetFullName(a, true) > this.GetFullName(b, true)) return factor;
    return 0;
  }

  GetLastShiftSorting(a: EmployeeDto, b: EmployeeDto, inverse: boolean): number {
    let aDate = a.lastShift ?? new Date(Date.UTC(2200, 1, 1));
    let bDate = b.lastShift ?? new Date(Date.UTC(2200, 1, 1));
    if (aDate < bDate) return -1;
    if (aDate > bDate) return 1;
    return this.GetNameSorting(a, b, inverse ? -1 : 1);
  }

  GetFullName(employee: EmployeeDto, lowerCase: boolean = false): string {
    if (lowerCase) return `${employee.firstName} ${employee.lastName}`.toLowerCase();
    return `${employee.firstName} ${employee.lastName}`;
  }


  Verify(id: string, type: SocialTypes) {
    this.employeeHttpService.verify(id, type).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Erfolg", "Verifizierungsanfrage erfolgreich verschickt.", AlertLevel.success, 2000);
      this.globalAlertService.show()
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Beim Verschicken der Anfrage ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  Add() {
    this.employeeHttpService.add(this.employee_firstname, this.employee_lastname, this.employee_email_reset, undefined, undefined, this.employee_email)
      .withLoadingIndicator()
      .subscribe({
        next: x => {
          //this.employees.splice(0,0,x);
          this.EmployeeService.Replace(x);
          this.Filter(this.filter_text);
          this.globalAlertService.createAlertBannerModel("Erfolgreich hinzugefügt", `${this.employee_firstname} ${this.employee_lastname} wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
          this.globalAlertService.show();
          this.employee_lastname = "";
          this.employee_firstname = "";
          this.employee_email = "";
          this.FilterTriggered()
        },
        error: err => {
          console.error(err);
          this.globalAlertService.createAlertBannerModel("Fehler", `Beim Hinzufügen ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
          this.globalAlertService.show();
        }
      })
  }

  Delete(employee: EmployeeDto) {
    let confirmation = confirm("Folgenden Mitarbeitenden sicher löschen:\n" + `${employee.firstName} ${employee.lastName}` + "\n" + employee.id);
    if (!confirmation) return;
    this.employeeHttpService.delete(employee.id).subscribe(x => {
      this.EmployeeService.Remove(employee.id);
      // this.employees.splice(this.employees.indexOf(employee), 1);
      this.Filter(this.filter_text);
      this.globalAlertService.createAlertBannerModel("Löschen erfolgreich", `${employee.firstName} ${employee.lastName} wurde erfolgreich entfernt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `${employee.firstName} ${employee.lastName} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  LoadData(): void {
    //this.employeeHttpService.list(this.relationshipId).subscribe(x => {
    //  this.error = undefined;
    //  this.employees = x;
    //  this.AssignCopy();
    //}, error => {
    //  console.error(error);
    //  this.error = error;
    //})
  }

  Download(): void {
    this.downloadService.FileDownload("/Company/Employee/Download", this.downloadInformation);
  }

  FilterTriggered() {
    let filter = `${this.employee_firstname}${(this.employee_firstname != '' && this.employee_lastname != '' ? ' ' : '')}${this.employee_lastname}`;
    this.Filter(filter);
  }

  Filter(value: string) {
    this.filter_text = value;
    this.filtered_employees = Object.assign([], this.EmployeeService.Entities?.filter(x =>
      (value == "" || `${x.firstName} ${x.lastName}`.toLowerCase().indexOf(this.filter_text.toLowerCase()) > -1) &&
      (this.relationshipId == "" || x.lastEmploymentRelationship?.id == this.relationshipId) &&
      (this.employee_email == "" || (x.emailAddress?.toLowerCase().indexOf(this.employee_email.toLowerCase()) ?? -1) > -1)
    ))
    this.OrderBy(this.SortedBy, this.Inverse);
  }

  ResetFilter() {
    this.employee_firstname = "";
    this.employee_lastname = "";
    this.employee_email = "";
    this.employee_email_reset = true;

    this.Filter("");
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.CompanyEmployeeSettings], {queryParams: {id: id}});
  }

  LoadAllEmployees() {
    this.relationshipId = '';
    this.relationship = undefined;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {employmentRelationship: ""},
      queryParamsHandling: 'merge'
    })
  }

  NavigateToEmploymentRelationship(id: string | undefined) {
    if (!id) {
      this.LoadAllEmployees()
      return;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {employmentRelationship: id},
      queryParamsHandling: 'merge'
    })
  }

  employmentRelationships: EmploymentRelationshipDto[] | undefined;

  selectionMode: boolean = false;

  toggleSelectionMode() {
    this.selectionMode = !this.selectionMode;
  }

  allSelected: boolean = false;

  isAllSelected(): boolean {
    return this.filtered_employees.findIndex(x => !x.selected) == -1
  }

  isAnySelected(): boolean {
    return this.filtered_employees.findIndex(x => x.selected) > -1;
  }

  selectAll(state: boolean) {
    // if (this.employees != undefined) {
    //   this.employees.forEach(x => {
    //     x.selected = false;
    //   })
    //   this.filtered_employees.forEach(x => {
    //     x.selected = state;
    //   })
    // }
    if (this.EmployeeService.Entities != undefined) {
      this.EmployeeService.Entities.forEach(x => {
        x.selected = false;
      })
      this.filtered_employees.forEach(x => {
        x.selected = state;
      })
    }
  }

  employmentRelationshipSelectorVisible = false;
  selectedEmploymentRelationship: EmploymentRelationshipDto | undefined;

  SetEmploymentRelationship(relationship: EmploymentRelationshipDto | undefined) {
    this.selectedEmploymentRelationship = relationship;
  }

  downloadExtended = false;

  setDownloadExtended(state: boolean) {
    this.downloadExtended = state;
  }

  relationshipId: string = "";
  relationship: EmploymentRelationshipDto | undefined;

  mouseEvent?: MouseEvent;
  visible: boolean = false;

  popupEmployee: EmployeeDto | undefined;

  openPopup(e: any, employee: EmployeeDto) {
    this.popupEmployee = employee;
    this.mouseEvent = e;
    this.visible = true;
  }

  detailedSalesVisible: boolean = false;
  detailedSalesEvents: EventDto[] = [];

  detailedSalesCalculateTotal(event: EventDto): number {
    return event.orders.flatMap(x => x.compositions)
      .reduce((sum: number, current) =>
        sum += current.quantity * (current.compositionOverride?.price ?? current.composition.price), 0);
  }

  protected readonly Routes = Routes;

  setCanLogin(employee: EmployeeDto) {
    this.employeeHttpService.setCanLogin(employee.id, employee.canLogin).subscribe(x => {
      {
        this.EmployeeService.Replace(employee);
        this.Filter(this.filter_text);
      }
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Login konnte nicht geändert werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      employee.canLogin = !employee.canLogin;
    })
  }

  protected readonly Platform = Platform;
  protected readonly SocialTypes = SocialTypes;
  protected readonly Sorting = Sorting;
  protected readonly ServerEndpoints = ServerEndpoints;
}
