import {Component} from '@angular/core';
import {RouterLink, RouterOutlet} from "@angular/router";
import {AuthenticationService} from "../../_auth/authentication.service";
import {Routes} from "../../enums/routes";

@Component({
  selector: 'app-self-service',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink
  ],
  templateUrl: './self-service.component.html',
  styleUrl: './self-service.component.scss'
})
export class SelfServiceComponent {
  constructor(public AuthenticationService: AuthenticationService) {
  }

  protected readonly Routes = Routes;
}
