import {Injectable} from '@angular/core';
import {DtoBaseService} from "../../../../_services/dto-services/dto-base.service";
import {EmployeeDto} from "../../x-models/employee-dto";
import {EmployeeHttpService} from "../../x-http-requests/employee-http.service";
import {Observable} from 'rxjs';
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {DtoSignalRService} from "../../../../_services/dto-services/dto-signal-r.service";
import {DtoServices} from "../../../../_services/dto-services/_enums/dto-services";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends DtoBaseService<EmployeeDto, EmployeeHttpService> {
  public Identifier: DtoServices = DtoServices.EmployeeService;

  Add(payload: any): Observable<EmployeeDto> {
    return this.HttpService.add(payload.FirstName, payload.LastName, false)
  }

  FetchAll(all: boolean): Observable<EmployeeDto[]> {
    return this.HttpService.list("");
  }

  FetchById(id: string, all: boolean): Observable<EmployeeDto> {
    return this.HttpService.get(id);
  }

  GetId(entity: EmployeeDto): string {
    return entity.id;
  }

  constructor(employeeHttpService: EmployeeHttpService, globalAlertService: GlobalAlertService, dtoSignalRService: DtoSignalRService) {
    super(employeeHttpService, globalAlertService, dtoSignalRService);
    this.Initialize();
  }

  FetchByRelationshipId(relationshipId: string, all: boolean): Observable<EmployeeDto[]> {
    return this.HttpService.list(relationshipId);
  }

  GetSelected(): EmployeeDto[] | undefined {
    return this.Entities?.filter(e => e.selected);
  }

  GetSelectedIds(): string[] | undefined {
    return this.GetSelected()?.map(x => x.id);
  }
}
