<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Lieferanten</h1>
    </div>
  </div>
  <form class="flex py-4 w-full gap-4 items-end" name="new-employee"
        *ngIf="PermissionService.CheckPermission(PermissionService.Company_Supplier_Add())">
    <div class="grow">
      <label for="supplier-name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
      <div class="mt-2">
        <input [(ngModel)]="supplier_name" type="text" name="supplier-name" id="supplier-name"
               class="block h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
      </div>
    </div>
    <div class="shrink">
      <div class="mt-2">
        <input (click)="Add()" type="submit" name="submit" id="submit"
               class="px-4 block h-10 w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
      </div>
    </div>
  </form>
  <div class="-mx-4 mt-8 sm:-mx-0">
    <table class="min-w-full divide-y divide-gray-300">
      <thead>
      <tr>
        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Angelegt
          am
        </th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
          Telefonnummer
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let supplier of filtered_suppliers">
        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ supplier.name }}</td>
        <td
          class="hidden whitespace-nowrap px-3 py-2 text-sm text-gray-500 lg:table-cell">{{ (supplier.created) | date : "dd.MM.YYYY, HH:mm" }}
          Uhr
        </td>
        <td
          class="whitespace-nowrap px-3 py-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">
          <a href="tel:{{supplier.phone}}">{{ supplier.phone }}</a>
        </td>
        <td
          class="whitespace-nowrap px-3 py-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">
          <a href="mailto:{{supplier.email}}">{{ supplier.email }}</a>
        </td>
        <td class="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 inline-flex w-full">
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Company_Supplier_Get())"
             (click)="redirectToSettings(supplier.id)"
             class="cursor-pointer text-gray-600 hover:text-gray-800 place-self-center pr-2 !ml-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
                 viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
            </svg>
          </a>
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Company_Supplier_Delete())"
             (click)="Delete(supplier)" class="cursor-pointer text-red-600 hover:text-red-900 place-self-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
