<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->

<div class="mx-auto max-w-md sm:max-w-7xl px-3">
  <div>
    <div class="text-center grid place-content-center">
      <div class="grid place-content-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-building-gear"
             viewBox="0 0 16 16">
          <path
            d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z"/>
          <path
            d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm4.386 1.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
        </svg>
      </div>
      <h2 class="mt-2 text-base font-semibold leading-6 text-gray-900">Add Companies</h2>
      <p class="mt-1 text-sm text-gray-500" *ngIf="companies != undefined && companies.length < 1">You haven’t added any
        companies yet.</p>
    </div>
    <form class="mt-6 sm:flex sm:items-center" action="#">
      <label for="company_name" class="sr-only">Company Name</label>
      <div class="grid grid-cols-1 sm:flex-auto">
        <input [(ngModel)]="name" type="text" name="company_name" id="company_name"
               class="peer relative col-start-1 row-start-1 border-2 border-gray-200 rounded-2 px-2 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
               placeholder="Company Name">
        <div
          class="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-theme-primary-700"
          aria-hidden="true"></div>
      </div>
      <div class="sm:ml-4 sm:mt-0 sm:flex-shrink-0">
        <button (click)="AddCompany()" type="submit"
                class="block w-full rounded-md bg-theme-primary-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
          Add Company
        </button>
      </div>
    </form>
  </div>
  <div class="mt-10">
    <h3 class="text-sm font-medium text-gray-500">List of Companies</h3>
    <app-loading *ngIf="companies == undefined"></app-loading>
    <ul role="list" class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
      <li *ngFor="let company of filtered_companies">
        <button type="button"
                class="group flex w-full items-center justify-between space-x-3 rounded-lg border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-theme-primary-900 focus:ring-offset-2">
          <span (click)="redirectToSettings(company.id)"
                class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dash"
                 viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
            </svg>
          </span>
          <span [routerLink]="['/owner/shards']" [queryParams]="{ company: company.id }" queryParamsHandling="merge"
                class="flex min-w-0 flex-1 items-center space-x-3">
            <span class="block flex-shrink-0">
              <img class="h-10 w-10 rounded-full" src="./assets/images/icon_nobackground_md.png" alt="">
            </span>
            <div class="block min-w-0 flex-1">
              <span class="block truncate text-sm font-medium text-gray-900">{{ company.name }}</span>
              <span class="block truncate text-sm font-medium text-gray-500">{{ company.id }}</span>
            </div>
          </span>
          <span (click)="RemoveCompany(company)"
                class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dash"
                 viewBox="0 0 16 16">
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </span>
        </button>
        <div *ngIf="company.address;else missingAddress" class="flex flex-row space-x-4 text-sm mt-4">
          <span>Address:</span>
          <div class="flex flex-col fw-light">
            <span>{{ company.address.addressLine1 }}</span>
            <span>{{ company.address.addressLine2 }}</span>
            <span>{{ company.address.zip }} {{ company.address.city }}</span>
          </div>
        </div>
        <ng-template #missingAddress>
          <span class="text-sm font-bold text-red-600">Address missing!</span>
        </ng-template>
        <hr class="my-2">
        <div class="flex flex-row space-x-4 text-sm">
          <span>Integrations</span>
          <div class="flex flex-col fw-light">
            <div class="flex space-x-4">
              <span class="fw-bold">fiskaly: </span>
              <div *ngIf="hasIntegrationReference(company, IntegrationProvider.Fiskaly);else fiskalyRegisterItem"
                   class="flex flex-col">
                <span class="text-green-600">Registered</span>
                <a class="text-theme-primary-700 hover:text-theme-primary-900 fw-bold"
                   (click)="fiskalyRegister(company)">Click to update...</a>
              </div>
              <ng-template #fiskalyRegisterItem>
                <a (click)="fiskalyRegister(company)" class="text-yellow-600">Register now!</a>
              </ng-template>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <app-error-banner [(error)]="error"></app-error-banner>
</div>
