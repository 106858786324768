import {EmploymentRelationshipNl} from "./employment-relationship-nl";

export class EmployeeStatsDto {
  firstShift?: Date;
  hoursWorked?: number;
  salaryAdditions: number = 0;
  employmentRelationship?: EmploymentRelationshipNl
}

export class EmployeeStatsHelper {
  static getTotal(stats: EmployeeStatsDto): number {
    if (stats.employmentRelationship == undefined) return 0;
    return stats.employmentRelationship.salary * (stats.hoursWorked ?? 0) + stats.salaryAdditions;
  }
}
