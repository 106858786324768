import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export abstract class DtoBaseHttpService<TDto> {
  protected constructor(public BaseUrl: string, public HttpClient: HttpClient) {

  }

  abstract UrlPath: string;

  public Fetch(ids: string[], all: boolean = false): Observable<TDto[]> {
    return this.HttpClient.post<TDto[]>(this.BaseUrl + this.UrlPath + "/List" + "?all=" + all, ids);
  }
}
