export function dRound(this: number, decimals: number) {
  return Math.round(this * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function toCurrencyFraction(this: number) {
  if (Math.abs(this) < Math.pow(10, -6)) return 0;
  return this.dRound(2);
}

declare global {
  interface Number {
    toCurrencyFraction: typeof toCurrencyFraction;
    dRound: typeof dRound;
  }
}

(Number.prototype as any).toCurrencyFraction = toCurrencyFraction;
(Number.prototype as any).dRound = dRound;
