import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {EnvironmentSetting} from "../../owner/x-models/environment-setting";
import {Tuple} from "../../models/_generic/tuple";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class MigrationsAssistantHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {

  }

  GetPatches(): Observable<Tuple<string[], EnvironmentSetting[]>> {
    return this.httpClient.get<Tuple<string[], EnvironmentSetting[]>>(this.baseUrl + ServerEndpoints.Administration_MigrationAssistant_GetPatches);
  }

  RunPatch(patch: string, content: any): Observable<Object> {
    return this.httpClient.patch(this.baseUrl + "/administration/MigrationAssistant/" + patch, content);
  }
}
