export enum BusinessCaseType {
  Umsatz = 1,
  Pfand = 2,
  Rabatt = 20
}

export class BusinessCaseTypeHelper {
  private static names: any = {
    1: "Umsatz",
    2: "Pfand",
    20: "Rabatt"
  }

  public static GetName(value: BusinessCaseType): string {
    return this.names[value];
  }

  public static GetBusinessCases: BusinessCaseType[] = [BusinessCaseType.Umsatz, BusinessCaseType.Pfand, BusinessCaseType.Rabatt];
}
