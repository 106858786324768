import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {UserDto} from "../../models/admin_owner/user/user-dto";
import {AuthenticationService} from "../../_auth/authentication.service";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string, private authenticationService: AuthenticationService) {
  }

  list(): Observable<UserDto[]> {
    return this.httpClient.get<UserDto[]>(this.baseUrl + ServerEndpoints.Administration_User_List);
  }

  get(id: string): Observable<UserDto> {
    return this.httpClient.get<UserDto>(this.baseUrl + ServerEndpoints.Administration_User_Get + "?id=" + id);
  }

  add(name: string, password: string, admin: boolean = false, login: boolean = false): Observable<UserDto> {
    return this.httpClient.post<UserDto>(this.baseUrl + ServerEndpoints.Administration_User_Add, {
      "Name": name,
      "Password": password,
      "Admin": admin,
      "Login": login,
      "Shard": this.authenticationService.userValue?.shard.id,
      "Company": this.authenticationService.userValue?.company.id
    });
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Administration_User_Delete + "?id=" + id);
  }
}
