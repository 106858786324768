import {Component} from '@angular/core';
import {AuthenticationService} from "../../_auth/authentication.service";
import {Side} from "../../_modules/x-components/x-models/x-enums/side";
import {CHECK} from "@syncfusion/ej2-filemanager";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {UserHttpService} from "../../selfservice/x-http-requests/user-http.service";
import {AlertLevel} from "../../enums/alert-level";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  constructor(public AuthenticationService: AuthenticationService, private gas: GlobalAlertService, private userHttpService: UserHttpService) {
  }

  pattern = new RegExp('^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,32}$');
  upper = new RegExp('(?=.*[A-Z])');
  lower = new RegExp('(?=.*[a-z])');
  special = new RegExp('(?=.*[!@#$&*])');
  numbers = new RegExp('(?=.*[0-9])');

  old: string = "";
  password: string = "";
  confirmation: string = "";

  CheckUpper(): boolean {
    return this.upper.test(this.password);
  }

  CheckLower(): boolean {
    return this.lower.test(this.password);
  }

  CheckSpecial(): boolean {
    return this.special.test(this.password);
  }

  CheckNumbers(): boolean {
    return this.numbers.test(this.password);
  }

  CheckLength(): boolean {
    return this.password.length >= 8 && this.password.length <= 32
  }

  CheckAll(): boolean {
    return this.pattern.test(this.password);
  }

  Validate(): boolean {
    return this.password == this.confirmation && this.CheckAll() && this.old != this.password && this.old != '';
  }

  protected readonly Side = Side;
  protected readonly CHECK = CHECK;

  change() {
    if (!this.Validate()) {
      this.gas.createAndShow("Fehler", "Bitte überprüfen Sie Ihre Eingaben.", AlertLevel.error);
      return;
    }

    this.userHttpService.ChangePassword(this.old, this.password).subscribe({
      next: () => {
        {
          this.gas.createAndShow("Erfolg", "Das Passwort wurde erfolgreich geändert. Beim nächsten Login bitte das neue Passwort nutzen.", AlertLevel.success);
          this.old = "";
          this.password = "";
          this.confirmation = "";
        }
      },
      error: (err) => {
        console.error(err);
        this.gas.createAndShow("Fehler", "Beim Speichern des Passworts ist ein Fehler aufgetreten.", AlertLevel.error);
      }
    })
  }
}
