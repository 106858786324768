export class TimeUtilities {
  static toIsoFromString(date: string): string {
    var tzo = -(new Date()).getTimezoneOffset(), dif = tzo >= 0 ? "+" : "-",
      pad = function(num: number) {
        return (num < 10 ? "0" : "") + num;
      };
    return date + dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" + pad(Math.abs(tzo) % 60);
  }

  static localTimeToUtc(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }


  /*
  * Converts a date to an ISO string.
  * @param date: Date
   */

  static toIsoString(date: Date): string {

    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  static dateToString(date: Date | undefined): string {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    let nDate = new Date(date.getTime() - (offset * 60 * 1000));
    return nDate.toISOString().slice(0,16);
  }

  static dayToString(date: Date | undefined): string {
    if (!date) return "";
    const offset = date.getTimezoneOffset();
    let nDate = new Date(date.getTime() - (offset * 60 * 1000));
    return nDate.toISOString().slice(0,10);
  }

  static toFullDate(date: Date): Date {
    date.setHours(0,0,0,0);
    return date;
  }

  static formatAsTodayOrFullDate(date: Date | undefined, options: Intl.DateTimeFormatOptions = {day: '2-digit', month: 'long', year: 'numeric'}): string {
    if (date === undefined) return "";
    let today = new Date();
    if(date.getDate() == today.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()) {
      return "Heute";
    }
    return date.toLocaleDateString('default', options)
  }
}
