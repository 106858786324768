import {Component, EventEmitter, Input} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../_auth/permission.service";
import {ModelBase} from "../../models/model-base";
import {SettingsPageComponent} from "../../x-components/settings/settings-page/settings-page.component";
import {SettingsInputElementModel} from "../../models/settings-input-element-model";
import {CompositionHttpService} from "../../http-requests/composition-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {CompositionDto} from "../../models/composition-dto";
import {Routes} from "../../enums/routes";
import {AlertLevel} from "../../enums/alert-level";
import {ObservableQueueElement} from "../../models/observable-queue-element";
import {concatMap, finalize, from} from "rxjs";
import {HttpPropertyChangeStatus} from "../../enums/http-property-change-status";
import {EventDto} from "../../models/event/event-dto";

@Component({
  selector: 'app-compositions-settings-page',
  templateUrl: '../../x-components/settings/settings-page/settings-page.component.html',//'./items-settings-page.component.html',
  styleUrls: ['./compositions-settings-page.component.scss']
})
export class CompositionsSettingsPageComponent extends SettingsPageComponent {

  public redirectRoute: string = Routes.Compositions;
  public others = false;
  public type: string = "";

  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newCompositionName: new SettingsInputElementModel(),
    newCompositionPrice: new SettingsInputElementModel(),
    newCompositionOrder: new SettingsInputElementModel(),
    newPublicName: new SettingsInputElementModel()
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public compositionHttpService: CompositionHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newCompositionName.propertyTitle = "Name";
    this.settingsInputElementModel.newCompositionName.requiredPermission = PermissionService.Inventory_Composition_ChangeName();
    this.settingsInputElementModel.newCompositionName.order = 0;

    this.settingsInputElementModel.newPublicName.propertyTitle = "Öffentlicher Name";
    this.settingsInputElementModel.newPublicName.requiredPermission = PermissionService.Inventory_Composition_ChangePublicProperty();
    this.settingsInputElementModel.newPublicName.order = 1;

    this.settingsInputElementModel.newCompositionOrder.propertyTitle = "Reihenfolge";
    this.settingsInputElementModel.newCompositionOrder.requiredPermission = PermissionService.Inventory_Composition_SetOrder();
    this.settingsInputElementModel.newCompositionOrder.order = 2;

    this.settingsInputElementModel.newCompositionPrice.propertyTitle = "Preis pro Einheit";
    this.settingsInputElementModel.newCompositionPrice.requiredPermission = PermissionService.Inventory_Composition_PriceChange();
    this.settingsInputElementModel.newCompositionPrice.order = 3;

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_Composition_Delete());

    this.route.queryParams.subscribe(params => {
      this.type = params.type == undefined ? "" : params.type;
      this.redirectRoute = Routes.Compositions + "?other=false&type=" + this.type +
        "&vt=" + (params.vt == undefined ? "" : params.vt) +
        "&search=" + (params.search == undefined ? "" : params.search);
    })
  }

  public model?: CompositionDto;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.compositionHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Artikel gelöscht", "Artikel wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };


  @Input() loadInputModel: Function = (id: string) => {
    this.compositionHttpService.get(id).subscribe((model: CompositionDto) => {
      this.others = false;
      this.model = model;
      this.settingsInputElementModel.newCompositionName.originalPropertyValue = this.settingsInputElementModel.newCompositionName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newCompositionPrice.originalPropertyValue = this.settingsInputElementModel.newCompositionPrice.changeableProperties.newPropertyValue = this.model?.price
      this.settingsInputElementModel.newCompositionOrder.originalPropertyValue = this.settingsInputElementModel.newCompositionOrder.changeableProperties.newPropertyValue = this.model?.order
      this.updateElements()
      //this.model.tags.find(tag => {
      //  if(tag.other) {
      //    this.redirectRoute =  Routes.Compositions+"?other=false&type=" + this.type
      //    this.others = true;
      //  }
      //})
    });
  };

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.savePrice())
    this.requiresChanges.push(this.saveOrder())
    this.requiresChanges.push(this.savePublicProperty("PublicName", this.settingsInputElementModel.newPublicName));

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();

  };

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCompositionName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionHttpService.changeName(this.model.id, this.settingsInputElementModel.newCompositionName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompositionDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompositionName.originalPropertyValue = this.settingsInputElementModel.newCompositionName.changeableProperties.newPropertyValue = this.model?.name
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  savePublicProperty(name: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionHttpService.changePublicProperty(this.model.id, name, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompositionDto) => {

      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des öffentlichen Eigenschaft.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;

    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveOrder(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionOrder.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionOrder.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCompositionOrder.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionHttpService.setOrder(this.model.id, this.settingsInputElementModel.newCompositionOrder.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompositionDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompositionOrder.originalPropertyValue = this.settingsInputElementModel.newCompositionOrder.changeableProperties.newPropertyValue = this.model?.order
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Reihenfolge.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  savePrice() {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionPrice.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompositionPrice.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCompositionPrice.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let newPrice = this.parseToNumber(this.settingsInputElementModel.newCompositionPrice.changeableProperties.newPropertyValue);
    if (newPrice === undefined) {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein. Fehlerbehaftete Eingabe: " + this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue, AlertLevel.error, 5000);
      this.globalAlertService.show();
      return HttpPropertyChangeStatus.NotChanged;
    }

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.compositionHttpService.changePrice(this.model.id, newPrice);
    observableQueueElement.callback = (model: CompositionDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompositionPrice.originalPropertyValue = this.settingsInputElementModel.newCompositionPrice.changeableProperties.newPropertyValue = this.model?.price
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Preises.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };


    this.observableQueue.push(observableQueueElement);
    return HttpPropertyChangeStatus.Changed;
  }

}
