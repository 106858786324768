import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {UserJwt} from "../../models/admin_owner/user/user-jwt";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class SetupHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {

  }

  isConfigured(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.baseUrl + ServerEndpoints.Management_Setup_IsConfigured);
  }

  doConfigure(username: string, password: string, company: string, shard: string): Observable<UserJwt> {
    return this.httpClient.post<UserJwt>(this.baseUrl + ServerEndpoints.Management_Setup_Configure, {
      "Username": username,
      "Password": password,
      "Company": company,
      "Shard": shard,
    })
  }
}
