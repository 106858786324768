<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Telegram</h1>
    </div>
  </div>
  <div class="shadow-lg rounded-lg pt-2 pb-3 px-3 mt-4"
       *ngIf="PermissionService.CheckPermission(PermissionService.Company_Telegram_AddBot())">
    <p class="font-lg">Neuen Telegram-Bot hinzufügen</p>
    <form class="inline-flex pt-2 w-full">
      <div class="w-full">
        <label for="bot-token" class="sr-only">Bot Token</label>
        <input #botToken type="text" name="bot-token" id="bot-token"
               class="relative px-2 block w-full rounded-none rounded-l-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
               placeholder="Token">
      </div>
      <button type="submit" (click)="AddBot()"
              class="shrink relative -ml-px inline-flex text-center items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-200">
        Hinzufügen
      </button>
    </form>
    <div class="pt-2 text-sm">
      <p class="fw-bold">Anleitung - Befehle anklicken zum Kopieren</p>
      <ol class="list-decimal pl-4">
        <li>Chat mit <a class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
                        href="https://web.telegram.org/k/#@BotFather" target="_blank">&#64;BotFather</a> auf Telegram öffnen
        </li>
        <li>Befehl <a class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
                      (click)="Copy('/newbot')">/newbot</a> eingeben
        </li>
        <li>Namen für den Bot eingeben</li>
        <li>Benutzernamen des Bots eingeben</li>
        <li>Token kopieren und oben einfügen</li>
        <li>Bot in Gruppe einfügen und Gruppenlink auf <a
          class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer" href="https://web.telegram.org/a"
          target="_blank">Telegram Web</a> kopieren und als Channel ID verwenden
        </li>
      </ol>
    </div>

  </div>
  <div class="w-full grid grid-cols-1 md:grid-cols-2 mt-3 gap-2">
    <div class="bg-white shadow-lg rounded-lg px-3 py-2">
      <p class="text-lg fw-bold">Verfügbare Bots</p>
      <hr class="my-2"/>
      <div class="inline-flex w-full" *ngFor="let bot of bots">
        <input [(ngModel)]="selectedBot" type="radio" name="bot-selector" id="bot_{{bot.id}}" [value]="bot">
        <label class="pl-2 truncate text-ellipsis" for="bot_{{bot.id}}">{{ bot.name }}</label>
      </div>
    </div>
    <div class="bg-white shadow-lg rounded-lg px-3 py-2" *ngIf="selectedBot">
      <div class="grid grid-cols-2">
        <p class="text-lg fw-bold">Optionen</p>
        <p *ngIf="PermissionService.CheckPermission(PermissionService.Company_Telegram_DeleteBot())"
           (click)="RemoveBot()"
           class="text-sm text-red-600 hover:text-red-800 cursor-pointer justify-self-end place-self-center">&minus;&nbsp;Löschen</p>
      </div>
      <hr class="my-2"/>
      <div class="flex flex-wrap w-full">
        <p class="shrink pr-2">Informationen:</p>
        <div class="grid grid-cols-1 grow">
          <p class="fw-bold truncate text-ellipsis">{{ selectedBot.name }}</p>
          <p class="truncate text-ellipsis text-xs">{{ selectedBot.id }}</p>
        </div>
      </div>
    </div>
    <div class="hidden md:flex"></div>
    <div class="bg-white shadow-lg rounded-lg px-3 py-2 w-full" *ngIf="selectedBot">
      <div class="grid grid-cols-2">
        <div class="flex w-full">
          <p class="text-lg fw-bold place-self-center">Channels</p>
        </div>
        <div
          class="relative text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer justify-self-end place-self-center">
          <div class="w-full">
            <label for="channel" class="sr-only">Bot Token</label>
            <input #channel type="text" name="channel" id="channel"
                   class="relative pl-2 pr-7 block w-full rounded-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                   placeholder="Channel ID">
          </div>
          <div (click)="AddChannel()" class="absolute top-0 right-0 h-full flex pr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="place-self-center bottom-0 top-0 right-0 bi bi-plus-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </div>
        </div>
      </div>
      <hr class="my-2"/>
      <div class="grid grid-cols-1 pb-2" *ngFor="let channel of selectedBot.linkedAuthUnits">
        <div class="flex w-full">
          <p class="grow cursor-pointer hover:fw-bold hover:text-theme-primary-700"
             (click)="EditChannel(channel)">{{ channel.name }}</p>
          <span *ngIf="PermissionService.CheckPermission(PermissionService.Company_Telegram_DeleteChannel())"
                (click)="DeleteChannel(channel)" class="text-red-600 hover:text-red-800 cursor-pointer pr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle"
                 viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </span>
        </div>
        <span class="w-full text-xs truncate text-ellipsis">{{ channel.id }}</span>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="channelConfig && PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramViewChannelConfiguration())"
  class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto lg:pl-72">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-3 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
        <div>
          <div class="grid grid-cols-1 content-center">
            <div class="flex">
              <p class="fw-bold text-lg place-self-center">Channel Konfiguration</p>
            </div>
          </div>
        </div>
        <hr class="my-2"/>
        <div class="grid grid-cols-1 md:grid-cols-2 w-full">
          <div>
            <p class="pb-2">Shards</p>
            <div class="flex" *ngFor="let shard of channelConfig.shards">
              <input
                [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
                [class.opacity-50]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
                [(ngModel)]="shard.active" class="place-self-center" type="checkbox" name="checkbox_{{shard.shard.id}}"
                id="checkbox_{{shard.shard.id}}">
              <label class="place-self-center select-none pl-2 text-sm"
                     for="checkbox_{{shard.shard.id}}">{{ shard.shard.name }}</label>
            </div>
          </div>
          <div>
            <p class="pb-2 md:w-full md:text-end pt-3 md:!pt-0">Art d. Hilfe</p>
            <div class="flex flex-row-reverse md:!flex-row w-full" *ngFor="let type of channelConfig.types">
              <label class="place-self-center select-none pr-2 pl-2 md:!pl-0 text-sm w-full md:text-end"
                     for="type_{{type.type}}">{{ helpTypeDescription.getDescription(type.type) }}</label>
              <input
                [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
                [class.opacity-50]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
                [(ngModel)]="type.active" class="place-self-center bg-red-200" type="checkbox" name="type_{{type.type}}"
                id="type_{{type.type}}">
            </div>
          </div>
        </div>
        <hr class="my-2"/>
        <div class="grid grid-cols-1 text-sm w-full gap-1">
          <label for="status-update" class="place-self-center grid-cols-2 select-none text-sm fw-bold w-full pb-1">Verkaufsbericht</label>
          <div class="grid grid-cols-12 justify-items-start">
            <input
              [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
              [class.opacity-50]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
              type="checkbox" [(ngModel)]="channelConfig.statusUpdateEnabled" id="status-update" name="status-update"
              class="col-span-1 place-self-center bg-red-200 justify-self-start">
            <label for="status-update"
                   class="place-self-center col-span-11 justify-self-start">{{ channelConfig.statusUpdateEnabled ? "Aktiviert" : "Deaktiviert" }}</label>
          </div>
          <div class="grid grid-cols-12 justify-items-start"
               [ngClass]="{'opacity-25 pointer-events-none': !channelConfig.statusUpdateEnabled}">
            <div class="inline-flex col-start-2 col-span-11">
              <label for="status-update" class="pr-2 place-self-center select-none">Alle</label>
              <input
                [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
                [class.opacity-50]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
                #timerMinutes [(ngModel)]="channelConfig.interval" type="number" name="timer-minutes" id="timer-minutes"
                class="place-self-center relative pl-2 pr-7 block w-full text-center rounded-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                placeholder="15">
              <label for="status-update" class="pl-2 place-self-center select-none">Minuten.</label>
            </div>
          </div>

        </div>
        <div class="mt-5 sm:mt-6 sm:grid grid-cols-1 sm:grid-cols-2 sm:gap-3">
          <button (click)="channelConfig = undefined;" type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0">
            Cancel
          </button>
          <button
            *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_TelegramEditChannelConfiguration())"
            (click)="SaveChannel()" type="button"
            class="mt-2 sm:!mt-0 inline-flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
