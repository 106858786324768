import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PasswordEntryComponent} from './password-entry/password-entry.component';
import {SDatePipe} from './pipes/s-date.pipe';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CrossCheckDisplayComponent} from './cross-check-display/cross-check-display.component';
import {SmallCalendarComponent} from './small-calendar/small-calendar.component';
import {LocalToUtcDirective} from './x-directives/local-to-utc.directive';
import {DownloadComponent} from "./download/download.component";
import {LoadingComponent} from "./loading/loading.component";
import {EmployeeAttributePopupComponent} from './employee-attribute-popup/employee-attribute-popup.component';
import {RouterLink} from "@angular/router";
import {ComboboxComponent} from "./combobox/combobox.component";
import {ComboboxSearchComponent} from "./combobox-search/combobox-search.component";
import {SwitchComponent} from "./switch/switch.component";
import {TabSelectionComponent} from "./tab-selection/tab-selection.component";
import {SelectableCalendarComponent} from './calendar/selectable-calendar/selectable-calendar.component';


@NgModule({
  declarations: [
    PasswordEntryComponent,
    SDatePipe,
    CrossCheckDisplayComponent,
    SmallCalendarComponent,
    LocalToUtcDirective,
    DownloadComponent,
    LoadingComponent,
    EmployeeAttributePopupComponent,
    ComboboxComponent,
    ComboboxSearchComponent,
    SwitchComponent,
    TabSelectionComponent,
    SelectableCalendarComponent
  ],
  exports: [
    PasswordEntryComponent,
    SDatePipe,
    SmallCalendarComponent,
    LocalToUtcDirective,
    DownloadComponent,
    LoadingComponent,
    EmployeeAttributePopupComponent,
    ComboboxComponent,
    ComboboxSearchComponent,
    SwitchComponent,
    TabSelectionComponent,
    SelectableCalendarComponent,
    CrossCheckDisplayComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterLink
  ]
})
export class XComponentsModule {
}
