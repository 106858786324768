<div class="w-full">
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Environment Settings</h1>
      </div>
    </div>
    <div class="mx-auto max-w-7xl shadow-md rounded-lg p-3 mt-3">
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Value</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex">
                    <span (click)="editable = !editable;ResetValues();" class="text-end ms-auto pr-4 cursor-pointer text-theme-primary-700 hover:text-theme-primary-900 select-none">{{ editable ? "Lock" : "Edit" }}</span>
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr *ngFor="let setting of GetSettings(editable)">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                    <input class="w-full border-2 rounded-md px-2 py-1 disabled:border-transparent disabled:bg-white" [disabled]="!setting.empty || !editable" type="text" (ngModelChange)="setting.updated = true" [(ngModel)]="setting.id" placeholder="{{setting.id}}">
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <input class="w-full border-2 rounded-md px-2 py-1 disabled:border-transparent disabled:bg-white" [disabled]="!editable" type="text" (ngModelChange)="setting.updated = true" [(ngModel)]="setting.value" placeholder="{{setting.value}}">
                  </td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                    <a class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer" (click)="Upsert(setting, setting.empty)" *ngIf="editable && setting.updated">Save</a>
                    <a class="text-red-600 hover:text-red-800 cursor-pointer" (click)="Delete(setting)" *ngIf="!editable && !setting.empty">Delete</a>
                  </td>
                </tr>

                <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
