<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center pb-4">
    <div class="inline-flex">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl place-self-center">Roles</h1>
    </div>
  </div>
</div>
<div class="pt-4 px-4 sm:px-6 lg:px-8 pb-4">
  <label for="name" class="block text-sm font-medium leading-6 text-gray-900">New Role</label>
  <form class="mt-2 flex rounded-md shadow-sm">
    <div class="relative flex flex-grow items-stretch focus-within:z-10">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks"
             viewBox="0 0 16 16">
          <path
            d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
          <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
        </svg>
      </div>
      <input type="text" name="name" id="name" [(ngModel)]="name"
             class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
             placeholder="Name">
    </div>
    <button (click)="AddRole()" type="submit"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
           viewBox="0 0 16 16">
        <path
          d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
      </svg>
      Hinzufügen
    </button>
  </form>
</div>
<div class="px-4 sm:px-6 lg:px-8">
  <div class="grid grid-cols-1 xl:grid-cols-3">
    <div class="p-2 select-none">
      <div class="grid">
        <h1 class="text-xl fw-bold">Roles</h1>
      </div>
      <hr class="my-2"/>
      <nav class="flex flex-1 flex-col overflow-y-auto" aria-label="Sidebar" style="max-height: 50vh">
        <ul role="list" class="space-y-1">
          <li *ngFor="let qrole of roles" class="flex mr-2">
            <svg (click)="RemoveRole(qrole)"
                 [ngClass]="{'pointer-events-none opacity-0 !cursor-default': !qrole.company && !PermissionService.CheckPermission(PermissionService.Owner())}"
                 xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="cursor-pointer bi bi-x-lg mr-2 text-red-500 hover:text-red-700 self-center" viewBox="0 0 16 16">
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
            <!-- Current: "bg-gray-50 text-theme-primary-700", Default: "text-gray-700 hover:text-theme-primary-700 hover:bg-gray-50" -->
            <a (click)="role = qrole" [class.bg-gray-200]="qrole == role"
               [class.!text-theme-primary-700]="qrole == role"
               class="cursor-pointer grow text-black group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold"
               [ngClass]="{'!text-yellow-600': qrole.visibilityState == VisibilityState.Public}">{{ qrole.visibilityState != VisibilityState.Public ? "" : "Global:" }} {{ qrole.name }}</a>

            <svg
              *ngIf="PermissionService.CheckPermission(PermissionService.Owner()) && qrole.company && user && qrole.company.id == user.company.id"
              xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
              class="bi bi-arrow-up-square-fill cursor-pointer ml-auto place-self-center px-1 transition-all"
              (click)="togglePublic(qrole)" [ngClass]="{'rotate-180': qrole.visibilityState == VisibilityState.Public}"
              viewBox="0 0 16 16">
              <path
                d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z"/>
            </svg>
          </li>
        </ul>
      </nav>
    </div>
    <div class="p-2">
      <div class="grid grid-cols-2">
        <h1 class="text-xl fw-bold">Permissions</h1>
        <input type="text" [(ngModel)]="searchText" (ngModelChange)="Filter($event)"
               class="w-full border-2 rounded-lg px-1.5">
      </div>
      <hr class="my-2"/>
      <fieldset class="select-none">
        <legend class="sr-only">Permissions</legend>
        <div *ngIf="role" class="flex flex-col overflow-auto" style="max-height: 50vh">
          <div class="relative flex items-start" *ngFor="let permission of permissions_filtered">
            <span class="inline-flex"
                  *ngIf="role.company || (!role.company && RPContain(role,permission)) || PermissionService.CheckPermission(PermissionService.Owner())">
              <svg (click)="TogglePermission(role,permission,true)" *ngIf="!RPContain(role, permission)"
                   xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-x-lg mr-2 text-red-500 hover:text-red-700 cursor-pointer self-center"
                   viewBox="0 0 16 16">
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
              </svg>
              <svg (click)="TogglePermission(role,permission,false)" *ngIf="RPContain(role, permission)"
                   xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-check-lg mr-2 text-green-500 hover:text-green-700 cursor-pointer self-center"
                   viewBox="0 0 16 16"
                   [ngClass]="{'pointer-events-none cursor-default': !role.company && !PermissionService.CheckPermission(PermissionService.Owner())}">
                <path
                  d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
              </svg>
              {{ permission.id }}
            </span>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="p-2">
      <div class="grid">
        <h1 class="text-xl fw-bold">Users</h1>
      </div>
      <hr class="my-2"/>
      <fieldset class="select-none" *ngIf="role">
        <legend class="sr-only">Users</legend>
        <div class="grid grid-cols-1" style="max-height: 50vh">
          <div class="relative flex items-start" *ngFor="let user of users">
            <span class="inline-flex">
              <svg *ngIf="!RUContain(role, user)" (click)="ToggleUser(role,user,true)"
                   xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-x-lg mr-2 text-red-500 hover:text-red-700 cursor-pointer self-center"
                   viewBox="0 0 16 16">
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
              </svg>
              <svg *ngIf="RUContain(role, user)" (click)="ToggleUser(role,user,false)"
                   xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-check-lg mr-2 text-green-500 hover:text-green-700 cursor-pointer self-center"
                   viewBox="0 0 16 16">
                <path
                  d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
              </svg>
              {{ user.name }}
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <app-error-banner [(error)]="error"></app-error-banner>
</div>
