import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {ShardDto} from "../../models/admin_owner/shard/shard-dto";
import {ServerEndpoints} from "../../server.endpoints";
import {PrinterDto} from "../../_modules/location/_models/printer-dto";
import {Address} from "../../models/common/address";
import {CompanyDto} from "../../models/admin_owner/company/company-dto";

@Injectable({
  providedIn: 'root'
})
export class ShardHttpService {

  constructor(private httpClient: HttpClient,
              @Inject('BASE_URL') private baseUrl: string,
              @Inject('BASE_HEADERS') private baseHeaders: HttpHeaders) {
  }

  list(company: string): Observable<ShardDto[]> {
    return this.httpClient.get<ShardDto[]>(this.baseUrl + ServerEndpoints.Management_Shard_List + "?company=" + company, {headers: this.baseHeaders});
  }

  add(name: string, company: string): Observable<ShardDto> {
    return this.httpClient.post<ShardDto>(this.baseUrl + ServerEndpoints.Management_Shard_Add + "?company=" + company, {
      "Name": name
    }, {headers: this.baseHeaders});
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Management_Shard_Delete + "?id=" + id, {headers: this.baseHeaders});
  }

  get(id: string): Observable<ShardDto> {
    return this.httpClient.get<ShardDto>(this.baseUrl + ServerEndpoints.Management_Shard_Get + "?id=" + id, {headers: this.baseHeaders});
  }

  changeName(id: string, name: string): Observable<ShardDto> {
    return this.httpClient.put<ShardDto>(this.baseUrl + ServerEndpoints.Management_Shard_ChangeName, {
      "Id": id,
      "Name": name
    }, {headers: this.baseHeaders});
  }

  setProperty(id: string, variable: string, value: string): Observable<ShardDto> {
    return this.httpClient.post<ShardDto>(`${this.baseUrl}${ServerEndpoints.Management_Shard_SetMetadata}/${id}`, {
      name: variable,
      value: value
    })
  }

  setAddress(id: string, address: Address): Observable<CompanyDto> {
    if (address.addressLine1 == "") address.addressLine1 = undefined!;
    if (address.addressLine2 == "") address.addressLine2 = undefined!;
    if (address.zip == "") address.zip = undefined!;
    if (address.city == "") address.city = undefined!;
    return this.httpClient.patch<CompanyDto>(`${this.baseUrl}${ServerEndpoints.Management_Shard_SetAddress}/${id}`, address);
  }
}
