export enum HelpType {
  None,
  Cvd,
  Security,
  Technical,
  Change,
  Runner,
  TSE
}

export class HelpTypeDescription {
  strings: string[] = ["-", "CVD", "Security", "Technik", "Wechselgeld", "Runner", "TSE"]

  getDescription(type: HelpType): string {
    return this.strings[type];
  }
}
