import {
  AfterViewInit, ApplicationRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {EmployeeAttributeDto} from "../../company/x-models/employee-attribute-dto";
import {EmployeeAttributeHttpService} from "../x-services/employee-attribute-http.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {PermissionService} from "../../../_auth/permission.service";
import {EmployeeDto} from "../../company/x-models/employee-dto";
import {EmployeeAttributeNl} from "../../company/x-models/employee-attribute-nl";
import {Routes} from "../../../enums/routes";
import {CompanyRoutes} from "../../company/x-models/x-enums/company-routes";
import {EmployeeNl} from "../../company/x-models/employee-nl";
import {Side} from "../../company/x-models/x-enums/side";
import {EmployeeAttributeService} from "../../company/_services/_dto-services/employee-attribute.service";
import {max} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-employee-attribute-popup',
  templateUrl: './employee-attribute-popup.component.html',
  styleUrls: ['./employee-attribute-popup.component.scss']
})
export class EmployeeAttributePopupComponent<T extends EmployeeDto | EmployeeNl> implements OnChanges, OnInit, AfterViewInit {
  @Input() mouseEvent?: MouseEvent;
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() employeeChange = new EventEmitter<EmployeeDto>();
  @Input() employee: T | undefined;
  @Input() side: Side = Side.Right;
  @Input() redirectPath: any = [];
  @Input() queryParams: any = {};
  @Input() useRelativeX: boolean = false;
  @Input() useRelativeY: boolean = false;
  @Input() prefix: string = "";
  @Output() heightChange = new EventEmitter<number>();
  @ViewChild("popup") popup!: HTMLElement;
  @Input() relative: HTMLElement | undefined;

  //employeeAttributes?: EmployeeAttributeDto[] = [];

  constructor(private employeeAttributeHttpService: EmployeeAttributeHttpService,
              private globalAlertService: GlobalAlertService,
              public PermissionService: PermissionService,
              public EmployeeAttributeService: EmployeeAttributeService,
              private appRef: ApplicationRef, private router: Router) {
  }

  ngAfterViewInit(): void {
    let obs = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        if (cr.height != this.popupHeight) {
          this.popupHeight = cr.height;
          this.getPaddingHeight()
        } else {
          this.popupHeight = cr.height;
        }
      }
    });
    obs.observe(document.getElementById("eaPopup")!);
  }

  @HostListener("document:click", ['$event'])
  onClick(event: MouseEvent) {
    //this.heightChange.emit(this.height)

    if (!event.target) {
      this.visibleChange.emit(false);
      return;
    }

    let sender: any = event.target;
    while (sender && ["eaPopup", "eaToggle", "eaDisEdit", "eaEdit"].indexOf(sender.id) == -1) {
      sender = sender.offsetParent ?? sender.parentElement ?? undefined
    }
    if (!this.useRelativeY || !this.useRelativeX) this.getPaddingHeight()

    if (!sender) {
      this.visibleChange.emit(false);
      this.editMode = false;
      this.editNewName = "";
    }
  }

  isOnPopup(element: any, event: MouseEvent): boolean {
    if (event.pageX >= this.positionX && event.pageX <= this.positionX + element.nativeElement.clientWidth &&
      event.pageY >= this.positionY && event.pageY <= this.positionY + element.nativeElement.clientHeight) {
      return this.visible;
    }
    return false;
  }

  getPaddingHeight(): number {
    if (!this.useRelativeX && !this.useRelativeY) return 0;

    let relative: any = this.relative;
    if (!relative) {
      relative = (this.popup as any).nativeElement;
      while (relative && relative.id != "eaRelative") {
        relative = relative.offsetParent ?? relative.parentElement ?? undefined;
      }
    }

    let height = relative.clientHeight - this.padding;
    let popupSum = this.popupHeight + this.positionY;

    if (height < popupSum) {
      this.padding = popupSum - height + 30;
    } else {
      this.padding = 0;
    }

    this.appRef.tick();

    return this.padding;
  }

  popupHeight: number = 0;

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mouseEvent && this.mouseEvent) {
      this.repositionPopup()
    }
  }

  positionX: number = 0;
  positionY: number = 0;

  padding: number = 0;

  @HostListener("window:resize", [])
  repositionPopup() {
    this.editMode = false;
    this.editNewName = "";
    if (!this.mouseEvent || !this.mouseEvent.target) return;
    let sender: HTMLElement = this.mouseEvent.target as HTMLElement;
    while (sender.id != "eaToggle") {
      sender = sender.parentElement!;
    }

    let relative: any = this.relative;
    if (!relative) {
      relative = (this.popup as any).nativeElement;
      while (relative && relative.id != "eaRelative") {
        relative = relative.offsetParent ?? relative.parentElement ?? undefined;
      }
    }

    this.positionX = this.side == Side.Right ? sender.getBoundingClientRect().left + sender.clientWidth + 20 : sender.getBoundingClientRect().left - 256 - 20;
    this.positionY = sender.getBoundingClientRect().top + window.pageYOffset + sender.clientHeight / 2 - 30;

    if (this.useRelativeX) {
      this.positionX -= relative.getBoundingClientRect().left

      if (!this.useRelativeY) {
        this.positionY -= relative.getBoundingClientRect().top + window.pageYOffset;
      }
    }
    if (this.useRelativeY) {
      this.positionY -= relative.getBoundingClientRect().top - relative.scrollTop
    }
    
    //this.heightChange.emit(this.popup.offsetHeight);
  }

  editMode: boolean = false;
  editNewName: string = "";

  addEmployeeAttribute(event: MouseEvent) {
    event.preventDefault()
    if (!this.editMode || !this.employee || !this.EmployeeAttributeService.Entities) return;
    this.employeeAttributeHttpService.add(this.editNewName, [this.employee.id]).subscribe(x => {
      this.EmployeeAttributeService.Replace(x);
      this.employee!.employeeAttributes.push(x as EmployeeAttributeNl)
      this.editNewName = "";
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Attribut konnte nicht erstellt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  setEmployeeAttributes(employeeAttributeIds: string[], state: boolean) {
    if (!this.employee || !this.EmployeeAttributeService.Entities) return;
    this.employeeAttributeHttpService.setEmployeeAttributes(employeeAttributeIds, [this.employee.id], state).subscribe(x => {
      if (x.item1) {
        x.item1.forEach(c => {
          this.EmployeeAttributeService.Replace(c);
        })
      }
      if (x.item2) {
        x.item2.forEach(c => {
          this.employee!.employeeAttributes = c.employeeAttributes;
        })
      }
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Attribut konnte nicht gesetzt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.visibleChange.emit(false);
      this.employeeChange.emit(undefined);
      this.editNewName = "";
      this.editMode = false;
    })
  }

  redirectToSettings(id: string) {
    this.queryParams = this.getQueryParams(id);
    this.router.navigate(['/' + Routes.CompanyModule + '/' + CompanyRoutes.EmployeeAttributesSettings], {queryParams: this.queryParams});
  }

  getQueryParams(id: string): any {
    this.queryParams["id"] = id;
    if (this.redirectPath.length > 0) {
      this.queryParams["redirect"] = this.redirectPath;
    }
    return this.queryParams;
  }

  containsAttribute(id: string): boolean {
    if (!this.employee) return false;
    return this.employee.employeeAttributes.findIndex(x => x.id == id) > -1;
  }


  protected readonly Routes = Routes;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly Side = Side;
}
