import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(private _http: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  public isConfigured(): Observable<boolean> {
    return this._http.get<boolean>(this.baseUrl + ServerEndpoints.Management_Setup_IsConfigured).pipe();
  }
}
