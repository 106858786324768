import { Component } from '@angular/core';
import {PermissionService} from "../../../_auth/permission.service";
import {Routes} from "../../../enums/routes";
import {CompanyRoutes} from "../x-models/x-enums/company-routes";

@Component({
  selector: 'app-navbar-company',
  templateUrl: './navbar-company.component.html',
  styleUrls: ['./navbar-company.component.scss']
})
export class NavbarCompanyComponent {
  constructor(public PermissionService: PermissionService) {
  }

  protected readonly Router = Routes;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly Routes = Routes;
}
