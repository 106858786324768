import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive, UrlTree} from "@angular/router";

@Component({
  selector: 'app-sidebar-button',
  standalone: true,
  imports: [
    NgIf,
    RouterLinkActive,
    RouterLink
  ],
  templateUrl: './sidebar-button.component.html',
  styleUrl: './sidebar-button.component.scss'
})
export class SidebarButtonComponent {
  @Input() title: string = "";
  @Input() subtitle: string = "";
}
