export class ColorUtilities {
  // Todo: Check if we can merge this function together with  ClientApp/src/app/utils/color-utilities.ts
  static rgbToHex(rgb: string): string | null {
    // Check if the input string matches the RGB format
    const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    if (!match) {
      // If the input doesn't match the expected format, return null
      return null;
    }

    // Extract the RGB values from the match
    const [, red, green, blue] = match.map(Number);

    // Convert the RGB values to hex
    const hex = ((red << 16) | (green << 8) | blue).toString(16).padStart(6, '0');

    // Return the hex color code
    return `#${hex}`;
  }
}
