import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {ItemHttpService} from "../http-requests/item-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CompositionHttpService} from "../http-requests/composition-http.service";
import {ItemDto} from "../models/item-dto";
import {CompositionDto} from "../models/composition-dto";
import {TagHttpService} from "../http-requests/tag-http.service";
import {TagDto} from "../models/tag-dto";
import { HttpErrorResponse, HttpEventType, HttpResponse } from "@angular/common/http";
import {PermissionService} from "../_auth/permission.service";
import {GenericSortingService} from "../_services/generic-sorting.service";
import {Routes} from "../enums/routes";
import {CompositionTypeDto} from "../models/composition-type/composition-type-dto";
import {CompositionTypeHttpService} from "../http-requests/composition-type-http.service";
import {GlobalAlertService} from "../_services/global-alert.service";
import {AlertLevel} from "../enums/alert-level";
import {CompositionTypeService} from "../_services/composition-type.service";
import {CompositionTypeNl} from "../models/composition-type/composition-type-nl";
import {UnitConverter} from "../enums/unit";
import {ViewType} from "../x-models/x-enums/ViewType";
import {VATRateHelper} from "../models/common/vat-rate";

@Component({
  selector: 'app-compositions',
  templateUrl: './compositions.component.html',
  styleUrls: ['./compositions.component.scss']
})
export class CompositionsComponent implements OnInit {
  compositionType: CompositionTypeDto | undefined;
  compositionTypeId: string = "";
  compositions?: CompositionDto[];
  filtered_compositions?: CompositionDto[];
  items?: ItemDto[];
  tags?: TagDto[];
  filtered_tags?: TagDto[];
  item: string = "";
  tag: string = "";
  itemName: string = ""
  tagName: string = "";
  searchText: string = "";
  other: boolean = false;
  error?: HttpErrorResponse;
  downloading: boolean = false;
  @ViewChild('NameChangedCustomInput') NameChangedCustomInput: any;

  selection_mode: boolean = false;
  selected_items: CompositionDto[] = [];
  selected_composition_type: CompositionTypeNl | undefined;
  composition_type_selector_visible: boolean = false;

  constructor(private itemHttpService: ItemHttpService, private compositionHttpService: CompositionHttpService, private tagHttpService: TagHttpService, @Inject('BASE_URL') baseUrl: string,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService, public sortingService: GenericSortingService,
              private compositionTypeHttpService: CompositionTypeHttpService, private globalAlertService: GlobalAlertService,
              public CompositionTypeService: CompositionTypeService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ShowCompositionTypeSelector() {
    if (this.selected_items.length == 0) return;
    this.selected_composition_type = undefined;
    this.composition_type_selector_visible = true;
  }

  SaveCompositionType() {
    let mapping = this.selected_items.map(x => x.id);
    this.compositionTypeHttpService.SetCompositionType(this.selected_composition_type?.id ?? "", mapping).subscribe(x => {
      if (this.compositions && !this.other) {
        this.compositions = this.compositions.filter(x => mapping.indexOf(x.id) == -1);
        this.FilterItem(this.searchText)
      }
      this.globalAlertService.createAlertBannerModel("Zuordnung erfolgreich", "Zuordnung erfolgreich durchgeführt.", AlertLevel.success, 2000);
      this.globalAlertService.show()
      this.HideCompositionTypeSelector(true);
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Zuordnungen konnten nicht gespeichert werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  HideCompositionTypeSelector(success: boolean = false) {
    this.composition_type_selector_visible = false;
    this.selected_composition_type = undefined;
    if (success) {
      this.selected_items = [];
      this.selection_mode = false;
    }
  }

  SetCompositionType(compositionType: CompositionTypeNl | undefined) {
    this.selected_composition_type = compositionType;
  }

  ToggleInSelection(composition: CompositionDto) {
    if (!this.selection_mode) return;
    let index = this.selected_items.indexOf(composition);
    if (index > -1) {
      this.selected_items.splice(index, 1);
    } else {
      this.selected_items.push(composition);
    }
  }

  IsInList(composition: CompositionDto) {
    return this.selected_items.indexOf(composition) > -1;
  }

  AssignCopy() {
    this.filtered_compositions = Object.assign([], this.compositions?.filter(item => {
      let drink = item.tags.findIndex(x => x.other) <= -1 || (!this.compositionType && !this.other) || this.compositionType;
      return (!this.other ? drink : !drink);
    }));
  }

  FilterItem(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    } else {
      this.filtered_compositions = Object.assign([], this.compositions?.filter(item => {
        let drink = item.tags.findIndex(x => x.other) <= -1 || (!this.compositionType && !this.other) || this.compositionType;
        let name = item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        return name && (!this.other ? drink : !drink);
      }))
    }
    this.SortOthers();
  }

  CompositionOther(composition: CompositionDto): boolean {
    return composition.tags.findIndex(x => x.other) > -1;
  }

  ChangePrice(composition: CompositionDto): void {
    let price = prompt("Getränk: " + composition.name + "\n\nNeuer Preis:", composition.price.toFixed(2));
    if (price == null || price == "") return;
    this.compositionHttpService.changePrice(composition.id, parseFloat(price)).subscribe(x => {
      this.compositions!.splice(this.compositions!.indexOf(composition), 1, x);
      this.FilterItem(this.searchText);
      this.globalAlertService.createAlertBannerModel("Änderung erfolgreich", `Der Preis wurde erfolgreich geändert.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Änderung fehlgeschlagen", `Die Preisänderung konnte nicht vorgenommen werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  LoadData() {
    this.itemHttpService.list().subscribe(result => {
      this.items = result;
    }, error => console.error(error));
    if (this.compositionTypeId != "") {
      this.compositionTypeHttpService.get(this.compositionTypeId).subscribe(x => {
        this.compositionType = x;
        let otherCompositions = this.sortingService.groupBasedSorting(this.compositionType.compositions.filter(x => x.tags.findIndex(c => c.other) > -1), ["components", 0, "id"], "price", false)
        let defaultCompositions = this.compositionType.compositions.filter(x => x.tags.findIndex(c => c.other) == -1);
        this.compositions = Object.assign([], defaultCompositions.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        }));
        this.compositions.push(...otherCompositions)
        this.FilterItem(this.searchText);
      }, error => {
        console.error(error);
        this.globalAlertService.createAlertBannerModel("Fehler", "Artikel konnten nicht geladen werden.", AlertLevel.error, 2000);
        this.globalAlertService.show();
        this.router.navigate([Routes.Compositions], {queryParams: {other: false, type: ''}})
      })
    } else {
      this.compositionHttpService.list(this.item, this.tag, !this.other).subscribe(result => {
        this.compositions = result;
        if (this.tag != "") {
          this.tagHttpService.get(this.tag).subscribe(x => {
            this.tagName = x.name;
          }, error => {
            console.error(error);
          })
        }
        if (this.item != "") {
          this.itemHttpService.get(this.item).subscribe(x => {
            this.itemName = x.name;
          }, error => {
            console.error(error);
          })
        }
        this.FilterItem(this.searchText);
      }, error => console.error(error));
    }
    this.tagHttpService.list().subscribe(result => {
      this.tags = result;
      this.filtered_tags = Object.assign([], this.tags.filter(tag => {
        if (tag.allCompositions) return false;
        return tag.other == this.other || tag.other
      }))
    }, error => console.error(error))
  }

  ToggleTag(composition: CompositionDto, tag: TagDto) {
    this.compositionHttpService.toggle(composition.id, tag.id).subscribe(result => {
      this.compositions?.splice(this.compositions?.indexOf(composition), 1, result);
      this.FilterItem(this.searchText);
    }, error => console.error(error));
  }

  SetItem(id: string = "") {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {item: id, type: this.compositionTypeId, other: this.other}
    })
  }

  SetTag(id: string = "") {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {tag: id, type: this.compositionTypeId, other: this.other}
    })
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  GetTagArray(composition: CompositionDto): string[] {
    let tags: string[] = []
    composition.tags.forEach(tag => {
      tags.push(tag.id);
    })
    return tags;
  }

  CheckCompositionTag(composition: CompositionDto, tag: TagDto): boolean {
    return composition.tags.findIndex(x => x.id == tag.id) >= 0;
  }

  DeleteComposition(composition: CompositionDto) {
    let confirmation = confirm("Folgenden Artikel sicher löschen:\n" + composition.name + "\n" + composition.id);
    if (!confirmation) return;
    this.compositionHttpService.delete(composition.id).subscribe(result => {
      this.compositions!.splice(this.compositions!.indexOf(composition), 1);
      this.FilterItem(this.searchText);
      this.globalAlertService.createAlertBannerModel("Erfolgreich gelöscht", `Der Artikel ${composition.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Löschen fehlgeschlagen", `Der Artikel ${composition.name} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  private downloadFile = (data: HttpResponse<Blob>) => {
    this.downloading = true;
    const downloadedFile = new Blob([data.body!], {type: data.body?.type});
    const a = document.createElement("a");
    a.setAttribute("style", "display: none;");
    document.body.appendChild(a);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = "_blank";

    const contentDispositionHeader = data.headers.get("content-disposition");
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
    const matches = regex.exec(contentDispositionHeader || '');

    a.download = matches && matches.length > 1 ? matches[1] : "all-compositions.xlsx";

    a.click();
    document.body.removeChild(a);
    this.downloading = false;
  }

  download = () => {
    this.downloading = true;
    this.compositionHttpService.download().subscribe((event) => {
      if (event.type === HttpEventType.Response) {
        this.downloadFile(event);
      }
    })
  }

  SortOthers() {
    if (!this.other) {
      return
    }
    this.filtered_compositions = this.sortingService.groupBasedSorting(this.filtered_compositions!, ["components", 0, "id"], "price", true)
  }

  CompositionChanged(composition: CompositionDto) {
    if (this.compositions == undefined) this.compositions = [];
    let index = this.compositions.findIndex(x => x.id == composition.id);
    if (index >= 0) {
      this.compositions.splice(index, 1, composition);
    } else {
      this.compositions.push(composition);
    }
  }

  @Output() compositionChanged = new EventEmitter<boolean>();

  UpdateCompositionName(compositionId: string, name: string) {
    this.compositionHttpService.changeName(compositionId, name).subscribe(result => {
      let composition = this.compositions?.find(x => x.id == compositionId);
      if (composition == undefined) return;
      composition.name = name;
      this.compositions?.splice(this.compositions?.indexOf(composition), 1, result);
      this.FilterItem(this.searchText);
      this.NameChangedCustomInput.RequestChangeText(true);
    }, error => {
      console.error(error)
      this.NameChangedCustomInput.RequestChangeText(false)
    });
  }


  redirectToSettings(id: string) {
    this.router.navigate([Routes.CompositionSettings], {
      queryParams: {
        id: id,
        type: this.compositionTypeId,
        vt: this.viewMode,
        search: this.searchText
      }
    });
  }

  redirectToCompositionComponentChange(id: string) {
    //redirect to items with composition as query parameter
    this.router.navigate([Routes.Items], {queryParams: {composition: id, compositionType: this.compositionTypeId}});
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.viewMode = params.vt == undefined ? ViewType.GridView : params.vt;
      this.item = params.item == undefined ? "" : params.item;
      this.tag = params.tag == undefined ? "" : params.tag;
      this.other = params.other == undefined ? false : params.other == "true";
      this.compositionTypeId = params.type == undefined ? "" : params.type;
      this.searchText = params.search == undefined ? "" : params.search;
      this.LoadData()
    })
  }

  field: string = "";
  inverse: boolean = false;

  SortBy(field: string) {
    if (this.filtered_compositions == undefined) this.filtered_compositions = [];
    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;
    this.field = field;
    switch (field) {
      case "order":
        this.filtered_compositions.sort((a, b) => {
          if (a.order - b.order != 0) return a.order - b.order;

          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        })
        break;
      case "name":
        this.filtered_compositions.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
        break;
      case "created":
        this.filtered_compositions.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
    }
    if (this.inverse) this.filtered_compositions.reverse();
  }

  viewMode: ViewType = ViewType.GridView

  protected readonly UnitConverter = UnitConverter;
  protected readonly ViewType = ViewType;
  protected readonly VATRateHelper = VATRateHelper;
}
