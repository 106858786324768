import {Injectable} from '@angular/core';
import {NavbarItem} from "../navbar-office/models/navbar-item";
import {NavbarInventoryComponent} from "../navbar-inventory/navbar-inventory.component";
import {PermissionService} from "../../_auth/permission.service";
import {NavbarAutomationComponent} from "../../_modules/automation/navbar-automation/navbar-automation.component";
import {NavbarLocationComponent} from "../../_modules/signalRTracker/navbar-devices/navbar-location.component";
import {NavbarCompanyComponent} from "../../_modules/company/navbar-company/navbar-company.component";
import {NavbarAdminComponent} from "../navbar-admin/navbar-admin.component";
import {NavbarOwnerComponent} from "../navbar-owner/navbar-owner.component";
import {NavbarHelpComponent} from "../navbar-help/navbar-help.component";
import {ItemBase} from "./item-base";
import {
  NavbarAnalysisComponent
} from "../../_modules/analytics/common/components/navigation/navbar-analysis-component/navbar-analysis.component";
import {NavbarLegalComponent} from "../../_modules/legal/navbar-legal/navbar-legal.component";
import {NavbarUserComponent} from "../navbar-user/navbar-user.component";
import {ContractType} from "./_models/contract-type";
import {AuthenticationService} from "../../_auth/authentication.service";
import {MetadataType} from "../../_models/_auth/_enums/metadata-type";

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  constructor(private permissionService: PermissionService,
              private authenticationService: AuthenticationService) {
  }

  userNavbar = new NavbarItem("Benutzer", NavbarUserComponent, true, false, 255);

  navbarItems: NavbarItem[] = [
    new NavbarItem("Inventar", NavbarInventoryComponent, this.permissionService.Navbar_ShowInventory()),
    new NavbarItem("Standort", NavbarLocationComponent, this.permissionService.Navbar_ShowLocations(), true, ContractType.ZeroDevicePackage),
    new NavbarItem("Analyse", NavbarAnalysisComponent, this.permissionService.Navbar_ShowAnalytics()),
    new NavbarItem("Automation", NavbarAutomationComponent, this.permissionService.Navbar_ShowAutomation()),
    new NavbarItem("Rechtliches", NavbarLegalComponent, this.permissionService.Navbar_ShowLegal()),
    // new NavbarItem("Fiskaly", NavbarFiskalyComponent, this.permissionService.CombinedCheck_FiskalyModule()),
    new NavbarItem("Unternehmen", NavbarCompanyComponent, this.permissionService.Navbar_ShowCompany(), true, ContractType.ZeroDevicePackage),
    new NavbarItem("Admin", NavbarAdminComponent, this.permissionService.Navbar_ShowAdmin(), true, ContractType.ZeroDevicePackage),
    new NavbarItem("Owner", NavbarOwnerComponent, this.permissionService.Navbar_ShowOwner(), true, ContractType.ZeroDevicePackage),
    new NavbarItem("Hilfe", NavbarHelpComponent, true, true, ContractType.ZeroDevicePackage),
    this.userNavbar
  ]

  itemBases: ItemBase[] = [];

  hasExpanded() {
    return this.navbarItems.some(x => x.isExpanded);
  }

  getAvailable(isNavbar: boolean = true): NavbarItem[] {
    return this.navbarItems.filter(x =>
      x.available && (!isNavbar || x.inNavBar) &&
      x.isDefault(this.authenticationService.contractType)
    );
  }

  getHelpItem(): NavbarItem {
    return this.navbarItems.filter(x => x.title == "Hilfe")[0];
  }
}
