import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ItemDto} from "../../models/item-dto";
import {HttpErrorResponse} from "@angular/common/http";
import {ItemHttpService} from "../../http-requests/item-http.service";
import {CategoryDto} from "../../models/category-dto";
import {CategoryHttpService} from "../../http-requests/category-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ComponentNl} from "../../models/component-nl";
import {CompositionHttpService} from "../../http-requests/composition-http.service";
import {TagHttpService} from "../../http-requests/tag-http.service";
import {TagDto} from "../../models/tag-dto";
import {PermissionService} from "../../_auth/permission.service";
import {AlertLevel} from "../../enums/alert-level";
import {PopUps} from "../../enums/pop-ups";
import {AlertBannerModel} from "../../models/alert-banner-model";
import {ItemService} from "../../_services/dto-services/item.service";
import {forkJoin, Observable, Subscription} from "rxjs";
import {ReplaceDtoElementModel} from "../../models/misc/replace-dto-element-model";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {Routes} from "../../enums/routes";
import {DeviceInformation} from "../../_modules/signalRTracker/x-models/device-information";
import {PlatformScannerService} from "../../_services/platform-scanner.service";
import {Platform} from "../../enums/platform";
import {Unit, UnitConverter} from "../../enums/unit";
import {CompositionCreateComponent} from "../../models/composition/composition-create-component";
import {SupplierService} from "../../_modules/company/_services/_dto-services/supplier.service";
import {AppModule} from "../../app.module";
import {TagNl} from "../../models/tag-nl";
import {NavbarService} from "../../x-components/navbar-base/navbar.service";
import {UrlHelper} from "../../helpers/url-helper";

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  //public items?: ItemDto[] = undefined;
  public filtered_items?: ItemDto[];
  public categories?: CategoryDto[] = undefined;
  public newItemName: string = "";
  public newItemCategory?: CategoryDto;
  public error?: HttpErrorResponse;
  public compositionError?: HttpErrorResponse;
  public inverse: boolean = false;
  public field: string = "";
  public category: string = "";
  public qCategory: CategoryDto | undefined;
  public newCompositionName: string = "";
  public newCompositionPrice: number = 0;
  public compositionCreator: boolean = false;
  public searchText: string = "";
  public tags: TagDto[] = [];
  public selectedTags: TagDto[] = [];
  public special: boolean = false;

  public editingItem: ItemDto | undefined;

  //PopUps
  public visiblePopUp: PopUps = PopUps.None;

  sourceDetailPPU: number | undefined;
  sourceDetailVPU: number | undefined;
  sourceDetailUPB: number | undefined;
  sourceDetailSK: string | undefined;
  supplierId: string = "";
  sourceDetailInvId: number | undefined;

  // suppliers: SupplierDto[] | undefined;

  // ComponentNl[] = [];
  componentsRework: CompositionCreateComponent[] = [];
  viewportWidth: number = 0;
  Platform = Platform;

  compositionId: string | undefined;
  originalComponents: CompositionCreateComponent[] = [];


  constructor(private itemHttpService: ItemHttpService,
              private categoryHttpService: CategoryHttpService,
              private compositionHttpService: CompositionHttpService,
              private tagHttpService: TagHttpService,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService,
              public itemService: ItemService,
              private globalAlertService: GlobalAlertService,
              public PlatformScannerService: PlatformScannerService,
              public NavbarService: NavbarService,
              private ref: ChangeDetectorRef) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  OpenSourceDetailsPopup(item: ItemDto) {
    this.editingItem = this.itemService.getItemInItems(item.id);
    this.visiblePopUp = PopUps.EditSourceDetails;
    this.sourceDetailVPU = item.latestSourceDetail?.volumePerUnit;
    this.sourceDetailPPU = item.latestSourceDetail?.pricePerUnit;
    this.sourceDetailUPB = item.latestSourceDetail?.unitsPerBundle;
    this.sourceDetailSK = item.latestSourceDetail?.supplierKey;
    this.sourceDetailInvId = item.latestSourceDetail?.inventoryId;
    this.supplierId = item.latestSourceDetail != undefined && item.latestSourceDetail.supplier != undefined ? item.latestSourceDetail.supplier.id : "";
  }


  AssignCopy() {
    if (this.itemService.items == undefined) {
      this.filtered_items = [];
      return;
    }
    this.filtered_items = Object.assign([], this.itemService.items.filter(c => c.category.id == this.category || this.category == ""));
    this.SortByField()
  }

  FilterItem(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    } else {
      this.filtered_items = Object.assign([], this.itemService.items?.filter(item =>
        item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 && (item.category.id == this.category || this.category == "")
      ))
    }
    this.SortByField()
  }

  //AddToComposition(itemId: string, filter: boolean = true) {
  //  if (itemId == "" && this.itemService.items != undefined && this.itemService.items.length > 0) itemId = this.itemService.items[0].id;
  //  if (this.components.find(x => x.item == itemId) != undefined && filter) return;
  //  let component = new ComponentNl();
  //  component.filter = filter;
  //  component.item = itemId;
  //  component.unitString = UnitConverter.GetString(this.itemService.getItemInItems(itemId)!.unit)
  //  this.components.push(component)
  //}

  AddToCompositionRework(item: ItemDto | undefined, filter: boolean = true) {
    if (!item && this.itemService.items && this.itemService.items.length > 0) item = this.itemService.items[0];
    if (item && this.componentsRework.find(x => x.item.id == item!.id) && filter) return;
    let component = new CompositionCreateComponent();
    component.item = item!;
    component.filter = filter;
    this.componentsRework.push(component);
  }

  //RemoveFromComposition(index: number) {
  //  this.components.splice(index, 1);
  //}

  RemoveFromCompositionsRework(component: CompositionCreateComponent) {
    this.componentsRework.splice(this.componentsRework.indexOf(component), 1);
  }

  saveComponentChange(clear: boolean = true) {
    // check if the original components are edited
    if (!this.originalComponentsEdited()) {
      this.globalAlertService.createAlertBannerModel("Keine Änderungen", "Es wurden keine Änderungen vorgenommen.", AlertLevel.info, 2000);
      this.globalAlertService.show();
      return
    }
    let components: ComponentNl[] = this.componentReworkToComponentNl()

    this.compositionHttpService.changeComponents(this.compositionId!, components)
      .subscribe({
        next: _ => {
          let compositionType = UrlHelper.QueryParams.compositionType;
          if (compositionType == undefined) return;
          this.redirectToArticleCategory(compositionType);
        },
        error: err => {
          console.error(err);
          this.globalAlertService.createAlertBannerModel("Fehler", "Änderungen konnten nicht gespeichert werden.", AlertLevel.error, 2000);
        }
      });


//http://localhost:4200/items?composition=f42fdd0d-a094-4f05-8adb-29df1d005a86
  }

  componentReworkToComponentNl() {
    let components: ComponentNl[] = [];
    this.componentsRework.forEach(component => {
      let comp = new ComponentNl();
      comp.unit = component.unit;
      comp.value = component.value;
      comp.item = component.item.id;
      components.push(comp);
    })
    return components;
  }

  CreateComposition(clear: boolean = true) {
    if (this.compositionId) {
      this.saveComponentChange(clear);
      return
    }

    let tags: string[] = [];
    this.selectedTags.forEach(tag => {
      tags.push(tag.id);
    })
    let components: ComponentNl[] = this.componentReworkToComponentNl()
    this.compositionHttpService.add(this.newCompositionName, this.newCompositionPrice, components, tags, this.special).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Erfolgreich erstellt", `Der Artikel ${x.name} wurde erfolgreich erstellt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
      if (!clear) return;
      this.newCompositionPrice = 0;
      this.newCompositionName = "";
      //this.components = [];
      this.componentsRework = [];
      this.compositionCreator = false;
      this.selectedTags = [];
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Fehler", "Der Artikel konnte nicht hinzugefügt werden", AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  setNewItemCategory(id: string) {
    this.newItemCategory = this.categories?.find(x => x.id == id);
  }

  AddItem(): void {
    if (this.newItemName.isNullOrWhitespace()) {
      this.globalAlertService.createAndShow("Fehler", "Bitte geben Sie einen Namen für das Item ein.", AlertLevel.error);
      return;
    }

    this.itemHttpService.add(this.newItemName, this.newItemCategory?.id).subscribe(x => {
      if (this.itemService.items == undefined) this.itemService.items = []
      this.error = undefined;
      this.itemService.items.splice(0, 0, x);
      //this.items.push(x);
      this.newItemName = "";
      this.FilterItem(this.searchText);
      this.globalAlertService.createAlertBannerModel("Erfolgreich erstellt", `Das Item ${x.name} wurde erfolgreich erstellt`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Das Item konnte nicht erstellt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    });
  }

  RemoveItem(item: ItemDto): void {
    let confirmation = confirm("Are you sure you want to delete the following item:\n" + item.name + "\n" + item.id);
    if (!confirmation) return;
    this.itemHttpService.delete(item.id).subscribe(x => {
      this.error = undefined;
      this.itemService.items!.splice(this.itemService.items!.indexOf(item), 1);
      this.FilterItem(this.searchText);
      this.globalAlertService.createAlertBannerModel("Item gelöscht", `Das Item ${item.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Das Löschen des Items ist fehlgeschlagen.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SetInvNumber(item: ItemDto): void {
    let invNumber = prompt("Neue Inventar-Nummer:", item.latestSourceDetail?.inventoryId?.toFixed(0) ?? "");
    this.itemHttpService.setSourceDetail(
      item.id,
      item.latestSourceDetail?.pricePerUnit ?? 0,
      item.latestSourceDetail?.volumePerUnit ?? 0,
      item.latestSourceDetail?.unitsPerBundle ?? 0,
      item.latestSourceDetail?.supplierKey ?? "",
      item.latestSourceDetail?.supplier?.id ?? "",
      invNumber == undefined ? undefined : parseInt(invNumber)
    ).subscribe(x => {
      this.itemService.items!.splice(this.itemService.items!.indexOf(item), 1, x);
      this.FilterItem(this.searchText);
    }, error => {
      console.error(error)
      this.globalAlertService.createAlertBannerModel("Fehler", "Inventarnummer konnte nicht gesetzt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
    //this.itemHttpService.setInvNumber(item.id, invNumber == undefined ? 0 : parseInt(invNumber)).subscribe(x => {
    //  item.invNumber = x.invNumber;
    //}, error => {
    //
    //});
  }

  SortBy(field: string) {

    if (field == this.field) this.inverse = !this.inverse;
    else this.inverse = false;

    // Allows functions to use the same sorting method if items are refreshed
    this.field = field;
    this.SortByField();
  }

  SortByField() {
    /**
     * Sorts the items by the field specified in this.field
     *
     * @return void
     */
    if (this.filtered_items == undefined) this.filtered_items = []

    switch (this.field) {
      case "name":
        this.filtered_items.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
        break;
      case "created":
        this.filtered_items.sort((a, b) => {
          if (a.created > b.created) return 1;
          if (a.created < b.created) return -1;
          return 0;
        })
        break;
      case "category":
        this.filtered_items.sort((a, b) => {
          if (a.category.name.toLowerCase() > b.category.name.toLowerCase()) return 1;
          if (a.category.name.toLowerCase() < b.category.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          return 0;
        })
        break;
      case "invnb":
        this.filtered_items.sort((a, b) => {
          return (a.latestSourceDetail?.inventoryId ?? 0) - (b.latestSourceDetail?.inventoryId ?? 0);
        })
    }
    if (this.inverse) this.filtered_items.reverse();
  }

  fetchCategories(): Observable<CategoryDto[]> {
    if (this.PermissionService.CheckPermission(this.PermissionService.Inventory_Category_Get()))
      return this.categoryHttpService.list()
    return new Observable<CategoryDto[]>();
  }

  fetchTags(): Observable<TagDto[]> {
    if (this.PermissionService.CheckPermission(this.PermissionService.Inventory_Tag_List()))
      return this.tagHttpService.list();
    return new Observable<TagDto[]>();
  }

  initializeCategories(result: CategoryDto[]): void {
    this.categories = result;
    if (this.categories.length > 0)
      if (this.category == undefined || this.category == "") this.newItemCategory = this.categories[0];
      else this.newItemCategory = this.categories.find(x => x.id == this.category)
    if (this.category != "") {
      this.qCategory = this.categories?.find(x => x.id == this.category);
    }
  }

  initializeTags(result: TagDto[]): void {
    this.tags = result.filter(c => !c.allCompositions);
  }

  initializeSupplierService() {
    if (this.PermissionService.CheckPermission(this.PermissionService.Company_Supplier_List())) {
      this.SupplierService = AppModule.injector.get(SupplierService);
      if (this.SupplierService) {
        this.SupplierService.EntityChanged.subscribe({
          next: async x => {
            if (this.supplierId == x?.id) {
              let sid = this.supplierId;
              this.supplierId = "";
              await this.delay(0);
              if (this.SupplierService?.Entities?.find(x => x.id == sid)) this.supplierId = sid;
            }
          }
        })
      }
      //this.supplierHttpService.list().subscribe(x => {
      //  this.suppliers = x;
      //}, error => {
      //  console.error(error);
      //  this.globalAlertService.createAlertBannerModel("Fehler", "Lieferanten konnten nicht geladen werden.", AlertLevel.error, 2000);
      //  this.globalAlertService.show();
      //})
    }
  }


  LoadData() {
    forkJoin({
      tags: this.fetchTags(),
      categories: this.fetchCategories()
    }).subscribe(results => {
      this.initializeCategories(results.categories);
      this.initializeTags(results.tags);
      this.loadCompositionForEdit()
      this.initializeSupplierService();
    }, error => console.error(error));

    // load items as list
    /*this.itemHttpService.list(this.category).subscribe(result => {
      this.itemService.items = result;
      this.AssignCopy();
    }, error => console.error(error));*/


  }

  public SupplierService: SupplierService | undefined;

  saveItemSourceDetails() {
    this.itemHttpService.setSourceDetail(this.editingItem?.id ?? "",
      this.sourceDetailPPU ?? 0,
      this.sourceDetailVPU ?? 0,
      this.sourceDetailUPB ?? 0,
      this.sourceDetailSK ?? "",
      this.supplierId,
      this.sourceDetailInvId).subscribe(x => {
      if (this.itemService.items == undefined) this.itemService.items = []
      this.error = undefined;
      this.itemService.items.splice(this.itemService.items.indexOf(this.editingItem!), 1, x);
      this.FilterItem(this.searchText);

      this.globalAlertService.createAlertBannerModel("Änderung erfolgreich", `Die Details von ${this.editingItem?.name} wurden erfolgreich gespeichert.`, AlertLevel.success, 2000);
      this.globalAlertService.show();

      this.editingItem = undefined;
      this.sourceDetailPPU = undefined;
      this.sourceDetailVPU = undefined;
      this.sourceDetailUPB = undefined;
      this.sourceDetailSK = undefined;
      this.sourceDetailInvId = undefined;

      this.supplierId = '';
      this.closePopUps();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Speichern fehlgeschlagen", "Speichern der Details ist fehlgeschlagen. Bitte Werte überprüfen.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SetCategory(id: string = "") {
    this.router.navigate(['.'], {relativeTo: this.route, queryParams: {category: id}});
  }

  ToggleTag(tag: TagDto, bypass: boolean = false) {
    // In the composition edit mode, tags are not selectable
    if (this.isCompositionEditMode() && !bypass) return

    if (this.selectedTags.indexOf(tag) > -1) this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
    else this.selectedTags.push(tag);
  }

  shortenWord(word: string, maxLength: number): string {
    maxLength = this.viewportWidth >= 800 ? 50 : 23;
    if (word.length <= maxLength) {
      return word;
    }
    return word.substring(0, maxLength - 3) + "...";
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.getViewportWidth();
  }

  private getViewportWidth(): void {
    this.viewportWidth = window.innerWidth;
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.ItemSettings], {queryParams: {id: id}});
  }

  //Pop ups
  closePopUps() {
    /**
     * Closes all pop ups
     *
     * @return void
     */
    this.editingItem = undefined;
    this.visiblePopUp = PopUps.None
  }


  ItemChanged(item: ItemDto) {
    /**
     * Replaces the element in the items array with the updated one
     * Applies the filter and sorting
     *
     */

    if (this.editingItem === undefined) {
      window.location.reload();
      // ToDo: The Banner wont show in this case
      //this.ShowAlertBanner("Ein Fehler ist aufgetreten."," Die Daten wurden erneut vom Server geladen.", AlertLevel.info)
    }

    let _ReplaceDtoElementModel: ReplaceDtoElementModel<ItemDto> = this.itemService.replaceItemGetNewOldItem(item);
    this.editingItem = undefined;


    // Apply filter and sorting
    this.FilterItem(this.searchText);
    // this.SortByField()


    this.ShowAlertBanner("Item aktualisiert.", "Name erfolgreich von '" + _ReplaceDtoElementModel.OldElement?.name + "' in '" + _ReplaceDtoElementModel.NewElement?.name + "' geändert.", AlertLevel.success)
  }


  // Alert Banner Functions

  ErrorBanner(message: string) {
    /**
     * Triggers the alert banner to show an error message.
     *
     * @param message The message to show
     *
     * @returns void
     */
    this.ShowAlertBanner("Error.", message, AlertLevel.error);
  }

  ShowAlertBanner(header: string, message: string, level: AlertLevel) {
    /**
     * Triggers the alert banner to show a message.
     *
     * @param header The header of the message
     * @param message The message to show
     * @param level The level of the message
     *
     * @returns void
     */


    this.globalAlertService.show(new AlertBannerModel(header, message, level));
  }

  units = Object.entries(Unit).slice(0, Object.entries(Unit).length / 2 - 1);

  ChangeUnitType(item: ItemDto, event: Event) {
    this.itemHttpService.setUnit(item.id, event).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Einheit geändert", "Die Einheit wurde erfolgreich geändert.", AlertLevel.success, 2000);
      this.globalAlertService.show();
      if (this.itemService.items == undefined) return;
      this.itemService.replaceItem(x);
      let components = this.componentsRework.filter(c => c.item.id == x.id);
      components.forEach(component => {
        component.item = x;
      })
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Einheit konnte nicht geändert werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      if (this.itemService.items == undefined) return;
      this.itemService.replaceItem(item);
      let components = this.componentsRework.filter(c => c.item.id == item.id);
      components.forEach(component => {
        component.item = item;
      })
    })
  }

  private itemsChangedSubscription: Subscription | undefined;

  private subscribeToItemsChanged() {
    this.itemsChangedSubscription = this.itemService.itemsChanged.subscribe(
      () => {
        this.AssignCopy();
        this.FilterItem(this.searchText);
      }
    );
  }

  originalComponentsEdited(): boolean {
    let isEdited = false;
    if (this.componentsRework.length != this.originalComponents.length) return true;
    this.componentsRework.forEach(component => {
      if (!this.isOriginalComponent(component)) {
        isEdited = true;
      }
    })
    return isEdited;
  }

  isOriginalComponent(component: CompositionCreateComponent): boolean {
    // check in the originalComponents array if the component is in there
    // check id, item and value
    // if item and value are the same but id is different return true

    let original = this.originalComponents.find(x => this.findComponent(component, x));
    return original != undefined;
  }

  findComponent(n: CompositionCreateComponent, o: CompositionCreateComponent): boolean {
    return n.item.id == o.item.id &&
      n.value == o.value &&
      n.unit == o.unit;
  }


  isCompositionEditMode() {
    return this.compositionId != undefined && this.compositionId != "";
  }

  loadCompositionForEdit() {
    // http://localhost:4200/items?composition=21bfb3c7-3a49-4322-8bd3-f8ad8f8324ce
    this.route.queryParams.subscribe({
      next: params => {
        if (params.composition == undefined) return;
        let compositionId = params.composition;
        if (compositionId == "" || compositionId == undefined) return;

        this.compositionId = compositionId;

        this.compositionHttpService.get(compositionId).subscribe(x => {
          this.newCompositionName = x.name;
          this.newCompositionPrice = x.price;
          this.componentsRework = [];
          x.components.forEach(component => {
            let comp = new CompositionCreateComponent();
            comp.id = component.id;
            comp.item = this.itemService.getItemInItems(component.item)!;
            comp.value = component.value;
            comp.unit = component.unit;
            this.componentsRework.push(comp);
            this.originalComponents.push({...comp});
          })
          this.addTagNlsToSelectedTags(x.tags);

          this.special = x.special;
          this.compositionCreator = true;
        }, error => {
          console.error(error)
          this.globalAlertService.createAlertBannerModel("Fehler", "Die Komposition konnte nicht geladen werden.", AlertLevel.error, 2000);
          this.globalAlertService.show();
        })
      }
    })
  }

  private addTagNlsToSelectedTags(tag: TagNl[]) {
    for (let tagNl of tag) {
      let tagDto = this.getTagById(tagNl.id);
      if (tagDto != undefined) {
        this.ToggleTag(tagDto, true)
      }
    }
  }

  getTagById(id: string): TagDto | undefined {
    return this.tags.find(x => x.id == id);
  }


  ngOnInit(): void {
    this.getViewportWidth();

    this.route.queryParams.subscribe(params => {
      this.category = params.category == undefined ? "" : params.category;
    });
    this.itemService.fetchItems();
    this.subscribeToItemsChanged();
    this.LoadData()
  }

  ngOnDestroy(): void {
    if (this.itemsChangedSubscription != undefined) {
      this.itemsChangedSubscription?.unsubscribe();
    }
  }

  redirectToArticleCategory(id: string) {
    // redirect to the Compositions page with the type as parameter
    this.router.navigate([Routes.Compositions], {queryParams: {type: id, other: false}});
  }


  protected readonly AlertLevel = AlertLevel;
  protected readonly PopUps = PopUps;
  protected readonly DeviceInformation = DeviceInformation;
  protected readonly JSON = JSON;
  protected readonly Object = Object;
  protected readonly Unit = Unit;
  protected readonly Number = Number;
  protected readonly UnitConverter = UnitConverter;
}
