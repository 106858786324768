<div class="px-4 sm:px-6 lg:px-8">
  <div class="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none justify-content-center">
    <div class="sm:flex sm:items-center">
      <div class="inline-flex w-full">
        <a class="pr-1 text-black" href="{{redirectRoute}}">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
               class="bi bi-box-arrow-in-left" viewBox="0 0 36 36">
            <path fill-rule="evenodd" transform="scale(2.25)"
                  d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
            <path fill-rule="evenodd" transform="scale(2.25)"
                  d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
          </svg>
        </a>
        <h1 class="font-semibold leading-6 text-gray-900 !text-3xl inline-flex w-full">
          <span class="place-self-end">Einstellungen</span>
        </h1>
      </div>
    </div>

    <div class="max-w-7xl shadow-lg rounded-lg p-4 mx-auto">
      <div class="flex justify-between">
        <h2 class="text-base font-semibold leading-7 text-gray-900 ">Bearbeiten</h2>
        <a *ngIf="deletePermission && deleteModel!=undefined && model!=undefined" (click)="deleteModelWrapper()"
           class="select-none text-red-600 hover:text-red-900 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
               class="bi bi-trash3" viewBox="-6 -6 36 36">
            <path transform="scale(1.5)"
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
          </svg>
        </a>
      </div>

      <dl class="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
        <ng-container *ngIf="isVisible">
          <ng-container *ngIf="settingsInputElementModel">
            <ng-container *ngFor="let property of sortedInputElements">
              <app-settings-input-element
                *ngIf="property.requiredPermission === undefined || PermissionService.CheckPermission(property.requiredPermission!)"
                [setSettingsInputElementModel]="property"></app-settings-input-element>
            </ng-container>
          </ng-container>
        </ng-container>


        <div class="pt-6 flex justify-content-center">
          <button
            class="flex-shrink-0 border-transparent border-4 text-theme-primary-700 hover:text-theme-primary-900 text-sm py-1 px-4 rounded"
            type="button"
            (click)="abortChangesWrapper()">
            Abbrechen & Zurück
          </button>
          <button
            class="flex-shrink-0 bg-theme-primary-700 hover:bg-theme-primary-900 text-sm text-white py-1 px-4 rounded disabled:bg-gray-400 disabled:border-gray-400 disabled:cursor-not-allowed"
            type="button"
            (click)="this.saveChangesWrapper()">
            <!--[disabled]="!(this.changeableProperties.newItemPricePerUnit.changeValue || this.changeableProperties.newItemQuantity.changeValue || this.changeableProperties.newItemName.changeValue)">-->
            Speichern
          </button>
        </div>
      </dl>
    </div>
  </div>
</div>
