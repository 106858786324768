import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {CategoryDto} from "../models/category-dto";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class CategoryHttpService {
  private _baseUrl: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  list(): Observable<CategoryDto[]> {
    return this.http.get<CategoryDto[]>(this._baseUrl + ServerEndpoints.Inventory_Category_List);
  }

  add(name: string): Observable<CategoryDto> {
    return this.http.post<any>(this._baseUrl + ServerEndpoints.Inventory_Category_Add, {name: name});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + ServerEndpoints.Inventory_Category_Delete + "?id=" + id);
  }

  changeName(id: string, name: string): Observable<any> {
    return this.http.put<CategoryDto>(this._baseUrl + ServerEndpoints.Inventory_Category_ChangeName, {
      Id: id,
      Name: name
    });
  }

  get(id: string): Observable<CategoryDto> {
    return this.http.get<CategoryDto>(this._baseUrl + ServerEndpoints.Inventory_Category_Get + "?id=" + id);
  }
}
