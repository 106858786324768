import {Injectable} from '@angular/core';
import {Platform} from "../enums/platform";

@Injectable({
  providedIn: 'root'
})
export class PlatformScannerService {

  constructor() {
  }

  private isIOS(): boolean {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  isMacOS(): boolean {
    let platforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    return platforms.includes(window.navigator.platform);
  }

  public isMobile(): boolean {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent) || this.isIOS();
  }

  public getUserAgent(): any {
    return navigator.userAgent
  }

  private getBrowser(): Platform {
    if (navigator.userAgent.includes('Chrome')) {
      return Platform.Chrome;
    } else if (navigator.userAgent.includes('Safari')) {

      return Platform.Safari;
    } else if (navigator.userAgent.includes('Mozilla')) {
      return Platform.Mozilla;
    } else {

      return Platform.Other;
    }
  }

  public getPlatform(): Platform {
    let isMobile = this.isMobile();

    switch (this.getBrowser()) {
      case Platform.Chrome:
        return isMobile ? Platform.ChromeMobile : Platform.Chrome;
      case Platform.Safari:
        return isMobile ? Platform.SafariMobile : Platform.Safari;
      case Platform.Mozilla:
        return isMobile ? Platform.MozillaMobile : Platform.Mozilla;
      default:
        return isMobile ? Platform.OtherMobile : Platform.Other;

    }
  }


}
