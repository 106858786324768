<app-basic-page title="Verträge" class="absolute inset-0 flex flex-col">
  <div class="xl:absolute xl:inset-y-0 xl:w-2/5 xl:left-0 xl:pr-4">
    <div class="grid grid-cols-2 gap-3 p-3 items-start" *ngIf="company">
      <div class="stat col-span-2">
        <span class="text-theme-primary-700 fw-bold text-xl">Aktueller Vertrag</span>
      </div>
      <div class="stat">
        <span class="header">Vertragsbeginn</span>
        <div class="inline-flex gap-x-0.5">
          <span class="label fw-light">{{ current ? (current.validFrom | date: "dd. MMMM yyyy") : "-" }}</span>
        </div>
      </div>
      <div class="stat">
        <span class="header">Vertragsende</span>
        <div class="inline-flex gap-x-0.5">
          <span class="label fw-light">{{ current ? (current.validTo | date: "dd. MMMM yyyy") : "-" }}</span>
        </div>
      </div>
      <div class="stat">
        <span class="header">Standorte</span>
        <div class="inline-flex gap-x-0.5">
          <span class="number">{{ company.customerValues.item1?.shardCount ?? "-" }}</span>
          <span class="number">/</span>
          <span class="number">{{ current ? (current.shardCount == -1 ? "&infin;" : current.shardCount) : "-" }}</span>
        </div>
      </div>
      <div class="stat">
        <span class="header">Registrierte Geräte</span>
        <div class="inline-flex gap-x-0.5">
          <span class="number">{{ company.customerValues.item1?.deviceCount ?? "-" }}</span>
          <span class="number">/</span>
          <span
            class="number">{{ current ? (current.deviceCount == -1 ? "&infin;" : current.deviceCount) : "-" }}</span>
        </div>
        <div class="inline-flex gap-x-0.5 pt-2 fw-light"
             *ngIf="company.customerValues.item2?.deviceCount != company.customerValues.item1?.deviceCount">
          <span class="">ab {{ nDate | date: "dd.MM.yyyy" }}:&nbsp;</span>
          <span>{{ company.customerValues.item2?.deviceCount ?? "-" }}</span>
          <span>/</span>
          <span>{{ next ? (next.deviceCount == -1 ? "&infin;" : next.deviceCount) : "-" }}</span>
        </div>
      </div>
      <div class="stat col-span-2" *ngIf="history().length > 0">
        <span class="text-theme-primary-700 fw-bold text-xl">Historie</span>
      </div>
      <div class="stat col-span-2" *ngIf="history().length > 0">
        <div *ngFor="let contract of history()" class="w-full">
          <app-contract-information [contract]="contract" [canEdit]="false"></app-contract-information>
        </div>
      </div>
    </div>
  </div>
  <div class="xl:absolute xl:w-3/5 xl:inset-y-0 xl:right-0 xl:pl-4">
    <span></span>
  </div>
</app-basic-page>

