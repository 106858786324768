import { Component } from '@angular/core';
import {AutomationRoutes} from "../x-models/x-enums/automation-routes";
import {Routes} from "../../../enums/routes";
import {PermissionService} from "../../../_auth/permission.service";

@Component({
  selector: 'app-navbar-automation',
  templateUrl: './navbar-automation.component.html',
  styleUrls: ['./navbar-automation.component.scss']
})
export class NavbarAutomationComponent {
  constructor(public PermissionService: PermissionService) {
  }

    protected readonly AutomationRoutes = AutomationRoutes;
    protected readonly Routes = Routes;
}
