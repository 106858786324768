<div class="absolute inset-0 bg-gray-500 opacity-75 z-50" *ngIf="show">

</div>
<div class="absolute inset-0 z-50" *ngIf="show">
  <div class="h-16 w-16 text-theme-primary-700 top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="animate-spin">
      <g fill="none" fill-rule="evenodd">
        <path
          d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/>
        <path fill="currentColor"
              d="M12 4.5a7.5 7.5 0 1 0 0 15a7.5 7.5 0 0 0 0-15M1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12S17.799 22.5 12 22.5S1.5 17.799 1.5 12"
              opacity="0.1"/>
        <path fill="currentColor"
              d="M12 4.5a7.46 7.46 0 0 0-5.187 2.083a1.5 1.5 0 0 1-2.075-2.166q.662-.635 1.428-1.148A10.46 10.46 0 0 1 12 1.5a1.5 1.5 0 0 1 0 3"/>
      </g>
    </svg>
  </div>
</div>
