<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<div class="space-y-10 divide-y divide-gray-900/10">
  <div *ngIf="false" class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 md:pr-4">
    <div class="px-4 sm:px-0">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Kartenzahlungen</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600">Einstellungen hinsichtlich der Integration mit
        <a href="https://www.sumup.com/de-de/" target="_blank"
           class="text-theme-primary-700 cursor-pointer hover:text-theme-primary-900">SumUp</a>.
      </p>
      <p class="mt-2 text-sm leading-6 text-gray-600">
        Der Schlüssel muss mindestens
        <span class="text-red-800" [class.!text-green-800]="encryption_key.match(upper)">einen Großbuchstaben,</span>
        <span class="text-red-800" [class.!text-green-800]="encryption_key.match(lower)">einen Kleinbuchstaben,</span>
        <span class="text-red-800" [class.!text-green-800]="encryption_key.match(digit)">eine Zahl</span>
        und
        <span class="text-red-800"
              [class.!text-green-800]="encryption_key.match(special)">ein Sonderzeichen enthalten,</span>
        sowie zwischen
        <span class="text-red-800" [class.!text-green-800]="encryption_key.match(length)">8-32 Zeichen</span>
        lang sein.
      </p>
    </div>

    <form class="relative bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div *ngIf="sumUpIsConfigured"
           class="bg-gray-300 sm:rounded-xl absolute inset-0 flex text-green-600 items-center">
        <div class="absolute right-0 top-0 p-3 text-gray-800 hover:text-black cursor-pointer"
             (click)="sumUpIsConfigured = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pencil-square"
               viewBox="0 0 16 16">
            <path
              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
        </div>
        <div class="mx-auto inline-flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
               class="place-self-center bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
          </svg>
          <span class="pl-2 text-black text-3xl place-self-center">SumUp</span>
        </div>
      </div>
      <div class="px-4 py-6 sm:p-8">
        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="encryption_key" class="block text-sm font-medium leading-6 text-gray-900">Schlüssel</label>
            <div class="mt-2">
              <input [(ngModel)]="encryption_key" type="password" name="encryption_key" id="encryption_key"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="encryption_key_confirm" class="block text-sm font-medium leading-6 text-red-800"
                   [class.!text-green-800]="encryption_key == encryption_key_confirm && encryption_key_confirm.match(pattern)">Schlüssel
              bestätigen</label>
            <div class="mt-2">
              <input [(ngModel)]="encryption_key_confirm" type="password" name="encryption_key_confirm"
                     id="encryption_key_confirm"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="su_api_key" class="inline-flex text-sm font-medium leading-6 text-gray-900">
              <span>Affiliate Key (API Key)</span>
              <a href="https://developer.sumup.com/protected/affiliate-keys/" target="_blank"
                 class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer place-self-center pl-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                  <path fill-rule="evenodd"
                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                </svg>
              </a>
            </label>
            <div class="mt-2">
              <input [(ngModel)]="su_api_key" id="su_api_key" name="su_api_key" type="password"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button (click)="CardPaymentReset()" type="button" class="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button (click)="CardPaymentSave()" type="submit"
                class="rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">
          Save
        </button>
      </div>
    </form>
  </div>

  <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 md:pr-4">
    <div class="px-4 sm:px-0">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Regionale Einstellungen</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600"></p>
    </div>

    <div class="relative bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div class="px-4 pt-2 pb-4 sm:p-8">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-6"
               *ngIf="permissionService.CheckPermission(permissionService.Administration_Settings_GetTimezone())">
            <label class="inline-flex text-sm font-medium leading-6 text-gray-900">
              <span>Server-Zeitzone</span>
            </label>
            <div class="mt-2">
              <ng-select
                [disabled]="!permissionService.CheckPermission(permissionService.Administration_Settings_SetTimezone())"
                [items]="timeZones" bindValue="id" bindLabel="name"
                class="ng-select cheqme-style text-sm w-full" placeholder="Zeitzone wählen (Standard: UTC)"
                [ngModel]="selectedTimeZone" (ngModelChange)="timeZoneSet($event)">
                <ng-option *ngFor="let zone of timeZones" [value]="zone">
                  {{ zone }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 md:pr-4">
    <div class="px-4 sm:px-0">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Dienstplan Einstellungen</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600"></p>
    </div>

    <div class="relative bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div class="px-4 pt-2 pb-4 sm:p-8">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6" *ngIf="shiftmaker_settings">
          <div class="sm:col-span-3"
               *ngIf="permissionService.CheckPermission(permissionService.Administration_Settings_GetShiftMakerSettings())">
            <label class="flex flex-col text-sm leading-6 text-gray-900">
              <span class="font-medium">Rundungsteile pro Stunde</span>
            </label>
            <div class="mt-2">
              <input [(ngModel)]="shiftmaker_settings.segmentsPerHour"
                     (focusout)="shiftmaker_settings_save()"
                     type="number" min="1" max="60" step="1"
                     [disabled]="!permissionService.CheckPermission(permissionService.Administration_Settings_SetShiftMakerSettings())"
                     class="mt-2 text-center block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            </div>
            <div class="mt-2 text-sm text-gray-600">
              <span class="font-bold">Hinweise:</span>
              <ul class="list-disc">
                <li>15-Minuten-Abschnitte &rarr; 4 Rundungsteile</li>
                <li>muss Teiler von 60 sein, z. B. 4, 6, 10, 15</li>
              </ul>
            </div>
          </div>
          <div class="sm:col-span-3"
               *ngIf="permissionService.CheckPermission(permissionService.Administration_Settings_GetShiftMakerSettings())">
            <label class="flex flex-col text-sm leading-6 text-gray-900">
              <span class="font-medium">Zeitpunkt zum Aufrunden</span>
            </label>
            <div class="mt-2">
              <input [(ngModel)]="shiftmaker_settings.segmentRoundingPoint"
                     (focusout)="shiftmaker_settings_save()"
                     type="number" min="1" max="60" step="1"
                     [disabled]="!permissionService.CheckPermission(permissionService.Administration_Settings_SetShiftMakerSettings())"
                     class="mt-2 text-center block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            </div>
            <div class="mt-2 text-sm text-gray-600">
              <span class="font-bold">Hinweise:</span>
              <ul class="list-disc">
                <li>ab 5 Minuten nach Abschnittsanfang aufrunden &rarr; 5 Minuten</li>
                <li>max. Wert 60 / Rundungsteile</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 md:pr-4">
    <div class="px-4 sm:px-0">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Logo Einstellungen</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600">Die maximal akzeptierte Dateigröße ist {{maxFileSizeMb}}MB.</p>
    </div>

    <div class="relative bg-white shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div class="px-4 pt-2 pb-4 sm:p-8">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-6" *ngIf="permissionService.CheckPermission(permissionService.Admin_Settings_GetTimezone())">
            <label class="inline-flex text-sm font-medium leading-6 text-gray-900">
              <span>Aktuelles Logo</span>
            </label>
            <div class="mt-2">

            </div>
            <label class="inline-flex text-sm font-medium leading-6 text-gray-900">
              <span>Neues Logo</span>
            </label>
            <div class="mt-2 flex">
              <input class="place-self-center p-0 m-0 w-full" type="file" id="file" accept="image/*" width="512" height="512" />
              <button class="place-self-center bg-cyan-600 hover:bg-cyan-800 cursor-pointer select-none rounded-lg text-white px-2 py-1" type="submit" (click)="upload($event)">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</div>
<app-error-banner [(error)]="error"></app-error-banner>
