import {Component, EventEmitter, Input} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ItemHttpService} from "../../http-requests/item-http.service";
import {PermissionService} from "../../_auth/permission.service";
import {ModelBase} from "../../models/model-base";
import {SettingsPageComponent} from "../../x-components/settings/settings-page/settings-page.component";
import {ItemDto} from "../../models/item-dto";
import {SettingsInputElementModel} from "../../models/settings-input-element-model";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {Routes} from "../../enums/routes";
import {HttpPropertyChangeStatus} from "../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../models/observable-queue-element";

@Component({
  selector: 'app-items-settings-page',
  templateUrl: '../../x-components/settings/settings-page/settings-page.component.html',//'./items-settings-page.component.html',
  styleUrls: ['./items-settings-page.component.scss']
})
export class ItemsSettingsPageComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.Items;
  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newItemName: new SettingsInputElementModel(),
    newItemPricePerUnit: new SettingsInputElementModel(),
    newItemQuantity: new SettingsInputElementModel(),
  };


  public model?: ItemDto;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;


  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.itemHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Item gelöscht", "Item wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  @Input() loadInputModel: Function = (id: string) => {
    this.itemHttpService.get(id).subscribe((model: ItemDto) => {
      this.model = model;
      this.settingsInputElementModel.newItemName.originalPropertyValue = this.settingsInputElementModel.newItemName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newItemPricePerUnit.originalPropertyValue = this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue = this.model?.latestSourceDetail?.pricePerUnit
      this.settingsInputElementModel.newItemQuantity.originalPropertyValue = this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue = this.model?.latestSourceDetail?.volumePerUnit
      this.updateElements()
    });
  };

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName());
    this.requiresChanges.push(this.saveSourceDetail());

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
    /*if (this.model === undefined) return;

    let successfulNameChange = this.saveName();

    let successfulSourceDetailChange = this.saveSourceDetail();

    if (successfulNameChange || successfulSourceDetailChange) {
      this.globalAlertService.createAlertBannerModel("Erfolgreich", "Die Änderungen wurden erfolgreich gespeichert.", AlertLevel.success, 5000);
      this.globalAlertService.show();
      setTimeout(() => {
        this.redirect();
      }, 2000);
    }*/

  };

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (!this.settingsInputElementModel.newItemName.changeableProperties.changeValue) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newItemName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newItemName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.itemHttpService.changeName(this.model?.id, this.settingsInputElementModel.newItemName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: ItemDto) => {
      this.model = model;
      this.settingsInputElementModel.newItemName.originalPropertyValue = this.settingsInputElementModel.newItemName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newItemName.changeableProperties.changeValue = false;
      observableQueueElement.successIndicator = true;
      this.updateElements()
    }

    observableQueueElement.callbackError = () => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Der Name konnte nicht geändert werden.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);
    return HttpPropertyChangeStatus.Changed;
  }


  saveSourceDetail(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (!this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.changeValue && !this.settingsInputElementModel.newItemQuantity.changeableProperties.changeValue) return HttpPropertyChangeStatus.NotChanged;

    let pricePerUnit = this.parseToNumber(this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue);
    let quantity = this.parseToNumber(this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue);


    if (pricePerUnit === undefined) {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein. Fehlerbehaftete Eingabe: " + this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue, AlertLevel.error, 5000);
      this.globalAlertService.show();
      return HttpPropertyChangeStatus.NotChanged;
    }
    if (quantity === undefined) {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein. Fehlerbehaftete Eingabe: " + this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue, AlertLevel.error, 5000);
      this.globalAlertService.show();
      return HttpPropertyChangeStatus.NotChanged;
    }

    if (pricePerUnit === this.model?.latestSourceDetail?.pricePerUnit && quantity === this.model?.latestSourceDetail?.volumePerUnit) return HttpPropertyChangeStatus.NotChanged;
    //upb: number = 0, sk: string = "", sid: string = ""
    let upb = this.model?.latestSourceDetail?.unitsPerBundle;
    let sk = this.model?.latestSourceDetail?.supplierKey;
    let sid = this.model?.latestSourceDetail?.supplier ? this.model?.latestSourceDetail?.supplier.id : "";

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.itemHttpService.setSourceDetail(this.model?.id, pricePerUnit, quantity, upb, sk, sid);
    observableQueueElement.callback = (model: ItemDto) => {
      this.model = model;
      this.settingsInputElementModel.newItemPricePerUnit.originalPropertyValue = this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue = this.model?.latestSourceDetail?.pricePerUnit
      this.settingsInputElementModel.newItemQuantity.originalPropertyValue = this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue = this.model?.latestSourceDetail?.volumePerUnit

      this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.changeValue = false;
      this.settingsInputElementModel.newItemQuantity.changeableProperties.changeValue = false;
      observableQueueElement.successIndicator = true;
      this.updateElements()
    }
    observableQueueElement.callbackError = () => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Gebindedetails.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);
    return HttpPropertyChangeStatus.Changed;
  }


  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public itemHttpService: ItemHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newItemName.propertyTitle = "Name";
    this.settingsInputElementModel.newItemPricePerUnit.propertyTitle = "Preis pro Einheit";
    this.settingsInputElementModel.newItemQuantity.propertyTitle = "Menge pro Einheit";


    this.settingsInputElementModel.newItemName.requiredPermission = PermissionService.Inventory_Item_ChangeName();
    this.settingsInputElementModel.newItemPricePerUnit.requiredPermission = PermissionService.Inventory_Item_SetSourceDetail();
    this.settingsInputElementModel.newItemQuantity.requiredPermission = PermissionService.Inventory_Item_SetSourceDetail();

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_Item_Delete());
  }


  /*saveName() {
    if (this.model === undefined) return false;
    if (!this.settingsInputElementModel.newItemName.changeableProperties.changeValue) {
      return false;
    }

    this.itemHttpService.changeName(this.model?.id, this.settingsInputElementModel.newItemName.changeableProperties.newPropertyValue).subscribe((model) => {
      this.model = model;
      this.settingsInputElementModel.newItemName.originalPropertyValue = this.settingsInputElementModel.newItemName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newItemName.changeableProperties.changeValue = false;
      this.updateElements()
    }, (error) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      return false;
    }
    )
    return true;

  }*/

  /*saveSourceDetail() {
    if (this.model === undefined) return false;

    if (!(this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.changeValue || this.settingsInputElementModel.newItemQuantity.changeableProperties.changeValue)) {
      return false;
    }

    let pricePerUnit = this.parseToNumber(this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue);
    let quantity = this.parseToNumber(this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue);


    if (pricePerUnit === undefined) {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein. Fehlerbehaftete Eingabe: " + this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue, AlertLevel.error, 5000);
      this.globalAlertService.show();
      return false;
    }
    if (quantity === undefined) {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein. Fehlerbehaftete Eingabe: " + this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue, AlertLevel.error, 5000);
      this.globalAlertService.show();
      return false;
    }

    this.itemHttpService.setSourceDetail(this.model?.id, pricePerUnit, quantity).subscribe((model) => {
        this.model = model;

        this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.changeValue = false;
        this.settingsInputElementModel.newItemQuantity.changeableProperties.changeValue = false;

        this.settingsInputElementModel.newItemPricePerUnit.originalPropertyValue = this.settingsInputElementModel.newItemPricePerUnit.changeableProperties.newPropertyValue = this.model?.latestSourceDetail?.pricePerUnit
        this.settingsInputElementModel.newItemQuantity.originalPropertyValue = this.settingsInputElementModel.newItemQuantity.changeableProperties.newPropertyValue = this.model?.latestSourceDetail?.volumePerUnit
        this.updateElements()
      }, error => {
        this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Gebindeeinstellungen", AlertLevel.error, 5000);
        this.globalAlertService.show();
        return false;
      }
    );
    return true;
  }*/

}
