import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ChangelogHttpService} from "../http-requests/changelog-http.service";
import {ChangelogItemDto} from "../x-models/changelog/changelog-item-dto";
import {marked} from "marked";
import {DomSanitizer} from "@angular/platform-browser";
import {
  ImageService,
  LinkService,
  MarkdownEditorService,
  MarkdownFormatter,
  RichTextEditorAllModule,
  RichTextEditorComponent,
  TableService,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import {DialogModule} from "@angular/cdk/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ToolbarModule} from "@syncfusion/ej2-angular-navigations";
import {createElement} from "@syncfusion/ej2-base";
import {GlobalAlertService} from "../_services/global-alert.service";
import {AlertLevel} from "../enums/alert-level";
import {PermissionService} from "../_auth/permission.service";
import {TimeUtilities} from "../utils/time-utilities";

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
  providers: [FormsModule, ReactiveFormsModule, RichTextEditorAllModule, DialogModule, ToolbarService, LinkService, ImageService, MarkdownEditorService, TableService],
})
export class ChangelogComponent implements OnInit, AfterViewInit {
  constructor(private changelogHttpService: ChangelogHttpService, public DomSanitizer: DomSanitizer,
              private globalAlertService: GlobalAlertService, public PermissionService: PermissionService) {
  }

  ngAfterViewInit(): void {
    if (!this.PermissionService.CheckPermission(this.PermissionService.Owner())) return;
    this.textArea = this.changelogRTE.contentModule.getEditPanel!() as HTMLTextAreaElement;
    this.textArea.addEventListener('keyup', () => {
      this.markdownConversion()
    });
    this.mdsource = document.getElementById('preview-code')!;
    this.mdsource.addEventListener('click', (e: MouseEvent) => {
      this.fullPreview();
      if ((e.target as HTMLElement).parentElement!.classList.contains('e-active')) {
        this.changelogRTE.disableToolbarItem(['Bold', 'Italic', 'StrikeThrough', 'Formats', 'OrderedList',
          'UnorderedList', 'CreateTable', 'SuperScript', 'SubScript', 'CreateLink', 'Image']);
      } else {
        this.changelogRTE.enableToolbarItem(['Bold', 'Italic', 'StrikeThrough', 'Formats', 'OrderedList',
          'UnorderedList', 'CreateTable', 'SuperScript', 'SubScript', 'CreateLink', 'Image']);
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.changelogHttpService.list().subscribe(x => {
      this.changelogItems = x;
      this.filterFunc();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Changelog-Items konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  @ViewChild("changelogRTE") public changelogRTE!: RichTextEditorComponent;
  @ViewChild("valueTemplate") public valueTemplate!: any;
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'StrikeThrough', '|',
      'Formats', 'OrderedList', 'UnorderedList', 'SuperScript', 'SubScript', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      {
        tooltipText: 'Preview',
        template: '<button id="preview-code" class="e-tbar-btn e-control e-btn e-icon-btn" style="display: flex;">' +
          '<span class="" style="margin: auto;">Preview</span></button>'
      }, '|', 'Undo', 'Redo']
  };
  public formatter: MarkdownFormatter = new MarkdownFormatter({listTags: {'OL': '1., 2., 3.'}});
  public textArea!: HTMLTextAreaElement;
  public mdsource!: HTMLElement;

  public onCreate(): void {

  }

  public markdownConversion(): void {
    if (this.mdsource.classList.contains('e-active')) {
      const id: string = this.changelogRTE.getID() + 'html-view';
      const htmlPreview: Element = this.changelogRTE.element.querySelector('#' + id)!;

      htmlPreview.innerHTML = <string>marked.parse((this.changelogRTE.contentModule.getEditPanel!() as HTMLTextAreaElement).value);
    }
  }

  public fullPreview(): void {
    const id: string = this.changelogRTE.getID() + 'html-preview';
    let htmlPreview: HTMLElement = this.changelogRTE.element.querySelector('#' + id) as HTMLElement;
    const previewTextArea: HTMLElement = this.changelogRTE.element.querySelector('.e-rte-content') as HTMLElement;
    if (this.mdsource.classList.contains('e-active')) {
      this.mdsource.classList.remove('e-active');
      this.textArea.style.display = 'block';
      htmlPreview.style.display = 'none';
      previewTextArea.style.overflow = 'hidden';
    } else {
      this.mdsource.classList.add('e-active');
      if (!htmlPreview) {
        htmlPreview = createElement('div', {className: 'e-content e-pre-source'});
        htmlPreview.id = id;
        this.textArea.parentNode!.appendChild(htmlPreview);
        previewTextArea.style.overflow = 'auto';
      }
      if (previewTextArea.style.overflow === 'hidden') {
        previewTextArea.style.overflow = 'auto';
      }
      this.textArea.style.display = 'none';
      htmlPreview.style.display = 'block';
      htmlPreview.innerHTML = <string>marked.parse((this.changelogRTE.contentModule.getEditPanel!() as HTMLTextAreaElement).value);
    }
  }

  changelogItems: ChangelogItemDto[] | undefined;
  changelogFiltered: ChangelogItemDto[] | undefined;
  filter: string = "";

  assignCopy() {
    if (!this.changelogItems) return;
    this.changelogFiltered = Object.assign([], this.changelogItems);
  }

  filterFunc() {
    if (!this.changelogItems) return;
    if (this.filter == "") {
      this.assignCopy();
      return;
    }

    this.changelogFiltered = Object.assign([], this.changelogItems.filter(x => x.content.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 || x.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1));
  }

  fixHtmlReplacement() {
    if (!this.editorContent) return;
    this.editorContent = this.editorContent.replace("&gt;", ">");
  }

  post() {
    this.changelogHttpService.add(this.editorTitle, this.editorContent).subscribe(x => {
      if (!this.changelogItems) this.changelogItems = [];
      this.changelogItems.splice(0, 0, x);
      this.editorContent = "#";
      this.editorTitle = "";
      this.filterFunc();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Changelog-Eintrag konnte nicht erstellt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  editorTitle: string = "";
  editorContent: string = "#";

  editingItem: ChangelogItemDto | undefined;
  editingItemActive: boolean = true;
  editingItemNewDate: Date | undefined;

  edit(item: ChangelogItemDto) {
    this.scrollToEditor()
    this.editingItem = item;
    this.editorTitle = item.name;
    this.editorContent = item.content;
  }

  scrollToEditor() {
    const element = document.getElementById("changelogRTE")
    if (element != null) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }

  update(id: string, title: string, content: string, date: Date | undefined = undefined) {
    this.changelogHttpService.update(id, title, content, date).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Erfolgreich", "Changelog-Item wurde erfolgreich aktualisiert.", AlertLevel.success, 2000);
      this.globalAlertService.show();
      this.changelogItems!.splice(this.changelogItems!.findIndex(c => c.id == x.id), 1, x);
      this.filterFunc();
      this.clearEdit()
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Changelog-Item konnte nicht geändert werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  setDate(date: string) {
    this.editingItemNewDate = new Date(date)
  }

  clearEdit() {
    this.editingItem = undefined;
    this.editorTitle = "";
    this.editorContent = "#";
  }

  deleteEdit() {
    if (!this.editingItem) return;
    let c = confirm("Den angezeigten Changelog-Eintrag sicher löschen?");
    if (!c) return;
    this.changelogHttpService.delete(this.editingItem.id).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Erfolg", "Changelog Eintrag-wurde gelöscht.", AlertLevel.success, 2000);
      this.globalAlertService.show();
      this.replaceAndFilter(x)
      this.clearEdit()
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Changelog-Item konnte nicht gelöscht werden.", AlertLevel.error, 2000);
      this.globalAlertService.show()
    })
  }

  reactivateEdit() {
    if (!this.editingItem) return;
    this.changelogHttpService.reactivate(this.editingItem.id).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Erfolg", "Changelog-Item erfolgreich reaktiviert.", AlertLevel.success, 2000);
      this.globalAlertService.show()
      this.replaceAndFilter(x);
      this.clearEdit();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Changelog-Item konnte nicht reaktiviert werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  replaceAndFilter(n: ChangelogItemDto) {
    if (!this.changelogItems) return;
    this.changelogItems.splice(this.changelogItems.findIndex(x => x.id == n.id), 1, n);
    this.filterFunc();
  }

  protected readonly TimeUtilities = TimeUtilities;
}
