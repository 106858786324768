<a *ngIf="!actions && !mobile" class="select-none cursor-pointer relative flex items-center bg-shape bg-gray-800 hover:bg-gray-600 px-[1.15rem] text-sm font-medium text-white h-10"
   [ngClass]="{'!bg-gray-300 !text-black': navbarItem.isSelected}">
  <div class="w-2 inset-y-0 left-0 absolute rounded-br-full bg-gray-800"></div>
  <span class="pb-2">{{ navbarItem.title }}</span>
  <div class="w-2 inset-y-0 right-0 absolute rounded-bl-full bg-gray-800"></div>
</a>

<a *ngIf="!actions && mobile" class="select-none cursor-pointer block text-right rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
[ngClass]="{'!bg-gray-900 !text-white': navbarItem.isSelected}">{{ navbarItem.title }}</a>

<div #itemContainer class="absolute -translate-y-24 transition-all" [ngClass]="{'!translate-y-0': navbarItem.isExpanded && navbarItem.isSelected}">
  <div *ngIf="actions" class="w-screen h-[6rem] bg-gray-300 p-1">
    <ng-content></ng-content>
  </div>
</div>

