import {Component, Inject, OnInit} from '@angular/core';
import {GeneralHttpService} from "../../http-requests/general-http.service";
import {AuthenticationService} from "../authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {UserSelectorService} from "../../_services/user-selector.service";
import {UserDto} from "../../models/admin_owner/user/user-dto";
import {UserJwt} from "../../models/admin_owner/user/user-jwt";
import {UrlHelper} from "../../helpers/url-helper";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  error: HttpErrorResponse | undefined;

  constructor(public AuthenticationService: AuthenticationService,
              private router: Router,
              private generalHttpService: GeneralHttpService,
              private route: ActivatedRoute,
              private globalAlertService: GlobalAlertService,
              public UserSelectorService: UserSelectorService) {
    if (this.AuthenticationService.userValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
  }

  login(name: string, password: string) {
    this.AuthenticationService.login(name, password).pipe(first()).subscribe({
      next: () => {
        const returnUrl = UrlHelper.QueryParams.returnUrl || '/';
        // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        window.open(returnUrl, '_self');
      }, error: (error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.globalAlertService.createAlertBannerModel("Fehler", "Bitte Benutzerdaten überprüfen.", AlertLevel.error, 3000);
          this.globalAlertService.show();
        } else if (error.status == 403) {
          this.globalAlertService.createAlertBannerModel("Fehler", "Aus Sicherheitsgründen wurde der Zugang temporär deaktiviert.", AlertLevel.error, 3000);
          this.globalAlertService.show();
        }
      }
    })

    //this.generalHttpService.login(name, password).subscribe(x => {
    //  CookieHelper.setCookie("JWT", x.jwt, 365);
    //  window.location.reload();
    //}, error => console.error(error));
  }

  reauth(user: UserJwt) {
    this.username = user.name;
    this.showLogin = true;
  }

  showLogin: boolean = false;

  protected readonly location = location;
}
