<div class="w-full inline-flex gap-x-2">
  <a (click)="e()" class="h-6 w-6 place-self-center" *ngIf="canEdit">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="1.5"
            d="m5 16l-1 4l4-1L19.586 7.414a2 2 0 0 0 0-2.828l-.172-.172a2 2 0 0 0-2.828 0zM15 6l3 3m-5 11h8"/>
    </svg>
  </a>
  <div class="w-full grid grid-cols-3 xl:grid-cols-5 place-self-center">
    <span class="col-span-3 xl:col-span-2">{{ contract.validFrom | date: "dd.MM.yyyy" }}
      - {{ contract.validTo | date: "dd.MM.yyyy" }}</span>
    <span>Shards:
    <span class="fw-light">{{ contract.shardCount }}</span>
  </span>
    <span>Geräte:
    <span class="fw-light">{{ contract.deviceCount }}</span>
  </span>
    <span>Monate:
    <span class="fw-light">{{ contract.minimalMonthsPerDevice }}</span>
  </span>
  </div>
</div>
