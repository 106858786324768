import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
  Output,
} from "@angular/core";
import { NavbarItem } from "../models/navbar-item";

@Component({
  selector: "app-navbar-office-item",
  templateUrl: "./navbar-office-item.component.html",
  styleUrls: ["./navbar-office-item.component.scss"],
})
export class NavbarOfficeItemComponent implements AfterViewInit {
  @Input() navbarItem!: NavbarItem;
  @Output() selectedItem = new EventEmitter<NavbarItem>();

  @Input() actions: boolean = false;
  @Input() mobile: boolean = false;

  @ViewChild("itemContainer") itemContainer!: ElementRef;

  constructor(private elementRef: ElementRef) {

  }

  ngAfterViewInit(): void {
    this.itemContainer.nativeElement.addEventListener("wheel", (e: WheelEvent) => {
      e.preventDefault();
      // noinspection JSSuspiciousNameCombination
      this.itemContainer.nativeElement.children[0].children[0].children[0].children[0].scrollLeft += e.deltaY;
    });
  }
}
