import {Component, OnInit} from '@angular/core';
import {EnvironmentSettingHttpService} from "../x-http-requests/environment-setting-http.service";
import {EnvironmentSetting} from "../x-models/environment-setting";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {PermissionService} from "../../_auth/permission.service";
import {AuthenticationService} from "../../_auth/authentication.service";
import {PermissionSet} from "../../_auth/Models/PermissionSet";

@Component({
  selector: 'app-environment-settings',
  templateUrl: './owner-environment-settings.component.html',
  styleUrls: ['./owner-environment-settings.component.scss']
})
export class OwnerEnvironmentSettingsComponent implements OnInit {
  settings: EnvironmentSetting[] = [];
  editable: boolean = false;
  private bSettings: EnvironmentSetting[] = [];

  constructor(private environmentSettingHttpService: EnvironmentSettingHttpService,
              private globalAlertService: GlobalAlertService,
              private authenticationService: AuthenticationService) { }

  LoadData(): void {
    this.environmentSettingHttpService.List().subscribe(x => {
      this.settings = x;
      this.settings.forEach(x => x.empty = false)
      this.settings.push(new EnvironmentSetting(true))
      this.bSettings = this.settings.map(x => JSON.parse(JSON.stringify(x)));
    }, error => {
      this.globalAlertService.createAlertBannerModel("Error", "An error occurred while loading the requested resource.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  GetSettings(editable: boolean): EnvironmentSetting[] {
    return this.settings.filter(x => !x.empty || editable);
  }

  ResetValues() {
    this.settings = []
    this.settings = this.bSettings.map(x => JSON.parse(JSON.stringify(x)));
  }

  Upsert(variable: EnvironmentSetting, empty: boolean) {
    let index = this.settings.indexOf(variable);
    if (index == -1 || !variable.updated) return;
    this.environmentSettingHttpService.Upsert(variable, empty).subscribe(x => {
      this.settings.splice(index, 1, x);
      this.bSettings.splice(index, 1, x);
      if (index == this.settings.length - 1) {
        this.settings.push(new EnvironmentSetting(true))
      }
    }, error => {
      this.settings.splice(index, 1, JSON.parse(JSON.stringify(this.bSettings[index])));
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Error", `Setting could not be saved! (${error.body})`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  Delete(variable: EnvironmentSetting) {
    let confirmation = confirm("Are you sure you want to delete the following setting:\n" + variable.id + "\n" + variable.value);
    if (!confirmation) return;
    let index = this.settings.indexOf(variable);
    if (index == -1) return;
    this.environmentSettingHttpService.Delete(variable.id).subscribe(x => {
      this.settings.splice(index, 1);
      this.bSettings.splice(index, 1);
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Error", `Setting could not be deleted! (${error.body})`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  ngOnInit(): void {
    this.LoadData();
    this.GetPermissions()
  }

  protected readonly Set = Set;

  GetPermissions() {
    let methodMappings: any = {};

    const prototype = Object.getPrototypeOf(new PermissionService(this.authenticationService));
    for (const methodName of Object.getOwnPropertyNames(prototype)) {
      try {
        const method = prototype[methodName];
        if (typeof method === "function" && methodName != "constructor") {
          const returnType = method();
          if (returnType instanceof PermissionSet) {
            methodMappings[methodName] = returnType;
          }
        }
      } catch (e) {

      }
    }

    for (const methodName of Object.getOwnPropertyNames(new PermissionService(this.authenticationService))) {
      try {
        const method = prototype[methodName];

        if (typeof method === "function" && methodName != "constructor") {
          const returnType = method();
          if (returnType instanceof PermissionSet) {
            methodMappings[methodName] = returnType;
          }
        }
      } catch (e) {

      }
    }
  }
}
