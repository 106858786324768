import {Component, HostListener} from '@angular/core';
import {Router} from "@angular/router";
import {CookieHelper} from "../../helpers/CookieHelper";
import {AuthenticationService} from "../../_auth/authentication.service";
import {UserJwt} from "../../models/admin_owner/user/user-jwt";
import {EmployeePanelRoutes} from "./employee-panel-routes";
import {Constants} from "../../constants";

@Component({
  selector: 'app-employee-panel',
  templateUrl: './employee-panel.component.html',
  styleUrls: ['./employee-panel.component.scss']
})
export class EmployeePanelComponent {
  constructor(private router: Router,
              private authenticationService: AuthenticationService) {
    this.user = this.authenticationService.userValue
  }

  user: UserJwt | null;
  sidebar: boolean = true;


  logout() {
    //CookieHelper.deleteCookie("JWT");
    CookieHelper.deleteCookie(Constants.Cookies.Hangfire);
    this.authenticationService.logout();
  }

  protected readonly EmployeePanelRoutes = EmployeePanelRoutes;
  protected readonly Router = Router;
}
