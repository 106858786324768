<div class="grid grid-cols-4 py-0.5 lg:p-2 rounded-xl mt-3 hover:bg-gray-100 select-none cursor-pointer"
     [routerLinkActive]="['bg-gray-200', 'text-theme-primary-700']">
  <div class="col-span-1 flex" #icon>
    <div class="m-auto h-8 w-8 lg:h-10 lg:w-10">
      <ng-content/>
    </div>
  </div>
  <div class="col-span-3 flex" #text>
    <div class="flex flex-col place-self-center -space-y-2">
      <span class="lg:text-lg">{{ title }}</span>
      <span *ngIf="subtitle != ''" class="text-gray-400">{{ subtitle }}</span>
    </div>
  </div>
</div>
