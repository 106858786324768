import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-navbar-item-small-container',
  templateUrl: './navbar-item-small-container.component.html',
  styleUrls: ['./navbar-item-small-container.component.scss']
})
export class NavbarItemSmallContainerComponent {
  @Input() breadcrumb: string | undefined;
}
