import {EventEmitter, Injectable} from '@angular/core';
import {ItemDto} from "../../models/item-dto";
import {ItemHttpService} from "../../http-requests/item-http.service";
import {PermissionService} from "../../_auth/permission.service";
import {BehaviorSubject, Observable, timer} from "rxjs";
import {ReplaceDtoElementModel} from "../../models/misc/replace-dto-element-model";
import {ReplacedElementStatus} from "../../enums/replaced-element-status";

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  public items?: ItemDto[] = undefined;
  public itemsChanged = new BehaviorSubject<ItemDto[]>([]);
  public indexPositionDict: { [id: string]: number } = {};

  constructor(private itemHttpService: ItemHttpService, public PermissionService: PermissionService) {
    this.fetchItems();
  }

  public fetchItems() {
    this.itemHttpService.list().subscribe(result => {
      this.items = result;
      this.itemsChanged.next(this.items);
    }, error => console.error(error));
  }

  public replaceItem(item: ItemDto): ReplacedElementStatus {


    if (this.items === undefined) {
      this.items = [item];
      return ReplacedElementStatus.UndefinedArrayCreatedAndElementAdded;
    }
    let index = this.items.findIndex(x => x.id === item.id);

    if (index !== -1) {
      this.items.splice(index, 1, item)
      this.itemsChanged.next(this.items);
      return ReplacedElementStatus.ElementReplaced;
    }

    this.items.push(item);
    this.itemsChanged.next(this.items);
    return ReplacedElementStatus.NotFoundAndElementAdded;
  }

  /**
   * Replaces an item and returns the old and new item.
   * If the item is not found, the old item is undefined.
   * If the array is undefined, it will be created.
   *
   * @param item to replace
   * @return ReplaceDtoElementModel<ItemDto> with old and new item
   */
  public replaceItemGetNewOldItem(item: ItemDto): ReplaceDtoElementModel<ItemDto> {

    if (this.items === undefined) {
      this.items = [];
    }

    let _ReplaceDtoElementModel: ReplaceDtoElementModel<ItemDto> = new ReplaceDtoElementModel<ItemDto>();
    let index = this.items.findIndex(x => x.id === item.id);

    if (index !== -1) {
      _ReplaceDtoElementModel.OldElement = this.items.slice(index, index + 1)[0];
    }

    this.replaceItem(item);
    _ReplaceDtoElementModel.NewElement = this.items[index];

    return _ReplaceDtoElementModel;

  }


  public getItemInItems(id: string): ItemDto | undefined {
    if (this.items === undefined) {
      return undefined;
    }
    return this.items.find(x => x.id === id);
  }

  public fetchSingleItem(id: string, all: boolean = false): Observable<ItemDto> {
    return this.itemHttpService.get(id);
  }


  public blinkTest() {
    timer(1000, 20000).subscribe(() => {
      if (this.items === undefined) {
        this.items = [];
      }
      if (this.items.length !== 0) {
        this.items = [];
      } else {
        this.itemHttpService.list().subscribe(result => {
            this.items = result;
            this.itemsChanged.next(this.items);
          }
        )
      }
    });
  }

}
