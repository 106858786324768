import {EventEmitter, Injectable} from '@angular/core';

import {AlertBannerModel} from "../models/alert-banner-model";
import {AlertLevel} from "../enums/alert-level";

@Injectable({
  providedIn: 'root'
})
export class GlobalAlertService {

  public AlertBannerModel: AlertBannerModel = new AlertBannerModel();

  public IsAlertBannerVisibleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  public setAlertBannerModel(alertBannerModel: AlertBannerModel) {
    this.AlertBannerModel = alertBannerModel;

  }

  public createAlertBannerModel(header:string= "", message:string= "", level:AlertLevel= AlertLevel.info, duration:number= 5000) {

    this.AlertBannerModel = new AlertBannerModel(header, message, level, duration);
  }

  public show(alertBannerModel: AlertBannerModel | undefined= undefined) {
    if (alertBannerModel !== undefined) {
      this.setAlertBannerModel(alertBannerModel)
    }
    this.IsAlertBannerVisibleChanged.emit(true);
  }

  public createAndShow(header:string= "", message:string= "", level:AlertLevel= AlertLevel.info, duration:number= 5000){
    this.AlertBannerModel = new AlertBannerModel(header, message, level, duration);
    this.IsAlertBannerVisibleChanged.emit(true);
  }

}
