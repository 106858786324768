import {Component, Input, OnInit} from '@angular/core';
import {SettingsPageComponent} from "../../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../../enums/routes";
import {CompanyRoutes} from "../../x-models/x-enums/company-routes";
import {SettingsInputElementModel} from "../../../../models/settings-input-element-model";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../../../_auth/permission.service";
import {EmployeeAttributeHttpService} from "../../../x-components/x-services/employee-attribute-http.service";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {EmployeeAttributeDto} from "../../x-models/employee-attribute-dto";
import {AlertLevel} from "../../../../enums/alert-level";
import {EmploymentRelationshipDto} from "../../x-models/employment-relationship-dto";
import {HttpPropertyChangeStatus} from "../../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../../models/observable-queue-element";

@Component({
  selector: 'app-employee-attributes-settings',
  templateUrl: '../../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./employee-attributes-settings.component.scss']
})
export class EmployeeAttributesSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.CompanyEmployee;
  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newEmployeeAttributeName: new SettingsInputElementModel(),
    newEmployeeShortName: new SettingsInputElementModel()
  };

  constructor(route: ActivatedRoute, PermissionService: PermissionService,
              public employeeAttributesHttpService: EmployeeAttributeHttpService,
              router: Router, globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);

    this.settingsInputElementModel.newEmployeeAttributeName.propertyTitle = "Name";
    this.settingsInputElementModel.newEmployeeAttributeName.requiredPermission = PermissionService.Company_EmployeeAttribute_ChangeName();
    this.settingsInputElementModel.newEmployeeAttributeName.order = 1;

    this.settingsInputElementModel.newEmployeeShortName.propertyTitle = "Abkürzung";
    this.settingsInputElementModel.newEmployeeShortName.requiredPermission = PermissionService.Company_EmployeeAttribute_ChangeShortName();
    this.settingsInputElementModel.newEmployeeShortName.order = 2;

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Company_EmployeeAttribute_Delete());
  }

  public model?: EmployeeAttributeDto;
  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.employeeAttributesHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Attribut gelöscht", "Attribut wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  @Input() loadInputModel = (id: string) => {
    this.employeeAttributesHttpService.get(id).subscribe((employeeAttribute: EmployeeAttributeDto) => {
        this.model = employeeAttribute;
        this.settingsInputElementModel.newEmployeeAttributeName.originalPropertyValue = this.settingsInputElementModel.newEmployeeAttributeName.changeableProperties.newPropertyValue = employeeAttribute.name;
        this.settingsInputElementModel.newEmployeeShortName.originalPropertyValue = this.settingsInputElementModel.newEmployeeShortName.changeableProperties.newPropertyValue = employeeAttribute.shortName;

        this.updateElements();
      }
    )
  }

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName());
    this.requiresChanges.push(this.saveShortName());

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper()
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEmployeeAttributeName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEmployeeAttributeName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEmployeeAttributeName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employeeAttributesHttpService.changeName(this.model.id, this.settingsInputElementModel.newEmployeeAttributeName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EmployeeAttributeDto) => {
      this.model = model;
      this.settingsInputElementModel.newEmployeeAttributeName.originalPropertyValue = this.settingsInputElementModel.newEmployeeAttributeName.changeableProperties.newPropertyValue = model.shortName;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveShortName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEmployeeShortName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEmployeeShortName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEmployeeShortName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employeeAttributesHttpService.changeShortName(this.model.id, this.settingsInputElementModel.newEmployeeShortName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EmployeeAttributeDto) => {
      this.model = model;
      this.settingsInputElementModel.newEmployeeShortName.originalPropertyValue = this.settingsInputElementModel.newEmployeeShortName.changeableProperties.newPropertyValue = model.shortName;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Abkürzung.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.subscribe(params => {
      if (params.redirect) {
        this.redirectRoute = params.redirect + "?";
      }
      if (params.date) {
        this.redirectRoute += "date=" + params.date + "&"
      }
      if (params.event) {
        this.redirectRoute += "event=" + params.event + "&"
      }
      if (params.employee) {
        this.redirectRoute += "id=" + params.employee
      }
    })
  }
}
