import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {SidebarButtonComponent} from "../_components/sidebar-button/sidebar-button.component";
import {EmployeePanelRoutes} from "../employee-panel-routes";
import {EventType, NavigationEnd, Router, RouterLink} from "@angular/router";
import {AuthenticationService} from "../../../_auth/authentication.service";

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    SidebarButtonComponent,
    RouterLink
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  constructor(public AuthenticationService: AuthenticationService, private router: Router) {
    this.router.events.subscribe({
      next: (e) => {
        if (e.type == EventType.NavigationEnd) {
          this.open = false;
          this.openChange.emit(this.open);
        }
      }
    })
  }

  @Input() open: boolean = false;
  @Output() openChange = new EventEmitter<boolean>();

  protected readonly EmployeePanelRoutes = EmployeePanelRoutes;
}
