import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {SignalRGroup} from "../x-models/signal-r-group";
import {Tuple} from "../../../models/_generic/tuple";
import {Device} from "../x-models/device";
import {SignalRClient} from "../x-models/signal-r-client";
import {RequestedInformation} from "../x-models/requested-information";
import {ServerEndpoints} from "../../../server.endpoints";
import {containerObject} from "@syncfusion/ej2-base";

@Injectable({
  providedIn: 'root'
})
export class SignalRHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  List(): Observable<Tuple<Device[], SignalRGroup>> {
    return this.httpClient.get<Tuple<Device[], SignalRGroup>>(this.baseUrl + ServerEndpoints.Device_Information_List);
  }

  RequestInformation(connectionId: string, requestedInformation: RequestedInformation): Observable<SignalRClient[]> {
    return this.httpClient.post<SignalRClient[]>(this.baseUrl + ServerEndpoints.Device_Information_RequestInformation + "?requestedInformation=" + requestedInformation, [connectionId]).pipe(map((data) => {
      data.forEach(client => {
        if (!client.requestedInformation) return;
        let requestedInformation: { [key: number]: string } = {};
        Object.keys(client.requestedInformation).forEach(key => {
          // @ts-ignore
          requestedInformation[RequestedInformation[key as keyof typeof RequestedInformation]] = client.requestedInformation![key];
        })
        client.requestedInformation = requestedInformation;
      })
      return data;
    }));
  }

  get(id: string): Observable<Device> {
    return this.httpClient.get<Device>(this.baseUrl + ServerEndpoints.Device_Information_Get + "?id=" + id);
  }

  changeName(id: string, name: string): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + ServerEndpoints.Device_Information_ChangeName, {
      Id: id,
      Name: name
    });
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Device_Information_Delete + "?id=" + id);
  }

  setLockedOut(id: string, lockedOut: boolean): Observable<Device> {
    return this.httpClient.put<Device>(this.baseUrl + ServerEndpoints.Device_Information_SetLockedOut + `?id=${id}&isLockedOut=${lockedOut}`, {});
  }
}
