import {ActivatedRouteSnapshot} from "@angular/router";

export class UrlHelper {
  static getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

  static getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
      .filter(v => v.routeConfig)
      .map(v => v.routeConfig!.path)
      .join('/');
  }

  static get QueryParams(): any {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (params, prop: string) => {
        return params.get(prop)
      }
    })
  }
}
