<div class="fixed inset-0 employee-background -z-10 bg-gray-100"></div>
<div class="fixed inset-x-0 top-0 h-10 lg:h-20 z-10 inline-flex select-none employee-header-background bg-gray-800">
  <a (click)="sidebar = !sidebar"
     class="lg:hidden standalone:hidden place-self-center ml-6 text-lg mr-auto inline-flex gap-x-2 border-2 rounded-lg">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
      <path fill="currentColor" d="M3 18v-2h18v2zm0-5v-2h18v2zm0-5V6h18v2z"/>
    </svg>
    <span class="sr-only">Menü</span>
  </a>
  <div class="inline-flex standalone:flex-row-reverse lg:flex-row-reverse">
    <span class="text-white text-base lg:text-2xl place-self-center fw-bold">
      CheqMe:
      <span class="fw-light hover:underline cursor-pointer inline-flex"
            [routerLink]="EmployeePanelRoutes.MyProfile">{{ user ? user.name : "Mitarbeiter" }}</span>
    </span>
    <img src="assets/images/icon.png" alt="company logo"
         class="mr-6 rounded-full place-self-center h-6 lg:h-12 lg:p-1 lg:m-2 ml-4 lg:!ml-6 lg:!my-4 border-t-white border-2 cursor-pointer"
         [routerLink]="EmployeePanelRoutes.Dashboard">
  </div>
</div>
<div class="fixed right-0 top-0 h-20 inline-flex z-10 select-none">
</div>

<div class="pt-10 standalone:pt-0 standalone:pb-20 lg:pt-0">
  <div
    class="fixed inset-x-0 bottom-0 top-10 cursor-pointer pointer-events-none opacity-0 lg:hidden bg-gray-800 transition-opacity z-[49]"
    [ngClass]="{'pointer-events-auto opacity-50': sidebar}"
    (click)="sidebar = false"></div>
  <div class="bg-red-300 fixed top-10 lg:top-20 left-0 bottom-0 w-72 -translate-x-full lg:translate-x-0 z-50"
       [ngClass]="{'translate-x-0': sidebar}">
    <app-sidebar [(open)]="sidebar"></app-sidebar>
  </div>
  <div class="fixed left-0 lg:left-72 top-10 lg:top-20 bottom-0 right-0 standalone:!bottom-sb-12">
    <router-outlet></router-outlet>
  </div>
</div>

<div
  class="fixed inset-x-0 bottom-0 h-sb-14 pb-safe-bottom hidden standalone:grid standalone:grid-cols-4 employee-header-background justify-around">
  <div class="flex flex-col h-16 p-2 text-gray-400" [routerLink]="EmployeePanelRoutes.Calendar"
       [routerLinkActive]="['text-theme-primary-700']">
    <div class="h-8 w-8 mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M16.5 5V3m-9 2V3M3.25 8h17.5M3 10.044c0-2.115 0-3.173.436-3.981a3.896 3.896 0 0 1 1.748-1.651C6.04 4 7.16 4 9.4 4h5.2c2.24 0 3.36 0 4.216.412c.753.362 1.364.94 1.748 1.65c.436.81.436 1.868.436 3.983v4.912c0 2.115 0 3.173-.436 3.981a3.896 3.896 0 0 1-1.748 1.651C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.412a3.896 3.896 0 0 1-1.748-1.65C3 18.128 3 17.07 3 14.955z"/>
      </svg>
    </div>
    <span class="mx-auto text-xs">Kalender</span>
  </div>
  <div class="flex flex-col h-16 p-2 text-gray-400" [routerLink]="EmployeePanelRoutes.Shifts"
       [routerLinkActive]="['text-theme-primary-700']">
    <div class="h-8 w-8 mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
        <path fill="currentColor"
              d="M28 51.906c13.055 0 23.906-10.828 23.906-23.906c0-13.055-10.828-23.906-23.883-23.906c-1.242 0-1.851.75-1.851 1.968v9.094c0 1.008.68 1.828 1.71 1.828c1.032 0 1.735-.82 1.735-1.828V8.148C39.93 8.968 47.898 17.5 47.898 28A19.84 19.84 0 0 1 28 47.922c-11.063 0-19.945-8.86-19.922-19.922c.023-4.922 1.781-9.398 4.711-12.844c.726-.914.773-2.015 0-2.836c-.774-.843-2.086-.773-2.93.282C6.273 16.773 4.094 22.164 4.094 28c0 13.078 10.828 23.906 23.906 23.906m3.75-20.297c1.851-1.922 1.477-4.547-.75-6.093l-12.4-8.649c-1.171-.82-2.39.399-1.57 1.57l8.649 12.399c1.547 2.227 4.171 2.625 6.07.773"/>
      </svg>
    </div>
    <span class="mx-auto text-xs">Arbeitszeiten</span>
  </div>
  <div class="flex flex-col h-16 p-2 text-gray-400" [routerLink]="EmployeePanelRoutes.MyProfile"
       [routerLinkActive]="['text-theme-primary-700']">
    <div class="h-8 w-8 mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g fill="none">
          <path d="M0 0h48v48H0z"/>
          <path fill="currentColor" fill-rule="evenodd"
                d="M24 27a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0-2a6 6 0 1 0 0-12a6 6 0 0 0 0 12" clip-rule="evenodd"/>
          <path fill="currentColor" fill-rule="evenodd"
                d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24S12.954 4 24 4s20 8.954 20 20M33.63 39.21A17.9 17.9 0 0 1 24 42a17.9 17.9 0 0 1-9.831-2.92q-.36-.45-.73-.93A2.14 2.14 0 0 1 13 36.845c0-1.077.774-1.98 1.809-2.131c6.845-1 11.558-.914 18.412.035A2.08 2.08 0 0 1 35 36.818c0 .48-.165.946-.463 1.31q-.461.561-.907 1.082m3.355-2.744c-.16-1.872-1.581-3.434-3.49-3.698c-7.016-.971-11.92-1.064-18.975-.033c-1.92.28-3.335 1.856-3.503 3.733A17.94 17.94 0 0 1 6 24c0-9.941 8.059-18 18-18s18 8.059 18 18a17.94 17.94 0 0 1-5.015 12.466"
                clip-rule="evenodd"/>
        </g>
      </svg>
    </div>
    <span class="mx-auto text-xs">Profil</span>
  </div>
  <div class="flex flex-col h-16 p-2 text-gray-400" (click)="sidebar = !sidebar">
    <div class="h-8 w-8 mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill="currentColor"
              d="M7 10a1 1 0 1 1-2 0a1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-5-8a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-7 8a7 7 0 1 1 14 0a7 7 0 0 1-14 0"/>
      </svg>
    </div>
    <span class="mx-auto text-xs">Mehr</span>
  </div>
</div>
