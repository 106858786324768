import {Component, EventEmitter, Input} from '@angular/core';
import {Routes} from "../../enums/routes";
import {SettingsPageComponent} from "../../x-components/settings/settings-page/settings-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CompositionHttpService} from "../../http-requests/composition-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {PermissionService} from "../../_auth/permission.service";
import {TagHttpService} from "../../http-requests/tag-http.service";
import {SettingsInputElementModel} from "../../models/settings-input-element-model";
import {TagDto} from "../../models/tag-dto";
import {HttpPropertyChangeStatus} from "../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../models/observable-queue-element";
import {AlertLevel} from "../../enums/alert-level";

@Component({
  selector: 'app-tags-settings-page',
  templateUrl: '../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./tags-settings-page.component.scss']
})
export class TagsSettingsPageComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.Tags;
  public settingsInputElementModel: { [key: string]: any } = {
    newTagName: new SettingsInputElementModel(),
    newTagColor: new SettingsInputElementModel(),
    newTagOrder: new SettingsInputElementModel()
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public tagHttpService: TagHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newTagName.propertyTitle = "Name";
    this.settingsInputElementModel.newTagName.requiredPermission = PermissionService.Inventory_Tag_ChangeName()
    this.settingsInputElementModel.newTagName.order = 1;


    this.settingsInputElementModel.newTagColor.propertyTitle = "Farbe";
    this.settingsInputElementModel.newTagColor.inputType = "color";
    this.settingsInputElementModel.newTagColor.requiredPermission = PermissionService.Inventory_Tag_ChangeColor()
    this.settingsInputElementModel.newTagColor.order = 2;

    this.settingsInputElementModel.newTagOrder.propertyTitle = "Reihenfolge";
    this.settingsInputElementModel.newTagOrder.inputType = "number";
    this.settingsInputElementModel.newTagOrder.requiredPermission = PermissionService.Inventory_Tag_SetOrder()
    this.settingsInputElementModel.newTagOrder.order = 3;

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_Tag_Delete());
  }

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.tagHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Tag gelöscht", "Tag wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };
  public model?: TagDto;
  public modelChanged = new EventEmitter<any>();
  public timeoutClick: boolean = false;


  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveColor())
    this.requiresChanges.push(this.saveOrder())

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  }

  @Input() loadInputModel: Function = (id: string) => {
    this.tagHttpService.get(id).subscribe((model: TagDto) => {
      this.model = model;
      this.settingsInputElementModel.newTagName.originalPropertyValue = this.settingsInputElementModel.newTagName.changeableProperties.newPropertyValue = model.name;
      this.settingsInputElementModel.newTagOrder.originalPropertyValue = this.settingsInputElementModel.newTagOrder.changeableProperties.newPropertyValue = model.order;
      this.settingsInputElementModel.newTagColor.originalPropertyValue = this.settingsInputElementModel.newTagColor.changeableProperties.newPropertyValue = "#" + model.color;
    });
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newTagName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newTagName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newTagName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.tagHttpService.changeName(this.model.id, this.settingsInputElementModel.newTagName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: TagDto) => {
      this.model = model;
      this.settingsInputElementModel.newTagName.originalPropertyValue = this.settingsInputElementModel.newTagName.changeableProperties.newPropertyValue = this.model?.name;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveOrder(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newTagOrder.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newTagOrder.changeableProperties.newPropertyValue === this.settingsInputElementModel.newTagOrder.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.tagHttpService.setOrder(this.model.id, this.settingsInputElementModel.newTagOrder.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: TagDto) => {
      this.model = model;
      this.settingsInputElementModel.newTagOrder.originalPropertyValue = this.settingsInputElementModel.newTagOrder.changeableProperties.newPropertyValue = this.model?.order;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Reihenfolge.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveColor(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newTagColor.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newTagColor.changeableProperties.newPropertyValue === this.settingsInputElementModel.newTagColor.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    // verify that the color is in the correct format
    let color = this.settingsInputElementModel.newTagColor.changeableProperties.newPropertyValue.replace("#", "");
    let regex = new RegExp("[0-9a-fA-F]{6}|[0-9a-fA-F]{3}");
    if (!regex.test(color)) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Farbe. Die Farbe muss im Hexadezimalformat angegeben werden.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      return HttpPropertyChangeStatus.NotChanged;
    }

    let observableQueueElement = new ObservableQueueElement()

    observableQueueElement.observable = this.tagHttpService.changeColor(this.model.id, color);
    observableQueueElement.callback = (model: TagDto) => {
      this.model = model;
      this.settingsInputElementModel.newTagColor.originalPropertyValue = this.settingsInputElementModel.newTagColor.changeableProperties.newPropertyValue = "#" + this.model?.color;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Farbe.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}
