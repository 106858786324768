import {PaymentProvider} from "../../_modules/location/_models/_enums/payment-provider";

export enum CalculationType {
  // < 10 is absolute, >= 10 is relative
  AbsolutePerHour = 1,
  AbsolutePerShift = 2,
  Percentage = 10
}

export const CalculationTypeLabels: { [key: number]: string } = {
  [CalculationType.AbsolutePerHour]: "Absolut pro Stunde",
  [CalculationType.AbsolutePerShift]: "Absolut pro Schicht",
  [CalculationType.Percentage]: "Mit Faktor (1 = 100%)"
};

export const CalculationTypes = Object.entries(CalculationType).slice(0, Object.entries(CalculationType).length / 2);
