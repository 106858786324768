<div class="overflow-hidden bg-white shadow sm:rounded-lg max-w-7xl mx-auto">
  <div class="px-4 py-6 sm:px-6">
    <h3 class="font-semibold text-lg text-gray-900">Profil</h3>
  </div>
  <div class="border-t border-gray-300">
    <dl class="divide-y divide-gray-300">
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-900 fw-bold">Benutzername</dt>
        <dd
          class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ AuthenticationService.userValue?.name }}
        </dd>
      </div>
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-900 fw-bold">Unternehmen</dt>
        <dd
          class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ AuthenticationService.userValue?.company?.name }}
        </dd>
        <dt class="text-sm font-medium text-gray-900 fw-bold">Standort</dt>
        <dd
          class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ AuthenticationService.userValue?.shard?.name }}
        </dd>
      </div>
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-900 fw-bold">Rollen</dt>
        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <p>
            <span
              *ngFor="let role of AuthenticationService.userValue?.permissions;let last = last">{{ role }}{{ !last ? ", " : "" }}</span>
          </p>
        </dd>
      </div>
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <div>
          <dt class="text-sm font-medium leading-6 text-gray-900 fw-bold">Sicherheit</dt>
          <div class="grid grid-cols-1 text-gray-500">
            <p class="mt-2 text-sm text-gray-500 inline-flex">
              <app-cross-check-display [Valid]="CheckUpper()" [Text]="'Großbuchstabe'" [IconSide]="Side.Left"/>
            </p>
            <p class="mt-2 text-sm text-gray-500">
              <app-cross-check-display class="" [Valid]="CheckLower()" [Text]="'Kleinbuchstabe'"
                                       [IconSide]="Side.Left"/>
            </p>
            <p class="mt-2 text-sm text-gray-500">
              <app-cross-check-display [Valid]="CheckNumbers()" [Text]="'Zahl'" [IconSide]="Side.Left"/>
            </p>
            <p class="mt-2 text-sm text-gray-500">
              <app-cross-check-display class="" [Valid]="CheckSpecial()" [Text]="'Sonderzeichen:&nbsp;!@#$&*'"
                                       [IconSide]="Side.Left"/>
            </p>
            <p class="mt-2 text-sm text-gray-500">
              <app-cross-check-display [Valid]="CheckLength()" [Text]="'8-32&nbsp;Zeichen'" [IconSide]="Side.Left"/>
            </p>
          </div>
        </div>
        <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <form class="space-y-6" name="password-change">
            <div class="relative -space-y-px rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"></div>
              <div>
                <label for="old_password" class="sr-only">Altes Passwort</label>
                <input [(ngModel)]="old" name="old_password" id="old_password" type="password"
                       autocomplete="current-password" required
                       class="px-3 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 sm:text-sm sm:leading-6"
                       placeholder="Altes Passwort">
              </div>
              <div>
                <label for="new_password" class="sr-only">Passwort</label>
                <input (change)="Validate()" [(ngModel)]="password" name="new_password" id="new_password"
                       type="password"
                       autocomplete="new-password" required
                       class="px-3 relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 sm:text-sm sm:leading-6"
                       placeholder="Neues Passwort">
              </div>
              <div>
                <label for="repeat_password" class="sr-only">Passwort</label>
                <input (change)="Validate()" [(ngModel)]="confirmation" id="repeat_password" name="repeat_password"
                       type="password"
                       autocomplete="new-password" required
                       class="px-3 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 sm:text-sm sm:leading-6"
                       placeholder="Passwort wiederholen">
              </div>
            </div>

            <div>
              <button type="submit"
                      class="flex w-full justify-center rounded-md bg-theme-primary-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700"
                      name="submit"
                      [ngClass]="{'!pointer-events-none !bg-gray-200 !text-gray-800': !Validate()}" (click)="change()">
                Passwort aktualisieren
              </button>
            </div>
          </form>
        </dd>
      </div>
    </dl>
  </div>
</div>
