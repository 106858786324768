import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {TagOverrideNl} from "../models/tag-override-nl";
import {CompositionOverrideNl} from "../models/composition-override-nl";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class OverrideHttpService {
  private _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  overrideTag(configuration: string, tag: string, active: boolean) {
    return this.http.put<TagOverrideNl>(this._baseUrl + ServerEndpoints.Inventory_Override_Tag, {
      configuration: configuration,
      tag: tag,
      active: active
    });
  }

  overrideComposition(configuration: string, composition: string, active: boolean | null = null, price: number | null = null) {
    return this.http.put<CompositionOverrideNl>(this._baseUrl + ServerEndpoints.Inventory_Override_Composition, {
      configuration: configuration,
      composition: composition,
      active: active,
      price: price
    });
  }
}
