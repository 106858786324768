<div class="px-4 sm:px-6 lg:px-8" *ngIf="employee">
  <div class="sm:flex sm:items-center">
    <div class="flex flex-row w-full">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl flex flex-col sm:flex-row">
        <span class="pr-2 text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
              [routerLink]="['/'+Routes.CompanyEmployee]"
              [queryParams]="{'start': start, 'end': end}">Mitarbeiter:</span>
        <span class="font-extralight">{{ employee.firstName }} {{ employee.lastName }}</span>
      </h1>
      <a *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_Get())"
         (click)="redirectToSettings()"
         class="cursor-pointer text-gray-600 hover:text-gray-800 sm:place-self-center pt-2.5 sm:pt-0 pr-2 ml-auto">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
             viewBox="0 0 16 16">
          <path
            d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
        </svg>
      </a>
    </div>
  </div>
  <div class="inline-flex py-2 w-full mt-2">
    <svg (click)="openPopup($event)" id="eaToggle" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
         fill="currentColor"
         class="mr-2 bi bi-info-circle-fill text-gray-500 cursor-pointer hover:text-gray-700 place-self-center"
         viewBox="0 0 16 16">
      <path
        d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
    </svg>
    <div class="flex place-self-center text-gray-500">
      <div *ngFor="let attr of employee.employeeAttributes" class="place-self-center">
        <span *ngIf="attr.shortName != ''"
              class="block sm:hidden border-x-2 -mx-px px-1 border-gray-500">{{ attr.shortName }}</span>
        <span class="hidden sm:block border-x-2 -mx-px px-1 border-gray-500">{{ attr.name }}</span>
      </div>
    </div>
    <div class="inline-flex ml-auto gap-x-2">
      <app-download class="place-self-center hidden" [OverrideTextSize]="false"
                    [DownloadInformation]="downloadInformation"
                    [Route]="ServerEndpoints.Document_Employee_DownloadIndividualDetails"
                    [Params]="getDownloadParams()"></app-download>
      <a *ngIf="employee.id && employee.telegram && (PlatformScannerService.isMobile() || employee.telegram.username)"
         href="{{ employee.telegram.username ? 'https://t.me/'+employee.telegram.username : 'tg://user?id='+employee.telegram.userId }}"
         target="_blank"
         class="ml-auto cursor-pointer rounded-full bg-white px-1 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 place-self-center border-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-telegram"
             viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
        </svg>
      </a>
    </div>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 pt-2">
    <div>
      <div class="mt-2 flex rounded-md shadow-sm">
        <span
          class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Start</span>
        <input [(ngModel)]="start"
               (ngModelChange)="LoadEmployeeSales(employee.id, start, end);LoadEmployeeShiftsStr(employee.id, start, end)"
               type="date" name="start" id="start"
               class="px-2 flex w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
      </div>
    </div>
    <div>
      <div class="mt-2 flex rounded-md shadow-sm">
        <span
          class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">Ende</span>
        <input [(ngModel)]="end"
               (ngModelChange)="LoadEmployeeSales(employee.id, start, end);LoadEmployeeShiftsStr(employee.id, start, end)"
               type="date" name="end" id="end"
               class="px-2 flex w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
      </div>
    </div>
  </div>

  <!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
  <div class="mt-4 print:hidden">
    <div class="hidden w-full">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select [ngModel]="view" id="tabs" name="tabs"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-theme-primary-500 focus:outline-none focus:ring-theme-primary-500 sm:text-sm">
        <option [selected]="view == EmployeeDetailsView.Shifts" [value]="EmployeeDetailsView.Sales">Schichten</option>
        <option *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeSalesView())"
                [selected]="view == EmployeeDetailsView.Sales" [value]="EmployeeDetailsView.Shifts">Getränke
        </option>
        <option *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeDetailsView())"
                [selected]="view == EmployeeDetailsView.Sales" [value]="EmployeeDetailsView.Shifts">Informationen
        </option>
      </select>
    </div>
    <div class="block overflow-x-auto pb-2">
      <div class="border-b border-gray-200 inline-flex w-full">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <!-- Current: "border-theme-primary-500 text-theme-primary-700", Default: "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700" -->
          <a (click)="view = EmployeeDetailsView.Shifts"
             class="cursor-pointer select-none border-transparent text-gray-700 hover:text-theme-primary-900 flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium"
             [ngClass]="{'border-theme-primary-500 text-theme-primary-700': view == EmployeeDetailsView.Shifts}">
            Schichten
            <!-- Current: "bg-theme-primary-100 text-theme-primary-700", Default: "bg-gray-100 text-gray-900" -->
            <span
              class="bg-gray-100 text-gray-900 ml-3 rounded-full py-0.5 px-2.5 text-xs font-medium inline-block"
              [ngClass]="{'bg-theme-primary-100 text-theme-primary-700': view == EmployeeDetailsView.Shifts}">
              <svg *ngIf="!employeeShifts" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-hourglass-split" viewBox="0 0 16 16">
                <path
                  d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
              </svg>
              <span *ngIf="employeeShifts">{{ employeeShifts.length }}</span>
            </span>
          </a>
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_EmployeeSalesList())"
             (click)="view = EmployeeDetailsView.Sales"
             class="cursor-pointer select-none border-transparent text-gray-700 hover:text-theme-primary-900 flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium"
             [ngClass]="{'border-theme-primary-500 text-theme-primary-700': view == EmployeeDetailsView.Sales}">
            Getränke
            <span
              class="bg-gray-100 text-gray-900 ml-3 rounded-full py-0.5 px-2.5 text-xs font-medium inline-block"
              [ngClass]="{'bg-theme-primary-100 text-theme-primary-700': view == EmployeeDetailsView.Sales}">
              <svg *ngIf="!employeeSales" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-hourglass-split" viewBox="0 0 16 16">
                <path
                  d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
              </svg>
              <span *ngIf="employeeSales">{{ getDrinkCount(employeeSales) }}</span>
            </span>
          </a>
          <a *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeDetailsView())"
             (click)="view = EmployeeDetailsView.Data"
             class="cursor-pointer select-none border-transparent text-gray-700 hover:text-theme-primary-900 flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium"
             [ngClass]="{'border-theme-primary-500 text-theme-primary-700': view == EmployeeDetailsView.Data}">
            Informationen
          </a>
        </nav>
      </div>
    </div>
  </div>

  @switch (view) {
    @case (EmployeeDetailsView.Shifts) {
      <div class="">
        @if (employmentRelationships) {
          <div class="w-full flex mt-4 -mb-4">
            <span class="ml-auto text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer select-none"
                  (click)="erVisible = true">Arbeitsverhältnis ändern</span>
          </div>
          <div class="print:hidden flow-root my-4 max-h-24 overflow-y-auto px-4 border-2 rounded-lg">
            <ul role="list" class="">
              <li *ngFor="let relationship of employmentRelationships;let index = index;">
                <div class="relative py-2">
                  <span class="absolute left-4 inset-y-0 -ml-px w-0.5 bg-gray-200" aria-hidden="true"></span>
                  <div class="relative flex space-x-3">
                    <div>
                <span class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-4 ring-white"
                      [class.!bg-theme-primary-900]="!isFinished(relationship)">
                  <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                      d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z"/>
                  </svg>
                </span>
                    </div>
                    <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p class="text-sm text-gray-500">
                          <span *ngIf="index == 0 && !isFinished(relationship)" class="fw-bold text-theme-primary-900">[Aktuell]</span>
                          Arbeitsverhältnis geändert zu <a
                          class="font-medium text-gray-900">{{ relationship.employmentRelationship!.name }}</a></p>
                      </div>
                      <div class="flex flex-column text-right md:!flex-row">
                        <div class="text-sm text-gray-500" *ngIf="isFinished(relationship)">
                          <time>{{ relationship.end | date: "dd.MM.yyyy, HH:mm" }} Uhr</time>
                          <span class=""> &dash;</span>
                        </div>
                        <div class="text-sm text-gray-500">
                          <time>&nbsp;{{ relationship.start | date: "dd.MM.yyyy, HH:mm" }} Uhr</time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        }

        <div class="mt-4 flow-root">
          <div class="-my-2 overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col"
                      class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"></th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Event
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Verkaufspunkt
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Start
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Ende
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Arbeitsstunden
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Stundenlohn
                  </th>
                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Zuschläge
                  </th>
                  <th scope="col"
                      class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr *ngFor="let shift of employeeShifts">
                  <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ shift.shardName }}</td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm font-medium pointer-events-none"
                      [ngClass]="{'pointer-events-auto text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer': PermissionService.CheckPermission(PermissionService.Permissions_ShiftMakerComponent())}"
                      [routerLink]="['/' + Routes.CompanyModule + '/' + CompanyRoutes.ShiftMaker]"
                      [queryParams]="{'event': shift.eventId, 'date': shift.eventStart.toISOString()}">{{ shift.eventName }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ shift.locationName }}</td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                      [class.bg-theme-primary-100]="!shift.start">{{ shift.start | date: "dd.MM.yyyy, HH:mm" }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                      [class.bg-theme-primary-100]="!shift.end">{{ shift.end | date: "dd.MM.yyyy, HH:mm" }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                      [class.bg-theme-primary-100]="!shift.stats?.hoursWorked"
                      [class.bg-red-200]="shift.stats?.hoursWorked != undefined && shift.stats?.hoursWorked! < 0">
                    {{ shift.stats?.hoursWorked | number: "1.0-2" }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <span
                      *ngIf="shift.stats?.employmentRelationship?.salary != undefined">{{ shift!.stats!.employmentRelationship!.salary | currency: "EUR" }}</span>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <span
                      *ngIf="shift.stats && shift.stats.salaryAdditions != 0">{{ shift.stats.salaryAdditions | currency: "EUR" }}</span>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
                      [class.text-red-600]="shift.stats && EmployeeStatsHelper.getTotal(shift.stats) < 0">
                    <span *ngIf="shift.stats">{{ EmployeeStatsHelper.getTotal(shift.stats) | currency: "EUR" }}</span>
                  </td>
                </tr>
                <tr *ngIf="employeeShifts && employeeShifts.length > 0" class="border-t-2 border-gray-950">
                  <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"></td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900"></td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900"></td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"></td>
                  <td class="whitespace-nowrap px-2 py-2 text-black font-bold">Summe:</td>
                  <td class="whitespace-nowrap px-2 py-2 text-black font-bold">{{ getTotalHours() | number: "1.0-2" }}
                  </td>
                  <td></td>
                  <td></td>
                  <td class="whitespace-nowrap px-2 py-2 text-black font-bold">{{ getTotalSalary() | currency: "EUR" }}
                  </td>
                </tr>

                <tr *ngIf="employeeShifts && employeeShifts.length == 0">
                  <td colspan="7">
                    <div class="w-full flex justify-center">
                      <span class="text-gray-600 text-center">Keine Ergebnisse in diesem Zeitraum</span>
                    </div>
                  </td>
                </tr>

                <!-- More transactions... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    }
    @case (EmployeeDetailsView.Sales) {
      <div class="mt-2 flow-root"
           *ngIf="PermissionService.CheckPermission(PermissionService.Company_Employee_EmployeeSalesList())">
    <span class="isolate inline-flex rounded-md shadow-sm">
      <button type="button"
              [ngClass]="{'!bg-gray-600 !text-white': employeeDetailsSalesView == EmployeeDetailsSalesView.List}"
              (click)="employeeDetailsSalesView = EmployeeDetailsSalesView.List"
              class="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-800 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">
        <span class="sr-only">List</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-view-list"
             viewBox="0 0 16 16">
          <path
            d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2m0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14"/>
        </svg>
      </button>
      <button type="button"
              [ngClass]="{'!bg-gray-600 !text-white': employeeDetailsSalesView == EmployeeDetailsSalesView.Event}"
              (click)="employeeDetailsSalesView = EmployeeDetailsSalesView.Event"
              class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-800 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">
        <span class="sr-only">Events</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week"
             viewBox="0 0 16 16">
          <path
            d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
          <path
            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
        </svg>
      </button>
    </span>
        <div class="-my-2 overflow-x-auto" *ngIf="employeeDetailsSalesView == EmployeeDetailsSalesView.List">
          <div class="inline-block min-w-full py-2 align-middle">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
              <tr>
                <th scope="col"
                    class="whitespace-nowrap py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900">
                  Artikel
                </th>
                <th scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                  Einzelpreis
                </th>
                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Anzahl
                </th>
                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Gesamt
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr *ngIf="employeeSalesError">
                <td colspan="4" class="">
                  <div class="w-full flex justify-center">
                    <span class="text-red-600 text-center">Fehler beim Laden, bitte Zeitraum überprüfen.</span>
                  </div>
                </td>
              </tr>
              <tr *ngIf="employeeSales && employeeSales.length == 0">
                <td colspan="4">
                  <div class="w-full flex justify-center">
                    <span class="text-gray-600 text-center">Keine Ergebnisse in diesem Zeitraum</span>
                  </div>
                </td>
              </tr>
              <tr *ngFor="let composition of employeeSales; let index = index;">
                <td class="whitespace-nowrap py-2 pl-2 pr-3 text-sm text-gray-500">
                  {{ composition.name }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden sm:block">
                  {{ composition.price | currency: "EUR" }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  {{ composition.quantity }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 cursor-pointer">
                  {{ composition.quantity * composition.price | currency: "EUR" }}
                </td>
              </tr>
              <!-- More transactions... -->
              <tr *ngIf="employeeSales && employeeSales.length > 0">
                <td></td>
                <td class="hidden sm:block"></td>
                <td class="whitespace-nowrap px-2 py-2 text-lg text-gray-900">
                  <span class="fw-bold">Summe:</span>
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-lg font-medium text-gray-900">
                  <span class="fw-bold">{{ GetEmployeeSalesSum() | currency: "EUR" }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="employeeDetailsSalesView == EmployeeDetailsSalesView.Event" class="mt-4 flex flex-col w-full">
          <table class="min-w-full">
            <tbody class="divide-y divide-gray-200 bg-white mb-3" *ngFor="let event of employeeSalesEvents">
            <tr>
              <td colspan="3">
                <span class="fw-light text-lg">{{ event.publicName ?? event.name }}</span>
              </td>
              <td class="flex">
            <span
              class="text-sm ml-auto">{{ event.start | date: GlobalDateService.getDateformat(GlobalDateFormat.LONG_WITH_TIME) }}
              - {{ event.end | date: GlobalDateService.getDateformat(GlobalDateFormat.LONG_WITH_TIME) }}</span>
              </td>
            </tr>
            <tr>
              <td class="whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Artikel</td>
              <td
                class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
                Einzelpreis
              </td>
              <td class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Anzahl</td>
              <td class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Gesamt</td>
            </tr>
            <tr *ngIf="event.orders && event.orders.length == 0">
              <td colspan="4">
                <div class="w-full flex justify-center">
                  <span class="text-gray-600 text-center">Keine Ergebnisse zu diesem Event</span>
                </div>
              </td>
            </tr>
            <tr *ngFor="let order of event.orders; let index = index;">
              <td class="whitespace-nowrap py-2 pr-3 text-sm text-gray-500">
                <p *ngFor="let composition of order.compositions">{{ composition.composition.name }}</p>
              </td>
              <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden sm:block">
                <p
                  *ngFor="let composition of order.compositions">{{ composition.compositionOverride?.price ?? composition.composition.price | currency: "EUR" }}</p>
              </td>
              <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                <p *ngFor="let composition of order.compositions">{{ composition.quantity }}x</p>
              </td>
              <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 cursor-pointer">
                {{ calculateOrderTotal(order) | currency: "EUR" }}
              </td>
            </tr>
            <!-- More transactions... -->
            <tr *ngIf="event.orders && event.orders.length > 0">
              <td></td>
              <td class="hidden sm:block"></td>
              <td class="whitespace-nowrap px-2 py-2 text-lg text-gray-900">
                <span class="fw-bold">Summe:</span>
              </td>
              <td class="whitespace-nowrap px-2 py-2 text-lg font-medium text-gray-900">
                <span class="fw-bold">{{ calculateEventTotal(event) | currency: "EUR" }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    }
    @case (EmployeeDetailsView.Data) {
      <div *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeDetailsView())"
           class="py-4">
        @if (!stats) {

        } @else {

          <div class="lg:col-start-3 lg:row-end-1">
            <h2 class="sr-only">Informationen</h2>
            <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
              <dl class="flex flex-col">
                <div class="flex-auto pl-6 py-6">
                  @if (stats.lastShift) {
                    @if (stats.lastShift.getTime() < now.getTime()) {
                      <dt class="text-sm font-semibold leading-6 text-gray-900">Letzter Dienst</dt>
                      <dd
                        class="mt-1 text-base leading-6 text-gray-900 inline-flex gap-x-1">
                        vor <span
                        class="font-semibold">{{ (now.getTime() - stats.lastShift.getTime()) / 86400000 | number: "1.0-0" }}</span>
                        Tagen
                        <span
                          class="ml-2 place-self-center inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                          {{ stats.lastShift | date: "dd.MM.yyyy" }}
                        </span>
                      </dd>
                    } @else {
                      <dt class="text-sm font-semibold leading-6 text-gray-900">Nächster Dienst</dt>
                      <dd
                        class="mt-1 text-base leading-6 text-gray-900">
                        in <span
                        class="font-semibold">{{ (stats.lastShift.getTime() - now.getTime()) / 86400000 | number: "1.0-0" }}</span>
                        Tagen
                        <span
                          class="ml-2 place-self-center inline-flex items-center rounded-md bg-gray-100 px-2 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                        {{ stats.lastShift | date: "dd.MM.yyyy" }}
                      </span>
                      </dd>
                    }
                  } @else {
                    <dd class="mt-1 text-base font-semibold leading-6 text-blue-00">NEU</dd>
                  }
                </div>
                <div
                  class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-800 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded-t-xl">
                  <div>
                    <div class="flex items-center gap-x-3">
                      <div class="hidden flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                        <div class="h-2 w-2 rounded-full bg-current"></div>
                      </div>
                      <h1 class="flex gap-x-3 text-base/7">
                        <span class="font-semibold text-white">Arbeitsverhältnis</span>
                        <span class="text-gray-600">:</span>
                        <span
                          class="text-white">{{ stats.employmentRelationship?.name ?? "Keins" }}</span>
                      </h1>
                    </div>
                    <p class="mt-0 text-xs/6 text-gray-400 flex gap-2" *ngIf="stats.firstShift">
                      <span>Erster Dienst:</span>
                      <span>{{ stats.firstShift | date: "dd.MM.yyyy" }}</span>
                    </p>
                  </div>
                </div>

                <!-- Stats -->
                <div class="grid grid-cols-1 bg-gray-800 sm:grid-cols-2 lg:grid-cols-4 rounded-b-xl">
                  <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">
                    <p class="text-sm/6 font-medium text-gray-400">Zusagen</p>
                    <p class="mt-2 flex flex-col items-baseline gap-x-2">
                      <span class="text-4xl font-semibold tracking-tight text-white">{{ stats.responseAccepted }}</span>
                      <span class="text-sm text-gray-400"
                            *ngIf="stats.lastAcceptedResponse">Zuletzt am {{ stats.lastAcceptedResponse.modified | date: "dd.MM.yyyy" }}</span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                    <p class="text-sm/6 font-medium text-gray-400">Absagen</p>
                    <p class="mt-2 flex flex-col items-baseline gap-x-2">
                      <span class="text-4xl font-semibold tracking-tight text-white">{{ stats.responseDenied }}</span>
                      <span class="text-sm text-gray-400"
                            *ngIf="stats.lastDeniedResponse">Zuletzt am {{ stats.lastDeniedResponse.modified | date: "dd.MM.yyyy" }}</span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                    <p class="text-sm/6 font-medium text-gray-400">Arbeitsstunden</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span
                        class="text-4xl font-semibold tracking-tight text-white">{{ stats.hoursWorked | number: "1.2-2" }}</span>
                    </p>
                  </div>
                  <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                    <p class="text-sm/6 font-medium text-gray-400">Durchschnittsstunden pro Monat</p>
                    <p class="mt-2 flex items-baseline gap-x-2">
                      <span
                        class="text-4xl font-semibold tracking-tight text-white">{{ getAverageHoursPerMonth(stats.firstShift, stats.hoursWorked) | number: "1.2-2" }}</span>
                    </p>
                  </div>
                </div>
                <div class="flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pb-6">

                  <div class="flow-root w-full">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full mt-4 divide-y divide-gray-300">
                          <tbody class="" *ngIf="!stats.additionalInformation.isNullOrWhitespace()">
                          <tr>
                            <td
                              class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 align-top">
                              Anmerkungen
                            </td>
                            <td
                              class="px-3 py-2 text-sm text-gray-500 w-full">
                              <span class="whitespace-pre-line">
                                {{ stats.additionalInformation }}
                              </span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        }
      </div>
    }
  }
</div>

<app-employee-attribute-popup [queryParams]="{'employee': employee?.id}"
                              [useRelativeY]="true" [relative]="AlignmentService.MainWindow"
                              [redirectPath]="['/' + Routes.CompanyEmployeeDetails]"
                              [(employee)]="employeeAttributeEmployee" [(visible)]="employeeAttributePopupVisible"
                              [mouseEvent]="mouseEvent"></app-employee-attribute-popup>

<app-employment-relationship-popup [(visible)]="erVisible" (visibleChange)="erReloadEmployee()"
                                   [changeIds]="employee ? [employee.id] : undefined"></app-employment-relationship-popup>
