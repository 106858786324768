export enum NewGroupStep {
  Event,
  Configuration,
  Reoccurrence,
  Time,
  Sorting,
  Target,
  Calculations,
  Items,
}
