<app-navbar-container breadcrumb="Benutzer">
  <!--<app-navbar-item-small-container>
    <app-navbar-item-small *ngFor="let user of AuthenticationService.usersValue"
                           [ngClass]="{'hidden': user.id === AuthenticationService.userValue?.id}"
                           title="{{ user.name }} | {{ user.shard.name }}"
                           (click)="AuthenticationService.switchUser(user.id)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
        <path fill="currentColor"
              d="M21 16a3 3 0 0 1 3 3v.715C24 23.292 19.79 26 14 26S4 23.433 4 19.715V19a3 3 0 0 1 3-3zm0 1.5H7a1.5 1.5 0 0 0-1.493 1.355L5.5 19v.715c0 2.674 3.389 4.785 8.5 4.785c4.926 0 8.355-2.105 8.496-4.624l.004-.161V19a1.5 1.5 0 0 0-1.355-1.493zM14 2a6 6 0 1 1 0 12a6 6 0 0 1 0-12m0 1.5a4.5 4.5 0 1 0 0 9a4.5 4.5 0 0 0 0-9"/>
      </svg>
    </app-navbar-item-small>
  </app-navbar-item-small-container>-->
  <app-navbar-item-large class="" title="Profil" [link]="'/' + Routes.AuthenticationProfile">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fill="currentColor"
            d="M10.561 8.073a6 6 0 0 1 3.432 5.142a.75.75 0 1 1-1.498.07a4.5 4.5 0 0 0-8.99 0a.75.75 0 0 1-1.498-.07a6 6 0 0 1 3.431-5.142a3.999 3.999 0 1 1 5.123 0M10.5 5a2.5 2.5 0 1 0-5 0a2.5 2.5 0 0 0 5 0"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large class="ml-auto" title="Benutzer wechseln"
                         (click)="AuthenticationService.switch()"
                         [onSelectedFunction]="true"
                         (onSelected)="AuthenticationService.switch()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M15.75 13.997a2.25 2.25 0 0 1 2.25 2.25v.918c0 .285-.045.567-.13.836h-2.035a1.75 1.75 0 0 0-2.822-1.98l-2.5 2.499a1.75 1.75 0 0 0 0 2.476l.935.933q-.697.07-1.452.07c-3.42 0-5.943-1.073-7.486-3.237A2.75 2.75 0 0 1 2 17.166v-.92a2.25 2.25 0 0 1 2.249-2.249zM9.997 2.002a5 5 0 1 1 0 10a5 5 0 0 1 0-10m4.784 15.782a.75.75 0 0 0-1.06-1.06l-2.5 2.5a.75.75 0 0 0 0 1.06l2.5 2.5a.75.75 0 0 0 1.06-1.061l-1.22-1.22h6.88l-1.22 1.22a.75.75 0 0 0 1.06 1.06l2.5-2.498a.75.75 0 0 0 0-1.06l-2.5-2.502a.75.75 0 1 0-1.06 1.06l1.218 1.22h-6.877z"/>
    </svg>
  </app-navbar-item-large>
  <!--<app-navbar-item-large title="Alle Accounts Ausloggen" (click)="AuthenticationService.logout(false, true)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m19 12l-4-4m4 4l-4 4m4-4H9m5 9a9 9 0 1 1 0-18"/>
    </svg>
  </app-navbar-item-large>-->
  <app-navbar-item-large class="" title="Ausloggen"
                         (click)="AuthenticationService.logout()"
                         [onSelectedFunction]="true"
                         (onSelected)="AuthenticationService.logout()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fill="currentColor" fill-rule="evenodd"
            d="M1 14s-1 0-1-1s1-4 6-4s6 3 6 4s-1 1-1 1zm5-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6m6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708"/>
    </svg>
  </app-navbar-item-large>
</app-navbar-container>
