import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {DtoBaseService} from "../../../../_services/dto-services/dto-base.service";
import {EmploymentRelationshipDto} from "../../x-models/employment-relationship-dto";
import {EmploymentRelationshipHttpService} from "../../x-http-requests/employment-relationship-http.service";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {DtoSignalRService} from "../../../../_services/dto-services/dto-signal-r.service";
import {DtoServices} from "../../../../_services/dto-services/_enums/dto-services";

@Injectable({
  providedIn: 'root'
})
export class EmploymentRelationshipService extends DtoBaseService<EmploymentRelationshipDto, EmploymentRelationshipHttpService> {
  public Identifier: DtoServices = DtoServices.EmploymentRelationshipService;

  Add(payload: any): Observable<EmploymentRelationshipDto> {
    return this.HttpService.add(payload.Name, payload["Salary"] as number, payload["Limit"] as number)
  }
  FetchAll(all: boolean): Observable<EmploymentRelationshipDto[]> {
      return this.HttpService.List();
  }
  FetchById(id: string, all: boolean): Observable<EmploymentRelationshipDto> {
    return this.HttpService.get(id);
  }
  GetId(entity: EmploymentRelationshipDto): string {
      return entity.id;
  }
  constructor(employmentRelationshipHttpService: EmploymentRelationshipHttpService,
              globalAlertService: GlobalAlertService, dtoSignalRService: DtoSignalRService) {
    super(employmentRelationshipHttpService, globalAlertService, dtoSignalRService);
    this.Initialize();
  }

  GetSelected(): EmploymentRelationshipDto[] | undefined {
    return this.Entities?.filter(e => e.selected);
  }

  GetSelectedIds(): string[] | undefined {
    return this.GetSelected()?.map(x => x.id);
  }
}
