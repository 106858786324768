import {Component, Input} from '@angular/core';
import {Side} from "../x-models/x-enums/side";

@Component({
  selector: 'app-cross-check-display',
  templateUrl: './cross-check-display.component.html',
  styleUrls: ['./cross-check-display.component.scss']
})
export class CrossCheckDisplayComponent {
  @Input() Valid!: boolean;
  @Input() Text: string = "";
  @Input() IconSide: Side = Side.Left;
  @Input() DarkMode: boolean = false;
  protected readonly Side = Side;
}
