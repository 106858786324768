import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {ItemDto} from "../models/item-dto";
import {ServerEndpoints} from "../server.endpoints";


@Injectable({
  providedIn: 'root'
})
export class ItemHttpService {
  private _baseUrl: string;


  constructor(public http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    //super(http, baseUrl, baseHeaders);
    this._baseUrl = baseUrl;

  }

  list(category: string = ""): Observable<ItemDto[]> {
    return this.http.get<ItemDto[]>(this._baseUrl + ServerEndpoints.Inventory_Item_List + "?category=" + category);
  }

  add(name: string, category: string | undefined): Observable<ItemDto> {
    return this.http.post<any>(this._baseUrl + ServerEndpoints.Inventory_Item_Add, {name: name, category: category});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + ServerEndpoints.Inventory_Item_Delete + "?id=" + id);
  }

  setSourceDetail(item: string, ppu: number, vpu: number, upb: number = 0, sk: string = "", sid: string = "", invId: number | undefined = undefined): Observable<ItemDto> {
    return this.http.post<ItemDto>(this._baseUrl + ServerEndpoints.Inventory_Item_SetSourceDetail, {
      item: item,
      pricePerUnit: ppu,
      volumePerUnit: vpu,
      unitsPerBundle: upb,
      supplierKey: sk,
      supplierId: sid,
      inventoryId: invId
    });
  }

  get(id: string, all: boolean = false): Observable<ItemDto> {
    return this.http.get<ItemDto>(this._baseUrl + ServerEndpoints.Inventory_Item_Get + "?id=" + id + "&all=" + String(all));
  }

  changeName(item: string, name: string): Observable<ItemDto> {
    return this.http.put<ItemDto>(this._baseUrl + ServerEndpoints.Inventory_Item_ChangeName, {
      Id: item,
      Name: name
    });
  }

  setUnit(item: string, unit: any): Observable<ItemDto> {
    return this.http.put<ItemDto>(this._baseUrl + ServerEndpoints.Inventory_Item_SetUnit + "?id=" + item + "&unit=" + unit, {});
  }
}
