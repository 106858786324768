import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompanyEdto} from "../x-models/company-edto";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class ContractHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  getCurrentCompany(): Observable<CompanyEdto> {
    return this.httpClient.get<CompanyEdto>(`${this.baseUrl}${ServerEndpoints.Administration_Contract_GetCurretCompany}`);
  }
}
