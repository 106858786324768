import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PermissionService} from "../../../../_auth/permission.service";
import {EmploymentRelationshipDto} from "../../x-models/employment-relationship-dto";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {AlertLevel} from "../../../../enums/alert-level";
import {EmploymentRelationshipService} from "../../_services/_dto-services/employment-relationship.service";
import {EmploymentRelationshipHttpService} from "../../x-http-requests/employment-relationship-http.service";
import {EmployeeService} from "../../_services/_dto-services/employee.service";

@Component({
  selector: 'app-employment-relationship-popup',
  templateUrl: './employment-relationship-popup.component.html',
  styleUrls: ['./employment-relationship-popup.component.scss']
})
export class EmploymentRelationshipPopupComponent implements OnInit {
  constructor(public PermissionService: PermissionService,
              public EmploymentRelationshipService: EmploymentRelationshipService,
              public EmployeeService: EmployeeService,
              private globalAlertService: GlobalAlertService) {
  }

  ngOnInit(): void {

  }

  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input() changeIds: string[] | undefined = undefined;

  selectedEmploymentRelationship: EmploymentRelationshipDto | undefined;

  Set(er: EmploymentRelationshipDto | undefined) {
    this.selectedEmploymentRelationship = er;
  }

  Save() {
    if (!this.changeIds || this.changeIds.length == 0) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Zum Bearbeiten der Beschäftigungsverhältnisse muss mindestens 1 Mitarbeiter ausgewählt sein.", AlertLevel.error, 3000);
      this.globalAlertService.show();
      return;
    }

    this.EmploymentRelationshipService.HttpService.setRelationship(this.selectedEmploymentRelationship?.id, this.changeIds).subscribe({
      next: x => {
        this.globalAlertService.createAlertBannerModel("Erfolgreich gespeichert", "Das Beschäftigungsverhältnis wurde erfolgreich gespeichert.", AlertLevel.success, 2000);
        this.globalAlertService.show();

        x.item2!.forEach(e => {
          let r = this.EmployeeService.ReplaceAndGet(e);
          if (r.NewElement && r.OldElement) r.NewElement.selected = r.OldElement.selected;
        });

        this.Discard();
      },
      error: error => {
        console.error(error);
        this.globalAlertService.createAlertBannerModel("Fehler", "Beim Setzen der Beschäftigungsverhältnisse ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
        this.globalAlertService.show();
      }
    })
  }

  Discard() {
    this.visible = false;
    this.visibleChange.next(this.visible);
  }
}
