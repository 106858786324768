import {ModelBase} from "../model-base";
import {IModelBase} from "../i-model-base";
import {ItemDto} from "../item-dto";

export class CompositionCreateComponent extends ModelBase implements IModelBase {
  unit: boolean = false;
  value: number = 0;
  item: ItemDto = new ItemDto();
  filter: boolean = true;
}
