import {Injectable} from '@angular/core';
import {UserJwt} from "../models/admin_owner/user/user-jwt";
import {CookieService} from "./cookie.service";
import {CookieType} from "../x-models/x-enums/cookie-type";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class UserSelectorService {
  constructor(private cookieService: CookieService) {
    let stored = localStorage.getItem('user_history');
    if (stored) {
      this.users = JSON.parse(stored);
    }
  }

  add(user: UserJwt) {
    let index = this.users.findIndex(x => x.id == user.id);
    if (index == -1) this.users.push(user);
    else this.users.splice(index, 1, user);

    this.sort();
    this.save();
  }

  remove(userId: string) {
    let index = this.users.findIndex(x => x.id == userId);
    if (index > -1) this.users.splice(index, 1);

    this.save();
  }

  private sort() {
    this.users = this.users.sort((a, b) => a.name.localeCompare(b.name));
  }

  private save() {
    this.cookieService.setLocalStorage(CookieType.Required, 'user_history', JSON.stringify(this.users));
  }

  private decode(token: string): any {
    try {
      return jwt_decode(token);
    } catch {
      return null;
    }
  }

  hasUsers(): boolean {
    return this.users.length > 0;
  }

  isExpired(expiration: Date): boolean {
    return expiration < new Date();

    //let decoded = this.decode(token);
    //if (!decoded) return true;
    //let exp = decoded.exp;
    //return Date.now() >= exp * 1000;
  }

  users: UserJwt[] = [];
}
