import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-basic-page',
  standalone: true,
  imports: [],
  templateUrl: './basic-page.component.html',
  styleUrl: './basic-page.component.scss'
})
export class BasicPageComponent {
  @Input() title: string = "Title";
}
