import {Component, Input} from '@angular/core';
import {Routes} from "../../../../enums/routes";
import {SettingsInputElementModel} from "../../../../models/settings-input-element-model";
import {SettingsPageComponent} from "../../../../x-components/settings/settings-page/settings-page.component";
import {ActivatedRoute, Router} from "@angular/router";
import {SignalRHttpService} from "../../x-http-requests/signal-r-http.service";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {PermissionService} from "../../../../_auth/permission.service";
import {Device} from "../../x-models/device";
import {HttpPropertyChangeStatus} from "../../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../../models/observable-queue-element";
import {AlertLevel} from "../../../../enums/alert-level";

@Component({
  selector: 'app-device-settings-page',
  templateUrl: '../../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./device-settings-page.component.scss']
})
export class DeviceSettingsPageComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.DeviceOverview;
  public settingsInputElementModel: { [key: string]: any } = {
    newDeviceName: new SettingsInputElementModel(),
    newDeviceLockedOut: new SettingsInputElementModel(),
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public signalRHttpService: SignalRHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);

    this.settingsInputElementModel.newDeviceName.propertyTitle = "Name";
    this.settingsInputElementModel.newDeviceName.requiredPermission = PermissionService.Device_Information_ChangeName()
    this.settingsInputElementModel.newDeviceName.order = 1;

    this.settingsInputElementModel.newDeviceLockedOut.propertyTitle = "Gesperrt";
    this.settingsInputElementModel.newDeviceLockedOut.requiredPermission = PermissionService.Device_Information_SetLockedOut();
    this.settingsInputElementModel.newDeviceLockedOut.order = 10;
    this.settingsInputElementModel.newDeviceLockedOut.inputType = "checkbox";


    this.deletePermission = PermissionService.CheckPermission(PermissionService.Device_Information_Delete());

    //this.deletePermission = true
    //this.deletePermission = PermissionService.CheckPermission(PermissionService.GetTagDeletePermission());
  }

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.signalRHttpService.delete(this.model.id)
      .withLoadingIndicator()
      .subscribe(() => {
        this.globalAlertService.createAlertBannerModel("Gerät gelöscht", "Gerät wurde erfolgreich gelöscht", AlertLevel.success, 5000)
        this.globalAlertService.show();
      });
  };
  public model?: Device;
  public timeoutClick: boolean = false;


  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveLockedOut())

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  }

  @Input() loadInputModel: Function = (id: string) => {
    this.signalRHttpService.get(id).subscribe((model: Device) => {
      this.model = model;
      this.settingsInputElementModel.newDeviceName.originalPropertyValue = this.settingsInputElementModel.newDeviceName.changeableProperties.newPropertyValue = model.name;
      this.settingsInputElementModel.newDeviceLockedOut.originalPropertyValue = this.settingsInputElementModel.newDeviceLockedOut.changeableProperties.newPropertyValue = model.isLockedOut;
    });
  }

  saveLockedOut(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newDeviceLockedOut.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newDeviceLockedOut.changeableProperties.newPropertyValue === this.settingsInputElementModel.newDeviceLockedOut.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.signalRHttpService.setLockedOut(this.model.id, this.settingsInputElementModel.newDeviceLockedOut.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: Device) => {
      this.model = model;
      this.settingsInputElementModel.newDeviceLockedOut.originalPropertyValue = this.settingsInputElementModel.newDeviceLockedOut.changeableProperties.newPropertyValue = this.model?.isLockedOut;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Sperrzustands.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newDeviceName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newDeviceName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newDeviceName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.signalRHttpService.changeName(this.model.id, this.settingsInputElementModel.newDeviceName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: Device) => {
      this.model = model;
      this.settingsInputElementModel.newDeviceName.originalPropertyValue = this.settingsInputElementModel.newDeviceName.changeableProperties.newPropertyValue = this.model?.name;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}
