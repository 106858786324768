import {Component, EventEmitter, Input} from '@angular/core';
import {SettingsPageComponent} from "../../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../../enums/routes";
import {SettingsInputElementModel} from "../../../../models/settings-input-element-model";
import {ItemDto} from "../../../../models/item-dto";
import {ModelBase} from "../../../../models/model-base";
import {AlertLevel} from "../../../../enums/alert-level";
import {HttpPropertyChangeStatus} from "../../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../../models/observable-queue-element";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {SupplierDto} from "../../x-models/supplier-dto";
import {SupplierHttpService} from "../../x-http-requests/supplier-http.service";
import {PermissionService} from "../../../../_auth/permission.service";

@Component({
  selector: 'app-supplier-settings',
  templateUrl: "../../../../x-components/settings/settings-page/settings-page.component.html",
  styleUrls: ['./supplier-settings.component.scss']
})
export class SupplierSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.CompanySupplier;
  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newSupplierName: new SettingsInputElementModel(),
  };


  public model?: SupplierDto;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;


  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.supplierHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Supplier gelöscht", "Supplier wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  @Input() loadInputModel: Function = (id: string) => {
    this.supplierHttpService.get(id).subscribe((model: SupplierDto) => {

      this.model = model;

      this.settingsInputElementModel.newSupplierName.originalPropertyValue = this.settingsInputElementModel.newSupplierName.changeableProperties.newPropertyValue = this.model?.name
      this.updateElements()
    });
  };

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName());

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  };

  saveName(): HttpPropertyChangeStatus {

    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (!this.settingsInputElementModel.newSupplierName.changeableProperties.changeValue) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newSupplierName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newSupplierName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.supplierHttpService.changeName(this.model?.id, this.settingsInputElementModel.newSupplierName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: SupplierDto) => {
      this.model = model;
      this.settingsInputElementModel.newSupplierName.originalPropertyValue = this.settingsInputElementModel.newSupplierName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newSupplierName.changeableProperties.changeValue = false;
      observableQueueElement.successIndicator = true;
      this.updateElements()
    }

    observableQueueElement.callbackError = () => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Der Name konnte nicht geändert werden.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);
    return HttpPropertyChangeStatus.Changed;
  }


  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public supplierHttpService: SupplierHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newSupplierName.propertyTitle = "Name";


    this.settingsInputElementModel.newSupplierName.requiredPermission = PermissionService.Company_Supplier_ChangeName();
    this.deletePermission = PermissionService.CheckPermission(PermissionService.Company_Supplier_Delete());
  }

}
