export enum LocationRoutes {
  Root = '',
  Printers = 'printers',
  PrinterSettings = 'printer/settings',
  CashPoints = 'cash-points',
  CashPointsClosingAssistant = 'cash-points/assistant/closing',
  Settings = 'settings',
  Events = 'events',
  EventDetails = 'event/summary',
}
