import {Component} from '@angular/core';

@Component({
  selector: 'layout-page-scrollable',
  standalone: true,
  imports: [],
  templateUrl: './page-scrollable.component.html',
  styleUrl: './page-scrollable.component.scss'
})
export class PageScrollableComponent {

}
