<layout-page-container>
  <layout-header-container title="Verkaufspunkte"/>
  <layout-form-container [permission]="PermissionService.Inventory_Location_Add()"
                         submitText="Verkaufspunkt anlegen" clearText="Werte zurücksetzen"
                         (submit)="AddLocation()" (clear)="reset()"
                         (searchValueChange)="FilterLocation($event)"
                         [element]="name">
    <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <label for="location-name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
        <input #name [(ngModel)]="newLocationName"
               type="text" name="location-name" id="location-name" autocomplete="location-name"
               class="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      </div>
    </div>
  </layout-form-container>
  <layout-page-scrollable>
    <app-loading *ngIf="locations == undefined"></app-loading>
    <table class="min-w-full divide-y divide-gray-300" *ngIf="filtered_locations && filtered_locations.length > 0">
      <thead>
      <tr>
        <th (click)="SortBy('order')" scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'order' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'order' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">R</a>
          </div>
        </th>
        <th (click)="SortBy('name')" scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'name' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'name' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Name</a>
          </div>
        </th>
        <th (click)="SortBy('created')" scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'created' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'created' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Erstellt
              am</a>
          </div>
        </th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">ID</th>
        <th
          *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_LocationReadConfiguration())"
          scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-sm">Preisprofil
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0 text-lg">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let location of filtered_locations; let index = index;trackBy:TrackByIndex;">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span>{{ location.order }}</span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span>{{ location.name }}</span>
        </td>
        <td
          class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ location.created | date : "dd.MM.YYYY, HH:mm" }}
          Uhr
        </td>
        <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
          <div class="inline-flex">
            <span *ngIf="location.receivesTips" class="place-self-center mr-2 text-yellow-600">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-coin"
                   viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"/>
                <path
                  d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
                <path
                  d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"/>
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
              </svg>
            </span>
            <span class="place-self-center">
              {{ location.id }}
            </span>
          </div>
        </td>
        <td
          *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_LocationReadConfiguration())"
          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex">
          <div class="place-self-center">
            <select
              [disabled]="!PermissionService.CheckPermission(PermissionService.CombinedPermission_LocationSetConfiguration())"
              [(ngModel)]="filtered_locations[index].showConfig"
              (ngModelChange)="onConfigurationChange(location, $event)"
              id="location" name="location"
              class="block w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
              <option value="none" [selected]="location.configuration == undefined">-- KEINE --</option>
              <option *ngFor="let configuration of configurations" [value]="configuration.id"
                      [selected]="location.configuration != undefined && configuration.id == location.configuration.id">{{ configuration.name }}
              </option>
            </select>
          </div>
        </td>
        <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <div class="flex gap-2">
            <a
              *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_LocationSetConfiguration())"
              (click)="pushConfiguration(location)"
              class="select-none text-gray-600 hover:text-gray-900 cursor-pointer !ml-auto">
              <svg *ngIf="loadingLocations.indexOf(location.id) == -1" xmlns="http://www.w3.org/2000/svg" width="20"
                   height="20" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
              </svg>
              <svg *ngIf="loadingLocations.indexOf(location.id) != -1" xmlns="http://www.w3.org/2000/svg" width="20"
                   height="20" fill="currentColor" class="bi bi-arrow-clockwise animate-spin" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                <path
                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
              </svg>
            </a>
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Location_Get())"
               (click)="redirectToSettings(location.id)"
               class="select-none text-gray-600 hover:text-gray-900 cursor-pointer inline-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </a>
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Location_Delete())"
               (click)="RemoveLocation(location)" class="select-none text-red-600 hover:text-red-900 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
              </svg>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </layout-page-scrollable>
</layout-page-container>
