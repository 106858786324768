import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyComponent} from './company.component';
import {RouterModule} from "@angular/router";
import {ShiftMakerComponent} from './shift-maker/shift-maker.component';
import {CompanyRoutes} from "./x-models/x-enums/company-routes";
import {AuthGuard} from "../../_auth/auth.guard";
import {XComponentsModule} from "../x-components/x-components.module";
import {CdkDrag, CdkDragPlaceholder, CdkDragPreview, CdkDropList} from "@angular/cdk/drag-drop";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {EmploymentRelationshipsComponent} from "./employment-relationships/employment-relationships.component";
import {PermissionService} from "../../_auth/permission.service";
import {CdkScrollable} from "@angular/cdk/overlay";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  EmploymentRelationshipPopupComponent
} from './x-popups/employment-relationship-popup/employment-relationship-popup.component';
import {SupplierPopupComponent} from './x-popups/supplier-popup/supplier-popup.component';
import {ContractsComponent} from "./contracts/contracts.component";


@NgModule({
  declarations: [
    CompanyComponent,
    ShiftMakerComponent,
    EmploymentRelationshipsComponent,
    EmploymentRelationshipPopupComponent,
    SupplierPopupComponent,
  ],
  exports: [
    EmploymentRelationshipPopupComponent,
    SupplierPopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: CompanyRoutes.ShiftMaker, component: ShiftMakerComponent,
        canActivate: [AuthGuard], data: {roles: PermissionService.Permissions_ShiftMakerComponent().default},
        title: "Dienstplan"
      },
      {
        path: CompanyRoutes.EmployeeRelationships,
        component: EmploymentRelationshipsComponent,
        canActivate: [AuthGuard],
        data: {roles: PermissionService.Permissions_EmploymentRelationshipComponent().default},
        title: "Beschäftigungsverhältnisse"
      }, {
        path: CompanyRoutes.Contracts,
        component: ContractsComponent,
        canActivate: [AuthGuard],
        data: {roles: PermissionService.Permissions_ContractComponent().default},
        title: "Verträge"
      },

      //{
      //  path: '**', redirectTo: '/', pathMatch: 'full'
      //}
    ]),
    XComponentsModule,
    CdkDrag,
    CdkDropList,
    CdkDragPlaceholder,
    CdkDragPreview,
    FormsModule,
    NgSelectModule,
    CdkScrollable,
    MatTooltipModule
  ]
})
export class CompanyModule {
}
