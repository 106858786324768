import {SchedulerReoccurrence} from "./x-enums/scheduler-reoccurrence";
import {CalculationType} from "./x-enums/calculation-type";
import {TypeOrder} from "./x-enums/type-order";
import {CalculationOperation} from "./x-enums/calculation-operation";
import {CalculationTargetType} from "./x-enums/calculation-target-type";
import {TimeUtilities} from "../../../utils/time-utilities";
import {RoundingType} from "./x-enums/rounding-type";

export class NewSchedulerGroup {
  schedulerReoccurrence: SchedulerReoccurrence | undefined;
  calculationType: CalculationType | undefined;
  typeOrder: TypeOrder | undefined;
  calculationOperation: CalculationOperation | undefined;
  interval: number = 10;
  firstStr: string = TimeUtilities.dateToString(new Date());
  roundingType: RoundingType = RoundingType.None;
  get first(): Date {
    return new Date(this.firstStr)
  }
  lastStr: string = TimeUtilities.dateToString(new Date());
  get last(): Date {
    return new Date(this.lastStr);
  }
  calculationValues: number[] = [];
  additionalIds: any = {};
  targetIds: string[] = [];
  calculationTargetType: CalculationTargetType | undefined;
}
