import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {EmployeeAttributeDto} from "../../company/x-models/employee-attribute-dto";
import {Tuple} from "../../../models/_generic/tuple";
import {EmployeeDto} from "../../company/x-models/employee-dto";
import {DtoBaseHttpService} from "../../../_services/dto-services/dto-base-http.service";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class EmployeeAttributeHttpService extends DtoBaseHttpService<EmployeeAttributeDto> {
  UrlPath: string = "/company/employeeattribute";

  constructor(httpClient: HttpClient,
              @Inject("BASE_URL") baseUrl: string) {
    super(baseUrl, httpClient)
  }

  list(): Observable<EmployeeAttributeDto[]> {
    return this.HttpClient.get<EmployeeAttributeDto[]>(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_List);
  }

  add(name: string, employeeIds: string[]): Observable<EmployeeAttributeDto> {
    return this.HttpClient.post<EmployeeAttributeDto>(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_Add, {
      name: name,
      employeeIds: employeeIds
    })
  }

  setEmployeeAttributes(employeeAttributeIds: string[] | undefined, employeeIds: string[] | undefined, state: boolean | undefined): Observable<Tuple<EmployeeAttributeDto[], EmployeeDto[]>> {
    return this.HttpClient.put<Tuple<EmployeeAttributeDto[], EmployeeDto[]>>(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_SetEmployeeAttributes, {
      employeeAttributeIds: employeeAttributeIds,
      employeeIds: employeeIds,
      state: state
    })
  }

  delete(id: string): Observable<any> {
    return this.HttpClient.delete(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_Delete + "?id=" + id);
  }

  get(id: string): Observable<EmployeeAttributeDto> {
    return this.HttpClient.get<EmployeeAttributeDto>(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_Get + "?id=" + id);
  }

  changeName(id: string, name: string): Observable<EmployeeAttributeDto> {
    return this.HttpClient.put<EmployeeAttributeDto>(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_ChangeName, {
      Name: name,
      id: id
    })
  }

  changeShortName(id: string, name: string): Observable<EmployeeAttributeDto> {
    return this.HttpClient.put<EmployeeAttributeDto>(this.BaseUrl + ServerEndpoints.Company_EmployeeAttribute_ChangeShortName, {
      Name: name,
      id: id
    })
  }
}
