export enum VATRate {
  NORMAL = 1,
  REDUCED_1 = 2,
  SPECIAL_RATE_1 = 3,
  SPECIAL_RATE_2 = 4,
  NULL = 5
}

export class VATRateHelper {
  private static values: any = {
    1: 0.19,
    2: 0.07,
    3: 0.107,
    4: 0.055,
    5: 0
  }

  public static GetValue(value: VATRate): number {
    return this.values[value.valueOf()];
  }

  private static readonly defaultRate: VATRate = VATRate.NORMAL;

  public static GetValueOrDefault(value: VATRate | undefined): number {
    value = value ?? this.defaultRate;
    return this.GetValue(value);
  }

  public static GetRates: VATRate[] = [VATRate.NORMAL, VATRate.REDUCED_1, VATRate.SPECIAL_RATE_1, VATRate.SPECIAL_RATE_2, VATRate.NULL];
}
