import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {GlobalDateFormat} from "../enums/global-date-format";

@Injectable({
  providedIn: 'root'
})
export class GlobalDateService {



  private dateTranslation: { [key: string]: { [key: string]: string } } = {
    'de-DE': {
      [GlobalDateFormat.LONG]: 'dd.MM.yyyy',
      [GlobalDateFormat.LONG_WITH_TIME]: 'dd.MM.yyyy HH:mm',
      [GlobalDateFormat.MONTHNAME_YEAR]: 'MMMM yyyy',
    },
  };


  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  getDateformat(format: GlobalDateFormat): string {
    return this.dateTranslation[this.locale][format];
  }

  getDateAsString(date: Date): string {
    return date.toLocaleDateString(this.locale, {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'});
  }

}
