import {CalendarEntry} from "./calendar-entry";
import {SDatePipe} from "../../pipes/s-date.pipe";
import {Position} from "./x-enums/position";
import {of} from "rxjs";

export class CalendarDay {
  readonly date: Date;
  readonly strDate;
  outOfMonth: boolean = false;
  calendarEntries: CalendarEntry[] = [];
  position: Position = Position.None;

  constructor(date: Date, outOfMonth: boolean = false) {
    this.date = date

    this.strDate = this.date.toLocaleDateString();
    this.outOfMonth = outOfMonth;
  }
}
