<app-navbar-container breadcrumb="Standort">
  <app-navbar-item-large title="Events" [link]="'/' + Routes.LocationModule + '/' + LocationRoutes.Events"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_EventsComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M14.885 18q-.877 0-1.497-.62q-.619-.618-.619-1.495q0-.877.62-1.497q.619-.619 1.496-.619t1.496.62q.619.619.619 1.496t-.62 1.496q-.618.619-1.495.619m-9.27 3q-.69 0-1.152-.462Q4 20.075 4 19.385V6.615q0-.69.463-1.152Q4.925 5 5.615 5h1.77V2.77h1.077V5h7.153V2.77h1V5h1.77q.69 0 1.152.463q.463.462.463 1.152v12.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h12.77q.23 0 .423-.192q.192-.193.192-.423v-8.77H5v8.77q0 .23.192.423q.193.192.423.192M5 9.615h14v-3q0-.23-.192-.423Q18.615 6 18.385 6H5.615q-.23 0-.423.192Q5 6.385 5 6.615zm0 0V6z"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Verkäufe" [link]="'/' + Routes.Orders"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_OrderComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor">
        <circle cx="5.5" cy="7.5" r="1"/>
        <path stroke-linecap="round" d="M8.5 6.5h11m-11 2h6"/>
        <circle cx="5.5" cy="12" r="1"/>
        <path stroke-linecap="round" d="M8.5 11h8m-8 2h7"/>
        <circle cx="5.5" cy="16.5" r="1"/>
        <path stroke-linecap="round" d="M8.5 15.5H18m-9.5 2h4"/>
      </g>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Verkaufspunkte" [link]="'/' + Routes.Locations"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_LocationsComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fill="currentColor"
            d="M9.5 7a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0M14 7c0 2.874-3.097 6.016-4.841 7.558a1.74 1.74 0 0 1-2.318 0C5.097 13.016 2 9.874 2 7a6 6 0 1 1 12 0m-1 0A5 5 0 0 0 3 7c0 1.108.615 2.395 1.57 3.683c.934 1.258 2.087 2.377 2.933 3.126a.74.74 0 0 0 .994 0c.846-.749 2-1.867 2.933-3.126C12.385 9.395 13 8.108 13 7"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Kassen" [link]="'/' + Routes.LocationModule + '/' + LocationRoutes.CashPoints"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_CashPointComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path
          d="M21 15h-2.5a1.503 1.503 0 0 0-1.5 1.5a1.503 1.503 0 0 0 1.5 1.5h1a1.503 1.503 0 0 1 1.5 1.5a1.503 1.503 0 0 1-1.5 1.5H17m2 0v1m0-8v1m-6 6H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h2m12 3.12V9a2 2 0 0 0-2-2h-2"/>
        <path d="M16 10V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v6m8 0H8m8 0h1m-9 0H7m1 4v.01M8 17v.01m4-3.02V14m0 3v.01"/>
      </g>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Geräte" [link]="'/'+Routes.DeviceOverview"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_DevicesComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-tablet" viewBox="0 0 16 16">
      <path
        d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large
    *ngIf="false && PermissionService.CheckPermission(PermissionService.Permissions_TableComponent())" class=""
    title="Tische" [link]="'/' + Routes.TablePlanner + '/table-manager'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="m6.73 19.02l1.616-4H11.5v-4.814q-3.421-.068-5.74-.856q-2.318-.788-2.318-1.85q0-1.142 2.483-1.927Q8.408 4.788 12 4.788q3.598 0 6.078.785q2.48.785 2.48 1.927q0 1.08-2.328 1.86q-2.328.778-5.73.846v4.813h3.154l1.615 4h-1.038l-1.2-3H8.969l-1.2 3zM12 9.211q2.694 0 4.854-.502t2.64-1.21q-.48-.708-2.64-1.21T12 5.788q-2.694 0-4.854.502T4.506 7.5q.48.708 2.64 1.21T12 9.212M12 7.5"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Drucker" [link]="'/' + Routes.LocationModule + '/' + LocationRoutes.Printers"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_PrinterComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M7 17v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2M7 17v2a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-2M7 17H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h1m0 0V5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2M6 7h12m0 0h1a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2M7 10h1"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large class="ml-auto" title="Einstellungen"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Admin())"
                         [link]="'/' + Routes.LocationModule + '/' + LocationRoutes.Settings">
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-gear-wide-connected" viewBox="0 0 16 16">
      <path
        d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
    </svg>
  </app-navbar-item-large>
</app-navbar-container>
