import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthUnit} from "../x-models/auth-unit";
import {By} from "@angular/platform-browser";
import {Tuple} from "../../../models/_generic/tuple";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class SettingHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  SetAuth(id: string, value: string, type: number = 0): Observable<any> {
    return this.httpClient.post(this.baseUrl + ServerEndpoints.Company_Setting_SetAuthPair, {
      "id": id,
      "value": value,
      "type": type
    })
  }

  SetAuths(data: object[]): Observable<any> {
    return this.httpClient.post(this.baseUrl + ServerEndpoints.Company_Setting_SetAuthPairs, data);
  }

  SumUpIsConfigured(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.baseUrl + ServerEndpoints.Company_Setting_SumUpIsConfigured);
  }

  TelegramIsConfigured(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.baseUrl + ServerEndpoints.Company_Setting_TelegramIsConfigured);
  }

  SetTimezone(timezone: string): Observable<AuthUnit> {
    return this.httpClient.put<AuthUnit>(this.baseUrl + ServerEndpoints.Administration_Settings_SetTimezone + "?timezone=" + timezone, {})
  }

  GetTimezone(): Observable<AuthUnit> {
    return this.httpClient.get<AuthUnit>(this.baseUrl + ServerEndpoints.Administration_Settings_GetTimezone)
  }

  SetLogo(url: string | null = null): Observable<Tuple<string | undefined, any>> {
    return this.httpClient.put<Tuple<string | undefined, any>>(this.baseUrl + ServerEndpoints.Administration_Settings_SetLogo + "?url=" + (!url ? "" : url), {});
  }

  GetLogo(): Observable<Tuple<string | undefined, any>> {
    return this.httpClient.get<Tuple<string | undefined, any>>(this.baseUrl + ServerEndpoints.Administration_Settings_GetLogo);
  }
}
