export enum AnalyticsRouting {

  // Index Module Routes
  MultiEventAnalysis = "multi-event-analysis",
  SingleEventAnalysis = "single-event-analysis",


  // Multi Event Analysis Module Routes
  TimeSelectorView = "time-selector",
  RevenuePerformanceTracker = "revenue-performance-tracker",



  Index = "",
  /*SingleEventAnalysis = "single-event-analysis",
  MultiEventAnalysis = "multi-event-analysis",*/

  EventPicker = "event-picker",
  DetailedEventAnalysis = "detailed-event-analysis"
}
