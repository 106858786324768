import {Injectable} from '@angular/core';
import {BehaviorSubject, finalize, Observable} from "rxjs";
import {ReplacedElementStatus} from "../../enums/replaced-element-status";
import {ReplaceDtoElementModel} from "../../models/misc/replace-dto-element-model";
import {GlobalAlertService} from "../global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {DtoSignalRService} from "./dto-signal-r.service";
import {DtoServices} from "./_enums/dto-services";
import {DtoServiceUpdate} from "./_models/dto-service-update";
import {DtoServiceUpdateType} from "./_enums/dto-service-update-type";
import {parseEnum} from "@angular/compiler-cli/linker/src/file_linker/partial_linkers/util";

@Injectable({
  providedIn: 'root'
})
export abstract class DtoBaseService<T, THttp> {
  protected constructor(httpService: THttp,
                        globalAlertService: GlobalAlertService,
                        dtoSignalRService: DtoSignalRService) {
    this.HttpService = httpService;
    this.AlertService = globalAlertService;

    this.DtoSignalRService = dtoSignalRService;

    this.LoadData();
  }

  public abstract Identifier: DtoServices;

  private GetIdentifier(): DtoServices {
    return this.Identifier;
  }

  public HttpService: THttp;
  private AlertService: GlobalAlertService;
  public DtoSignalRService: DtoSignalRService;

  public Entities?: T[] = undefined;
  public EntitiesChanged = new BehaviorSubject<T[] | undefined>([]);
  public EntityChanged = new BehaviorSubject<T | undefined>(undefined);

  abstract FetchAll(all: boolean): Observable<T[]>;

  abstract FetchById(id: string, all: boolean): Observable<T>;

  abstract Add(payload: any): Observable<T>;

  abstract GetId(entity: T): string;

  public Initialize(): void {
    this.DtoSignalRService.RegisterReconnect(this);
  }

  public LoadData(all: boolean = false): void {
    this.FetchAll(all).pipe(finalize(() => {
      this.EntitiesChanged.next(this.Entities);
    })).subscribe({
      next: entities => {
        this.Entities = entities;
      },
      error: error => {
        this.Entities = undefined;
        console.error(error);
      }
    })
  }

  public Replace(entity: T): ReplacedElementStatus {
    if (this.Entities === undefined) {
      this.Entities = [entity];
      return ReplacedElementStatus.UndefinedArrayCreatedAndElementAdded;
    }

    let index = this.Entities.findIndex(e => this.GetId(e) === this.GetId(entity));

    if (index > -1) {
      this.Entities.splice(index, 1, entity);
      this.EntitiesChanged.next(this.Entities);
      this.EntityChanged.next(entity);
      return ReplacedElementStatus.ElementReplaced;
    }

    this.Entities.splice(0, 0, entity);
    this.EntitiesChanged.next(this.Entities);
    this.EntityChanged.next(entity);
    return ReplacedElementStatus.NotFoundAndElementAdded;
  }

  public ReplaceAndGet(entity: T): ReplaceDtoElementModel<T> {
    if (this.Entities === undefined) {
      this.Entities = [];
    }

    let _ReplaceDtoElementModel: ReplaceDtoElementModel<T> = new ReplaceDtoElementModel<T>();
    let index = this.Entities.findIndex(e => this.GetId(e) === this.GetId(entity));

    if (index > -1) {
      _ReplaceDtoElementModel.OldElement = this.Entities.slice(index, index + 1)[0];
    }

    this.Replace(entity);
    _ReplaceDtoElementModel.NewElement = this.Entities[index];
    return _ReplaceDtoElementModel;
  }

  public GetById(id: string): T | undefined {
    if (this.Entities === undefined) return undefined;
    return this.Entities.find(e => this.GetId(e) === id);
  }

  public Create(payload: any) {
    this.Add(payload).subscribe({
      next: entity => {
        this.Replace(entity);
        this.AlertService.createAlertBannerModel("Erfolg", "Das Objekt wurde erfolgreich angelegt!", AlertLevel.success, 2000);
        this.AlertService.show()
      },
      error: error => {
        console.error(error);
        this.AlertService.createAlertBannerModel("Fehler", "Objekt konnte nicht erstellt werden.", AlertLevel.error, 2000);
        this.AlertService.show()
      }
    })
  }

  public Remove(id: string): boolean {
    if (this.Entities === undefined) return false;

    let index = this.Entities.findIndex(e => this.GetId(e) === id);
    if (index > -1) {
      this.Entities.splice(index, 1);
      this.EntitiesChanged.next(this.Entities);
      return true;
    }

    return false;
  }

  ProcessUpdate(update: DtoServiceUpdate) {
    if (update.dtoService != this.Identifier) {
      throw new Error("DTO-Service mismatch, aborting...");
    }
    let types = Object.keys(update.ids).map(x => Number.parseInt(x) as DtoServiceUpdateType);
    types.forEach(type => {
      let ids: string[] = update.ids[type.valueOf().toString()];
      switch (type) {
        case DtoServiceUpdateType.Remove: {
          ids.forEach(id => {
            this.Remove(id);
          })
          break;
        }
        default: {
          // @ts-ignore
          let entities = this.HttpService.Fetch(ids, false).subscribe({
            next: (entities: T[]) => {
              entities.forEach(entity => {
                this.Replace(entity);
              })
            },
            error: (error: any) => {
              console.error(error);
            }
          })
          break;
        }
      }
    })
  }
}
