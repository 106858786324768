import {Component, Input} from '@angular/core';
import {SettingsPageComponent} from "../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../enums/routes";
import {LocationRoutes} from "../_models/_enums/location-routes";
import {SettingsInputElementModel} from "../../../models/settings-input-element-model";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {PermissionService} from "../../../_auth/permission.service";
import {AlertLevel} from "../../../enums/alert-level";
import {PrinterDto} from "../_models/printer-dto";
import {PrinterHttpService} from "../_services/printer-http.service";
import {HttpPropertyChangeStatus} from "../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../models/observable-queue-element";

@Component({
  selector: 'app-printer-settings',
  templateUrl: '../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./printer-settings.component.scss']
})
export class PrinterSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = `${Routes.LocationModule}/${LocationRoutes.Printers}`
  public settingsInputElementModel: { [key: string]: any } = {
    newPrinterName: new SettingsInputElementModel(),
    newPrinterConnectionString: new SettingsInputElementModel(),
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public printerHttpService: PrinterHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);

    this.settingsInputElementModel.newPrinterName.propertyTitle = "Name";
    this.settingsInputElementModel.newPrinterName.requiredPermission = PermissionService.Device_Printer_ChangeName()
    this.settingsInputElementModel.newPrinterName.order = 1;

    this.settingsInputElementModel.newPrinterConnectionString.propertyTitle = "Verbindungszeichenfolge";
    this.settingsInputElementModel.newPrinterConnectionString.requiredPermission = PermissionService.Device_Printer_SetVariable();
    this.settingsInputElementModel.newPrinterConnectionString.order = 10;

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Device_Printer_Delete());
  }

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.printerHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAndShow("Drucker gelöscht", "Drucker wurde erfolgreich gelöscht", AlertLevel.success)
    });
  };
  public model?: PrinterDto;
  public timeoutClick: boolean = false;

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveVariable("ConnectionString", this.settingsInputElementModel.newPrinterConnectionString))

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();
  }

  @Input() loadInputModel: Function = (id: string) => {
    this.printerHttpService.get(id).subscribe((model: PrinterDto) => {
      this.model = model;
      this.settingsInputElementModel.newPrinterName.originalPropertyValue = this.settingsInputElementModel.newPrinterName.changeableProperties.newPropertyValue = model.name;
      this.settingsInputElementModel.newPrinterConnectionString.originalPropertyValue = this.settingsInputElementModel.newPrinterConnectionString.changeableProperties.newPropertyValue = model.connectionString;
    });
  }

  saveVariable(variable: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.printerHttpService.setVariable(this.model.id, variable, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: PrinterDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAndShow("Fehler", "Fehler beim Speichern des Werts.", AlertLevel.error);
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newPrinterName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newPrinterName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newPrinterName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.printerHttpService.changeName(this.model.id, this.settingsInputElementModel.newPrinterName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: PrinterDto) => {

      this.model = model;
      this.settingsInputElementModel.newPrinterName.originalPropertyValue = this.settingsInputElementModel.newPrinterName.changeableProperties.newPropertyValue = model.name;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAndShow("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error);
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}
