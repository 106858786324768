<div class="absolute inset-0 z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true"
     *ngIf="json">
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div class="absolute inset-0 bg-gray-500 bg-opacity-50 cursor-pointer" (click)="close()"></div>
  <div class="absolute inset-0 overflow-hidden pointer-events-none">
    <div class="absolute inset-0 overflow-hidden cursor-pointer">
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex max-w-full cursor-default">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div (click)="copy()"
             class="bg-gray-500 text-white p-4 pointer-events-auto cursor-pointer absolute bottom-4 left-4 flex z-10 rounded-full">
          <div class="inline-flex gap-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                 class="bi bi-copy place-self-center" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
            </svg>
            <span class="place-self-center">Kopieren</span>
          </div>
        </div>
        <div class="pointer-events-auto w-screen max-w-2xl">
          <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 class="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">JSON-Viewer</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button (click)="close()" type="button"
                          class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2">
                    <span class="absolute -inset-2.5"></span>
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                         aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="relative mt-6 flex-1 px-4 sm:px-6">
              <ngx-json-viewer [json]="json" [expanded]="false"></ngx-json-viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
