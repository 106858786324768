/**
 * Available Tailwind Transition Duration
 * @enum {number}
 */
export enum TailwindTransitionDuration {
  Duration0 = 0,
  Duration75 = 75,
  Duration100 = 100,
  Duration150 = 150,
  Duration200 = 200,
  Duration300 = 300,
  Duration500 = 500,
  Duration700 = 700,
  Duration1000 = 1000
}

