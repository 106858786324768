<layout-page-container>
  <layout-header-container title="Items">
    <span *ngIf="qCategory" class="inline-flex pl-2 no-linebreak place-self-end text-lg w-full"> - {{ qCategory.name }}
      <a (click)="SetCategory()" class="place-self-center pl-2 text-red-400 hover:text-red-600 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-x-circle-fill" viewBox="0 0 16 16">
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>
      </a>
    </span>
    <div class="w-full grid">
      <button *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_CompositionCreate())"
              (click)="compositionCreator = true;" type="button"
              class="cursor-pointer inline-flex relative place-self-end rounded-full bg-theme-primary-700 px-4 py-2.5 !pr-14 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-900 h-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-diagram-2 place-self-center" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
        </svg>
        <span
          class="self-center pl-2 hidden md:block">Artikel {{ isCompositionEditMode() ? "bearbeiten" : "anlegen" }}</span>
        <div *ngIf="componentsRework != undefined"
             class="bg-white text-theme-primary-700 rounded-full absolute inset-y-0.5 right-0.5 w-9 flex">
          <span class="m-auto">{{ componentsRework.length }}</span>
        </div>
      </button>
    </div>
  </layout-header-container>
  <layout-form-container [permission]="PermissionService.CombinedPermission_ItemAdd()"
                         submitText="Item anlegen" clearText="Felder leeren" [element]="name"
                         (clear)="newItemName = ''" (submit)="AddItem()" (searchValueChange)="FilterItem($event)">
    <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
      <div class="sm:col-span-4">
        <label for="item-name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
        <input #name [(ngModel)]="newItemName"
               type="text" name="item-name" id="item-name" autocomplete="item-name"
               class="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      </div>
      <div class="sm:col-span-2">
        <label for="item-category" class="block text-sm font-medium leading-6 text-gray-900">Kategorie</label>
        <ng-select name="item-category" id="item-category" [items]="categories" bindLabel="name" bindValue="id"
                   [ngModel]="newItemCategory?.id"
                   [clearable]="false" (ngModelChange)="setNewItemCategory($event)"
                   class="ng-select cheqme-style mt-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
        </ng-select>
      </div>
    </div>
  </layout-form-container>

  <!--<div class="pt-4" *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_ItemAdd())">
    <label for="newItemName" class="block text-sm font-medium leading-6 text-gray-900">Neues Item</label>
    <form name="new-item" class="mt-2 grid grid-cols-1 sm:grid-cols-4 rounded-md shadow-sm">
      <div class="relative sm:col-span-2 flex-full flex flex-grow items-stretch focus-within:z-10">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmarks"
               viewBox="0 0 16 16">
            <path
              d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"/>
            <path
              d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"/>
          </svg>
        </div>
        <input type="text" name="newItemName" id="newItemName" [(ngModel)]="newItemName"
               class="block w-full rounded-t-md sm:rounded-none sm:rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
               placeholder="Name">
      </div>
      <select *ngIf="PlatformScannerService.getPlatform() != Platform.Safari" style="text-align-last: center"
              name="newItemCategory" id="newItemCategory" [(ngModel)]="newItemCategory"
              class="mb-2 sm:!mb-0 relative -mt-px sm:mt-0 rounded-b-md sm:-ml-px inline-flex items-center gap-x-1.5 sm:rounded-none px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <option *ngFor="let category of categories" [ngValue]="category">{{ category.name }}</option>
      </select>
      <app-combobox *ngIf="PlatformScannerService.getPlatform() == Platform.Safari" [classes]="'md:-ml-px'"
                    [nyObjects]="categories" [displayProperty]="'name'" [idProperty]="'id'"
                    (nySelection)="newItemCategory = $event" [filter]="false"></app-combobox>

      <button (click)="AddItem()" type="submit"
              class="relative sm:-ml-px inline-flex justify-center items-center gap-x-1.5 col-span-1 rounded-md sm:rounded-none sm:rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
             viewBox="0 0 16 16">
          <path
            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
        </svg>
        Hinzufügen
      </button>
    </form>
</div>-->
  <!--<input #search (input)="FilterItem(search.value)" type="text" name="search" id="search"
         class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 mb-4"
         placeholder="Item suchen...">-->
  <layout-page-scrollable>
    <app-loading *ngIf="!itemService.items"></app-loading>
    <table class="min-w-full divide-y divide-gray-300" *ngIf="filtered_items && filtered_items.length > 0">
      <thead>
      <tr>
        <th (click)="SortBy('name')" scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'name' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'name' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Name</a>
          </div>
        </th>
        <th (click)="SortBy('created')" scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'created' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'created' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Erstellt
              am</a>
          </div>
        </th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 2xl:table-cell text-sm">
          ID
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-sm">Maßeinheit</th>
        <th (click)="SortBy('invnb')" scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-lg">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'invnb' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'invnb' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Inv&nbsp;#</a>
          </div>
        </th>
        <th (click)="SortBy('category')" scope="col"
            class="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-sm">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'category' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'category' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Kategorie</a>
          </div>
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0 text-lg">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let item of filtered_items">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 inline-flex">
          <a *ngIf="PermissionService.CheckPermission(PermissionService.CombinedPermission_CompositionCreate())"
             (click)="AddToCompositionRework(item)"
             class="place-self-center cursor-pointer text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus"
                 viewBox="0 0 16 16">
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </a>
          <a
            [ngClass]="{'pointer-events-none text-gray-800' : !PermissionService.CheckPermission(PermissionService.Permissions_CompositionComponent())}"
            [routerLink]="['/compositions']" [queryParams]="{ item: item.id }"
            class="place-self-center text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">{{ item.name }}</a>
        </td>
        <td
          class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 md:table-cell">{{ (item.created) | date : "dd.MM.YYYY, HH:mm" }}
          Uhr
        </td>
        <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 2xl:table-cell">{{ item.id }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <select *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Item_SetUnit())"
                  class="border-2 rounded-md p-1" [ngModel]="item.unit" (ngModelChange)="ChangeUnitType(item, $event)">
            <option *ngFor="let unit of units"
                    [ngValue]="Number(unit[0])"
                    [selected]="Number(unit[0]) == item.unit">{{ UnitConverter.GetString(Number(unit[0])) }}
            </option>
          </select>
          <span
            *ngIf="!PermissionService.CheckPermission(PermissionService.Inventory_Item_SetUnit())">{{ UnitConverter.GetString(Number(item.unit)) }}</span>
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
          <a
            [ngClass]="{'pointer-events-none text-gray-800' : !PermissionService.CheckPermission(PermissionService.Inventory_Item_SetSourceDetail())}"
            class="cursor-pointer text-theme-primary-700 hover:text-theme-primary-900"
            (click)="SetInvNumber(item)">
            <!--{{ item.invNumber == 0 ? "-" : item.invNumber }}-->
            {{ item.latestSourceDetail?.inventoryId == null ? "-" : item.latestSourceDetail?.inventoryId }}
          </a>
        </td>
        <td class="hidden sm:inline-flex whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <a *ngIf="PermissionService.CheckPermission((PermissionService.Inventory_Category_Get()))"
             (click)="SetCategory(item.category.id)"
             class="place-self-center pr-2 cursor-pointer text-theme-primary-700 hover:text-theme-primary-900">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                 viewBox="0 0 16 16">
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </a>
          {{ item.category.name }}
        </td>
        <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <div class="flex gap-2">
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Item_SetSourceDetail())"
               (click)="OpenSourceDetailsPopup(item)"
               class="select-none text-gray-600 hover:text-gray-900 cursor-pointer !ml-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </a>
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Item_Get())"
               (click)="redirectToSettings(item.id)"
               class="select-none text-gray-600 hover:text-gray-900 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
                   viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </a>
            <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Item_Delete())"
               (click)="RemoveItem(item)" class="select-none text-red-600 hover:text-red-900 cursor-pointer mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
              </svg>
            </a>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </layout-page-scrollable>
</layout-page-container>

<!-- sidebar -->
<div [class.hidden]="!compositionCreator" class="absolute inset-y-0 right-0 w-full max-w-xl z-10 transition-all"
     aria-labelledby="slide-over-title"
     role="dialog"
     aria-modal="true">
  <div class="absolute inset-0 overflow-hidden pointer-events-none">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none absolute inset-y-0 right-0 flex max-w-full">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <form class="w-screen max-w-xl pl-10 pointer-events-none h-full flex flex-col">
          <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-2xl pointer-events-auto">
            <div class="relative h-full flex flex-col">
              <button (click)="compositionCreator = false" type="button"
                      class="absolute right-4 top-8 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-primary-900">
                <span class="sr-only">Close panel</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                     aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
              <div class="px-3 pt-6 flex flex-col">
                <h2 class="font-semibold text-2xl text-gray-900" id="slide-over-title">
                  Artikel {{ isCompositionEditMode() ? "bearbeiten" : "anlegen" }}</h2>
                <div>
                  <label for="newCompositionName" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input [(ngModel)]="this.newCompositionName" type="text" name="newCompositionName"
                           id="newCompositionName"
                           class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="Name">
                  </div>
                </div>
                <div class="mt-2">
                  <label for="price" class="block text-sm font-medium leading-6 text-gray-900">Preis</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">&euro;</span>
                    </div>
                    <input [(ngModel)]="this.newCompositionPrice" type="number" name="price" id="price"
                           class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="0.00" aria-describedby="price-currency">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                    </div>
                  </div>
                </div>
                <div class="flex mt-3">
                  <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
                  <button (click)="special = !special" [class.!bg-theme-primary-700]="special" type="button"
                          class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2"
                          role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span aria-hidden="true" [class.!translate-x-5]="special"
                          class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
                  </button>
                  <span class="ml-3 text-sm" id="SpecialToggle">
                    <span class="font-medium text-gray-900">Special</span>
                  </span>
                </div>
                <div class="inline-flex py-6 w-full">
                  <a (click)="AddToCompositionRework(undefined, false)" class="place-self-center pr-4 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                    </svg>
                  </a>
                  <label class="place-self-center font-medium leading-6 text-gray-900 select-none">Items</label>
                  <div class="grid w-full select-none">
                    <a (click)="componentsRework = []"
                       class="pr-0 text-red-600 hover:text-red-800 place-self-end cursor-pointer select-none">Alle
                      entfernen</a>
                  </div>
                </div>
              </div>
              <div class="w-full px-3">
                <div class="h-0.5 bg-gray-300 rounded-full w-full"></div>
              </div>
              <div class="overflow-y-scroll px-3 h-full">
                <div *ngFor="let component of componentsRework; let index = index;trackBy:TrackByIndex;"
                     class="inline-flex w-full py-1">
                  <a (click)="RemoveFromCompositionsRework(component)"
                     class="text-red-400 hover:text-red-600 place-self-center pr-4 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                         class="bi bi-dash-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </a>
                  <div class="place-self-center w-full w-100 flex">
                    <div class="shrink flex">
                      <span (click)="componentsRework[index].unit = !componentsRework[index].unit"
                            [class.!text-black]="componentsRework[index].unit"
                            class="cursor-pointer select-none fw-bold text-gray-200 place-self-center pr-2 hidden md:block">Einheit</span>
                      <span (click)="componentsRework[index].unit = !componentsRework[index].unit"
                            [class.!text-black]="componentsRework[index].unit"
                            class="cursor-pointer select-none fw-bold text-gray-200 place-self-center pr-2 md:hidden">E</span>
                    </div>
                    <div class="relative rounded-md shadow-sm grow">
                      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" #amount>
                        <span class="text-gray-500 sm:text-sm select-none"
                              *ngIf="!componentsRework[index].unit">{{ UnitConverter.GetString(componentsRework[index].item.unit) }}</span>
                        <span class="text-gray-500 sm:text-sm select-none"
                              *ngIf="componentsRework[index].unit && componentsRework[index].item.latestSourceDetail?.volumePerUnit">
                          {{ componentsRework[index].item.latestSourceDetail?.volumePerUnit }}&nbsp;{{ UnitConverter.GetString(componentsRework[index].item.unit) }}&nbsp;x
                        </span>
                      </div>
                      <input [(ngModel)]="componentsRework[index].value"
                             [class.ring-gray-300]="!componentsRework[index].filter"
                             [class.ring-yellow-600]="componentsRework[index].filter" type="number"
                             name="amount_{{index}}" id="amount"
                             class="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                             [style.padding-left.px]="amount.getBoundingClientRect().width + 5"
                             placeholder="0.00">
                      <div class="absolute inset-y-0 right-0 flex items-center" style="max-width: 50% !important;">
                        <label for="{{'item_selector_' + index}}" class="sr-only">Item</label>
                        <select [(ngModel)]="componentsRework[index].item" id="{{'item_selector_' + index}}"
                                name="{{'item_selector_' + index}}"
                                class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm">
                          <option *ngFor="let item of itemService.items"
                                  [selected]="item.id == componentsRework[index].item.id"
                                  [class.hidden]="!item.category.items.includes(componentsRework[index].item.id) && componentsRework[index].filter"
                                  [ngValue]="item">{{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full px-3">
                <div class="h-0.5 bg-gray-300 rounded-full w-full"></div>
              </div>
              <div class="relative px-3 flex flex-col">
                <div class="flex-shrink-0 justify-end py-1 hidden tall:flex" *ngIf="!isCompositionEditMode()">
                  <div class="-mt-px flex flex-wrap divide-gray-200 my-2 gap-x-2">
                    <div *ngFor="let tag of tags" (click)="ToggleTag(tag)" class="mt-2"
                         [ngClass]="{'cursor-pointer':!isCompositionEditMode(),'cursor-default': isCompositionEditMode()}">
                      <span *ngIf="selectedTags.indexOf(tag) != -1"
                            class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium border-2 border-gray-900 max-w-full"
                            style="{{'border-color: #' + (!isCompositionEditMode() ? tag.color : 'e5e7eb') + '; color: #' + (!isCompositionEditMode() ? tag.color : 'e5e7eb')}}">
                        <svg class="-ml-1 mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8"
                             style="{{'border-color: #' + (!isCompositionEditMode() ? tag.color : 'e5e7eb')}}">
                          <circle cx="4" cy="4" r="3"/>
                        </svg>
                        <span href="" class="d-inline-flex text-lg truncate"
                              style="{{'color: #' + (!isCompositionEditMode() ? tag.color : 'e5e7eb')}}">{{ tag.name }}</span>
                      </span>
                      <span *ngIf="selectedTags.indexOf(tag) == -1"
                            class="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium border-2 text-gray-200 border-gray-200 max-w-full">
                        <svg class="-ml-1 mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx="4" cy="4" r="3"/>
                        </svg>
                        <span href="" class="d-inline-flex text-lg truncate">{{ tag.name }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex py-4">
                  <button (click)="CreateComposition(true)" type="button"
                          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                    Speichern & Schließen
                  </button>
                  <button (click)="CreateComposition(false)" type="submit"
                          class="ml-4 inline-flex justify-center rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-900">
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true"
     *ngIf="this.visiblePopUp === PopUps.EditSourceDetails && editingItem">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <form
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-md sm:p-6">
        <div>
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-info-circle"
                 viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </div>
          <div class="mt-1 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900 fw-bold" id="modal-title">Item-Details</h3>
            <div class="mt-2">

              <div class="pb-3 text-left">
                <p><span class="fw-bold">Name: </span>{{ editingItem.name }}</p>
                <p><span class="fw-bold">Kategorie: </span>{{ editingItem.category.name }}</p>
                <p><span class="fw-bold">ID: </span>{{ editingItem.id }}</p>
              </div>

              <div class="grid grid-cols-2 gap-4">
                <div class="col-span-2">
                  <label for="invId" class="block text-sm font-medium leading-6 text-gray-900 text-left">Inventar
                    ID</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input #invId [(ngModel)]="sourceDetailInvId" type="number" step="1" name="invId" id="invId"
                           class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="">
                  </div>
                </div>

                <div class="">
                  <label for="vpu" class="block text-sm font-medium leading-6 text-gray-900 text-left">Volumen pro
                    Einheit</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input #vpu [(ngModel)]="sourceDetailVPU" type="number" name="vpu" id="vpu"
                           class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="0.00" aria-describedby="price-currency">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span class="text-gray-500 sm:text-sm">{{ UnitConverter.GetString(editingItem.unit) }}</span>
                    </div>
                  </div>
                </div>

                <div class="">
                  <label for="ppu" class="block text-sm font-medium leading-6 text-gray-900 text-left">Preis pro
                    Einheit</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span class="text-gray-500 sm:text-sm">&euro;</span>
                    </div>
                    <input [(ngModel)]="sourceDetailPPU" type="number" name="ppu" id="ppu"
                           class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="0.00" aria-describedby="price-currency">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span class="text-gray-500 sm:text-sm">EUR</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="SupplierService?.Entities" class="col-span-2 grid grid-cols-1">
                  <label for="supplier" class="block text-sm font-medium leading-6 text-gray-900 text-left pb-1">Lieferant</label>
                  <select [(ngModel)]="supplierId" id="supplier" name="supplier"
                          class="border-2 p-1 rounded-md text-sm">
                    <option [value]="''">-- KEINER --</option>
                    <option *ngFor="let supplier of SupplierService!.Entities; let last = last"
                            [value]="supplier.id">{{ supplier.name }}
                    </option>
                  </select>
                </div>

                <div class="pb-3">
                  <label for="upb" class="block text-sm font-medium leading-6 text-gray-900 text-left">Einheiten pro
                    Gebinde</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input #upb [(ngModel)]="sourceDetailUPB" type="number" name="upb" id="upb"
                           class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="0" aria-describedby="price-currency">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span class="text-gray-500 sm:text-sm">St.</span>
                    </div>
                  </div>
                </div>

                <div class="pb-3">
                  <label for="sk"
                         class="block text-sm font-medium leading-6 text-gray-900 text-left">Lieferanten-Code</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <input #sk [(ngModel)]="sourceDetailSK" type="text" name="sk" id="sk"
                           class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
                           placeholder="" aria-describedby="price-currency">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button (click)="saveItemSourceDetails()" type="submit"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:col-start-2 sm:mt-0">
            Speichern
          </button>
          <button (click)="closePopUps()" type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:!bg-gray-200 sm:col-start-1 sm:mt-0">
            Abbrechen
          </button>
        </div>

      </form>
    </div>
  </div>
</div>
