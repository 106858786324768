import {Component, OnInit} from '@angular/core';
import {SupplierDto} from "../x-models/supplier-dto";
import {SupplierHttpService} from "../x-http-requests/supplier-http.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../../_auth/permission.service";
import {SupplierService} from "../_services/_dto-services/supplier.service";

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {
  // Data Loading Variables
  // all_suppliers: SupplierDto[] | undefined;
  filtered_suppliers: SupplierDto[] = [];
  search_term: string = "";

  // New Supplier Variables
  supplier_name: string = "";

  constructor(private supplierHttpService: SupplierHttpService, private globalAlertService: GlobalAlertService,
              private route: ActivatedRoute, private router: Router, public PermissionService: PermissionService,
              private supplierService: SupplierService) {
    this.supplierService.EntitiesChanged.subscribe({
      next: x => {
        this.Filter(this.search_term)
      }
    })
  }

  Add() {
    this.supplierHttpService.add(this.supplier_name).subscribe(x => {
      this.supplierService.Replace(x);
      // if (this.all_suppliers == undefined) this.all_suppliers = [];
      // this.all_suppliers.splice(0,0, x);
      this.Filter(this.search_term);
      this.supplier_name = "";
      this.globalAlertService.createAlertBannerModel("Erfolg", `Der Lieferant "${x.name}" wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Beim Speichern ist ein Fehler aufgetreten.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  Delete(supplier: SupplierDto) {
    let confirmation = confirm("Löschung des Lieferanten bestätigen:\n"+supplier.name+"\n"+supplier.id);
    if (!confirmation) return;
    this.supplierHttpService.delete(supplier.id).subscribe(x => {
      this.supplierService.Remove(supplier.id);
      // this.all_suppliers!.splice(this.all_suppliers!.indexOf(supplier), 1);
      this.Filter(this.search_term);
      this.globalAlertService.createAlertBannerModel("Erfolg", `Der Lieferant ${supplier.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Löschen des Lieferanten fehlgeschlagen.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  //AssignCopy() {
  //  if (this.all_suppliers == undefined) return;
  //  this.filtered_suppliers = Object.assign([], this.all_suppliers);
  //}

  Filter(search: string) {
    this.search_term = search;
    this.filtered_suppliers = Object.assign([], this.supplierService.Entities?.filter(x => x.name.toLowerCase().indexOf(this.search_term.toLowerCase()) > -1));
  }

  ResetFilter() {
    this.search_term = "";
    this.Filter(this.search_term);
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.CompanySupplierSettings], {queryParams: {id: id}});
  }

  LoadData() {
    //this.supplierHttpService.list().subscribe(x => {
    //  this.all_suppliers = x;
    //  this.Filter(this.search_term);
    //}, error => {
    //  console.error(error);
    //  this.globalAlertService.createAlertBannerModel("Fehler", "Beim Laden der Lieferanten ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
    //  this.globalAlertService.show();
    //})
  }

  ngOnInit(): void {
    this.LoadData();
  }

}
