import {Component, Input} from '@angular/core';

@Component({
  selector: 'layout-header-container',
  standalone: true,
  imports: [],
  templateUrl: './header-container.component.html',
  styleUrl: './header-container.component.scss'
})
export class HeaderContainerComponent {
  @Input() title: string = "Title";
}
