import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GeneralHttpService} from "./http-requests/general-http.service";
import {CookieHelper} from "./helpers/CookieHelper";
import {AuthenticationService} from "./_auth/authentication.service";
import {Routes} from "./enums/routes";
import {Roles} from "./_auth/x-enums/roles";
import {NavbarService} from "./x-components/navbar-base/navbar.service";
import {KeyboardService} from "./_services/keyboard.service";
import {WindowStateService} from "./_services/window-state.service";
import {CookieService} from "./_services/cookie.service";
import {A} from "@angular/cdk/keycodes";
import {LoadingIndicatorService} from "./_services/loading-indicator.service";
import {AlignmentService} from "./_services/alignment.service";
import {SelfServiceRoutes} from "./_modules/self-service/self-service-routes";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {
  title = 'app';
  isAuthorized: boolean | null = null;
  isEmployee: boolean = true;

  loaded: boolean = false;

  ngAfterViewInit(): void {
    this.loaded = true;
  }

  constructor(public authenticationService: AuthenticationService, @Inject("BASE_URL") public baseUrl: string,
              public route: ActivatedRoute, public router: Router, public NavbarService: NavbarService,
              protected keyboardService: KeyboardService, private windowStateService: WindowStateService,
              private cookieService: CookieService, public AlignmentService: AlignmentService) {
    this.router.events.subscribe((x) => {
      if (x instanceof NavigationStart) {
        this.isEmployee = !this.IsFullUser()
      }
    })
  }

  @ViewChild("relative") set relative(value: ElementRef | undefined) {
    this.AlignmentService.MainWindow = value?.nativeElement;
  }

  @HostListener('document:click', ['$event'])
  click(event: MouseEvent) {
    let element = event.target as HTMLElement;
    this.keyboardService.isOptionPressed = event.altKey;
    if (this.keyboardService.isOptionPressed && element.tagName.toLowerCase() == "a") {
      event.preventDefault();
      window.open(element.getAttribute("href")!, '_blank');
      this.keyboardService.isOptionPressed = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keyboardService.pressedKey(event);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEventUp(event: KeyboardEvent) {
    this.keyboardService.releasedKey(event);
  }

  @HostListener("window:blur", ["$event"])
  handleWindowBlur(event: FocusEvent) {
    this.keyboardService.resetKeys();
  }

  IsFullUser(): boolean {
    return !this.authenticationService.userValue?.permissions.includes(Roles.Employee);
  }

  IsSelfService(): boolean {
    return location.pathname.includes(Routes.SelfServiceModule);
  }

  IsLoggedIn(): boolean {
    return !!this.authenticationService.userValue?.jwt;
  }

  protected readonly A = A;
  protected readonly LoadingIndicatorService = LoadingIndicatorService;
  protected readonly Routes = Routes;
}
