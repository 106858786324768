export enum Position {
  None = 0,
  Left = 10,
  TopLeft = 15,
  Top = 20,
  TopRight = 25,
  Right = 30,
  BottomRight = 35,
  Bottom = 40,
  BottomLeft = 45
}
