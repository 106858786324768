import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from "@angular/common/http";
import {DownloadInformation} from "../x-models/download-information";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders,
              private globalAlertService: GlobalAlertService) { }

  download(url: string, data: any){
    if (data != undefined) {
      return this.http.post(this.baseUrl + url, data, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      });
    }

    return this.http.get(this.baseUrl + url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

  downloadFile = (data: HttpResponse<Blob>, fallback: string, downloadInformation: DownloadInformation | undefined) => {
    const downloadedFile = new Blob([data.body!], {type: data.body?.type});
    const a = document.createElement("a");
    a.setAttribute("style", "display: none;");
    document.body.appendChild(a);
    a.href = URL.createObjectURL(downloadedFile);
    a.target = "_blank";

    const contentDispositionHeader = data.headers.get("content-disposition");
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
    const matches = regex.exec(contentDispositionHeader || '');

    a.download = matches && matches.length > 1 ? matches[1] : fallback;

    a.click();
    document.body.removeChild(a);

    if (downloadInformation != undefined) downloadInformation.downloading = false;
  }

  FileDownload(url: string, downloadInformation: DownloadInformation | undefined, data: any = undefined, fallback: string = "untitled.xlsx") {
    if (downloadInformation != undefined) downloadInformation.downloading = true;
    this.download(url, data).subscribe(x => {
      if (x.type == HttpEventType.Response) {
        this.downloadFile(x, fallback, downloadInformation)
      }
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Es ist ein Fehler beim Download aufgetreten.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      if (downloadInformation) {
        downloadInformation.downloading = false;
      }
    })
  }
}
