import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {

  }

  ChangePassword(old: string, password: string) {
    return this.httpClient.put(this.baseUrl + "/auth/User/ChangePassword", {
      "OldPassword": old,
      "NewPassword": password
    });
  }
}
