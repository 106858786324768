import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {ChangelogItemDto} from "../x-models/changelog/changelog-item-dto";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class ChangelogHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  list(): Observable<ChangelogItemDto[]> {
    return this.httpClient.get<ChangelogItemDto[]>(this.baseUrl + ServerEndpoints.Miscellaneous_Changelog_List);
  }

  add(title: string, content: string): Observable<ChangelogItemDto> {
    return this.httpClient.post<ChangelogItemDto>(this.baseUrl + ServerEndpoints.Management_Changelog_Add, {
      title: title,
      content: content
    });
  }

  update(id: string, title: string, content: string, created: Date | undefined): Observable<ChangelogItemDto> {
    return this.httpClient.put<ChangelogItemDto>(this.baseUrl + ServerEndpoints.Management_Changelog_Update + "?id=" + id + "&created=" + (!created ? "" : created.toISOString()), {
      title: title,
      content: content
    });
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Management_Changelog_Delete + "?id=" + id);
  }

  reactivate(id: string): Observable<ChangelogItemDto> {
    return this.httpClient.put<ChangelogItemDto>(this.baseUrl + ServerEndpoints.Management_Changelog_Reactivate + "?id=" + id, {});
  }
}
