import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EmployeeDto} from "../x-models/employee-dto";
import {EmployeeOdto} from "../x-models/employee-odto";
import {EmployeeNl} from "../x-models/employee-nl";
import {CompositionMinimal} from "../../../models/composition/composition-minimal";
import {SocialTypes} from "../../../x-models/x-enums/social-types";
import {EventDto} from "../../../models/event/event-dto";
import {DtoBaseHttpService} from "../../../_services/dto-services/dto-base-http.service";
import {ServerEndpoints} from "../../../server.endpoints";
import {EmployeeStatsExtended} from "../../../models/company/employee-stats-extended";
import {EmployeeShiftOverviewDto} from "../_models/employee/employee-shift-overview-dto";
import {EmploymentRelationshipLinkerDto} from "../x-models/employment-relationship-linker-dto";

@Injectable({
  providedIn: 'root'
})
export class EmployeeHttpService extends DtoBaseHttpService<EmployeeDto> {
  UrlPath: string = "/Company/Employee";

  constructor(httpClient: HttpClient,
              @Inject("BASE_URL") baseUrl: string) {
    super(baseUrl, httpClient)
  }

  list(relationshipId: string): Observable<EmployeeDto[]> {
    return this.HttpClient.get<EmployeeDto[]>(this.BaseUrl + ServerEndpoints.Company_Employee_List + "?employmentRelationship=" + relationshipId);
  }

  listNl(start: Date | undefined = undefined): Observable<EmployeeNl[]> {
    if (start) {
      return this.HttpClient.get<EmployeeNl[]>(this.BaseUrl + ServerEndpoints.Company_Employee_ListNl + "?start=" + start.toISOString());
    }
    return this.HttpClient.get<EmployeeNl[]>(this.BaseUrl + ServerEndpoints.Company_Employee_ListNl);
  }

  get(id: string): Observable<EmployeeDto> {
    return this.HttpClient.get<EmployeeDto>(this.BaseUrl + ServerEndpoints.Company_Employee_Get + "?id=" + id);
  }

  add(first: string, last: string, sendPasswordReset: boolean, joined?: Date, left?: Date, email?: string): Observable<EmployeeDto> {
    if (email?.isNullOrWhitespace() == true) email = undefined;

    return this.HttpClient.post<EmployeeDto>(this.BaseUrl + ServerEndpoints.Company_Employee_Add, {
      "FirstName": first,
      "LastName": last,
      "Joined": joined?.toISOString(),
      "Left": left?.toISOString(),
      "EmailAddress": email,
      "SendPasswordReset": sendPasswordReset
    })
  }

  delete(id: string) {
    return this.HttpClient.delete(this.BaseUrl + ServerEndpoints.Company_Employee_Delete + "?id=" + id);
  }

  employeeOrders(id: string): Observable<EmployeeOdto[]> {
    return this.HttpClient.get<EmployeeOdto[]>(this.BaseUrl + ServerEndpoints.Inventory_Event_GetEmployeeOrders + "?id=" + id);
  }

  changeName(id: string, firstname: string, lastname: string): Observable<EmployeeDto> {
    return this.HttpClient.put<EmployeeDto>(this.BaseUrl + ServerEndpoints.Company_Employee_ChangeName, {
      "Id": id,
      "FirstName": firstname,
      "LastName": lastname
    })
  }

  setCanLogin(id: string, allow: boolean): Observable<EmployeeDto> {
    return this.HttpClient.put<EmployeeDto>(this.BaseUrl + ServerEndpoints.Company_Employee_AllowEmployeeLogin + "?id=" + id + "&allow=" + allow, {});
  }

  resetSocial(id: string, name: string): Observable<EmployeeDto> {
    return this.HttpClient.delete<EmployeeDto>(this.BaseUrl + ServerEndpoints.Company_Employee_ResetSocial + "?id=" + id + "&name=" + name);
  }

  employeeSalesList(id: string, start: string, end: string): Observable<CompositionMinimal[]> {
    start += "T00:00:00.000000";
    end += "T23:59:59.999999";
    return this.HttpClient.get<CompositionMinimal[]>(this.BaseUrl +
      ServerEndpoints.Company_Employee_EmployeeSalesList +
      "?employeeId=" + id +
      "&start=" + start +
      "&end=" + end);
  }

  employeeSalesOrders(id: string, start: string, end: string): Observable<EventDto[]> {
    start += "T00:00:00.000000";
    end += "T23:59:59.999999";
    return this.HttpClient.get<EventDto[]>(this.BaseUrl +
      ServerEndpoints.Company_Employee_EmployeeSalesOrders +
      "?employeeId=" + id +
      "&start=" + start +
      "&end=" + end
    );
  }


  setSocial(id: string, name: string, value: string | undefined): Observable<EmployeeDto> {
    return this.HttpClient.put<EmployeeDto>(this.BaseUrl + ServerEndpoints.Company_Employee_SetSocial + "?id=" + id, {
      name: name,
      value: value
    })
  }

  verify(id: string, type: SocialTypes) {
    return this.HttpClient.get(this.BaseUrl + ServerEndpoints.Company_Employee_RequestVerification + "?socialType=" + type.valueOf() + "&id=" + id);
  }

  getEmployeeShiftList(id: string, start: Date, end: Date): Observable<EmployeeShiftOverviewDto> {
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0)
    end.setDate(end.getDate() + 1);

    return this.HttpClient.get<EmployeeShiftOverviewDto>(this.BaseUrl + ServerEndpoints.Company_Employee_GetEmployeeShiftList + "?id=" + id + "&start=" + start.toISOString() + "&end=" + end.toISOString());
  }

  setPublicProperty(id: string, name: string, value: string): Observable<EventDto> {
    return this.HttpClient.put<EventDto>(this.BaseUrl + ServerEndpoints.Company_Employee_SetPublicProperty + "?id=" + id, {
      name: name,
      value: value
    })
  }

  downloadIndividualDetails(id: string, start: Date, end: Date) {

  }

  getStats(employee: string): Observable<EmployeeStatsExtended> {
    return this.HttpClient.get<EmployeeStatsExtended>(`${this.BaseUrl}${ServerEndpoints.Company_Employee_GetStats}/${employee}`);
  }

  saveValue(id: string, name: string, newPropertyValue: any): Observable<EmployeeDto> {
    if (newPropertyValue == "") newPropertyValue = undefined;

    return this.HttpClient.post<EmployeeDto>(`${this.BaseUrl}${ServerEndpoints.Company_Employee_SetValue}/${id}`, {
      name: name,
      value: newPropertyValue
    })
  }

  sendPasswordReset(id: string): Observable<EmployeeDto> {
    return this.HttpClient.post<EmployeeDto>(`${this.BaseUrl}${ServerEndpoints.Company_Employee_SendPasswordReset}/${id}`, {});
  }

  getEmploymentRelationships(id: string): Observable<EmploymentRelationshipLinkerDto[]> {
    return this.HttpClient.get<EmploymentRelationshipLinkerDto[]>(`${this.BaseUrl}${ServerEndpoints.Company_Employee_GetEmploymentRelationships}/${id}`);
  }
}
