import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {CompanyDto} from "../../models/admin_owner/company/company-dto";
import {ServerEndpoints} from "../../server.endpoints";
import {Address} from "../../models/common/address";

@Injectable({
  providedIn: 'root'
})
export class CompanyHttpService {

  constructor(private httpClient: HttpClient,
              @Inject("BASE_URL") private baseUrl: string,
              @Inject("BASE_HEADERS") private baseHeaders: HttpHeaders) {
  }

  list(): Observable<CompanyDto[]> {
    return this.httpClient.get<CompanyDto[]>(this.baseUrl + ServerEndpoints.Management_Company_List, {headers: this.baseHeaders});
  }

  get(id: string): Observable<CompanyDto> {
    return this.httpClient.get<CompanyDto>(this.baseUrl + ServerEndpoints.Management_Company_Get + "?id=" + id, {headers: this.baseHeaders});
  }

  add(name: string): Observable<CompanyDto> {
    return this.httpClient.post<CompanyDto>(this.baseUrl + ServerEndpoints.Management_Company_Add, {
      "Name": name
    }, {headers: this.baseHeaders});
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Management_Company_Delete + "?id=" + id, {headers: this.baseHeaders});
  }

  changeName(id: string, name: string): Observable<CompanyDto> {
    return this.httpClient.put<CompanyDto>(this.baseUrl + ServerEndpoints.Management_Company_ChangeName, {
      "Id": id,
      "Name": name
    }, {headers: this.baseHeaders});
  }

  changeAlias(id: string, alias: string): Observable<CompanyDto> {
    return this.httpClient.put<CompanyDto>(this.baseUrl + ServerEndpoints.Management_Company_SetAlias + "?id=" + id + "&alias=" + alias, {});
  }

  setAddress(id: string, address: Address): Observable<CompanyDto> {
    if (address.addressLine1 == "") address.addressLine1 = undefined!;
    if (address.addressLine2 == "") address.addressLine2 = undefined!;
    if (address.zip == "") address.zip = undefined!;
    if (address.city == "") address.city = undefined!;
    return this.httpClient.patch<CompanyDto>(`${this.baseUrl}${ServerEndpoints.Management_Company_SetAddress}/${id}`, address);
  }

  setProperty(id: string, variable: string, value: string): Observable<CompanyDto> {
    return this.httpClient.post<CompanyDto>(`${this.baseUrl}${ServerEndpoints.Management_Company_SetMetadata}/${id}`, {
      name: variable,
      value: value
    })
  }
}
