import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SchedulerGroupDto} from "../../x-models/scheduler-group-dto";
import {SchedulerHttpService} from "../../x-services/scheduler-http.service";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {AlertLevel} from "../../../../enums/alert-level";
import {CalculationTargetType} from "../../x-models/x-enums/calculation-target-type";
import {CompositionNl} from "../../../../models/composition-nl";
import {Tuple} from "../../../../models/_generic/tuple";
import * as SignalR from '@microsoft/signalr';
import {AuthenticationService} from "../../../../_auth/authentication.service";
import {SchedulerObjectType} from "../../x-models/x-enums/scheduler-object-type";
import {SignalRResponse} from "../../../signalRTracker/x-models/signal-r-response";
import {Chart} from "chart.js/auto";
import {TimeUtilities} from "../../../../utils/time-utilities";
import {ColorUtilities} from "../../x-utilities/color-utilities";
import {CurrencyPipe} from "@angular/common";
import {SettingHttpService} from "../../../company/x-http-requests/setting-http.service";
import {ServerEndpoints} from "../../../../server.endpoints";

@Component({
  selector: 'app-stock-market',
  templateUrl: './stock-market.component.html',
  styleUrls: ['./stock-market.component.scss']
})
export class StockMarketComponent implements OnInit {
  constructor(private route: ActivatedRoute, private schedulerHttpService: SchedulerHttpService,
              private globalAlertService: GlobalAlertService, private authenticationService: AuthenticationService,
              @Inject("BASE_URL") private baseUrl: string, private settingHttpService: SettingHttpService) {
  }

  chart: Chart | undefined;
  data: any = {
    labels: []
  };

  ngOnInit(): void {
    this.subscribeToQueryParams(() => {
      this.querySchedulerGroup(() => {
        const ctx = document.getElementById("chart");
        if (!ctx || !this.allCompositions) throw new Error();

        this.data.datasets = this.allCompositions.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        }).map(x => {
          return {
            label: x.publicName ?? x.name,
            id: x.id,
            data: [null, null, null, null, null, null, null, null, null, x.compositionOverride?.price ?? x.price],
            borderWidth: 5
          }
        });
        for (let i = 0; i < 9; i++) {
          this.data.labels.push("");
        }
        this.data.labels.push(new Date().toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}))

        // @ts-ignore
        this.chart = new Chart(ctx, {
          type: "line",
          data: this.data,
          options: {
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value, index, ticks) {
                    return `${(value as number).toFixed(2).replace(".", ",")} €`
                  },
                  font: {
                    size: 24
                  }
                }
              },
              x: {
                ticks: {
                  font: {
                    size: 24
                  }
                }
              }
            },
            plugins: {
              legend: {
                display: false,
              }
            }
          }
        })
      });
      this.SignalRConnect()
    })
    this.settingHttpService.GetLogo().subscribe(x => {
      this.imageUrl = x.item1;
    })
  }


  selectedSchedulerGroupId: string | undefined;

  subscribeToQueryParams(func: Function) {
    this.route.queryParams.subscribe(params => {
      this.selectedSchedulerGroupId = params.schedulergroup;
      func();
    })
  }

  selectedSchedulerGroup: SchedulerGroupDto | undefined;
  allCompositions: CompositionNl[] | undefined;

  querySchedulerGroup(func: Function) {
    if (!this.selectedSchedulerGroupId) return;
    this.schedulerHttpService.getSchedulerGroup<CompositionNl[]>(this.selectedSchedulerGroupId, CalculationTargetType.Composition).subscribe(x => {
      this.selectedSchedulerGroup = x.item1;
      this.allCompositions = x.item2;
      if (this.allCompositions) {
        for (let i = 0; i < this.allCompositions.length; i++) {
          this.old[this.allCompositions[i].id as string] = i;
        }
      }
      this.sortCompositions();
      func();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Beim Laden der Automation ist ein Fehler aufgetreten.", AlertLevel.error, 3000);
      this.globalAlertService.show();
      this.selectedSchedulerGroup = undefined;
      this.allCompositions = undefined;
    })
  }

  sortedCompositions: CompositionNl[] | undefined;

  sortCompositions() {
    if (!this.allCompositions) return;
    this.sortedCompositions = Object.assign([], this.allCompositions.sort((a, b) => {
      if (a.sales > b.sales) return -1;
      if (a.sales < b.sales) return 1;
      return 0;
    }))
  }

  getMaxSales(): number | undefined {
    if (!this.sortedCompositions) return undefined
    return this.sortedCompositions[0].sales;
  }

  getAmount(): Tuple<number, number> | undefined {
    if (!this.selectedSchedulerGroup || this.selectedSchedulerGroup.calculationValues.length == 0) return undefined;
    let sorted = this.selectedSchedulerGroup.calculationValues.sort((a, b) => {
      if (a > b) return -1;
      if (a < b) return 1;
      return 0;
    });
    return new Tuple<number, number>(sorted[0], sorted[sorted.length - 1]);
  }

  getIndex(composition: CompositionNl): number {
    if (!this.allCompositions || !this.sortedCompositions) return 0;
    return this.sortedCompositions.indexOf(composition);
  }

  test() {
    this.sortedCompositions![2].sales += 20;
    this.sortCompositions()
  }

  getAngle(composition: CompositionNl): number {
    if (!this.allCompositions || !this.sortedCompositions) throw new Error();
    let aIndex = this.old[composition.id];
    let sIndex = this.sortedCompositions.indexOf(composition);
    let difference = aIndex - sIndex;
    if (difference > 1) return 0;
    if (difference == 1) return 45;
    if (difference == 0) return 90;
    if (difference == -1) return 135;
    if (difference < -1) return 180;
    return 0;
  }

  getWidth(composition: CompositionNl): number {
    let max = this.getMaxSales();
    if (!max) return 0;
    let width = composition.sales / max;
    return width * 100;
  }


  items: any = [1, 2, 3, 4, 5];

  imageUrl: string | undefined;

  public Connection: SignalR.HubConnection | undefined;

  SignalRConnect(): void {
    this.Connection = new SignalR.HubConnectionBuilder()
      .configureLogging(SignalR.LogLevel.Information)
      .withUrl(this.baseUrl + "/hubs/automation", {
        accessTokenFactory: () => this.authenticationService.userValue!.jwt
      })
      .withAutomaticReconnect()
      .build()

    this.Connection.start().then(() => {
      this.SignalRAutomationSubscribe(this.Connection, this.selectedSchedulerGroupId);
    }).catch(error => {
      return console.error(error);
    })

    this.Connection.onreconnected(() => {
      this.SignalRAutomationSubscribe(this.Connection, this.selectedSchedulerGroupId);
    })

    this.Connection.on("AutomationUpdateListener", (data: CompositionNl[], meta: any) => {
      this.patchCompositions(data, meta)
    })
  }

  countdownConfig = {leftTime: 0, format: 'm:ss'}
  old: any = {};

  patchCompositions(compositions: CompositionNl[], meta: any) {
    if (Object.keys(meta).indexOf("server") > -1 && meta["server"] == true) {
      this.allCompositions = compositions;
      for (let i = 0; i < this.allCompositions.length; i++) {
        this.old[this.allCompositions[i].id as string] = i;
      }
      this.sortCompositions();
      if (Object.keys(meta).indexOf("seconds") > -1) {
        this.countdownConfig = {leftTime: meta["seconds"], format: 'm:ss'}
      }
    }
    compositions.forEach(x => {
      let composition = this.allCompositions?.find(c => c.id == x.id);
      if (composition) {
        composition.sales = x.sales;
        composition.compositionOverride = x.compositionOverride;
      }
    });
    this.sortCompositions()

    if (Object.keys(meta).indexOf("server") > -1 && meta["server"] == true) {
      if (!this.allCompositions) throw new Error();
      let cCompositions = this.allCompositions.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
      for (let i = 0; i < cCompositions.length; i++) {
        this.data.datasets[i].data.push(cCompositions[i].compositionOverride?.price ?? cCompositions[i].price)
        if (this.data.datasets[i].data.length > 10) this.data.datasets[i].data.splice(0, 1);
        let comp = this.allCompositions.find(x => x.id == this.data.datasets[i].id);
        if (comp) comp.color = ColorUtilities.rgbToHex((this.chart?.data.datasets[i].borderColor ?? "rgb(255, 255, 255)").toString()) ?? "#ffffff";
      }
      this.data.labels.push(new Date().toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}))
      if (this.data.labels.length > 10) this.data.labels.splice(0, 1);

      this.chart?.update()
    }
  }

  SignalRAutomationSubscribe(connection: SignalR.HubConnection | undefined, objectId: string | undefined): boolean {
    if (!connection || !objectId) return false;

    if (this.Connection && this.selectedSchedulerGroupId) {
      this.Connection.invoke("Subscribe", this.selectedSchedulerGroupId, SchedulerObjectType.Group.valueOf()).then((x: SignalRResponse) => {
        if (x.success) {
          this.globalAlertService.createAlertBannerModel("Verbindung hergestellt", "Das Automationsevent wurde erfolgreich aboniert.", AlertLevel.success, 2000);
          this.globalAlertService.show()
          return true;
        } else {
          throw new Error();
        }
      }).catch((e) => {
        this.globalAlertService.createAlertBannerModel("Fehler", "Verbindung konnte nicht hergestellt werden.", AlertLevel.error, 2000);
        this.globalAlertService.show()
        console.error(e);

        return false;
      })
    }

    return false;
  }
}
