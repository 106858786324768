/**
 * Enum for ReplacedElementStatus
 * @readonly
 * @enum {number}
 * {@link #ElementReplaced} Successfully replaced the element
 *
 * {@link #ElementNotReplaced} The element was not replaced.
 *
 * {@link #NotFoundAndElementAdded} The element was not found but added to the array
 *
 * {@link #UndefinedArrayCreatedAndElementAdded} The array was not Initialized, a new Array has been created and the element was added
 *
 * {@link #UndefinedArrayCreatedAndElementNotAdded} The array was not Initialized, a new Array has been created but the element was not added
 *
 * {@link #UndefinedArrayNotCreated} The array was not Initialized, a new Array was not created and the element was not added
 */
export enum ReplacedElementStatus {
  ElementReplaced = 0,
  ElementNotReplaced = 1,
  NotFoundAndElementAdded = 2,
  UndefinedArrayCreatedAndElementAdded = 3,
  UndefinedArrayCreatedAndElementNotAdded = 4,
  UndefinedArrayNotCreated = 5,
}
