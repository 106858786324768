import {Component, EventEmitter, Input, Output} from '@angular/core';
import copy from "copy-to-clipboard";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {AlertLevel} from "../../../../enums/alert-level";

@Component({
  selector: 'app-json-viewer-popup',
  templateUrl: './json-viewer-popup.component.html',
  styleUrls: ['./json-viewer-popup.component.scss']
})
export class JsonViewerPopupComponent {
  @Input() json: any | undefined;
  @Output() jsonChange = new EventEmitter<any | undefined>();

  constructor(private globalAlertService: GlobalAlertService) {
  }

  close() {
    this.json = undefined;
    this.jsonChange.next(this.json);
  }

  copy() {
    copy(JSON.stringify(this.json));
    this.globalAlertService.createAlertBannerModel("Kopiert", "Der Inhalt wurde in die Zwischenablage kopiert.", AlertLevel.info, 2000);
    this.globalAlertService.show();
  }
}
