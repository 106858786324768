import {Component, HostListener, Input} from '@angular/core';
import {AlertLevel} from "../../enums/alert-level";
import {timeout} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AlertBannerModel} from "../../models/alert-banner-model";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {TailwindTransitionDuration} from "../../enums/tailwind/tailwind-transition-duration";
import {AuthenticationService} from "../../_auth/authentication.service";
import {Routes} from "../../enums/routes";
import {AutomationRoutes} from "../../_modules/automation/x-models/x-enums/automation-routes";
import {NavbarService} from "../navbar-base/navbar.service";


@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent {
  @Input() AlertBannerModel: AlertBannerModel = new AlertBannerModel();
  @Input() IsHoveredOver: boolean = false;
  private _timeoutId: any | undefined;

  public tailwindTransitionDuration: string = TailwindTransitionDuration.Duration1000.toString();
  public globalGuid: string = ""



  @HostListener('mouseenter') onMouseEnter() {
    this.Appear("HoveredOver");
  }


  @HostListener('mouseleave') onMouseLeave() {
    this.Disappear("HoveredOver");
  }

  InTransition: boolean = false;
  // If any value within this dictionary is true, then the alert banner will be visible.
  private IsVisibleDict: { [key: string]: boolean } = { "Default": false, "HoveredOver": false,  };
  IsVisible: boolean = false;
  constructor(private globalAlertService: GlobalAlertService, public authenticationService: AuthenticationService,
              public NavbarService: NavbarService) {
    this.globalAlertService.IsAlertBannerVisibleChanged.subscribe((value) => {
      if (value){
        this.AlertBannerModel = this.globalAlertService.AlertBannerModel;
        this.Show();
      }
    }
    )
  }

  @Input() Show(message:string= "", header:string= "", level:AlertLevel= AlertLevel.info, duration:number= 5000) {
    /**
     * Shows the alert banner with the specified parameters.
     * Calls the Appear and Disappear function.
     * If the parameter is not default, then it will override the default value in the AlertBannerModel.
     *
     * @param message Overrides the AlertBannerModel.Message
     * @param header Overrides the AlertBannerModel.Header
     * @param level Overrides the AlertBannerModel.Level
     * @param duration Overrides the AlertBannerModel.DisplayDuration
     *
     * @return void
     */
    this.OverrideProperties(message, header, level, duration);

    this.Appear("Default");

    clearTimeout(this._timeoutId);
    let localGuid = crypto.randomUUID()
    this.globalGuid =  localGuid

    this._timeoutId = setTimeout(() => {
      if(this.globalGuid != localGuid) return;
      this.Disappear("Default");
    }, Math.abs(this.AlertBannerModel.DisplayDuration))

  }

  private OverrideProperties(message:string= "", header:string= "", level:AlertLevel= AlertLevel.info, duration:number= 5000) {
    /**
     * If the parameter is not default, then it will override the default value in the AlertBannerModel. Can be used to override one or more properties.
     *
     * @param message Overrides AlertBannerModel.Message
     * @param header Overrides AlertBannerModel.Header
     * @param level Overrides AlertBannerModel.Level
     * @param duration Overrides AlertBannerModel.DisplayDuration
     *
     * @return void
     */
    if(message != "") {
      this.AlertBannerModel.Message = message;
    }
    if(header != "") {
      this.AlertBannerModel.Header = header;
    }
    if(level != AlertLevel.info) {
      this.AlertBannerModel.Level = level;
    }
    if(duration != 5000) {
      this.AlertBannerModel.DisplayDuration = duration;
    }
  }

  Appear(key:string= "Default") {
    /**
     * Makes the alert banner visible. Sets key string to true in IsVisibleDict.
     *
     * @param key The key string to set to true in IsVisibleDict.
     *
     * @return void
     */
    this.IsVisibleDict[key] = true;
    this.IsVisible = true;
  }

  Disappear(key:string= "Default") {
    /**
     * Sets key string to false in IsVisibleDict. Only makes the alert banner invisible if all values in IsVisibleDict are false. (OR logic)
     *
     * @param key The key string to set to false in IsVisibleDict.
     *
     * @return void
     */
    this.IsVisibleDict[key] = false;
    if(this.EvaluateIsVisible())
      return

    // Start fading effect
    this.InTransition = true;
    setTimeout(() => {
      this.IsVisible = false;
      this.InTransition = false;
    }, Number(this.tailwindTransitionDuration));

  }

  EvaluateIsVisible() {
    /**
     * Evaluates if the alert banner should be visible. Or logic.
     *
     * @return boolean
     */
    for (let key in this.IsVisibleDict) {
      if (this.IsVisibleDict[key])
        return true;
    }
    return false;
  }




  protected readonly AlertLevel = AlertLevel;
  protected readonly transition = transition;
  protected readonly location = location;
  protected readonly Routes = Routes;
  protected readonly AutomationRoutes = AutomationRoutes;
}




