<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Patches</h1>
      <div>
        <p class="pt-2">
          <span class="fw-bolder text-red-600 pr-2">Warnung!</span>
          <span>Die Durchführung von Patches kann zu Fehlern führen. Bitte erst nach sogfältiger Recherche und Absprache Aktionen ausführen.</span>
          <br />
          <span class="fw-light text-gray-700">Für Fehlfunktionen, welche durch die nicht sachgerechte Nutzung von Patches herbeigeführt werden, ist jegliche Haftung ausgeschlossen.</span>
        </p>
      </div>
      <div class="pt-4">
        <div>
          <h2 class="text-sm font-medium text-gray-500" [ngClass]="{'!text-red-600': patchLoadingError}">
            Patch-Liste
            <span class="" *ngIf="patchLoadingError">konnten nicht geladen werden...</span>
          </h2>
          <ul role="list" class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6 2xl:grid-cols-3"
          *ngIf="!patchLoadingError">
            <li class="col-span-1 flex rounded-md shadow-sm" *ngFor="let patch of patches">
              <div class="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
              [ngClass]="{'bg-yellow-600': !rPatches, 'bg-green-600': rPatches && RPatchesContain(patch), 'bg-gray-600': rPatches && !RPatchesContain(patch)}">
                <svg *ngIf="!rPatches" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-question" viewBox="0 0 16 16">
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>
                <svg *ngIf="rPatches && RPatchesContain(patch)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                </svg>
                <svg *ngIf="rPatches && !RPatchesContain(patch)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </div>
              <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div class="flex-1 truncate px-4 py-2 text-sm">
                  <a class="font-medium text-gray-900 hover:text-gray-600">{{ patch }}</a>
                  <p class="text-gray-500">{{ RPatchesContain(patch) ? (RPatch(patch)?.value | date: "dd.MM.yyyy, HH:mm") + " Uhr" : (!rPatches ? "-" : "noch nicht ausgeführt") }}</p>
                </div>
                <div class="flex-shrink-0 pr-2 mt-1">
                  <button (click)="StartPatch(patch)" *ngIf="rPatches && patches" type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-skip-end" viewBox="0 0 16 16">
                      <path d="M12.5 4a.5.5 0 0 0-1 0v3.248L5.233 3.612C4.713 3.31 4 3.655 4 4.308v7.384c0 .653.713.998 1.233.696L11.5 8.752V12a.5.5 0 0 0 1 0V4zM5 4.633 10.804 8 5 11.367V4.633z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
