<div *ngIf="visible && PermissionService.CheckPermission(PermissionService.CombinedPermission_EmployeeEditEmployeeRelationship())" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
          </div>
          <div class="mt-3 sm:ml-4 sm:mt-0 place-self-center grow">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Arbeitsverhältnis ändern</h3>
            <div class="mt-2 flex flex-col gap-2 cursor-pointer">
              <div class="bg-white border-gray-200 border-2 rounded-md p-2 flex flex-col ring-theme-primary-700"
                   [ngClass]="{'ring-2 ring-offset-1': selectedEmploymentRelationship == undefined}"
                   (click)="Set(undefined)">
                <span>Ohne Zuordnung</span>
              </div>
              <div *ngFor="let employmentRelationship of EmploymentRelationshipService.Entities"
                   class="bg-white border-gray-200 border-2 rounded-md p-2 flex flex-col ring-theme-primary-700"
                   [ngClass]="{'ring-2 ring-offset-1': employmentRelationship == selectedEmploymentRelationship}"
                   (click)="Set(employmentRelationship)">
                <span>{{ employmentRelationship.name }}</span>
                <span class="text-gray-400 text-sm">{{ employmentRelationship.id }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button (click)="Save()" type="button" class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto bg-theme-primary-700 hover:bg-theme-primary-900">Speichern</button>
          <button (click)="Discard()" type="button" class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
</div>
