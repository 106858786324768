import {HttpEvent, HttpEventType, HttpResponse} from "@angular/common/http";

export function downloadFile(this: HttpResponse<Blob>) {
  const file = new Blob([this.body!], {type: this.body?.type});
  const a = document.createElement("a");
  a.setAttribute("style", "display:none;");
  a.href = URL.createObjectURL(file);
  a.target = "_blank";

  const header = this.headers.get('content-disposition');
  const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
  const match = regex.exec(header || '');

  a.download = match ? match[1] : "file";

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

declare module "@angular/common/http" {
  interface HttpResponse<T> {
    downloadFile: typeof downloadFile;
  }
}

(HttpResponse.prototype as any).downloadFile = downloadFile;
