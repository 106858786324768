<div class="px-4 sm:px-6 lg:px-8" >


  <div class="sm:flex sm:items-center">
    <div class="inline-flex w-full">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl inline-flex w-full">
        <span class="place-self-end">Analyse</span>

      </h1>
    </div>
  </div>
  <router-outlet name="analytics"></router-outlet>
</div>
