export function toCustomISOString(this: Date): string {
  let tzo = -this.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num: number) {
      return (num < 10 ? '0' : '') + num;
    };

  let year = this.getFullYear().toString();
  while (year.length < 4) {
    year = '0' + year;
  }

  return year +
    '-' + pad(this.getMonth() + 1) +
    '-' + pad(this.getDate()) +
    'T' + pad(this.getHours()) +
    ':' + pad(this.getMinutes()) +
    ':' + pad(this.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
}

(Date.prototype as any).toCustomISOString = toCustomISOString;

export function toInputDateTimeString(this: Date): string {
  return this.toCustomISOString().slice(0, 16);
}

(Date.prototype as any).toInputDateTimeString = toInputDateTimeString;

export function toInputDateString(this: Date): string {
  return this.toCustomISOString().slice(0, 10);
}

(Date.prototype as any).toInputDateString = toInputDateString;

export function toIsoDateString(this: string): string {
  let tzo = -(new Date(this)).getTimezoneOffset(), dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      return (num < 10 ? "0" : "") + num;
    };
  return this + dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" + pad(Math.abs(tzo) % 60);
}

(String.prototype as any).toIsoDateString = toIsoDateString;

export function toDateFromInput(this: string): Date {
  let date = new Date(new Date(this)
    .toISOString()
    .replace("Z", "")
    .toIsoDateString());
  return date;
}

(String.prototype as any).toDateFromInput = toDateFromInput;

export function toDateTimeFromInput(this: string): Date {
  return new Date(this);
}

(String.prototype as any).toDateTimeFromInput = toDateTimeFromInput;

declare global {
  interface Date {
    toCustomISOString: typeof toCustomISOString;
    toInputDateTimeString: typeof toInputDateTimeString;
    toInputDateString: typeof toInputDateString;
  }

  interface String {
    toDateFromInput: typeof toDateFromInput;
    toDateTimeFromInput: typeof toDateTimeFromInput;
    toIsoDateString: typeof toIsoDateString;
  }
}

export class InputDate extends Date {
  get input_date(): string {
    return this.toInputDateString();
  }

  set input_date(value: string) {
    let date = value.toDateFromInput();
    this.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    this.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
  }

  get input_datetime(): string {
    return this.toInputDateTimeString();
  }

  set input_datetime(value: string) {
    let date = value.toDateTimeFromInput();
    this.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    this.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
  }
}
