import {Component, OnInit} from '@angular/core';
import {CompositionTypeHttpService} from "../http-requests/composition-type-http.service";
import {CompositionTypeNl} from "../models/composition-type/composition-type-nl";
import {GlobalAlertService} from "../_services/global-alert.service";
import {AlertLevel} from "../enums/alert-level";
import {CompositionTypeService} from "../_services/composition-type.service";
import {Routes} from "../enums/routes";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../_auth/permission.service";
import {VATRate, VATRateHelper} from "../models/common/vat-rate";
import {BusinessCaseTypeHelper} from "../models/common/business-case-type";

@Component({
  selector: 'app-compositions-type',
  templateUrl: './compositions-type.component.html',
  styleUrls: ['./compositions-type.component.scss']
})
export class CompositionsTypeComponent implements OnInit {

  constructor(private compositionTypeHttpService: CompositionTypeHttpService,
              private globalAlertService: GlobalAlertService,
              private compositionTypeService: CompositionTypeService,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService) {
  }

  compositionTypes: CompositionTypeNl[] | undefined;
  type_name: string = "";

  Add() {
    this.compositionTypeHttpService.Add(this.type_name).subscribe(x => {
      if (!this.compositionTypes) this.compositionTypes = [];
      this.compositionTypes.splice(0, 0, x);
      this.globalAlertService.createAlertBannerModel("Hinzufügen erfolgreich", `Artikel-Kategorie ${x.name} wurde erfolgreich hinzugefügt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
      this.type_name = "";
      this.compositionTypeService.CompositionTypes = this.compositionTypes.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 0;
        return 0;
      })
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Artikel-Kategorie ${this.type_name} konnte nicht angelegt werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  Delete(compositionType: CompositionTypeNl) {
    let confirmation = confirm("Folgende Artikel-Kategorie wird gelöscht:\n" + compositionType.name + "\n" + compositionType.id);
    if (!confirmation) return;

    if (this.compositionTypes == undefined) return;
    this.compositionTypeHttpService.delete(compositionType.id).subscribe(x => {
      this.compositionTypes!.splice(this.compositionTypes!.indexOf(compositionType), 1);
      this.compositionTypeService.CompositionTypes.splice(this.compositionTypeService.CompositionTypes.findIndex(x => x.id == compositionType.id), 1);
      this.globalAlertService.createAlertBannerModel("Erfolgreich gelöscht", `Artikel-Kategorie ${compositionType.name} wurde erfolgreich gelöscht.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Artikel-Kategorie ${compositionType.name} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.CompositionTypeSettings], {queryParams: {id: id}});
  }


  LoadData() {
    this.compositionTypeHttpService.ListNl().subscribe(x => {
      this.compositionTypes = x;
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Artikel-Kategorien konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  ngOnInit(): void {
    this.LoadData();
  }

  protected readonly Routes = Routes;
  protected readonly VATRate = VATRate;
  protected readonly VATRateHelper = VATRateHelper;
  protected readonly BusinessCaseTypeHelper = BusinessCaseTypeHelper;
}
