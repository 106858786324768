import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent implements OnInit {
  @Input() error: HttpErrorResponse | undefined;
  @Output() errorChange = new EventEmitter<HttpErrorResponse>();

  constructor() { }

  ClearError() {
    this.errorChange.emit(undefined);
  }

  ngOnInit(): void {

  }

}
