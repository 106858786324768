<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Arbeitsverhältnisse</h1>
    </div>
  </div>

  <form class="grid grid-cols-2 space-y-2 pb-4 w-full gap-x-4 items-end shadow-lg p-3 rounded-md mt-4"
        name="new-employee">
    <div class="col-span-2 flex w-full">
      <span class="fw-bold">Suchen
      <span *ngIf="PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Add())">
        / Neues Arbeitsverhältnis anlegen
      </span>
      </span>
    </div>
    <div class="grow"
         [class.col-span-2]="!PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Add())">
      <label for="er_name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
      <div class="mt-1">
        <input [(ngModel)]="er_name" (ngModelChange)="Filter()" type="text" name="er_name" id="er_name"
               autocomplete="er_name"
               class="block h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
      </div>
    </div>
    <div class="grow col-start-2"
         *ngIf="PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Add())">
      <label for="er_salary" class="block text-sm font-medium leading-6 text-gray-900">Stundenlohn</label>
      <div class="mt-1 relative">
        <div class="w-12 absolute flex rounded-l-md border-r-2 inset-y-2">
          <span class="m-auto text-gray-600">&euro;&nbsp;/&nbsp;h</span>
        </div>
        <input [(ngModel)]="er_salary" type="number" step="0.01" name="er_salary" id="er_salary"
               autocomplete="er_salary"
               class="block !pl-14 h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
      </div>
    </div>
    <div class="grow"
         [class.col-span-2]="!PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Add())">
      <label for="er_multiplier" class="block text-sm font-medium leading-6 text-gray-900">Multiplier</label>
      <div class="mt-1">
        <input [(ngModel)]="er_multiplier" type="number" name="er_multiplier" id="er_multiplier"
               autocomplete="er_multiplier"
               class="block h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      </div>
    </div>
    <div class="grow col-start-2"
         *ngIf="PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Add())">
      <label for="er_limit" class="block text-sm font-medium leading-6 text-gray-900">Limit</label>
      <div class="mt-1 relative">
        <div class="w-12 absolute flex rounded-l-md border-r-2 inset-y-2">
          <span class="m-auto text-gray-600">&euro;</span>
        </div>
        <input [(ngModel)]="er_limit" type="number" step="0.01" name="er_limit" id="er_limit" autocomplete="er_limit"
               class="block !pl-14 h-10 w-full rounded-md border-2 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
      </div>
    </div>
    <div class="col-span-2 shrink"
         *ngIf="PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Add())">
      <label for="submit" class="block text-sm font-medium leading-6 text-gray-900 sr-only"></label>
      <div class="mt-2">
        <input value="Abschicken" (click)="Add()" type="submit" name="submit" id="submit" autocomplete="family-name"
               class="px-4 block h-10 w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-900 sm:text-sm sm:leading-6">
      </div>
    </div>
  </form>

  <app-loading class="mt-4" *ngIf="!relationships_filtered"></app-loading>

  <div class="mt-4 flex flex-col w-full" *ngIf="relationships_filtered && relationships_filtered.length > 0">
    <div>
      <span *ngIf="!selectionMode"
            class="font-bold cursor-pointer text-theme-primary-700 hover:text-theme-primary-900 select-none"
            (click)="enterSelectionMode()">Bearbeiten</span>
      <div *ngIf="selectionMode" class="inline-flex space-x-2">
        <a
          class="font-bold select-none place-self-center text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
          (click)="leaveSelectionMode()">Fertig</a>
        <app-download
          *ngIf="PermissionService.CheckPermission(PermissionService.Document_EmploymentRelationship_Download())"
          [Route]="ServerEndpoints.Document_EmploymentRelationship_Download" [DownloadInformation]="downloadInformation"
          [Params]="downloadParams" class="place-self-center ml-auto select-none" Text="Zeiten exportieren"
          [OverrideTextSize]="false"
          [Data]="downloadData"></app-download>
      </div>
    </div>
    <div *ngIf="selectionMode" class="grid grid-cols-2 bg-white rounded-lg p-2 shadow-lg mt-2 gap-x-3">
      <input [class.text-red-600]="!areValidDates()" type="date" [ngModel]="getDate(Side.Left)"
             (ngModelChange)="setDate($event, Side.Left)">
      <input [class.text-red-600]="!areValidDates()" type="date" [ngModel]="getDate(Side.Right)"
             (ngModelChange)="setDate($event, Side.Right)">
    </div>
  </div>

  <div class="mt-4 rounded-md shadow-lg p-3" *ngIf="relationships_filtered">
    <span *ngIf="relationships_filtered.length == 0">Keine Einträge vorhanden.</span>

    <ul role="list" class="divide-y divide-gray-100">
      <li *ngFor="let relationship of relationships_filtered"
          class="relative flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-2 sm:flex-nowrap">
        <div>
          <p class="text-sm font-semibold leading-6 text-gray-900">
            <a [routerLink]="['/' + Routes.CompanyEmployee]" [queryParams]="{'employmentRelationship': relationship.id}"
               class="hover:underline text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">{{ relationship.name }}</a>
          </p>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p>
              <span class="">{{ relationship.salary | currency: "EUR" }} / Stunde</span>
            </p>
            <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
              <circle cx="1" cy="1" r="1"/>
            </svg>
            <p>
              <span *ngIf="relationship.limit != undefined">Grenze: {{ relationship.limit | currency: "EUR" }}</span>
              <span *ngIf="relationship.limit == undefined">Ohne Begrenzung</span>
            </p>
          </div>
        </div>
        <dl class="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
          <div class="flex w-16 gap-x-2.5">
            <dt>
              <span class="sr-only">Total employees</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person"
                   viewBox="0 0 16 16">
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
              </svg>
            </dt>
            <dd class="text-sm leading-6 text-gray-900">{{ relationship.employees.length }}</dd>
          </div>
          <div>
            <input class="" type="checkbox" *ngIf="selectionMode" [ngModel]="relationship.selected">
            <a
              *ngIf="!selectionMode && PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Get())"
              (click)="redirectToSettings(relationship.id)"
              class="select-none text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer inline-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                   viewBox="0 0 16 16">
                <path
                  d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
              </svg>
            </a>
          </div>
        </dl>
        <div class="absolute -inset-x-4 inset-y-0 cursor-pointer bg-transparent opacity-25"
             *ngIf="selectionMode" (click)="relationship.selected = !relationship.selected;setDownloadData()"
             [class.!bg-theme-primary-300]="relationship.selected">
        </div>
      </li>
    </ul>
  </div>
</div>
