<body class="bg-transparent">
  @if (IsLoggedIn() && !IsSelfService()) {
    <!-- if user is logged in -->
    @if (isEmployee) {
      <!-- if user is employee -->
      <main class="absolute pt-10 lg:pt-20 inset-0 overflow-y-auto !px-4 lg:!px-8" #relative>
        <app-alert-banner class=""></app-alert-banner>
        <router-outlet></router-outlet>
      </main>
    } @else {
      <!-- if user is not employee -->
      <app-navbar-office></app-navbar-office>
      <main>
        <div class="fixed top-12 bottom-0 inset-x-0 transition-[top] py-8 px-2 lg:!p-8 overflow-y-auto" #relative
             [ngClass]="{'!top-36': NavbarService.hasExpanded()}"> <!-- px-4 sm:px-6 lg:px-8 -->
          <app-alert-banner class=""></app-alert-banner>
          <router-outlet></router-outlet>
        </div>
      </main>
      <app-help-integration></app-help-integration>
    }
  } @else {
    <!-- if user is not logged in or self-service -->
    <main #relative>
      <app-alert-banner class=""></app-alert-banner>
      <router-outlet></router-outlet>
    </main>
  }

<app-fullscreen-loader [show]="LoadingIndicatorService.loading"></app-fullscreen-loader>
<app-info-message *ngIf="keyboardService.isOptionPressed"
                  message="Links werden in neuem Tab geöffnet"></app-info-message>
<app-cookie-settings></app-cookie-settings>
</body>
