import {Injectable} from '@angular/core';
import jwt_decode from "jwt-decode";
import {CookieBase, CookieSetting} from "../x-models/cookie-setting";
import {CookieType} from "../x-models/x-enums/cookie-type";
import {GlobalAlertService} from "./global-alert.service";
import {AlertLevel} from "../enums/alert-level";

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private readonly setItem;
  private oSetCookie;

  constructor(private globalAlertService: GlobalAlertService) {
    this.cookies[0].forced = true;
    this.setItem = localStorage.setItem;
    localStorage.setItem = function (key: string, value: string) {
      throw new Error(`localStorage.setItem('${key}', '${value}') is not allowed in this context. Use CookieService instead.`);
    };

    (() => {
      this.oSetCookie = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie')?.set;
      let oGetCookie = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie')?.get;
      //if (this.oSetCookie) {
      //  Object.defineProperty(document, 'cookie', {
      //    set(value: string) {
      //      throw new Error(`Setting cookie not allowed in this context. Use CookieService instead.`);
      //    },
      //    get() {
      //      return oGetCookie?.call(document);
      //    }
      //  })
      //}
    })();

    let pCookies = localStorage.getItem("cookie_settings");
    if (pCookies == null) {
      this.settingsVisible = true;
      this.pCookies = [];
    } else {
      this.pCookies = JSON.parse(pCookies) || [];
      this.pCookies.forEach(x => {
        let cookie = this.cookies.find(c => c.id == x.id);
        if (cookie) cookie.enabled = x.enabled;
      })
    }
  }

  getDecodedToken(): any {
    try {
      return jwt_decode(this.getCookie("JWT"));
    } catch (Error) {
      return null;
    }
  }

  getPermissions(): any {
    try {
      const roles = this.getDecodedToken()["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      return typeof roles == "string" ? [roles] : roles;
    } catch (e) {
      return null
    }
  }

  settingsVisible: boolean = false;

  toggleSettings() {
    this.settingsVisible = !this.settingsVisible;
  }

  showSettings() {
    this.settingsVisible = true;
  }

  closeSettings() {
    this.settingsVisible = false;
  }

  cookies: CookieSetting[] = [
    new CookieSetting(CookieType.Required, "Notwendige Cookies", "Diese Cookies sind für den Betrieb der Website unbedingt erforderlich. Sie gewährleisten die Grundfunktionen und Sicherheitsmerkmale der Website.", true),
    new CookieSetting(CookieType.Functional, "Funktionale Cookies", "Funktionale Cookies ermöglichen es uns, Ihre Präferenzen und Einstellungen (wie Sprachauswahl und Benutzeranpassungen) zu speichern, um Ihnen eine verbesserte und persönlichere Nutzungserfahrung auf unserer Website zu bieten.", true),
  ]

  pCookies: CookieBase[];

  async acceptAll() {
    this.cookies.forEach(c => {
      if (!c.forced) c.enabled = true;
    });
    await this.saveSettings();
  }

  async denyAll() {
    this.cookies.forEach(c => {
      if (!c.forced) c.enabled = false;
    });
    await this.saveSettings();
  }

  async saveSettings() {
    let cookies = this.cookies.filter(c => !c.forced).map(c => new CookieBase(c.id, c.enabled));
    this.setLocalStorage(CookieType.Required, "cookie_settings", JSON.stringify(cookies));
    this.pCookies = cookies;
    await this.delay(400);
    this.settingsVisible = false;
  }

  warningDisplayed: boolean = false;

  setLocalStorage(type: CookieType, key: string, value: string) {
    if (type == CookieType.Required) {
      this.setItem.call(localStorage, key, value);
    } else {
      let cookie = this.pCookies.find(c => c.id == type);
      if (cookie && cookie.enabled) {
        this.setItem.call(localStorage, key, value);
      } else {
        if (this.warningDisplayed) return;
        localStorage.removeItem(key);
        this.globalAlertService.createAlertBannerModel("Warnung", "Nicht alle Cookies wurden akzeptiert, dies kann zu Beeinträchtigungen im Nutzererlebnis führen.", AlertLevel.warning, 3000);
        this.globalAlertService.show();
        this.warningDisplayed = true;
      }
    }
  }

  setCookie(type: CookieType, name: string, value: string, expireDays: number, path: string = '') {
    if (type == CookieType.Required) this.fSetCookie(name, value, expireDays, path);
    else {
      let cookie = this.pCookies.find(c => c.id == type);
      if (cookie && cookie.enabled) this.fSetCookie(name, value, expireDays, path);
      else {
        if (this.warningDisplayed) return;
        this.globalAlertService.createAlertBannerModel("Warnung", "Nicht alle Cookies wurden akzeptiert, dies kann zu Beeinträchtigungen im Nutzererlebnis führen.", AlertLevel.warning, 3000);
        this.globalAlertService.show();
        this.warningDisplayed = true;
      }
    }
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name: string) {
    this.fSetCookie(name, '', -1);
  }

  private fSetCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    this.oSetCookie?.call(document, `${name}=${value}; ${expires}${cpath}`);
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
