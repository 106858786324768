import {Component} from '@angular/core';
import {PermissionService} from "../../_auth/permission.service";
import {Routes} from "../../enums/routes";
import {CookieService} from "../../_services/cookie.service";

@Component({
  selector: 'app-navbar-help',
  templateUrl: './navbar-help.component.html',
  styleUrls: ['./navbar-help.component.scss']
})
export class NavbarHelpComponent {
  constructor(public PSInstance: PermissionService, public CookieService: CookieService) {
  }

  protected readonly Routes = Routes;
  protected readonly window = window;
  protected readonly PermissionService = PermissionService;
}
