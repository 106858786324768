import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ShiftMakerSettings} from "../../_models/settings/shift-maker-settings";
import {Observable} from "rxjs";
import {ServerEndpoints} from "../../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class SettingsHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private http: HttpClient) {
  }

  getShiftMakerSettings(): Observable<ShiftMakerSettings> {
    return this.http.get<ShiftMakerSettings>(`${this.baseUrl}${ServerEndpoints.Administration_Settings_GetShiftMakerSettings}`);
  }

  setShiftMakerSettings(settings: ShiftMakerSettings): Observable<ShiftMakerSettings> {
    return this.http.post<ShiftMakerSettings>(`${this.baseUrl}${ServerEndpoints.Administration_Settings_SetShiftMakerSettings}`, settings);
  }
}
