import {Inject, Injectable, OnDestroy} from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import {HubConnectionState} from '@microsoft/signalr';
import {AuthenticationService} from "../../_auth/authentication.service";
import {DtoSignalREndpoints} from "./_enums/dto-signal-r-endpoints";
import {DtoServices} from "./_enums/dto-services";
import {DtoBaseService} from "./dto-base.service";
import {DtoServiceUpdate} from "./_models/dto-service-update";

const retryTimes = [0, 3000, 10000, 20000];

@Injectable({
  providedIn: 'root'
})

export class DtoSignalRService implements OnDestroy {
  constructor(@Inject("BASE_URL") private baseUrl: string,
              private authenticationService: AuthenticationService) {
    this.Connect();
  }

  ngOnDestroy() {
    this.Disconnect();
  }

  public Connection: SignalR.HubConnection | undefined = undefined;
  private dtoServices: any = {};

  private Connect(): void {
    if (this.Connection == undefined) {
      this.Connection = new SignalR.HubConnectionBuilder()
        .configureLogging(SignalR.LogLevel.Information)
        .withUrl(this.baseUrl + "/hubs/synchronization", {
          accessTokenFactory: () => this.authenticationService.userValue!.jwt
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .build()

      this.Connection.onreconnected(x => {
        this.Reconnect();
      })

      this.Connection.on(DtoSignalREndpoints.DtoUpdate.toString(), (update: DtoServiceUpdate) => {
        try {
          this.dtoServices[update.dtoService].ProcessUpdate(update);
        } catch (e) {
          console.error(e)
        }
      });

      if (this.Connection.state != HubConnectionState.Disconnected) {

      } else {
        this.Connection.start().then(() => {
          console.log("DTO-SignalR connected");
          this.Reconnect();
        }).catch((err: any) => {
          return console.error(err);
        });
      }
    }
  }

  private Disconnect(): void {
    if (this.Connection === undefined) return;
    this.Connection.stop().then((r: any) => {
      console.log("DTO-SignalR disconnected: " + r);
      this.Connection = undefined;
    }, (error: any) => {
      console.error(error);
    })
  }

  private Reconnect(): void {
    Object.keys(this.dtoServices).forEach(identifier => {
      const service = this.dtoServices[identifier];
      service.LoadData();

      this.Connection!.invoke(DtoSignalREndpoints.RegisterService.toString(), service.Identifier.toString())
        .then(result => {
          console.log(`DTO-Service connected: ${identifier}`)
        })
        .catch(error => {
          console.error(error)
        });
    });
  }

  public RegisterReconnect(service: DtoBaseService<any, any>) {
    this.dtoServices[service.Identifier] = service;
    while (this.Connection === undefined) {
      this.Connect()
    }

    if (this.Connection.state == HubConnectionState.Connected) {
      this.Connection.invoke(DtoSignalREndpoints.RegisterService.toString(), service.Identifier.toString())
        .then(result => {
          console.log(`DTO-Service connected: ${service.Identifier}`)
        })
        .catch(error => {
          console.error(error)
        });
    }
  }

  public DeregisterReconnect(identifier: DtoServices) {
    delete this.dtoServices[identifier];
  }
}
