<ng-container *ngIf="IsVisible">
  <div [ngClass]="{'mb-4': IsVisible,
  'top-12': authenticationService.userValue && !authenticationService.isEmployee() && !location.pathname.startsWith('/' + Routes.AutomationModule + '/' + AutomationRoutes.Views) && !NavbarService.hasExpanded(),
  'top-36': authenticationService.userValue && !authenticationService.isEmployee() && !location.pathname.startsWith('/' + Routes.AutomationModule + '/' + AutomationRoutes.Views) && NavbarService.hasExpanded(),
  'top-10 lg:top-20': authenticationService.userValue && authenticationService.isEmployee(),
  '!top-0': !authenticationService.userValue}"
       class="fixed inset-x-0 z-[100] w-full transition-[top]">

    <ng-container *ngIf="this.AlertBannerModel.Level===AlertLevel.success">
      <div
        class=" bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 pb-2 shadow-md ease-in duration-{{tailwindTransitionDuration}}"
        role="alert"
        [ngClass]="{'opacity-100': !InTransition, 'opacity-0 transition-opacity ': InTransition}">
        <div class="flex">
          <div class="py-1">
            <svg class="fill-current h-12 w-12 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path
                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
            </svg>

          </div>
          <div>
            <p class="font-bold">{{ this.AlertBannerModel.Header }}</p>
            <p class="text-sm">{{ this.AlertBannerModel.Message }}</p>
          </div>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="this.AlertBannerModel.Level===AlertLevel.error">
      <div
        class=" bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 pb-2 shadow-md transition-opacity duration-700 ease-in opacity-100"
        role="alert">

        <div class="flex ">
          <div class="py-1">
            <svg class="fill-current h-12 w-12 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
          <div>
            <p class="font-bold">{{ this.AlertBannerModel.Header }}</p>
            <p class="text-sm">{{ this.AlertBannerModel.Message }}</p>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="this.AlertBannerModel.Level===AlertLevel.info">
      <div
        class=" bg-blue-50 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 pb-2 shadow-md transition-opacity duration-700 ease-in opacity-100"
        role="alert">
        <div class="flex">
          <div class="py-1">
            <svg class="fill-current h-12 w-12 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>
          </div>
          <div>
            <p class="font-bold">{{ this.AlertBannerModel.Header }}</p>
            <p class="text-sm">{{ this.AlertBannerModel.Message }}</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="this.AlertBannerModel.Level===AlertLevel.warning">
      <div
        class=" bg-orange-50 border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 pb-2 shadow-md transition-opacity duration-700 ease-in opacity-100"
        role="alert">
        <div class="flex">
          <div class="py-1">
            <svg class="fill-current h-12 w-12 text-orange-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
            </svg>
          </div>
          <div>
            <p class="font-bold">{{ this.AlertBannerModel.Header }}</p>
            <p class="text-sm">{{ this.AlertBannerModel.Message }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
