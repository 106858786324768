import {Component} from '@angular/core';
import {PermissionService} from "../../_auth/permission.service";
import {Routes} from "../../enums/routes";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss']
})
export class NavbarAdminComponent {
  constructor(public PermissionService: PermissionService) {
  }

  protected readonly Routes = Routes;
}
