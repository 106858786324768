import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {EmploymentRelationshipDto} from "../x-models/employment-relationship-dto";
import {Tuple} from "../../../models/_generic/tuple";
import {EmployeeDto} from "../x-models/employee-dto";
import {EmployeeCostDto} from "../../../models/employee-cost-dto";
import {DtoBaseHttpService} from "../../../_services/dto-services/dto-base-http.service";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class EmploymentRelationshipHttpService extends DtoBaseHttpService<EmploymentRelationshipDto> {
  UrlPath: string = "/company/employmentrelationship";

  constructor(@Inject("BASE_URL") baseUrl: string,
              httpClient: HttpClient) {
    super(baseUrl, httpClient);
  }

  List(): Observable<EmploymentRelationshipDto[]> {
    return this.HttpClient.get<EmploymentRelationshipDto[]>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_List);
  }

  add(name: string, salary: number, limit?: number, multiplier?: number): Observable<EmploymentRelationshipDto> {
    return this.HttpClient.post<EmploymentRelationshipDto>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_Add, {
      name: name,
      salary: salary,
      limit: limit,
      multiplier: multiplier
    })
  }

  delete(id: string): Observable<any> {
    return this.HttpClient.delete<any>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_Delete + "?id=" + id);
  }

  get(id: string): Observable<EmploymentRelationshipDto> {
    return this.HttpClient.get<EmploymentRelationshipDto>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_Get + "?id=" + id);
  }

  changeName(id: string, name: string): Observable<EmploymentRelationshipDto> {
    return this.HttpClient.put<EmploymentRelationshipDto>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_ChangeName, {
      Id: id,
      Name: name
    })
  }

  setValues(id: string, name: string, value: any): Observable<EmploymentRelationshipDto> {
    return this.HttpClient.put<EmploymentRelationshipDto>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_SetValues + "?id=" + id, {
      name: name,
      value: value
    })
  }

  setRelationship(id: string | undefined, employeeIds: string[] | undefined): Observable<Tuple<EmploymentRelationshipDto, EmployeeDto[]>> {
    return this.HttpClient.put<Tuple<EmploymentRelationshipDto, EmployeeDto[]>>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_SetRelationship + "?id=" + (id ?? ""),
      employeeIds != undefined && employeeIds.length == 0 ? undefined : employeeIds
    )
  }

  getEmployeeCostsFromEvent(eventId: string): Observable<EmployeeCostDto[]> {
    return this.HttpClient.get<EmployeeCostDto[]>(this.BaseUrl + ServerEndpoints.Company_EmploymentRelationship_GetEventEmploymentCosts + "?eventId=" + eventId);
  }
}
