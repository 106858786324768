<div class="text-center rounded-3">
  <div #calender class="">
    <div class="flex items-center text-gray-900">
      <button (click)="previousMonth()" type="button"
              class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-900 hover:text-gray-700 rounded-md"
              [class.text-white]="darkMode">
        <span class="sr-only">Previous month</span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clip-rule="evenodd"/>
        </svg>
      </button>
      <div (click)="thisMonth()"
           class="flex-auto select-none text-sm font-semibold text-gray-900 cursor-pointer hover:!text-gray-500"
           [class.text-white]="darkMode">
        {{ strMonth }} {{ strYear }}
      </div>
      <button (click)="nextMonth()" type="button"
              class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-900 hover:text-gray-700 rounded-md"
              [class.text-white]="darkMode">
        <span class="sr-only">Next month</span>
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd"/>
        </svg>
      </button>
    </div>
    <div class="mt-3 grid grid-cols-7 text-xs leading-6 text-gray-300">
      <div class="text-gray-500" *ngFor="let weekday of strWeekdays">{{ weekday }}</div>
    </div>
    <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
         [ngClass]="{'bg-transparent': darkCalendar}">
      <!--
        Always include: "py-1.5 hover:bg-gray-100 focus:z-10"
        Is current month, include: "bg-white"
        Is not current month, include: "bg-gray-50"
        Is selected or is today, include: "font-semibold"
        Is selected, include: "text-white"
        Is not selected, is not today, and is current month, include: "text-gray-900"
        Is not selected, is not today, and is not current month, include: "text-gray-400"
        Is today and is not selected, include: "text-theme-primary-700"

        Top left day, include: "rounded-tl-lg"
        Top right day, include: "rounded-tr-lg"
        Bottom left day, include: "rounded-bl-lg"
        Bottom right day, include: "rounded-br-lg"
      -->
      <button *ngFor="let day of calendarDays" type="button"
              class="py-1.5 text-gray-400 n-calender-hover focus:z-10 flex flex-col"
              [ngClass]="{
                    'rounded-tl-lg': day.position == Position.TopLeft,
                    'rounded-tr-lg': day.position == Position.TopRight,
                    'rounded-br-lg': day.position == Position.BottomRight,
                    'rounded-bl-lg': day.position == Position.BottomLeft,
            'bg-white text-gray-900': !day.outOfMonth && !darkCalendar,
            'bg-transparent text-gray-200': !day.outOfMonth && darkCalendar,
            'bg-gray-50 text-gray-400': day.outOfMonth && !darkCalendar,
            'bg-gray-700 text-gray-400': day.outOfMonth && darkCalendar,
            '!text-theme-primary-700 fw-bold': isToday(day.date) && !isSelected(day),
            '!text-white': isToday(day.date) && isSelected(day),
            '!bg-theme-primary-700 !text-white': isSelected(day)
          }" (click)="select(day)">
        <time datetime="2021-12-28" class="mx-auto flex h-7 w-7 items-center justify-center rounded-full border-4"
              [style.border-left-color]="getColor(day, Position.Left)"
              [style.border-right-color]="getColor(day, Position.Right)"
              [style.border-top-color]="getColor(day, Position.Top)"
              [style.border-bottom-color]="getColor(day, Position.Bottom)"
        >{{ day.date.getDate() }}
        </time>
      </button>
    </div>
  </div>
</div>
