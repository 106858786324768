import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthUnit} from "../x-models/auth-unit";
import {TelegramChannelConfiguration, TelegramMessageSetter} from "../x-models/telegram-channel-configuration";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class TelegramHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  ListBots(): Observable<AuthUnit[]> {
    return this.httpClient.get<AuthUnit[]>(this.baseUrl + ServerEndpoints.Company_Telegram_GetBots);
  }

  AddBot(token: string): Observable<AuthUnit> {
    return this.httpClient.post<AuthUnit>(this.baseUrl + ServerEndpoints.Company_Telegram_AddBot + "?token=" + token, {});
  }

  DeleteBot(id: string): any {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Company_Telegram_DeleteBot + "?id=" + id);
  }

  AddChannel(bot: string, channel: string): Observable<AuthUnit> {
    return this.httpClient.post<AuthUnit>(this.baseUrl + ServerEndpoints.Company_Telegram_AddChannel + "?bot=" + bot + "&channel=" + channel, {});
  }

  DeleteChannel(id: string): Observable<any> {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Company_Telegram_DeleteChannel + "?id=" + id);
  }

  SetChannelSettings(config: TelegramChannelConfiguration): Observable<any> {
    let body: any = {};
    body["HelpTypeActiveMapper"] = {};
    body["ShardActiveMapper"] = {};
    config.types.forEach(type => {
      if (!type.active) return;
      body["HelpTypeActiveMapper"][type.type] = type.active;
    })
    config.shards.forEach(shard => {
      if (!shard.active) return;
      body["ShardActiveMapper"][shard.shard.id] = shard.active;
    })
    body["interval"] = config.interval;
    body["statusUpdateEnabled"] = config.statusUpdateEnabled;
    return this.httpClient.put(this.baseUrl + ServerEndpoints.Company_Telegram_SetChannelSettings + "?id=" + config.channel.id, body);
  }

  GetChannelSettings(id: string): Observable<TelegramMessageSetter> {
    return this.httpClient.get<TelegramMessageSetter>(this.baseUrl + ServerEndpoints.Company_Telegram_GetChannelSettings + "?id=" + id);
  }
}
