import { Injectable } from '@angular/core';
import {List} from "postcss/lib/list";

@Injectable({
  providedIn: 'root'
})
export class GenericSortingService {

  constructor() { }

  private keysTokeyValue(items: any, keys: Array<any>) {
    let item = items;
    for (let key_ of keys) {
      item = item[key_];
    }
    return item;
  }


  public groupBasedSorting<T>(items: T[], groupKey: string | Array<any>, sortKey: string, inverse: boolean): T[] {
    let groups: { [key: string]: T[] } = {};
    items.forEach(item => {
      let group;
      if (typeof groupKey == "string") {
        // @ts-ignore
        group = item[groupKey];
      } else {
        group = this.keysTokeyValue(item, groupKey);
      }

      if (groups[group] == undefined) {
        groups[group] = [];
      }
      groups[group].push(item);
    });
    let sortedGroups: T[][] = [];
    for (let group in groups) {
      sortedGroups.push(this.sortBy(groups[group], sortKey, inverse));
    }
    return this.flat(sortedGroups);
  }

  public sortBy<T>(items: T[], sortKey: string, inverse: boolean): T[] {
    let sortedItems = items.sort((a, b) => {
      // @ts-ignore
      let aVal = a[sortKey];
      // @ts-ignore
      let bVal = b[sortKey];
      if (typeof aVal == "string") {
        return aVal.localeCompare(bVal);
      } else if (typeof aVal == "number") {
        return aVal - bVal;
      } else {
        return 0;
      }
    });
    if (inverse) {
      return sortedItems.reverse();
    } else {
      return sortedItems;
    }
  }

  flat<T>(items: T[][]): T[] {
    let flat: T[] = [];
    items.forEach(item => {
      item.forEach(subItem => {
        flat.push(subItem);
      });
    });
    return flat;
  }

  /**
   * Sorts multiple arrays based on the sortKey
   * @param items
   * @param sortKey
   * @param inverse
   */
  static sortMultipleArrays(items: any[][], sortKey: number, inverse: boolean): any[][] {
    let sortedItems = [];

    for (let i = 0; i < items[sortKey].length; i++) {
      let newElement = []
      for (let j = 0; j < items.length; j++) {
        newElement.push(items[j][i]);
      }
      sortedItems.push(newElement);
    }
    // sort new Array
    sortedItems.sort((a, b) => {
      // @ts-ignore
      let aVal = a[sortKey];
      // @ts-ignore
      let bVal = b[sortKey];
      if (typeof aVal == "string") {
        return aVal.localeCompare(bVal);
      } else if (typeof aVal == "number") {
        return aVal - bVal;
      } else {
        return 0;
      }
    });
    if (inverse) {
      sortedItems = sortedItems.reverse();
    }
    let newItems = [];
    for (let i = 0; i < items.length; i++) {
      newItems.push([]);
    }
    for (let i = 0; i < sortedItems.length; i++) {
      for (let j = 0; j < items.length; j++) {
        // @ts-ignore
        newItems[j].push(sortedItems[i][j]);
      }
    }

    return newItems;
  }


}
