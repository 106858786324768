import {Sorting} from "../x-models/x-enums/sorting";

export abstract class Sortable {
  public SortedBy: Sorting = Sorting.None;
  public Inverse: boolean = true;

  abstract OrderBy(sortedBy: Sorting, inverse: boolean): void;

  SetSorting(sortedBy: Sorting): void {
    if (this.SortedBy == sortedBy) {
      return this.OrderBy(sortedBy, !this.Inverse);
    }
    return this.OrderBy(sortedBy, false);
  }
}
