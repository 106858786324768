import {SDatePipe} from "../../pipes/s-date.pipe";

export class CalendarEntry {
  id: string;
  start: Date;
  strStart: string;
  end: Date;
  color: string = "#4c4c4c";

  constructor(start: Date, end: Date, color: string = "#4c4c4c", id: string = "") {
    this.start = new Date(start);
    this.end = new Date(end);
    this.strStart = start.toLocaleDateString()
    this.color = color;
    this.id = id;
  }
}
