import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Sorting} from "../../x-models/x-enums/sorting";
import {NgIf} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-sorting-indicator',
  templateUrl: './sorting-indicator.component.html',
  imports: [
    NgIf
  ],
  styleUrls: ['./sorting-indicator.component.scss']
})
export class SortingIndicatorComponent {
  @Input() currentSorting!: Sorting;
  @Input() inverseState!: boolean;
  @Input() thisSorting!: Sorting;
  @Input() displayName!: string;
  @Output() sortingChange = new EventEmitter();
}
