import {AlertLevel} from "../enums/alert-level";

/**
 * A model for the alert banner component.
 */
export class AlertBannerModel {


  public Level: AlertLevel;
  public Message: string;
  public Header: string;
  public DisplayDuration: number;

  constructor(Header: string = "", Message: string = "", Level: AlertLevel = AlertLevel.info, displayDuration: number = 5000) {
    this.Message = Message;
    this.Header = Header;
    this.Level = Level;
    this.DisplayDuration = displayDuration;

  }

  setAttributes(Header: string, Message: string, AlertLevel: AlertLevel, displayDuration: number=5000) {
    /**
     * Sets all attributes internally.
     *
     * @return void
     */

    this.Message = Message;
    this.Header = Header;
    this.Level = AlertLevel;
    this.DisplayDuration = displayDuration;
  }

}
