import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContractBase} from "../../../company/x-models/contract-base";
import {DatePipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-contract-information',
  standalone: true,
  imports: [
    DatePipe,
    NgIf
  ],
  templateUrl: './contract-information.component.html',
  styleUrl: './contract-information.component.scss'
})
export class ContractInformationComponent {
  @Input() contract!: ContractBase;
  @Output() edit = new EventEmitter<ContractBase>();
  @Input() canEdit: boolean = false;

  e() {
    this.edit.emit(this.contract)
  }
}
