import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {PermissionDto} from "../../models/permission/permission-dto";
import {PermissionNl} from "../../models/permission/permission-nl";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class PermissionHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  list(): Observable<PermissionDto[]> {
    return this.httpClient.get<PermissionDto[]>(this.baseUrl + ServerEndpoints.Administration_Permission_List);
  }
}
