import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CalendarDay} from "./x-models/calendar-day";
import {CalendarEntry} from "./x-models/calendar-entry";
import {Position} from "./x-models/x-enums/position";
import {Tuple} from "../../../models/_generic/tuple";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-small-calendar',
  templateUrl: './small-calendar.component.html',
  styleUrls: ['./small-calendar.component.scss']
})
export class SmallCalendarComponent implements OnChanges, OnInit {

  ngOnChanges(changes: SimpleChanges): void {
    //this.createDayArray(this.lastDate, this.selectedDate);
    if (changes["calendarEntries"]) {
      this.mapEvents();
    }
    if (changes["nextSelectedDay"]) {
      if (this.nextSelectedDay) this.select(this.nextSelectedDay);
    }
  }

  constructor(private router: Router, private route: ActivatedRoute) {
    this.today = new Date();
    this.today.setHours(0,0,0,0);

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params["date"]) {
        this.lastDate = new Date(params["date"]);
        this.selectedDate = new CalendarDay(this.lastDate, true);
      }
      this.createDayArray(this.lastDate, this.selectedDate)
    })
  }

  today: Date;
  strMonth: string = "";
  strYear: string = "";
  lastDate: Date = new Date();

  @Input() selectedDate: CalendarDay | undefined;
  @Output() selectedDateChange = new EventEmitter<CalendarDay>();

  @Input() dateRange: Tuple<Date, Date> = new Tuple<Date, Date>();
  @Output() dateRangeChange: EventEmitter<Tuple<Date, Date>> = new EventEmitter<Tuple<Date, Date>>();

  calendarDays: CalendarDay[] = [];
  @Input() calendarEntries: CalendarEntry[] = [];

  @Input() nextSelectedDay: CalendarDay | undefined;

  strWeekdays: string[] = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]

  @Input() darkMode: boolean = false;
  @Input() darkCalendar: boolean = false;

  createLabels(date: Date) {{
    this.strMonth = date.toLocaleString("de-DE", { month: "long" });
    this.strYear = date.getFullYear().toString();
  }}

  select(date: CalendarDay) {
    if (date.outOfMonth) {
      this.createDayArray(date.date, date)
    } else {
      this.selectedDate = date;
      this.selectedDateChange.emit(this.selectedDate);
    }
  }

  createDayArray(date: Date = new Date(), cd: CalendarDay | undefined = undefined) {
    this.createLabels(date)
    let offset;
    let days: CalendarDay[] = [];

    let start = date
    start.setHours(0,0,0,0);
    start.setFullYear(date.getFullYear(), date.getMonth(), 1);

    this.lastDate = new Date(start);

    offset = ((start.getDay()-1)%7+7)%7;
    start.setDate(start.getDate() - offset)

    for (let i = 0; i < offset; i++) {
      let day = new CalendarDay(new Date(start), true)
      days.push(day)
      start.setDate(start.getDate()+1);
    }
    offset = this.daysInMonth(start.getMonth(), start.getFullYear())-start.getDate();
    for (let i = 0; i <= offset; i++) {
      let ncd = new CalendarDay(new Date(start), false);
      if (cd?.strDate == ncd.strDate) {
        this.select(ncd);
      }
      days.push(ncd);
      start.setDate(start.getDate()+1);
    }

    offset = (start.getDay()*-1+8)%7;
    for (let i = 0; i < offset; i++) {
      days.push(new CalendarDay(new Date(start), true))
      start.setDate(start.getDate()+1);
    }

    days[0].position = Position.TopLeft;
    days[6].position = Position.TopRight;
    days[days.length-1].position = Position.BottomRight;
    days[days.length-7].position = Position.BottomLeft;

    this.calendarDays = days;

    //this.mapEvents();

    if (this.calendarDays.length > 0) {
      this.dateRange.item1 = this.calendarDays[0].date;
      this.dateRange.item2 = this.calendarDays[this.calendarDays.length - 1].date;
      this.dateRangeChange.emit(this.dateRange);
    }
  }

  mapEvents() {
    this.calendarEntries.forEach(x => {
      let day = this.calendarDays.find(c => c.strDate == x.strStart);
      if (day == undefined) return;
      if (day.calendarEntries.findIndex(c => c.id == x.id) > -1) return;
      day.calendarEntries.push(x);
    })
  }



  nextMonth() {
    let next = new Date(this.lastDate);
    this.changeMonth(next, 1);
    this.createDayArray(next);
  }

  thisMonth() {
    this.selectedDate = new CalendarDay(new Date())
    this.createDayArray(new Date(), this.selectedDate)
    //this.selectedDateChange.emit()
  }

  previousMonth() {
    let next = new Date(this.lastDate);
    this.changeMonth(next, -1);
    this.createDayArray(next)
  }

  // highlights
  isToday(date: Date): boolean {
    return date.getFullYear() == this.today.getFullYear() &&
      date.getMonth() == this.today.getMonth() &&
      date.getDate() == this.today.getDate()
  }

  isSelected(date: CalendarDay): boolean {
    return date.strDate == this.selectedDate?.strDate;
  }

  getColor(day: CalendarDay, position: Position): string {
    if (day.calendarEntries.length == 0) return "transparent";
    if (day.calendarEntries.length == 1) return day.calendarEntries[0].color;

    switch (position) {
      case Position.Left:
        return day.calendarEntries[0].color;

      case Position.Right:
        return day.calendarEntries[1].color;

      case Position.Top:
        if (day.calendarEntries.length == 2) return day.calendarEntries[0].color;
        return day.calendarEntries[2].color;

      case Position.Bottom:
        if (day.calendarEntries.length == 2) return day.calendarEntries[1].color;
        if (day.calendarEntries.length == 3) return "transparent";
        return day.calendarEntries[3].color;
    }
    return "transparent";
  }

  // utils
  changeMonth(date: Date, amount: number) {
    let month = date.getMonth();
    let target = month + amount;
    if (target >= 0 && target <= 11) {
      date.setMonth(target)
    } else {
      date.setFullYear(date.getFullYear() + (target > 0 ? 1 : -1) ,((target % 12) + 12) % 12);
    }
  }

  daysInMonth (month: number, year: number): number {
    return new Date(year, month+1, 0).getDate();
  }

  protected readonly Position = Position;
}
