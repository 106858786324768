import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {catchError, map, Observable, of, tap} from 'rxjs';
import {SetupHttpService} from "./x-http-requests/setup-http.service";
import {SetupService} from "./setup.service";

@Injectable({
  providedIn: 'root'
})
export class SetupGuard  {
  constructor(private setupHttpService: SetupHttpService, private router: Router,
              private setupService: SetupService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.setupService.isConfigured().pipe(map(x => {
      if (x) this.router.navigate(['/']);
      return !x;
    }), catchError(error => {
      this.router.navigate(['/']);
      return of(false)
    }));
  }
}
