<layout-page-container>
  <layout-header-container title="Kategorien"/>
  <layout-form-container submitText="Kategorie anlegen" clearText="Felder leeren"
                         [permission]="PermissionService.Inventory_Category_Add()"
                         (clear)="newCategoryName = ''" (submit)="AddCategory()" [hasSearch]="false"
                         [element]="name">
    <div class="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <label for="category-name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
        <input #name [(ngModel)]="newCategoryName"
               type="text" name="category-name" id="category-name" autocomplete="category-name"
               class="mt-2 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
      </div>
    </div>
  </layout-form-container>

  <layout-page-scrollable>
    <app-loading *ngIf="!categories"></app-loading>
    <table class="min-w-full divide-y divide-gray-300" *ngIf="categories && categories.length > 0">
      <thead>
      <tr>
        <th (click)="SortBy('name')" scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'name' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'name' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Name</a>
          </div>
        </th>
        <th (click)="SortBy('created')" scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
          <div class="inline-flex">
            <div class="grid grid-cols-1 gap-1">
              <svg [class.opacity-100]="this.field == 'created' && !this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mb-1" viewBox="0 0 16 16">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
              <svg [class.opacity-100]="this.field == 'created' && this.inverse" xmlns="http://www.w3.org/2000/svg"
                   width="16" height="16" fill="currentColor"
                   class="place-self-center bi bi-arrow-down opacity-50 -mt-1" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </div>
            <a
              class="cursor-pointer select-none place-self-center pl-2 text-sm text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer">Erstellt
              am</a>
          </div>
        </th>
        <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell text-sm">
          ID
        </th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-lg hidden">Role</th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0 text-lg">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr *ngFor="let category of categories">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <a class="text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer"
             [ngClass]="{'pointer-events-none !text-gray-800' : !PermissionService.CheckPermission(PermissionService.Permissions_ItemComponent())}"
             [routerLink]="['/items']" [queryParams]="{ category: category.id }"
             queryParamsHandling="merge">{{ category.name }}</a>
        </td>
        <td
          class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ (category.created) | date : "dd.MM.YYYY, HH:mm" }}
          Uhr
        </td>
        <td class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ category.id }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden">Member</td>
        <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Category_Get())"
             (click)="redirectToSettings(category.id)"
             class="select-none text-gray-600 hover:text-gray-900 cursor-pointer p-1  inline-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
            </svg>
          </a>
          <a *ngIf="PermissionService.CheckPermission(PermissionService.Inventory_Category_Delete())"
             (click)="RemoveCategory(category)"
             class="select-none text-red-600 hover:text-red-900 p-1 cursor-pointer inline-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </a>
        </td>

      </tr>
      </tbody>
    </table>
  </layout-page-scrollable>
</layout-page-container>
