import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import {SetupHttpService} from "../x-http-requests/setup-http.service";
import {AuthenticationService} from "../../_auth/authentication.service";
import {first} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UrlHelper} from "../../helpers/url-helper";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class SetupIndexComponent implements OnInit {
  username: string = "";
  password: string = "";
  cPassword: string = "";
  company: string = "";
  shard: string = "";
  error: HttpErrorResponse | undefined;

  constructor(private setupHttpService: SetupHttpService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  launchSetup() {
    if (this.password != this.cPassword) {
      this.error = new HttpErrorResponse({
        statusText: "Passwords are not matching!"
      });
      return;
    }
    this.setupHttpService.doConfigure(this.username, this.password, this.company, this.shard).subscribe(x => {
      this.authenticationService.login(this.username, this.password).pipe(first())
        .subscribe({
          next: () => {
            const returnUrl = UrlHelper.QueryParams.returnUrl || '/';
            // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            this.router.navigateByUrl(returnUrl);
          }, error: error => {
            console.error(error);
            this.error = error;
          }
        });
    }, error => {
      console.error(error);
      this.error = error;
    })
  }
}
