<div class="fixed inset-0 z-50 background p-4 text-white">
  <div id="header" class="w-full flex max-h-48 relative pr-8">
    <div class="flex">
      <img alt="N5CM Logo" src="assets/images/icon_nobackground_md.png" class="place-self-center h-full py-8">
      <span class="font-bold place-self-center text-8xl">N5CM</span>
    </div>
    <div class="absolute flex inset-0 flex-grow">
      <div class="mx-auto place-self-center space-x-8 w-full grid grid-cols-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="currentColor" class="bi bi-stopwatch place-self-center justify-self-end mr-8" viewBox="0 0 16 16">
          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
        </svg>
        <span class="place-self-center text-6xl font-bold justify-self-start p-0 ml-1">
          <countdown [config]="countdownConfig" />
        </span>
      </div>
    </div>
    <div class="absolute inset-y-0 right-8" *ngIf="imageUrl">
      <img alt="Shard Logo" [src]="imageUrl" class="place-self-center h-full py-[4rem]">
    </div>
  </div>

  <div id="body" class="absolute inset-x-12 top-64 bottom-16 grid grid-cols-2">
    <div id="graph" class="h-full w-full border-r-2 border-gray-500 pr-8">
      <canvas id="chart" class=""></canvas>
    </div>
    <div *ngIf="allCompositions" id="compositions" class="h-full w-full flex flex-col border-l-2 border-gray-500 justify-between pl-4 relative">
      <div class="absolute inset-x-0 grid grid-cols-5 transition-all duration-1000 ease-linear pl-4" [style.top.%]="getIndex(composition) / allCompositions.length * 100" *ngFor="let composition of allCompositions; let index = index">
        <div class="col-span-2 flex ml-auto relative transition-all duration-1000 ease-linear bg-white h-25 place-self-center" [style.min-width.%]="getWidth(composition)">
          <span class="ml-auto place-self-center px-2 text-black font-bold">{{ composition.sales }}x</span>
          <div class="h-25 bg-white place-self-center w-full"></div>
        </div>
        <div class="w-full col-span-3 rounded-3xl border-4 bg-black py-3 px-4 flex" [style.box-shadow]="'2px 5px 16px 0px ' + composition.color" [style.border-color]="composition.color">
          <span class="text-3xl place-self-center font-light">{{ composition.publicName ?? composition.name }}</span>
          <div class="ml-auto space-x-4 flex place-self-center">
            <!-- [style.color]="(!composition.compositionOverride?.price ? '#ffffff' : (composition.compositionOverride!.price! > composition.price ? '#FA2056' : '#1FD760'))" -->
            <span class="text-5xl font-light place-self-center">
              {{ composition.compositionOverride?.price ?? composition.price | currency: "EUR" }}
            </span>
            <div class="flex h-16 w-16 relative">
              <div class="absolute inset-0">
                <svg [class.opacity-0]="getAngle(composition) == 90" [style.fill]="getAngle(composition) < 90 ? '#FA2056' : '#1FD760'" [style.rotate.deg]="getAngle(composition)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64" class="place-self-center transition-all duration-1000">
                  <path d="m19.707 9.293-7-7a1 1 0 0 0-1.414 0l-7 7A1 1 0 0 0 5 11h3v10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V11h3a1 1 0 0 0 .707-1.707z" data-name="Up"/>
                </svg>
              </div>
              <div class="absolute inset-0" *ngIf="getAngle(composition) == 90">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute text-gray-500" width="64" height="64">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="status" class="absolute inset-x-0 bottom-0 flex">
    <span class="mx-auto my-3 text-gray-500">{{ Connection?.state }}</span>
  </div>
</div>
