export function isNullOrWhitespace(this: string | undefined | null): boolean {
  return this === null || this === undefined || this.trim() === "";
}

declare global {
  interface String {
    isNullOrWhitespace: typeof isNullOrWhitespace;
  }
}

(String.prototype as any).isNullOrWhitespace = isNullOrWhitespace;
