import {HostListener, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  constructor() {
  }

  isOptionPressed: boolean = false;

  pressedKey(event: KeyboardEvent) {
    if (event.key?.toLowerCase() == "alt") {
      this.isOptionPressed = true;
    }
  }

  releasedKey(event: KeyboardEvent) {
    if (event.key?.toLowerCase() == "alt") {
      this.isOptionPressed = false;
    }
  }

  resetKeys() {
    this.isOptionPressed = false;
  }
}
