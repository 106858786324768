import {Component, EventEmitter, Input} from '@angular/core';
import {SettingsPageComponent} from "../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../enums/routes";
import {SettingsInputElementModel} from "../../../models/settings-input-element-model";
import {ActivatedRoute} from "@angular/router";
import {PermissionService} from "../../../_auth/permission.service";
import {Router} from "@angular/router";
import {ShardDto} from "../../../models/admin_owner/shard/shard-dto";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {ModelBase} from "../../../models/model-base";
import {ShardHttpService} from "../../x-http-requests/shard-http.service";
import {HttpPropertyChangeStatus} from "../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../models/observable-queue-element";
import {AlertLevel} from "../../../enums/alert-level";
import {PrinterDto} from "../../../_modules/location/_models/printer-dto";
import {Address} from "../../../models/common/address";


@Component({
  selector: 'app-shard-settings',
  templateUrl: '../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./shard-settings.component.scss']
})
export class ShardSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.OwnerShards;

  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newShardName: new SettingsInputElementModel(),

    taxNumber: new SettingsInputElementModel(),
    taxId: new SettingsInputElementModel(),

    address: new SettingsInputElementModel(),
    taxSeparator: new SettingsInputElementModel(),
    color: new SettingsInputElementModel(),
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public shardHttpService: ShardHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);

    this.settingsInputElementModel.newShardName.propertyTitle = "Name";
    this.settingsInputElementModel.newShardName.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.newShardName.order = 0;

    this.settingsInputElementModel.color.propertyTitle = "Color";
    this.settingsInputElementModel.color.inputType = "color";
    this.settingsInputElementModel.color.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.color.order = 1;

    this.settingsInputElementModel.taxSeparator.propertyTitle = "Taxes";
    this.settingsInputElementModel.taxSeparator.inputType = "separator";
    this.settingsInputElementModel.taxSeparator.order = 10;
    this.settingsInputElementModel.taxSeparator.requiredPermission = PermissionService.Owner();

    this.settingsInputElementModel.taxNumber.propertyTitle = "Tax Number";
    this.settingsInputElementModel.taxNumber.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.taxNumber.order = 11;

    this.settingsInputElementModel.taxId.propertyTitle = "Tax ID";
    this.settingsInputElementModel.taxId.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.taxId.order = 12;

    this.settingsInputElementModel.address.propertyTitle = "Address";
    this.settingsInputElementModel.address.inputType = "address";
    this.settingsInputElementModel.address.order = 2;
    this.settingsInputElementModel.address.requiredPermission = PermissionService.Owner();

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Owner());
  }

  public model?: ShardDto;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.shardHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Shard gelöscht", "Shard wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  @Input() loadInputModel: Function = (id: string) => {
    this.shardHttpService.get(id).subscribe((model: ShardDto) => {
      this.model = model;
      this.settingsInputElementModel.newShardName.originalPropertyValue = this.settingsInputElementModel.newShardName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.taxNumber.originalPropertyValue = this.settingsInputElementModel.taxNumber.changeableProperties.newPropertyValue = this.model?.metadata?.taxNumber
      this.settingsInputElementModel.taxId.originalPropertyValue = this.settingsInputElementModel.taxId.changeableProperties.newPropertyValue = this.model?.metadata?.taxId
      this.settingsInputElementModel.color.originalPropertyValue = this.settingsInputElementModel.color.changeableProperties.newPropertyValue = this.model?.metadata?.color


      // address
      if (!this.model.address) this.model.address = new Address();
      this.settingsInputElementModel.address.originalPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      this.settingsInputElementModel.address.changeableProperties.newPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      this.updateElements()
    });
  };

  @Input() saveChanges: Function = () => {

    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveVariable("TaxNumber", this.settingsInputElementModel.taxNumber))
    this.requiresChanges.push(this.saveVariable("TaxId", this.settingsInputElementModel.taxId))
    this.requiresChanges.push(this.saveVariable("Color", this.settingsInputElementModel.color))
    this.requiresChanges.push(this.saveAddress())

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();

  };


  saveVariable(variable: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.shardHttpService.setProperty(this.model.id, variable, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: ShardDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAndShow("Fehler", "Fehler beim Speichern des Werts.", AlertLevel.error);
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newShardName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newShardName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newShardName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.shardHttpService.changeName(this.model.id, this.settingsInputElementModel.newShardName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: ShardDto) => {
      this.model = model;
      this.settingsInputElementModel.newShardName.originalPropertyValue = this.settingsInputElementModel.newShardName.changeableProperties.newPropertyValue = this.model?.name
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveAddress(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.address.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (JSON.stringify(this.settingsInputElementModel.address.changeableProperties.newPropertyValue) ===
      JSON.stringify(this.settingsInputElementModel.address.originalPropertyValue)) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.shardHttpService.setAddress(this.model.id, this.settingsInputElementModel.address.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: ShardDto) => {
      this.model = model;
      this.settingsInputElementModel.address.originalPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      this.settingsInputElementModel.address.changeableProperties.newPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Adresse.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}

