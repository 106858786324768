import { Component, OnInit } from '@angular/core';
import {UserDto} from "../../models/admin_owner/user/user-dto";
import {CompanyDto} from "../../models/admin_owner/company/company-dto";
import {ShardDto} from "../../models/admin_owner/shard/shard-dto";
import {ShardHttpService} from "../x-http-requests/shard-http.service";
import {CompanyHttpService} from "../x-http-requests/company-http.service";
import {UserHttpService} from "../x-http-requests/user-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import {ShardNl} from "../../models/admin_owner/shard/shard-nl";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class OwnerUserComponent implements OnInit {
  users?: UserDto[];
  filtered_users?: UserDto[];
  companies?: CompanyDto[];
  company?: CompanyDto;
  shard?: ShardNl;
  name: string = "";
  password: string = "";
  cPassword: string = "";
  company_filter: string = "";
  shard_filter: string = "";
  searchText: string = "";
  error?: HttpErrorResponse;
  textError: string = "";
  creator: boolean = false;

  protected: boolean = false;
  owner: boolean = false;
  admin: boolean = false;

  constructor(private shardHttpService: ShardHttpService,
              private companyHttpService: CompanyHttpService,
              private userHttpService: UserHttpService,
              private route: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  AssignCopy() {
    this.filtered_users = Object.assign([], this.users);
  }

  FilterItems(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    }
    else {
      this.filtered_users = Object.assign([], this.users?.filter(user =>
        user.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ));
    }
  }

  LoadData() {
    this.userHttpService.list(this.company_filter, this.shard_filter).subscribe(x => {
      this.users = x;
      this.AssignCopy();
    }, error => console.error(error));
    if (this.company_filter != "") {
      this.companyHttpService.get(this.company_filter).subscribe(x => {
        this.companies = [x];
        this.company = x;
        this.SetShard()
      }, error => console.error(error));
    }
    else {
      this.companyHttpService.list().subscribe(x => {
        this.companies = x.filter(x => x.shards.findIndex(c => c.id == this.shard_filter || this.shard_filter == "") > -1);
        this.companies.forEach(x => {
          x.shards = x.shards.filter(x => x.id == this.shard_filter || this.shard_filter == "");
        })
        if (this.companies.length > 0)
          this.company = this.companies[0];
          this.SetShard();
      }, error => console.error(error));
    }
  }

  AddUser() {
    if (this.cPassword != this.password) {
      this.error = new HttpErrorResponse({
        statusText: "Passwords are not matching!"
      });
      return;
    }
    this.userHttpService.add(this.name, this.password,
      this.shard == undefined ? "" : this.shard.id,
      this.company == undefined ? "" : this.company.id
      , [], [],
      this.protected, this.admin, this.owner).subscribe(x => {
        if (this.users == undefined) this.users = [];
        this.error = undefined;
        this.users.splice(0,0,x);
        this.FilterItems(this.searchText);
        this.name = "";
        this.password = "";
        this.cPassword = "";
    }, error => {
        this.error = error;
        console.error(error);
    });
  }

  RemoveUser(user: UserDto): void {
    let confirmation = confirm("Are you sure you want to delete the following user:\n"+user.name+"\n"+user.id+
    "\nShard: "+user.shard.name+"\nCompany: "+user.company.name);
    if (!confirmation) return;
    this.userHttpService.remove(user.id).subscribe(x => {
      this.error = undefined;
      this.users?.splice(this.users?.indexOf(user), 1);
      this.FilterItems(this.searchText);
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  SetShard() {
    if (this.company != undefined && this.company.shards.length > 0) this.shard = this.company.shards[0];
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.company_filter = params.company == undefined ? "" : params.company;
      this.shard_filter = params.shard == undefined ? "" : params.shard;
    })
    this.LoadData();
  }

}
