import {Component} from '@angular/core';
import {Routes} from "../../../enums/routes";
import {PermissionService} from "../../../_auth/permission.service";
import {LegalRoutes} from "../_models/_enums/LegalRoutes";

@Component({
  selector: 'app-navbar-legal',
  templateUrl: './navbar-legal.component.html',
  styleUrls: ['./navbar-legal.component.scss']
})
export class NavbarLegalComponent {
  constructor(public PermissionService: PermissionService) {
  }

  protected readonly Routes = Routes;
  protected readonly LegalRoutes = LegalRoutes;
}
