import {Component, Input} from '@angular/core';
import {KeyboardService} from "../../_services/keyboard.service";

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss']
})
export class InfoMessageComponent {
  constructor() {

  }

  @Input() header: string = "Info";
  @Input() message: string = "Info message";
}
