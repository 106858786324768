<div class="fixed bg-gray-100 inset-0 -z-10"></div>

<a class="inline-flex gap-x-1 fixed top-4 left-4"
   [routerLink]="(AuthenticationService.isLoggedIn ? ['/' + Routes.Home] : ['/' + Routes.Login])">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" class="h-8 w-8 place-self-center">
    <path fill="currentColor"
          d="M168.49 199.51a12 12 0 0 1-17 17l-80-80a12 12 0 0 1 0-17l80-80a12 12 0 0 1 17 17L97 128Z"/>
  </svg>
  @if (AuthenticationService.isLoggedIn) {
    <span class="place-self-center">Zur Startseite</span>
  } @else {
    <span class="place-self-center">Zum Login</span>
  }
</a>

<div
  class="w-full max-w-xl flex flex-col justify-center px-6 py-24 lg:px-8 mx-auto">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm mb-4">
    <img class="mx-auto h-32 w-auto rounded-full border-2 border-white" src="./assets/images/icon.png"
         alt="CheqMe Logo" [routerLink]="['/login']">
  </div>

  <router-outlet></router-outlet>
</div>

