<div class="bg-white">
  <div class="mx-auto max-w-7xl py-4 sm:px-6 sm:py-6 lg:px-8">
    <div class="relative isolate overflow-hidden bg-gray-900 px-6 py-12 text-center shadow-2xl sm:rounded-3xl sm:px-16">
      <div class="border-b border-gray-900/10 pb-0">
        <div class="flex justify-content-center mb-4">
          <img class="h-24" src="./assets/images/icon_nobackground_md.png" alt="N5CM Logo" />
        </div>
        <h2 class="text-base font-semibold leading-7 text-white">Configure Nymu5 ClubManagement</h2>
        <p class="mt-1 text-sm leading-6 text-gray-400">You will <span class="fw-bold">NOT</span> be able to modify these later at this point.</p>
        <p class="mt-1 text-sm leading-6 text-gray-400">Please enter the Owner-Data.</p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="username" class="block text-sm font-medium leading-6 text-gray-300">Username</label>
            <div class="mt-2">
              <input [(ngModel)]="username" type="text" name="username" id="username" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-300">Password</label>
            <div class="mt-2">
              <input [(ngModel)]="password" type="password" name="password" id="password" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-start-4 sm:col-span-3">
            <label for="cPassword" class="block text-sm font-medium leading-6 text-gray-300">Confirm Password</label>
            <div class="mt-2">
              <input [(ngModel)]="cPassword" type="password" name="cPassword" id="cPassword" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="company" class="block text-sm font-medium leading-6 text-gray-300">Company Name</label>
            <div class="mt-2">
              <input [(ngModel)]="company" type="text" name="company" id="company" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="shard" class="block text-sm font-medium leading-6 text-gray-300">Shard Name</label>
            <div class="mt-2">
              <input [(ngModel)]="shard" type="text" name="shard" id="shard" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
            </div>
          </div>

          <button (click)="launchSetup()" class="col-span-full bg-white/[.1] hover:bg-white/[.3] rounded border-purple-darker p-2 shadow-md font-semibold text-white">Configure now!</button>
        </div>
      </div>
      <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
        <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7" />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stop-color="#7775D6" />
            <stop offset="1" stop-color="#E935C1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>
<app-error-banner [(error)]="error"></app-error-banner>
