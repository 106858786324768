import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ChangeableProperty} from '../changeable-property';
import {Input, Output} from "@angular/core";
import {SettingsInputElementModel} from "../../../models/settings-input-element-model";
import {isStringArray} from "@angular/compiler-cli/src/ngtsc/annotations/common";
import {PlatformScannerService} from "../../../_services/platform-scanner.service";
import {Platform} from "../../../enums/platform";

export enum ChangeValueStatus {
  Unchanged,
  Changed,
  Invalid
}


@Component({
  selector: 'app-settings-input-element',
  templateUrl: './settings-input-element.component.html',
  styleUrls: ['./settings-input-element.component.scss']
})
export class SettingsInputElementComponent {
  @ViewChild('SettingsInput') settingsInput: any;
  settingsInputElementModel: SettingsInputElementModel = new SettingsInputElementModel();
  public displayValue: string = "";
  public inputType: string = "text";


  @Input() set setSettingsInputElementModel(value: SettingsInputElementModel) { //model: { value: SettingsInputElementModel }) {
    this.settingsInputElementModel = value //model.value;
    this.hasValueChanged()
    this.settingsInputElementModel.changeableProperties.editMode = false;

    this.setInputType(typeof (this.settingsInputElementModel.originalPropertyValue));
  }

  public validAndChangedInput: ChangeValueStatus = ChangeValueStatus.Unchanged;
  timeoutClick: boolean = false;

  constructor(private cd: ChangeDetectorRef, public platformScannerService: PlatformScannerService) {
  }


  setInputType(type: string) {
    if (this.settingsInputElementModel.inputType !== undefined) {
      this.inputType = this.settingsInputElementModel.inputType
      return
    }

    switch (type) {
      case "password":
        this.inputType = "password";
        break;
      case "number":
        this.inputType = "number";
        break;
      case "text":
        this.inputType = "text";
        break;
      default:
        this.inputType = "text";
        break;
    }
  }

  hasValueChanged() {
    if (
      (
        this.settingsInputElementModel.changeableProperties.newPropertyValue === undefined ||
        this.settingsInputElementModel.changeableProperties.newPropertyValue === null ||
        this.settingsInputElementModel.changeableProperties.newPropertyValue === ""
      ) && (
        this.settingsInputElementModel.originalPropertyValue === undefined ||
        this.settingsInputElementModel.originalPropertyValue === null ||
        this.settingsInputElementModel.originalPropertyValue === ""
      )) {
      this.settingsInputElementModel.changeableProperties.changeValue = false;
      this.validAndChangedInput = ChangeValueStatus.Unchanged;
      return
    }

    if (
      this.settingsInputElementModel.changeableProperties.newPropertyValue === undefined ||
      this.settingsInputElementModel.changeableProperties.newPropertyValue === null ||
      this.settingsInputElementModel.changeableProperties.newPropertyValue === ""
    ) {
      this.settingsInputElementModel.changeableProperties.changeValue = false;
      this.validAndChangedInput = ChangeValueStatus.Invalid;
      return
    }

    if (
      (
        this.settingsInputElementModel.changeableProperties.newPropertyValue === this.settingsInputElementModel.originalPropertyValue &&
        typeof (this.settingsInputElementModel.originalPropertyValue) !== "object"
      ) || (
        JSON.stringify(this.settingsInputElementModel.changeableProperties.newPropertyValue) === JSON.stringify(this.settingsInputElementModel.originalPropertyValue) &&
        typeof (this.settingsInputElementModel.originalPropertyValue) === "object"
      ) || (
        typeof (this.settingsInputElementModel.originalPropertyValue) === "string" &&
        this.settingsInputElementModel.changeableProperties.newPropertyValue.toLowerCase() === this.settingsInputElementModel.originalPropertyValue.toLowerCase()
      )) {
      this.settingsInputElementModel.changeableProperties.changeValue = false;
      this.validAndChangedInput = ChangeValueStatus.Unchanged;
      return
    }

    this.settingsInputElementModel.changeableProperties.changeValue = true;
    this.validAndChangedInput = ChangeValueStatus.Changed;
  }

  getNewValue() {


    return this.settingsInputElementModel.changeableProperties.newPropertyValue;
  }


  setNewValueEvent(value: InputEvent) {
    this.setNewValue(value)
  }

  setNewValue(value: any) {

    this.settingsInputElementModel.changeableProperties.newPropertyValue = value;


    this.hasValueChanged();
  }


  enableEditMode() {
    if (this.timeoutClick) return;
    this.timeoutClick = true;
    setTimeout(() => {
      this.timeoutClick = false;
    }, 400);


    this.settingsInput.nativeElement.select();
    this.settingsInputElementModel.changeableProperties.editMode = true;
  }

  resetProperty() {
    if (this.timeoutClick) return;
    this.timeoutClick = true;
    setTimeout(() => {
      this.timeoutClick = false;
    }, 400);

    this.settingsInputElementModel.changeableProperties.editMode = false;
    if (typeof (this.settingsInputElementModel.originalPropertyValue) === "object") {
      this.settingsInputElementModel.changeableProperties.newPropertyValue = JSON.parse(JSON.stringify(this.settingsInputElementModel.originalPropertyValue));
    } else {
      this.settingsInputElementModel.changeableProperties.newPropertyValue = this.settingsInputElementModel.originalPropertyValue;
    }
    this.hasValueChanged();
  }

  compare(a: any, b: any): boolean {
    if (typeof (a) !== "object") return a != b;
    return JSON.stringify(a) != JSON.stringify(b);
  }


  readonly ChangeValueStatus = ChangeValueStatus;
  protected readonly Platform = Platform;
}
