import { Injectable } from '@angular/core';
import {CookieService} from "../_services/cookie.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private cookieService: CookieService) { }

  getRoles(): string[] {
    return this.cookieService.getPermissions();
  }
}
