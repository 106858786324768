import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-switch-medium',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() disabled: boolean = false;
  @Input()  isOn = false;
  @Output() isOnChange = new EventEmitter<boolean>();

  toggle() {
    this.isOn = !this.isOn;
    this.isOnChange.emit(this.isOn);
  }
}
