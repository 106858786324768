import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {NavbarService} from "../navbar.service";

@Component({
  selector: 'app-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss']
})
export class NavbarContainerComponent {
  @Input() breadcrumb: string | undefined;
}
