import {Component, EventEmitter, Input} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../_auth/permission.service";
import {ModelBase} from "../../models/model-base";
import {SettingsPageComponent} from "../../x-components/settings/settings-page/settings-page.component";
import {SettingsInputElementModel} from "../../models/settings-input-element-model";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {Routes} from "../../enums/routes";
import {AlertLevel} from "../../enums/alert-level";
import {ObservableQueueElement} from "../../models/observable-queue-element";
import {HttpPropertyChangeStatus} from "../../enums/http-property-change-status";
import {CategoryHttpService} from "../../http-requests/category-http.service";
import {CategoryDto} from "../../models/category-dto";


@Component({
  selector: 'app-category-settings',
  templateUrl: '../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./category-settings.component.scss']
})
export class CategorySettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.Categories;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.categoryHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Kategorie gelöscht", "Kategorie wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };


  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newCategoryName: new SettingsInputElementModel(),
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public categoryHttpService: CategoryHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newCategoryName.propertyTitle = "Name";

    this.settingsInputElementModel.newCategoryName.requiredPermission = PermissionService.Inventory_Category_ChangeName();
    this.deletePermission = PermissionService.CheckPermission(PermissionService.Inventory_Category_Delete());
  }

  public model?: CategoryDto;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;


  @Input() loadInputModel: Function = (id: string) => {
    this.categoryHttpService.get(id).subscribe((model: CategoryDto) => {
      this.model = model;
      this.settingsInputElementModel.newCategoryName.originalPropertyValue = this.settingsInputElementModel.newCategoryName.changeableProperties.newPropertyValue = this.model?.name
      this.updateElements();
    });


  };

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())


    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();

  };


  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCategoryName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCategoryName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCategoryName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.categoryHttpService.changeName(this.model.id, this.settingsInputElementModel.newCategoryName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CategoryDto) => {
      this.model = model;
      this.settingsInputElementModel.newCategoryName.originalPropertyValue = this.settingsInputElementModel.newCategoryName.changeableProperties.newPropertyValue = this.model?.name
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }


}

