import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {SupplierDto} from "../x-models/supplier-dto";
import {DtoBaseHttpService} from "../../../_services/dto-services/dto-base-http.service";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class SupplierHttpService extends DtoBaseHttpService<SupplierDto> {
  UrlPath: string = "/Company/Supplier";

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
    super(baseUrl, httpClient);
  }

  list(): Observable<SupplierDto[]> {
    return this.httpClient.get<SupplierDto[]>(this.baseUrl + ServerEndpoints.Company_Supplier_List);
  }

  add(name: string): Observable<SupplierDto> {
    return this.httpClient.post<SupplierDto>(this.baseUrl + ServerEndpoints.Company_Supplier_Add, {
      name: name,
    })
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Company_Supplier_Delete + "?id=" + id);
  }

  update(supplier: SupplierDto): Observable<SupplierDto> {
    return this.httpClient.put<SupplierDto>(this.baseUrl + ServerEndpoints.Company_Supplier_Update, supplier);
  }

  get(id: string, disabled: boolean = false): Observable<SupplierDto> {
    return this.httpClient.get<SupplierDto>(this.baseUrl + ServerEndpoints.Company_Supplier_Get + "?id=" + id + "&disabled=" + disabled);
  }

  changeName(id: string, name: string): Observable<SupplierDto> {
    return this.httpClient.put<SupplierDto>(this.baseUrl + ServerEndpoints.Company_Supplier_ChangeName, {
      Id: id,
      Name: name
    })
  }
}
