import { Component, OnInit } from '@angular/core';
import {ConfigurationHttpService} from "../http-requests/configuration-http.service";
import {TagHttpService} from "../http-requests/tag-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfigurationDto} from "../models/configuration-dto";
import { HttpErrorResponse } from "@angular/common/http";
import {TagDto} from "../models/tag-dto";
import {OverrideHttpService} from "../http-requests/override-http.service";
import {CompositionDto} from "../models/composition-dto";
import {CompositionNl} from "../models/composition-nl";
import {CbComposition, CbConfig, CbTag} from "../models/configuration-builder";
import {ConfigurationbuilderHttpService} from "../http-requests/configurationbuilder-http.service";
import {PermissionService} from "../_auth/permission.service";
import {ConfigurationSpecial} from "../models/composition/composition-special";
import {AlertLevel} from "../enums/alert-level";
import {GlobalAlertService} from "../_services/global-alert.service";

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {
  public configurations?: ConfigurationDto[];
  public filtered_configurations?: ConfigurationDto[];
  public searchText: string = "";
  public error?: HttpErrorResponse;
  public newConfigurationName: string = "";
  public activeConfiguration?: CbConfig;
  public activeTag?: CbTag;
  public tags?: TagDto[];
  public store: any = {
    _tag: true,
    compositions: []
  };
  public configurationBuild: CbConfig[] = [];

  constructor(private configurationHttpService: ConfigurationHttpService,
              private tagHttpService: TagHttpService,
              private overrideHttpService: OverrideHttpService,
              private cbHttpService: ConfigurationbuilderHttpService,
              private route: ActivatedRoute,
              public router: Router,
              public PermissionService: PermissionService,
              private globalAlertService: GlobalAlertService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  AssignCopy(): void {
    this.filtered_configurations = Object.assign([], this.configurations);
  }

  //AssignTagCopy(): void {
  //  if (this.tags == undefined) this.tags = [];
  //  this.overridden_tags = this.tags.map(x => Object.assign({}, x));
  //}

  FilterItem(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    }
    else {
      this.filtered_configurations = Object.assign([], this.filtered_configurations?.filter(configuration =>
        configuration.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ))
    }
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  AddConfiguration(template: string = ""): void {
    this.cbHttpService.add(this.newConfigurationName, template).subscribe(x => {
      this.configurationBuild.splice(0,0,x);
      this.SetActiveConfiguration(this.configurationBuild[0]);
      this.newConfigurationName = "";
      this.FilterItem(this.searchText);
      this.globalAlertService.createAlertBannerModel("Hinzufügen erfolgreich", `Das Preisprofil ${x.name} wurde erfolgreich angelegt.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Preisprofil konnte nicht hinzugefügt werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  CloneConfiguration(configuration: CbConfig) {
    let name = prompt("Neuer Veranstaltungsname:", "");
    this.newConfigurationName = name == null ? "" : name;
    this.AddConfiguration(configuration.id);
  }

  RemoveConfiguration(configuration: CbConfig): void {
    let confirmation = confirm("Löschen des folgenden Preisprofils bestätigen:\n" + configuration.name + "\n" + configuration.id);
    if (!confirmation) return;
    this.configurationHttpService.delete(configuration.id).subscribe(x => {
      this.configurationBuild.splice(this.configurationBuild.indexOf(configuration), 1);
      if (this.configurationBuild.length > 0 && (this.activeConfiguration == undefined || this.activeConfiguration == configuration)) this.activeConfiguration = this.configurationBuild[0];
      this.globalAlertService.createAlertBannerModel("Löschen erfolgreich", `Löschen des Preisprofils ${configuration.name} erfolgreich.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
      //this.FilterItem(this.searchText);
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", `Das Preisprofil ${configuration.name} konnte nicht gelöscht werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  ToggleTag(configuration: CbConfig, tag: CbTag) {
    this.overrideHttpService.overrideTag(configuration.id, tag.id, tag.active).subscribe(x => {

    }, error => {
      console.error(error);
      tag.active = !tag.active;
    })
  }

  //ToggleComposition(configuration: CbConfig, composition: CbComposition, index: number) {
  //  this.overrideHttpService.overrideComposition(configuration.id, composition.id, composition.active ? null : false).subscribe(x => {
  //  }, error => {
  //    console.error(error);
  //    composition.active = !composition.active;
  //  })
  //}

  OverrideCompositionPrice(configuration: CbConfig, composition: CbComposition) {
    let price = prompt("Veranstaltung: " + configuration.name + "\nGetränk: " + composition.name + "\n\nPreis eingeben...", composition.oPrice.toFixed(2));
    this.SetComposition(configuration, composition, !composition.active ? false : null, (price == null || parseFloat(price) == composition.price || price == "") ? null : parseFloat(price));
  }

  SetComposition(configuration: CbConfig, composition: CbComposition, active: boolean | null = null, price: number | null = null) {
    this.overrideHttpService.overrideComposition(configuration.id, composition.id, active, price).subscribe(x => {
      if (price != null) {
        composition.oPrice = price;
      }
      if (price == null && active == null) {
        composition.oPrice = composition.price;
        composition.active = true;
      }

      configuration.tags.forEach(tag => {
        let comp = tag.compositions.find(x => x.id == composition.id);
        if (comp == undefined) return;

        comp.oPrice = composition.oPrice;
        comp.active = composition.active;
      })
    }, error => {
      console.error(error);
      if (active != null) composition.active = !composition.active;
    })
  }



  SetActiveConfiguration(configuration: CbConfig):void {
    this.activeConfiguration = configuration;
    this.activeTag = this.activeConfiguration.tags.find(x => x.id == this.activeTag?.id);
    if (this.activeTag == null && this.activeConfiguration.tags.length > 0) this.activeTag = this.activeConfiguration.tags[0];
  }

  SetActiveTag(tag: CbTag): void {
    this.activeTag = tag;
  }

  SetActiveTagById(id: string): void {
    this.activeTag = this.activeConfiguration?.tags.find(x => x.id == id);
  }

  // Special Setter
  special: ConfigurationSpecial | undefined;

  ConfigureSpecial(special: ConfigurationSpecial) {
    this.cbHttpService.setSpecial(special).subscribe((x: any) => {
      this.special = undefined;
      this.globalAlertService.createAlertBannerModel("Konfiguration erfolgreich", `Die Einstellungen wurden erfolgreich gespeichert.`, AlertLevel.success, 2000);
      this.globalAlertService.show();
    }, (error: any) => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Konfiguration fehlgeschlagen", `Die Einstellungen konnten nicht gespeichert werden.`, AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  SetSpecialColor(color: string) {
    if (this.special == undefined) return;
    this.special.color = color.replace("#", "");
  }


  LoadData(): void {
    // load configurations for list view
    this.cbHttpService.list().subscribe(x => {
      this.configurationBuild = x;
      if (this.configurationBuild.length > 0) this.activeConfiguration = this.configurationBuild[0];
      else return;
      if (this.activeConfiguration.tags.length > 0) this.activeTag = this.activeConfiguration.tags[0];
    }, error => console.error(error));
  }

  SetActive(configuration: ConfigurationDto, tag: TagDto): void {
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

    });
    this.LoadData();
  }
}
