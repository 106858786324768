<app-navbar-container breadcrumb="Rechtliches">
  <app-navbar-item-large title="Einstellungen" [link]="'/'+Routes.LegalModule + '/' + LegalRoutes.Settings"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_legalSettingsComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g fill="none">
        <g fill="currentColor" fill-rule="evenodd" clip-path="url(#gravityUiGearDot0)" clip-rule="evenodd">
          <path
            d="M7.199 2A.199.199 0 0 0 7 2.199c0 1.808-1.958 2.939-3.524 2.034a.199.199 0 0 0-.272.073l-.8 1.388a.199.199 0 0 0 .072.271c1.566.905 1.566 3.165 0 4.07a.199.199 0 0 0-.073.271l.801 1.388a.199.199 0 0 0 .272.073C5.042 10.862 7 11.993 7 13.8c0 .11.089.199.199.199H8.8c.11 0 .199-.089.199-.199c0-1.808 1.958-2.939 3.524-2.034a.199.199 0 0 0 .271-.073l.802-1.388a.199.199 0 0 0-.073-.271c-1.303-.753-1.516-2.434-.665-3.5a.75.75 0 0 1 1.172.936a.852.852 0 0 0 .243 1.265a1.7 1.7 0 0 1 .622 2.32l-.802 1.388a1.699 1.699 0 0 1-2.32.622a.85.85 0 0 0-1.274.735c0 .938-.76 1.699-1.699 1.699H7.2c-.938 0-1.699-.76-1.699-1.699a.85.85 0 0 0-1.274-.735a1.698 1.698 0 0 1-2.32-.622l-.802-1.388a1.699 1.699 0 0 1 .622-2.32a.85.85 0 0 0 0-1.472a1.699 1.699 0 0 1-.622-2.32l.801-1.388a1.699 1.699 0 0 1 2.32-.622A.85.85 0 0 0 5.5 2.2c0-.94.76-1.7 1.699-1.7H9.3a.75.75 0 1 1 0 1.5H7.2Zm.8 7.5a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3M8 11a3 3 0 1 0 0-6a3 3 0 0 0 0 6"/>
          <path d="M12.5 5.5a2 2 0 1 0 0-4a2 2 0 0 0 0 4"/>
        </g>
        <defs>
          <clipPath id="gravityUiGearDot0">
            <path fill="currentColor" d="M0 0h16v16H0z"/>
          </clipPath>
        </defs>
      </g>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Technische Sicherheitseinrichtung"
                         *ngIf="PermissionService.CheckPermission(PermissionService.Permissions_LegalTSEComponent())"
                         [link]="'/' + Routes.LegalModule + '/' + LegalRoutes.TSS">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M13 19h1a1 1 0 0 1 1 1h7v2h-7a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1H2v-2h7a1 1 0 0 1 1-1h1v-1.66C8.07 16.13 6 13 6 9.67v-4L12 3l6 2.67v4c0 3.33-2.07 6.46-5 7.67zM12 5L8 6.69V10h4zm0 5v6c1.91-.47 4-2.94 4-5v-1z"/>
    </svg>
  </app-navbar-item-large>
</app-navbar-container>
