import {Component, EventEmitter, Input} from '@angular/core';
import {SettingsPageComponent} from "../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../enums/routes";
import {SettingsInputElementModel} from "../../../models/settings-input-element-model";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../../_auth/permission.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {ModelBase} from "../../../models/model-base";
import {HttpPropertyChangeStatus} from "../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../models/observable-queue-element";
import {AlertLevel} from "../../../enums/alert-level";
import {CompanyHttpService} from "../../x-http-requests/company-http.service";
import {CompanyDto} from "../../../models/admin_owner/company/company-dto";
import {Address} from "../../../models/common/address";


@Component({
  selector: 'app-company-setting',
  templateUrl: '../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./company-setting.component.scss']
})
export class CompanySettingComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.OwnerCompanies;

  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newCompanyName: new SettingsInputElementModel(),
    newCompanyAlias: new SettingsInputElementModel(),

    // address
    address: new SettingsInputElementModel(),

    // tax
    taxSeparator: new SettingsInputElementModel(),
    taxNumber: new SettingsInputElementModel(),
    taxId: new SettingsInputElementModel(),
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public companyHttpService: CompanyHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);
    this.settingsInputElementModel.newCompanyName.propertyTitle = "Name";
    this.settingsInputElementModel.newCompanyName.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.newCompanyName.order = 1;

    this.settingsInputElementModel.newCompanyAlias.propertyTitle = "Alias";
    this.settingsInputElementModel.newCompanyAlias.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.newCompanyAlias.order = 2;

    // address
    this.settingsInputElementModel.address.propertyTitle = "Address";
    this.settingsInputElementModel.address.inputType = "address";
    this.settingsInputElementModel.address.order = 20;
    this.settingsInputElementModel.address.requiredPermission = PermissionService.Owner();

    // tax
    this.settingsInputElementModel.taxSeparator.propertyTitle = "Taxes";
    this.settingsInputElementModel.taxSeparator.inputType = "separator";
    this.settingsInputElementModel.taxSeparator.order = 30;
    this.settingsInputElementModel.taxSeparator.requiredPermission = PermissionService.Owner();

    this.settingsInputElementModel.taxNumber.propertyTitle = "Tax Number";
    this.settingsInputElementModel.taxNumber.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.taxNumber.order = 31;

    this.settingsInputElementModel.taxId.propertyTitle = "Tax ID";
    this.settingsInputElementModel.taxId.requiredPermission = PermissionService.Owner();
    this.settingsInputElementModel.taxId.order = 32;

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Owner());
  }

  public model?: CompanyDto;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.companyHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Unternehmen entfernt", "Unternehmen wurde erfolgreich entfernt", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };
  @Input() loadInputModel: Function = (id: string) => {
    this.companyHttpService.get(id).subscribe((model: CompanyDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompanyName.originalPropertyValue = this.settingsInputElementModel.newCompanyName.changeableProperties.newPropertyValue = this.model?.name
      this.settingsInputElementModel.newCompanyAlias.originalPropertyValue = this.settingsInputElementModel.newCompanyAlias.changeableProperties.newPropertyValue = this.model?.alias

      this.settingsInputElementModel.taxNumber.originalPropertyValue = this.settingsInputElementModel.taxNumber.changeableProperties.newPropertyValue = this.model?.metadata?.taxNumber
      this.settingsInputElementModel.taxId.originalPropertyValue = this.settingsInputElementModel.taxId.changeableProperties.newPropertyValue = this.model?.metadata?.taxId


      if (!this.model.address) {
        this.model.address = new Address();
      }

      this.settingsInputElementModel.address.originalPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      this.settingsInputElementModel.address.changeableProperties.newPropertyValue = JSON.parse(JSON.stringify(this.model?.address))

      this.updateElements()
    });
  };

  @Input() saveChanges: Function = () => {

    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName())
    this.requiresChanges.push(this.saveAlias())
    this.requiresChanges.push(this.saveAddress())

    this.requiresChanges.push(this.saveVariable("TaxNumber", this.settingsInputElementModel.taxNumber))
    this.requiresChanges.push(this.saveVariable("TaxId", this.settingsInputElementModel.taxId))

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper();

  };


  saveAlias(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompanyAlias.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompanyAlias.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCompanyAlias.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.companyHttpService.changeAlias(this.model.id, this.settingsInputElementModel.newCompanyAlias.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompanyDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompanyAlias.originalPropertyValue = this.settingsInputElementModel.newCompanyAlias.changeableProperties.newPropertyValue = this.model?.alias
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Alias.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompanyName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newCompanyName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newCompanyName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.companyHttpService.changeName(this.model.id, this.settingsInputElementModel.newCompanyName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompanyDto) => {
      this.model = model;
      this.settingsInputElementModel.newCompanyName.originalPropertyValue = this.settingsInputElementModel.newCompanyName.changeableProperties.newPropertyValue = this.model?.name
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveAddress(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.address.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (JSON.stringify(this.settingsInputElementModel.address.changeableProperties.newPropertyValue) ===
      JSON.stringify(this.settingsInputElementModel.address.originalPropertyValue)) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.companyHttpService.setAddress(this.model.id, this.settingsInputElementModel.address.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompanyDto) => {
      this.model = model;
      this.settingsInputElementModel.address.originalPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      this.settingsInputElementModel.address.changeableProperties.newPropertyValue = JSON.parse(JSON.stringify(this.model?.address))
      observableQueueElement.successIndicator = true;
      this.updateElements()
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern der Adresse.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  saveVariable(variable: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement = new ObservableQueueElement()
    observableQueueElement.observable = this.companyHttpService.setProperty(this.model.id, variable, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: CompanyDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAndShow("Fehler", "Fehler beim Speichern des Werts.", AlertLevel.error);
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}


