import {Component, Input} from '@angular/core';
import {DownloadInformation} from "../x-models/download-information";
import {DownloadService} from "../x-services/download.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {
  @Input() DownloadInformation: DownloadInformation = new DownloadInformation();
  @Input() Route: string | undefined;
  @Input() Params: string = "";
  @Input() Data: any = undefined;
  @Input() Text: string = "Download";
  @Input() OverrideTextSize: boolean = true;

  constructor(private downloadService: DownloadService, private globalAlertService: GlobalAlertService) {
  }

  Download() {
    if (this.Route == undefined) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Beim Download ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
      this.globalAlertService.show()
      return;
    }
    this.downloadService.FileDownload(this.Route + "?" + this.Params, this.DownloadInformation, this.Data);
  }
}
