import {Component, Inject} from '@angular/core';
import {Routes} from "../../enums/routes";
import {AuthenticationService} from "../../_auth/authentication.service";
import {CookieService} from "../../_services/cookie.service";
import {CookieType} from "../../x-models/x-enums/cookie-type";
import {OwnerRoutes} from "../../_modules/owner/_models/_enums/owner-routes";
import {Constants} from "../../constants";

@Component({
  selector: 'app-navbar-owner',
  templateUrl: './navbar-owner.component.html',
  styleUrls: ['./navbar-owner.component.scss']
})
export class NavbarOwnerComponent {
  constructor(private authenticationService: AuthenticationService, @Inject("BASE_URL") private baseUrl: string,
              private cookieService: CookieService) {
  }

  protected readonly Routes = Routes;

  openHangfireDashboard(): void {
    this.cookieService.setCookie(CookieType.Required, Constants.Cookies.Hangfire, this.authenticationService.userValue!.jwt, 7, "/; domain=" + document.location.hostname);
    if (this.baseUrl.indexOf('localhost') > -1) {
      window.open(this.baseUrl + "/hangfire", "_blank");
    } else {
      window.open(location.origin + "/hangfire", "_blank");
    }
    //location.replace(location.host + "/hangfire");
  }

  protected readonly OwnerRoutes = OwnerRoutes;
}
