import {Component, OnInit} from '@angular/core';
import {BasicPageComponent} from "../../../x-components/basic-page/basic-page.component";
import {ContractHttpService} from "../x-http-requests/contract-http.service";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {ContractBase} from "../x-models/contract-base";
import {CompanyEdto} from "../x-models/company-edto";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {H} from "@angular/cdk/keycodes";
import {
  ContractInformationComponent
} from "../../owner/_components/contract-information/contract-information.component";

@Component({
  selector: 'app-contracts',
  standalone: true,
  imports: [
    BasicPageComponent,
    NgIf,
    DatePipe,
    NgForOf,
    ContractInformationComponent
  ],
  templateUrl: './contracts.component.html',
  styleUrl: './contracts.component.scss'
})
export class ContractsComponent implements OnInit {
  constructor(private contractHttpService: ContractHttpService, private gas: GlobalAlertService) {
    this.cDate = new Date();
    this.nDate = new Date();
    this.nDate.setMonth(this.nDate.getMonth() + 1);
    this.nDate.setDate(1);
    this.cDate.setDate(1);
  }

  ngOnInit(): void {
    this.load();
  }

  cDate: Date;
  nDate: Date;

  current: ContractBase | undefined = undefined;
  next: ContractBase | undefined = undefined;

  private _company: CompanyEdto | undefined = undefined;
  get company(): CompanyEdto | undefined {
    return this._company;
  }

  set company(value: CompanyEdto | undefined) {
    if (value) {
      this.current = value.contracts.find(x => x.validFrom <= this.cDate && x.validTo > this.cDate);
      this.next = value.contracts.find(x => x.validFrom <= this.nDate && x.validTo > this.nDate);
    }
    this._company = value;
  }

  load() {
    this.contractHttpService.getCurrentCompany()
      .withLoadingIndicator()
      .subscribe({
        next: company => {
          this.company = company;
        },
        error: err => {
          this.company = undefined;
          console.error(err);
          this.gas.createAndShow("Fehler", "Vertragsdaten konnten nicht geladen werden.", AlertLevel.error);
        }
      })
  }

  history(): ContractBase[] {
    if (!this._company) return [];
    return this._company.contracts.filter(x => x.validTo < this.cDate)
  }

  protected readonly H = H;
}


