<div class="pt-3 sm:flex items-center">
  <dt *ngIf="this.inputType != 'separator'"
      class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 mb-0"
      [ngClass]="{'opacity-0': this.inputType == 'button', 'hidden sm:!flex': this.inputType == 'button'}"
  >
    {{ this.settingsInputElementModel.propertyTitleFunction ? this.settingsInputElementModel.propertyTitleFunction() : this.settingsInputElementModel.propertyTitle }}
  </dt>
  <dd class="mt-2 sm:mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto items-center">

    <div class="relative sm:col-span-2  flex-full flex flex-grow items-stretch focus-within:z-10">
      <div
        *ngIf="validAndChangedInput === ChangeValueStatus.Changed && this.inputType != 'separator' || (validAndChangedInput !== ChangeValueStatus.Unchanged && this.inputType == 'checkbox' && this.settingsInputElementModel.changeableProperties.newPropertyValue == true)"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-bookmarks"
             viewBox="0 0 16 16">
          <path
            d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
        </svg>
      </div>

      <div
        *ngIf="validAndChangedInput === ChangeValueStatus.Invalid && this.inputType != 'separator' || (validAndChangedInput !== ChangeValueStatus.Unchanged && this.inputType == 'checkbox' && this.settingsInputElementModel.changeableProperties.newPropertyValue == false)"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-bookmarks"
             viewBox="0 0 16 16">
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
      </div>

      <div
        *ngIf="validAndChangedInput === ChangeValueStatus.Unchanged && this.inputType != 'separator'"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-bookmarks"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
        </svg>
      </div>

      <div class="flex w-full" *ngIf="this.inputType" [ngSwitch]="this.inputType">
        <!-- DEFAULT -->
        <input *ngSwitchDefault
               class="block w-full rounded-md border-0  pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
               #SettingsInput
               [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
               [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue"
               (ngModelChange)="this.setNewValueEvent($event)"
               [type]="this.inputType"
               [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari,'pt-2':this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local','py-1.5':!(this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local')}">

        <ng-select *ngSwitchCase="'dropdown'" class="w-full"
                   [items]="this.settingsInputElementModel.items"
                   [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue"
                   (ngModelChange)="this.setNewValueEvent($event)"
                   [clearable]="this.settingsInputElementModel.isClearable"
                   notFoundText="Keine Ergebnisse" bindLabel="item2" bindValue="item1">

        </ng-select>
        <textarea *ngSwitchCase="'textarea'"
                  rows="5"
                  class="block w-full rounded-md border-0 py-1.5 pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
                  #SettingsInput
                  [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
                  [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue"
                  (ngModelChange)="this.setNewValueEvent($event)"
                  [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari}"
        ></textarea>
        <input *ngSwitchCase="'checkbox'"
               class="block w-full text-start rounded-md border-0 py-1.5 pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
               #SettingsInput
               (click)="this.settingsInputElementModel.changeableProperties.newPropertyValue = !this.settingsInputElementModel.changeableProperties.newPropertyValue; this.validAndChangedInput = this.settingsInputElementModel.changeableProperties.newPropertyValue ? ChangeValueStatus.Changed : ChangeValueStatus.Invalid;"
               [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
               [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue ? 'wahr' : 'falsch'"
               (ngModelChange)="this.setNewValueEvent($event)"
               type="button">
        <button *ngSwitchCase="'button'"
                (click)="this.settingsInputElementModel.changeableProperties.newPropertyValue = '*changed';
              this.settingsInputElementModel.changeableProperties.editMode = true;
              hasValueChanged()"
                class="block w-full rounded-md border-0 py-1.5 pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
                #SettingsInput
                [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari}"
        >{{ this.settingsInputElementModel.propertyTitleFunction ? this.settingsInputElementModel.propertyTitleFunction() : this.settingsInputElementModel.propertyTitle }}
        </button>

        <div *ngSwitchCase="'separator'" class="w-full grid grid-cols-1 pt-4">
          <span
            *ngIf="(this.settingsInputElementModel.propertyTitle && this.settingsInputElementModel.propertyTitle != '') || (this.settingsInputElementModel.propertyTitleFunction)"
            class="text-lg fw-light">{{ this.settingsInputElementModel.propertyTitleFunction ? this.settingsInputElementModel.propertyTitleFunction() : this.settingsInputElementModel.propertyTitle }}</span>
          <div class="bg-gray-300 w-full h-0.5 rounded-1"></div>
        </div>

        <div *ngSwitchCase="'address'" class="w-full grid grid-cols-1 pl-10">
          <input #SettingsInput
                 class="block w-full rounded-t-md border-0  pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
                 [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
                 [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue?.addressLine1"
                 placeholder="Address Line 1"
                 (ngModelChange)="this.settingsInputElementModel.changeableProperties.newPropertyValue!.addressLine1 = $event;this.setNewValueEvent(this.settingsInputElementModel.changeableProperties.newPropertyValue)"
                 [type]="this.inputType"
                 [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari,'pt-2':this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local','py-1.5':!(this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local')}">
          <input #SettingsInput
                 class="block w-full border-0  pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
                 [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
                 [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue?.addressLine2"
                 placeholder="Address Line 2"
                 (ngModelChange)="this.settingsInputElementModel.changeableProperties.newPropertyValue!.addressLine2 = $event;this.setNewValueEvent(this.settingsInputElementModel.changeableProperties.newPropertyValue)"
                 [type]="this.inputType"
                 [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari,'pt-2':this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local','py-1.5':!(this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local')}">
          <input #SettingsInput
                 class="block w-full border-0  pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
                 [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
                 [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue?.zip"
                 placeholder="ZIP"
                 (ngModelChange)="this.settingsInputElementModel.changeableProperties.newPropertyValue!.zip = $event;this.setNewValueEvent(this.settingsInputElementModel.changeableProperties.newPropertyValue)"
                 [type]="this.inputType"
                 [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari,'pt-2':this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local','py-1.5':!(this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local')}">
          <input #SettingsInput
                 class="block w-full rounded-b-md border-0  pl-9 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6 bg-white disabled:!bg-gray-200 "
                 [disabled]="!this.settingsInputElementModel.changeableProperties.editMode"
                 [ngModel]="this.settingsInputElementModel.changeableProperties.newPropertyValue?.city"
                 placeholder="City"
                 (ngModelChange)="this.settingsInputElementModel.changeableProperties.newPropertyValue!.city = $event;this.setNewValueEvent(this.settingsInputElementModel.changeableProperties.newPropertyValue)"
                 [type]="this.inputType"
                 [ngClass]="{' safari-disabled-color': this.platformScannerService.getPlatform() == Platform.Safari,'pt-2':this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local','py-1.5':!(this.platformScannerService.getPlatform() == Platform.Safari&&this.inputType=='datetime-local')}">
        </div>
      </div>
    </div>

    @if (['separator'].indexOf(this.inputType) < 0) {
      <a *ngIf="!this.settingsInputElementModel.changeableProperties.editMode"
         [ngClass]="{'pointer-events-none opacity-0': ['button', 'dropdown'].indexOf(this.inputType) > -1}"
         (click)="enableEditMode()"
         class="select-none text-gray-600 hover:text-gray-900 cursor-pointer ">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
             viewBox="0 0 16 16">
          <path
            d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
        </svg>
      </a>
      <a
        *ngIf="(this.settingsInputElementModel.changeableProperties.editMode  || compare(this.settingsInputElementModel.changeableProperties.newPropertyValue, this.settingsInputElementModel.originalPropertyValue))"
        (click)=" this.resetProperty()"
        class="select-none text-gray-600 hover:text-gray-900 cursor-pointer ">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
          <path
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
        </svg>
      </a>
    }
  </dd>
</div>

<!--
<div class="pt-6 sm:flex items-center">
  <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 mb-6 sm:mb-0">{{propertyName}}</dt>
  <dd class="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto items-center">
    <input
      class="appearance-none block w-full  text-gray-700  border rounded py-3 px-4  leading-tight "
      #SettingsInput
      [disabled]="!this.changeableProperty.editMode"
      [ngModel]="displayValue"
      (ngModelChange)="this.setNewValueEvent($event)"
      [ngClass]="{'bg-green-200': validAndChangedInput === ChangeValueStatus.Changed, 'bg-red-200': validAndChangedInput === ChangeValueStatus.Invalid, 'bg-gray-200': validAndChangedInput === ChangeValueStatus.Unchanged}"
    >
    <a *ngIf="!this.changeableProperty.editMode"
       (click)="enableEditMode()"
       class="select-none text-gray-600 hover:text-gray-900 cursor-pointer ">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
           viewBox="0 0 16 16">
        <path
          d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
      </svg>
    </a>
    <a
      *ngIf="this.changeableProperty.editMode || this.changeableProperty.newPropertyValue != this.originalValue"
      (click)=" this.resetProperty()"
      class="select-none text-gray-600 hover:text-gray-900 cursor-pointer ">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pen"
           viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
      </svg>
    </a>
  </dd>
</div>
-->
