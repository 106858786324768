import {Component, Inject, OnInit} from '@angular/core';
import {SchedulerObjectType} from "../x-models/x-enums/scheduler-object-type";
import {SchedulerItemNl} from "../x-models/scheduler-item-nl";
import {SchedulerGroupDto} from "../x-models/scheduler-group-dto";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {SchedulerHttpService} from "../x-services/scheduler-http.service";
import {AlertLevel} from "../../../enums/alert-level";
import {SchedulerReoccurrence} from "../x-models/x-enums/scheduler-reoccurrence";
import {NewSchedulerGroup} from "../x-models/new-scheduler-group";
import {EventHttpService} from "../../../management/x-http-requests/event-http.service";
import {EventDto} from "../../../models/event/event-dto";
import {ConfigurationHttpService} from "../../../http-requests/configuration-http.service";
import {ConfigurationDto} from "../../../models/configuration-dto";
import {NewGroupStep} from "../x-models/x-enums/new-group-step";
import {TypeOrder} from "../x-models/x-enums/type-order";
import {CalculationTargetType} from "../x-models/x-enums/calculation-target-type";
import {CalculationType} from "../x-models/x-enums/calculation-type";
import {CalculationOperation} from "../x-models/x-enums/calculation-operation";
import {CompositionDto} from "../../../models/composition-dto";
import {CompositionHttpService} from "../../../http-requests/composition-http.service";
import {Routes} from "../../../enums/routes";
import {AutomationRoutes} from "../x-models/x-enums/automation-routes";
import {RoundingType} from "../x-models/x-enums/rounding-type";
import {CompositionTypeHttpService} from "../../../http-requests/composition-type-http.service";
import {CompositionTypeDto} from "../../../models/composition-type/composition-type-dto";

@Component({
  selector: 'app-automation-manager',
  templateUrl: './automation-manager.component.html',
  styleUrls: ['./automation-manager.component.scss']
})
export class AutomationManagerComponent implements OnInit {
  constructor(private globalAlertService: GlobalAlertService, private schedulerHttpService: SchedulerHttpService,
              private eventHttpService: EventHttpService, private configurationHttpService: ConfigurationHttpService,
              private compositionHttpService: CompositionHttpService, private compositionTypeHttpService: CompositionTypeHttpService,
              @Inject("BASE_URL") public baseUrl: string) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.eventHttpService.list().subscribe(x => {
      this.events = x;
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Events konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.events = undefined;
    })

    this.configurationHttpService.list().subscribe(x => {
      this.configurations = x;
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Konfigurationen konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.configurations = undefined;
    })

    this.compositionHttpService.list("", "", false, true).subscribe(x => {
      this.compositions = x;
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Objekte konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.compositions = undefined;
    })

    this.compositionTypeHttpService.List().subscribe(x => {
      this.compositionTypes = x;
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Artikel-Kategorien konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.compositionTypes = undefined;
    })

    this.schedulerHttpService.getSchedulerObjects().subscribe(x => {
      this.schedulerGroups = x.item1!;
      this.schedulerItems = x.item2!;
      this.filter();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Automationen konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      this.schedulerGroups = undefined;
      this.schedulerItems = undefined;
    })
  }

  showDisabled = false;

  showDisabledChanged() {
    if (!this.showDisabled && this.selectedSchedulerGroup) {
      if (!this.selectedSchedulerGroup.active) this.selectedSchedulerGroup = undefined;
    }
    this.filter();
  }

  filter() {
    if (!this.schedulerItems) {
      this.filteredSchedulerItems = undefined;
    } else {
      this.filteredSchedulerItems = Object.assign([], this.schedulerItems.filter(x => x.active || this.showDisabled));
      this.filteredSchedulerItems = this.filteredSchedulerItems.sort((a, b) => b.first.valueOf() - a.first.valueOf());
    }

    if (!this.schedulerGroups) {
      this.filteredSchedulerGroups = undefined;
    } else {
      this.filteredSchedulerGroups = Object.assign([], this.schedulerGroups.filter(x => x.active || this.showDisabled));
      this.filteredSchedulerGroups = this.filteredSchedulerGroups.sort((a, b) => b.first.valueOf() - a.first.valueOf());
    }
  }


  isVisible(step: NewGroupStep): boolean {
    if (step.valueOf() > 0 && !this.newSchedulerGroup?.additionalIds["EventId"]) return false;
    if (step.valueOf() > 1 && !this.newSchedulerGroup?.additionalIds["ConfigurationId"]) return false;
    if (step.valueOf() > 2 && this.newSchedulerGroup?.schedulerReoccurrence == undefined) return false;
    if (step.valueOf() > 4 && this.newSchedulerGroup?.typeOrder == undefined) return false;
    if (step.valueOf() > 5 && this.newSchedulerGroup?.calculationTargetType == undefined) return false;
    if (step.valueOf() > 6 && (this.newSchedulerGroup?.calculationType == undefined || this.newSchedulerGroup?.calculationOperation == undefined)) return false;
    return true;
  }

  setItems(wForContinue: boolean = true): boolean {
    if (!wForContinue) return this.isVisible(NewGroupStep.Items);
    return this.isVisible(NewGroupStep.Items) && this.continue;
  }

  eventName(id: string): string {
    return this.events?.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  compositionName(id: string): string {
    return this.compositions?.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  compositionTypeName(id: string): string {
    return this.compositionTypes?.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  configurationName(id: string): string {
    return this.configurations?.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  configurations: ConfigurationDto[] | undefined;
  events: EventDto[] | undefined;
  compositions: CompositionDto[] | undefined;
  compositionTypes: CompositionTypeDto[] | undefined;

  selectedComposition: CompositionDto | undefined | null = null;
  selectedCompositionType: CompositionTypeDto | undefined | null = null;
  selectedSchedulerGroup: SchedulerGroupDto | undefined = undefined;

  schedulerItems: SchedulerItemNl[] | undefined;
  filteredSchedulerItems: SchedulerItemNl[] | undefined;
  schedulerGroups: SchedulerGroupDto[] | undefined;
  filteredSchedulerGroups: SchedulerGroupDto[] | undefined;

  addComposition(id: string) {
    if (!this.newSchedulerGroup || !id) return;

    if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Percentage) this.newSchedulerGroup.calculationValues.push(100);
    else if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Product) this.newSchedulerGroup.calculationValues.push(1);
    else if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Fixed) this.newSchedulerGroup.calculationValues.push(this.compositions?.find(x => x.id == id)?.price ?? 0);
    else if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Sum) this.newSchedulerGroup.calculationValues.push(0);
    else return;

    this.newSchedulerGroup.targetIds.push(id);
    this.selectedComposition = null;
  }

  removeComposition(index: number) {
    this.newSchedulerGroup?.targetIds.splice(index, 1);
    this.newSchedulerGroup?.calculationValues.splice(index, 1);
  }

  addCompositionType(id: string) {
    if (!this.newSchedulerGroup || !id) return;

    if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Percentage) this.newSchedulerGroup.calculationValues.push(100);
    else if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Product) this.newSchedulerGroup.calculationValues.push(1);
    else if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Fixed) this.newSchedulerGroup.calculationValues.push(0);
    else if (this.newSchedulerGroup.calculationOperation == CalculationOperation.Sum) this.newSchedulerGroup.calculationValues.push(0);
    else return;

    this.newSchedulerGroup.targetIds.push(id);
    this.selectedCompositionType = null;
  }

  sendSchedulerGroup() {
    if (!this.newSchedulerGroup) return;
    if (this.newSchedulerGroup.interval < 1 && this.newSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring && this.baseUrl.indexOf("localhost") == -1) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Intervalle unter 1 Minute können bei schlechten Internetverhältnissen ggf. zu gehäuften Problemen führen und sind deshalb deaktiviert.", AlertLevel.warning, 5000);
      this.globalAlertService.show()
      return;
    }
    this.schedulerHttpService.scheduleGroup(this.newSchedulerGroup).subscribe(x => {
      this.globalAlertService.createAlertBannerModel("Erfolg", "Automation wurde erfolgreich hinzugefügt!", AlertLevel.success, 2000);
      this.globalAlertService.show();
      this.newSchedulerGroup = new NewSchedulerGroup();
      this.continue = false;
      this.schedulerGroups?.splice(0, 0, x);
      this.selectedSchedulerGroup = x;
      this.filter();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Automation konnte nicht gespeichert werden!", AlertLevel.error, 2000);
      this.globalAlertService.show()
    })
  }

  deleteSchedulerGroup() {
    if (!this.selectedSchedulerGroup) return;
    let c = confirm("Ausgewaähltes Element löschen?");
    if (!c) return;

    this.schedulerHttpService.delete(this.selectedSchedulerGroup.id).subscribe(x => {
      this.schedulerGroups?.splice(this.schedulerGroups?.indexOf(this.selectedSchedulerGroup!), 1, x);
      this.selectedSchedulerGroup = undefined;
      this.globalAlertService.createAlertBannerModel("Erfolg", "Das Element wurde erfolgreich gelöscht.", AlertLevel.success, 3000);
      this.globalAlertService.show();
      this.filter();
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Beim Löschen ist ein Fehler aufgetreten.", AlertLevel.error, 3000);
      this.globalAlertService.show()
    })
  }

  continue: boolean = false;

  schedulerObjectType = [
    //{id: SchedulerObjectType.Item, name: "Item"},
    {id: SchedulerObjectType.Group, name: "Group"},
  ]

  getSchedulerObjectTypeName(id: SchedulerObjectType): string {
    return this.schedulerObjectType.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  schedulerReoccurrence = [
    {id: SchedulerReoccurrence.Once, name: "Einmalig", implemented: false},
    {id: SchedulerReoccurrence.Reoccurring, name: "Intervall", implemented: true},
  ]

  getSchedulerReoccurrenceName(id: SchedulerReoccurrence): string {
    return this.schedulerReoccurrence.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  typeOrder = [
    {id: TypeOrder.None, name: "Keine", implemented: false},
    {id: TypeOrder.Count, name: "Anzahl", implemented: true},
  ]

  getTypeOrderName(id: TypeOrder): string {
    return this.typeOrder.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  calculationTargetType = [
    {id: CalculationTargetType.Composition, name: "Objekt"},
    {id: CalculationTargetType.CompositionType, name: "Artikel-Kategorie"}
  ]

  getCalculationTargetTypeName(id: CalculationTargetType): string {
    return this.calculationTargetType.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  calculationType = [
    //{id: CalculationType.CalculationBasePrice, name: "Standardpreis"},
    {id: CalculationType.CalculationAlternativePrice, name: "Aktionspreis"},
  ]

  getCalculationTypeName(id: CalculationType): string {
    return this.calculationType.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  calculationOperation = [
    {id: CalculationOperation.Fixed, name: "Festpreis"},
    //{id: CalculationOperation.Sum, name: "Summe"},
    //{id: CalculationOperation.Product, name: "Multiplikation"},
    {id: CalculationOperation.Percentage, name: "Prozentsatz"},
  ]

  getCalculationOperationName(id: CalculationOperation): string {
    return this.calculationOperation.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  roundingType = [
    {id: RoundingType.None, name: "nicht runden"},
    {id: RoundingType.TenthNormal, name: "Zehntel, kaufmännisch"},
    {id: RoundingType.TenthFloor, name: "Zehntel, abrunden"},
    {id: RoundingType.TenthCeiling, name: "Zehntel, auftrunden"},
    {id: RoundingType.OnesNormal, name: "Einer, kaufmännisch"},
    {id: RoundingType.OnesFloor, name: "Einer, abrunden"},
    {id: RoundingType.OnesCeiling, name: "Einer, aufrunden"},
  ]

  getRoundingType(id: RoundingType): string {
    return this.roundingType.find(x => x.id == id)?.name ?? "[FEHLER]";
  }

  newSchedulerGroup: NewSchedulerGroup | undefined = new NewSchedulerGroup();

  selectedObjectType: SchedulerObjectType = SchedulerObjectType.Group;
  protected readonly SchedulerObjectType = SchedulerObjectType;
  protected readonly SchedulerReoccurrence = SchedulerReoccurrence;
  protected readonly NewGroupStep = NewGroupStep;
  protected readonly CalculationTargetType = CalculationTargetType;
  protected readonly TypeOrder = TypeOrder;
  protected readonly CalculationType = CalculationType;
  protected readonly Routes = Routes;
  protected readonly AutomationRoutes = AutomationRoutes;
}
