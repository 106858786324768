import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {CbConfig} from "../models/configuration-builder";
import {Observable} from "rxjs";
import {ConfigurationDto} from "../models/configuration-dto";
import {ConfigurationSpecial} from "../models/composition/composition-special";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationbuilderHttpService {

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {


  }

  list(): Observable<CbConfig[]> {
    return this.http.get<CbConfig[]>(this.baseUrl + ServerEndpoints.Inventory_ConfigurationBuilder_List);
  }

  add(name: string, template: string): Observable<CbConfig> {
    return this.http.post<any>(this.baseUrl + ServerEndpoints.Inventory_ConfigurationBuilder_Add + "?template=" + template, {name: name});
  }

  setSpecial(special: ConfigurationSpecial): any {
    return this.http.put<any>(this.baseUrl + ServerEndpoints.Inventory_ConfigurationBuilder_SetSpecial, {
      "Id": special.id,
      "Active": special.active,
      "Name": special.name,
      "Color": special.color,
      "ConfigurationId": special.configurationId
    });
  }
}
