import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Tuple} from "../../../models/_generic/tuple";
import {SchedulerGroupDto} from "../x-models/scheduler-group-dto";
import {CalculationTargetType} from "../x-models/x-enums/calculation-target-type";
import {SchedulerObjectType} from "../x-models/x-enums/scheduler-object-type";
import {SchedulerReoccurrence} from "../x-models/x-enums/scheduler-reoccurrence";
import {SchedulerItemNl} from "../x-models/scheduler-item-nl";
import {CalculationType} from "../x-models/x-enums/calculation-type";
import {CalculationOperation} from "../x-models/x-enums/calculation-operation";
import {NewSchedulerGroup} from "../x-models/new-scheduler-group";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class SchedulerHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  getSchedulerGroup<T>(id: string, type: CalculationTargetType): Observable<Tuple<SchedulerGroupDto, T>> {
    return this.httpClient.get<Tuple<SchedulerGroupDto, T>>(this.baseUrl + ServerEndpoints.Automation_Scheduler_GetSchedulerGroup + "?id=" + id + "&calculationTargetType=" + type);
  }

  getSchedulerObjects(schedulerObjectType: SchedulerObjectType | undefined = undefined, schedulerReoccurrence: SchedulerReoccurrence | undefined = undefined,
                      calculationType: CalculationType | undefined = undefined, calculationTargetType: CalculationTargetType | undefined = undefined,
                      calculationOperation: CalculationOperation | undefined = undefined): Observable<Tuple<SchedulerGroupDto[], SchedulerItemNl[]>> {
    let url = this.baseUrl + ServerEndpoints.Automation_Scheduler_GetSchedulerObjects + "?";
    if (schedulerObjectType) url += `schedulerObjectType=${schedulerObjectType}&`
    if (schedulerReoccurrence) url += `schedulerReoccurrence=${schedulerReoccurrence}&`
    if (calculationType) url += `calculationType=${calculationType}&`
    if (calculationTargetType) url += `calculationTargetType=${calculationTargetType}&`
    if (calculationOperation) url += `calculationOperation=${calculationOperation}&`

    return this.httpClient.get<Tuple<SchedulerGroupDto[], SchedulerItemNl[]>>(url);
  }

  scheduleGroup(newSchedulerGroup: NewSchedulerGroup): Observable<SchedulerGroupDto> {
    let obj: any = JSON.parse(JSON.stringify(newSchedulerGroup));
    obj.first = newSchedulerGroup.first.toISOString();
    obj.last = newSchedulerGroup.last.toISOString();
    obj.interval = obj.interval * 60;

    return this.httpClient.post<SchedulerGroupDto>(this.baseUrl + ServerEndpoints.Automation_Scheduler_ScheduleGroup, obj);
  }

  delete(id: string): Observable<SchedulerGroupDto> {
    return this.httpClient.delete<SchedulerGroupDto>(this.baseUrl + ServerEndpoints.Automation_Scheduler_DeleteGroup + "?id=" + id);
  }
}
