import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {UserDto} from "../../models/admin_owner/user/user-dto";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  constructor(private httpClient: HttpClient,
              @Inject("BASE_URL") private baseUrl: string,
              @Inject("BASE_HEADERS") private baseHeaders: HttpHeaders) {
  }


  list(company: string, shard: string): Observable<UserDto[]> {
    return this.httpClient.get<UserDto[]>(this.baseUrl + ServerEndpoints.Management_User_List + "?company=" + company + "&shard=" + shard, {headers: this.baseHeaders});
  }

  add(name: string, password: string, shard: string, company: string, roles: string[] = [], groups: string[] = [],
      protect: boolean = false, admin: boolean = false, owner: boolean = false): Observable<UserDto> {
    return this.httpClient.post<UserDto>(this.baseUrl + ServerEndpoints.Management_User_Create, {
      "Name": name,
      "Password": password,
      "Shard": shard,
      "Company": company,
      "Roles": roles,
      "Groups": groups,
      "Protected": protect,
      "Owner": owner,
      "Admin": admin
    }, {headers: this.baseHeaders});
  }

  remove(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Management_User_Delete + "?id=" + id, {headers: this.baseHeaders});
  }
}
