import {Component} from '@angular/core';
import {Routes} from "../../enums/routes";
import {AuthenticationService} from "../../_auth/authentication.service";

@Component({
  selector: 'app-navbar-user',
  templateUrl: './navbar-user.component.html',
  styleUrls: ['./navbar-user.component.scss']
})
export class NavbarUserComponent {
  constructor(public AuthenticationService: AuthenticationService) {
  }

  protected readonly Routes = Routes;
}
