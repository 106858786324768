import { Component } from '@angular/core';
import {CookieService} from "../../_services/cookie.service";

@Component({
  selector: 'app-cookie-settings',
  templateUrl: './cookie-settings.component.html',
  styleUrls: ['./cookie-settings.component.scss']
})
export class CookieSettingsComponent {
  constructor(public CookieService: CookieService) {
  }
}
