import { Component, OnInit } from '@angular/core';
import {UserDto} from "../../models/admin_owner/user/user-dto";
import {CompanyDto} from "../../models/admin_owner/company/company-dto";
import {ShardDto} from "../../models/admin_owner/shard/shard-dto";
import { HttpErrorResponse } from "@angular/common/http";
import {UserHttpService} from "../x-http-requests/user-http.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class AdminUserComponent implements OnInit {
  users?: UserDto[];
  filtered_users?: UserDto[];
  name: string = "";
  password: string = "";
  cPassword: string = "";
  searchText: string = "";
  error?: HttpErrorResponse;
  creator: boolean = false;
  admin: boolean = false;
  login: boolean = true;

  constructor(private userHttpService: UserHttpService,
              private route: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  AssignCopy(): void {
    this.filtered_users = Object.assign([], this.users);
  }

  FilterItems(value: string) {
    this.searchText = value;
    if (!value) this.AssignCopy();
    else {
      this.filtered_users = Object.assign([], this.users?.filter(user =>
        user.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ));
    }
  }

  GetLoginUsers(login: boolean): UserDto[] {
    return Object.assign([], this.filtered_users?.filter(user =>
      user.login == login
    ))
  }

  LoadData() {
    this.userHttpService.list().subscribe(x => {
      this.users = x;
      this.AssignCopy();
    }, error => {
      console.error(error);
      this.error = error;
    });
  }

  AddUser() {
    if (this.cPassword != this.password) {
      this.error = new HttpErrorResponse({
        statusText: "Passwords are not matching!"
      });
      return;
    }
    this.userHttpService.add(this.name, this.password, this.admin, this.login).subscribe(x => {
      if (this.users == undefined) this.users = [];
      this.error = undefined;
      this.users.splice(0,0,x);
      this.FilterItems(this.searchText);
      this.name = "";
      this.password = "";
      this.cPassword = "";
      this.admin = false;
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  RemoveUser(user: UserDto) {
    let confirmation = confirm("Are you sure you want to delete the following user:\n"+user.name+"\n"+user.id);
    if (!confirmation) return;
    this.userHttpService.delete(user.id).subscribe(x => {
      this.error = undefined;
      this.users?.splice(this.users?.indexOf(user), 1);
      this.FilterItems(this.searchText);
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  ngOnInit(): void {
    this.LoadData();
  }
}
