import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {RoleDto} from "../../models/role/role-dto";
import {RoleNl} from "../../models/role/role-nl";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class RoleHttpService {

  constructor(private httpClient: HttpClient, @Inject("BASE_URL") private baseUrl: string) {
  }

  get(id: string): Observable<RoleDto> {
    return this.httpClient.get<RoleDto>(this.baseUrl + ServerEndpoints.Administration_Role_Get + "?id=" + id);
  }

  list(): Observable<RoleDto[]> {
    return this.httpClient.get<RoleDto[]>(this.baseUrl + ServerEndpoints.Administration_Role_List);
  }

  add(name: string, permissions: string[] = []): Observable<RoleDto> {
    return this.httpClient.post<RoleDto>(this.baseUrl + ServerEndpoints.Administration_Role_Add, {
      "Name": name,
      "Permissions": permissions
    });
  }

  delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Administration_Role_Delete + "?id=" + id);
  }

  togglePermission(role: string, permission: string, state: boolean | null = null): Observable<RoleDto> {
    return this.httpClient.put<RoleDto>(this.baseUrl + ServerEndpoints.Administration_Role_TogglePermission + "?role=" + role + "&permission=" + permission + "&state=" + state, {});
  }

  addUser(role: string, user: string | null = null, users: string[] | null = null): Observable<RoleDto> {
    return this.httpClient.put<RoleDto>(this.baseUrl + ServerEndpoints.Administration_Role_AddUser + "?role=" + role + "&user=" + user, {});
  }

  removeUser(role: string, user: string | null = null, users: string[] | null = null): Observable<RoleDto> {
    return this.httpClient.put<RoleDto>(this.baseUrl + ServerEndpoints.Administration_Role_RemoveUser + "?role=" + role + "&user=" + user, {});
  }

  togglePublic(role: string, state: boolean | undefined = undefined): Observable<RoleDto> {
    return this.httpClient.put<RoleDto>(this.baseUrl + "/management/role/TogglePublic?roleId=" + role + "&state=" + (state ?? ""), {});
  }
}
