import {Component, OnInit} from '@angular/core';
import {MigrationsAssistantHttpService} from "../x-http-requests/migrations-assistant-http.service";
import {EnvironmentSetting} from "../../owner/x-models/environment-setting";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

@Component({
  selector: 'app-patches',
  templateUrl: './patches.component.html',
  styleUrls: ['./patches.component.scss']
})
export class PatchesComponent implements OnInit {

  constructor(private migrationsAssistantHttpService: MigrationsAssistantHttpService,
              private gas: GlobalAlertService) { }

  ngOnInit(): void {
    this.LoadData()
  }

  /* Variables */
  patchLoadingError: boolean = false;
  patches: string[] | undefined;
  rPatches: EnvironmentSetting[] | undefined;

  LoadData(): void {
    this.migrationsAssistantHttpService.GetPatches().subscribe(x => {
      this.patches = x.item1;
      this.rPatches = x.item2;
      this.patchLoadingError = false;
    }, error => {
      this.gas.createAlertBannerModel("Fehler", "Patches konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.gas.show();
      this.patchLoadingError = true;
    });
  }

  RPatchesContain(patch: string): boolean {
    if (!this.rPatches) return false;
    return this.rPatches.findIndex(p => p.id == patch) > -1;
  }

  RPatch(patch: string) : EnvironmentSetting | undefined {
    return this.rPatches?.find(p => p.id == patch);
  }

  StartPatch(patch: string) {
    let text = prompt("Bitte zur Bestätigung eingeben: " + patch.split("_")[0].toUpperCase() + "\n\nACHTUNG: Dies kann NICHT rückgängig gemacht werden.\n");
    this.migrationsAssistantHttpService.RunPatch(patch, text == patch.split("_")[0].toUpperCase()).subscribe(x => {
      this.gas.createAlertBannerModel("Erfolg", `Patch ${patch} wurde erfolgreich durchgeführt.`, AlertLevel.success, 2000);
      this.gas.show();
      this.LoadData();
    }, (error) => {
      this.gas.createAlertBannerModel("Fehler", `Patch ${patch} konnte nicht durchgeführt werden. (${error})`, AlertLevel.error, 2000);
      this.gas.show();
    })
  }
}
