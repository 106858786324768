<div class="px-4 sm:px-6 lg:!px-0 flex flex-col space-y-4">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Automations-Manager</h1>
    </div>
  </div>
  <div class="absolute inset-8 flex flex-col gap-x-3">
    <div class="absolute inset-0 lg:hidden flex">
      <span class="text-2xl m-auto text-center">Nicht verfügbar, Bildschirmbreite nicht ausreichend.</span>
    </div>
    <div class="absolute inset-0 top-4 hidden lg:block">
      <div class="border-b border-gray-200 h-14">
        <div class="sm:flex sm:items-baseline">
          <h3 class="text-base font-semibold leading-6 text-gray-900">Automationen</h3>
          <div class="mt-4 sm:ml-10 sm:mt-0">
            <nav class="-mb-px flex space-x-8">
              <!-- Current: "border-theme-primary-900 text-theme-primary-700", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
              <a *ngIf="filteredSchedulerItems"
                 class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-1 text-sm font-medium cursor-pointer select-none"
                 [ngClass]="{'border-theme-primary-700 text-theme-primary-700 hover:text-theme-primary-900': selectedObjectType == SchedulerObjectType.Item}"
                 (click)="selectedObjectType = SchedulerObjectType.Item;selectedSchedulerGroup = undefined">Single</a>
              <a *ngIf="filteredSchedulerGroups"
                 class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-1 text-sm font-medium cursor-pointer select-none"
                 [ngClass]="{'border-theme-primary-700 text-theme-primary-700 hover:text-theme-primary-900': selectedObjectType == SchedulerObjectType.Group}"
                 (click)="selectedObjectType = SchedulerObjectType.Group;selectedSchedulerGroup = undefined">Multi</a>
            </nav>
          </div>
          <div class="flex space-x-1 place-self-end pb-1 pl-3 ml-auto">
            <input id="show-inactive" type="checkbox" class="" [(ngModel)]="showDisabled"
                   (ngModelChange)="showDisabledChanged()">
            <label for="show-inactive" class="cursor-pointer select-none">Inaktive Automationen anzeigen</label>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-4 p-2 top-14 absolute inset-x-0 bottom-0">
        <div class="flex flex-col overflow-y-auto pr-2 relative border-r-2 border-gray-500 space-y-2 p-2"
             *ngIf="filteredSchedulerGroups && selectedObjectType == SchedulerObjectType.Group">
          <div *ngFor="let group of filteredSchedulerGroups">
            <div class="rounded-2xl bg-white shadow-lg flex flex-col p-2 cursor-pointer select-none hover:bg-gray-100"
                 (click)="selectedSchedulerGroup = selectedSchedulerGroup == group ? undefined : group"
                 [ngClass]="{'border-2 border-theme-primary-700': group == selectedSchedulerGroup}">
              <span class="text-red-600 select-none text-sm font-bold" *ngIf="!group.active">[ INAKTIV ]</span>
              <span class="text-green-600 select-none text-sm font-bold"
                    *ngIf="group.active && showDisabled">[ AKTIV ]</span>
              <span class="font-bold">{{ eventName(group.additionalIds["EventId"]) }}</span>
              <span
                class="font-light text-sm">{{ group.first | date: "dd.MM.yyyy HH:mm" }}{{ (group.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring ? " - " + (group.last | date: 'dd.MM.yyyy HH:mm') : '') }}</span>
              <span class="font-light text-sm">{{ configurationName(group.additionalIds["ConfigurationId"]) }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col relative border-r-2 border-gray-500"
             *ngIf="filteredSchedulerItems && selectedObjectType == SchedulerObjectType.Item">
          <div *ngFor="let item of filteredSchedulerItems">

          </div>
        </div>

        <div class="col-span-3 border-l-2 border-gray-300 -ml-px space-y-6 overflow-y-auto p-3"
             *ngIf="selectedSchedulerGroup && events && configurations">
          <div class="space-y-10 divide-y divide-gray-900/10">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <div class="w-full py-2 flex">
                  <span class="text-red-600 hover:text-red-800 cursor-pointer select-none"
                        *ngIf="selectedSchedulerGroup.active" (click)="deleteSchedulerGroup()">Löschen</span>
                  <span class="text-red-600 select-none" *ngIf="!selectedSchedulerGroup.active">[ INAKTIV ]</span>
                </div>
                <p><span class="font-bold">ID:</span> {{ selectedSchedulerGroup.id }}</p>
                <p><span
                  class="font-bold">Event:</span> {{ eventName(selectedSchedulerGroup.additionalIds["EventId"]!) }}</p>
                <p><span
                  class="font-bold">Preisprofil:</span> {{ configurationName(selectedSchedulerGroup.additionalIds["ConfigurationId"]!) }}
                </p>
                <p><span class="font-bold">Start:</span> {{ selectedSchedulerGroup.first | date: "dd.MM.yyyy HH:mm" }}
                  Uhr</p>
                <p *ngIf="selectedSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring"><span
                  class="font-bold">Ende:</span> {{ selectedSchedulerGroup.last | date: "dd.MM.yyyy HH:mm" }} Uhr</p>
                <p *ngIf="selectedSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring"><span
                  class="font-bold">Intervall:</span> {{ selectedSchedulerGroup.interval / 60 }} Minute(n)</p>
                <p><span class="font-bold">Sortierung:</span> {{ getTypeOrderName(selectedSchedulerGroup.typeOrder!) }}
                </p>
                <p><span
                  class="font-bold">Objekttyp:</span> {{ getCalculationTargetTypeName(selectedSchedulerGroup.calculationTargetType!) }}
                </p>
                <p><span
                  class="font-bold">Berechnugnsziel:</span> {{ getCalculationTypeName(selectedSchedulerGroup.schedulerItems[0].calculationType!) }}
                </p>
                <p><span
                  class="font-bold">Berechnung:</span> {{ getCalculationOperationName(selectedSchedulerGroup.schedulerItems[0].calculationOperation!) }}
                </p>
                <p><span
                  class="font-bold">Runden:</span> {{ getRoundingType(selectedSchedulerGroup.schedulerItems[0].roundingType) }}
                </p>
              </div>

              <div class="bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
                <div class="px-4 py-2 space-y-2"
                     *ngIf="selectedSchedulerGroup.calculationTargetType == CalculationTargetType.Composition && compositions">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                       *ngFor="let item of selectedSchedulerGroup.schedulerItems;let index = index;">
                    <div class="sm:col-span-3">
                      <label for="target_{{ item.id }}"
                             class="block text-sm font-medium leading-6 text-gray-900">Objekt</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input id="target_{{ item.id }}" type="text" disabled
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="compositionName(item.calculationTargetId)">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label for="value_{{ item.id }}" class="block text-sm font-medium leading-6 text-gray-900">Zuordnungswert</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input disabled id="value_{{ item.id }}" type="number"
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="selectedSchedulerGroup.calculationValues[index]">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-2 space-y-2"
                     *ngIf="selectedSchedulerGroup.calculationTargetType == CalculationTargetType.CompositionType && compositionTypes">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                       *ngFor="let item of selectedSchedulerGroup.schedulerItems;let index = index;">
                    <div class="sm:col-span-3">
                      <label for="target_{{ item.id }}" class="block text-sm font-medium leading-6 text-gray-900">Artikel-Kategorie</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input id="target_{{ item.id }}" type="text" disabled
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="compositionTypeName(item.calculationTargetId)">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label for="value_{{ item.id }}" class="block text-sm font-medium leading-6 text-gray-900">Zuordnungswert</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input disabled id="value_{{ item.id }}" type="number"
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="selectedSchedulerGroup.calculationValues[index]">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full pt-2 flex flex-col" *ngIf="selectedSchedulerGroup.active">
              <span class="text-theme-primary-900">Verfügbare Ansichten</span>
              <div class="flex w-full space-x-4"
                   *ngIf="selectedSchedulerGroup.schedulerItems.length > 0 && selectedSchedulerGroup.typeOrder == TypeOrder.Count && selectedSchedulerGroup.calculationTargetType == CalculationTargetType.Composition && selectedSchedulerGroup.schedulerItems[0].calculationType == CalculationType.CalculationAlternativePrice">
                <a target="_blank"
                   [href]="'/' + Routes.AutomationModule + '/' + AutomationRoutes.ViewStockMarket + '?schedulergroup=' + selectedSchedulerGroup.id"
                   class="text-gray-500 hover:text-gray-800 hover:underline cursor-pointer select-none">
                  Stock Market
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-3 border-l-2 border-gray-300 -ml-px space-y-6 overflow-y-auto p-3"
             *ngIf="schedulerGroups && selectedObjectType == SchedulerObjectType.Group && newSchedulerGroup && events && configurations && !selectedSchedulerGroup">
          <div class="space-y-10 divide-y divide-gray-900/10" *ngIf="!setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Event</h2>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="event" class="block text-sm font-medium leading-6 text-gray-900">Ziel-Event</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="event" class="w-full" [items]="events" bindLabel="name" bindValue="id"
                                     [(ngModel)]="newSchedulerGroup.additionalIds['EventId']">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="space-y-10 divide-y divide-gray-900/10"
               *ngIf="isVisible(NewGroupStep.Configuration) && !setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Preisprofil</h2>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="configuration" class="block text-sm font-medium leading-6 text-gray-900">Ziel-Preisprofil</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="configuration" class="w-full" [items]="configurations" bindLabel="name"
                                     bindValue="id" [(ngModel)]="newSchedulerGroup.additionalIds['ConfigurationId']">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="space-y-10 divide-y divide-gray-900/10"
               *ngIf="isVisible(NewGroupStep.Reoccurrence) && !setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Wiederholung</h2>
                <ul class="mt-1 text-sm leading-6 text-gray-600 list-disc">
                  <li><span class="font-bold">Einmalig:</span> Ausführung zum Startzeitpunkt</li>
                  <li><span class="font-bold">Intervall:</span> Ausführung alle X Minuten nach dem Startzeitpunkt bis
                    zum Endzeitpunkt
                  </li>
                </ul>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="schedulerReoccurrence" class="block text-sm font-medium leading-6 text-gray-900">Wiederholung</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="schedulerReoccurrence" class="w-full" [items]="schedulerReoccurrence"
                                     bindLabel="name" bindValue="id"
                                     [(ngModel)]="newSchedulerGroup.schedulerReoccurrence">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="space-y-10 divide-y divide-gray-900/10" *ngIf="isVisible(NewGroupStep.Time) && !setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Zeiteinstellungen</h2>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-3"
                         [class.sm:col-span-6]="newSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Once">
                      <label for="first" class="block text-sm font-medium leading-6 text-gray-900">Start</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input class="w-full px-2 py-1 border-gray-300 rounded-md border-2" type="datetime-local"
                                 id="first" [(ngModel)]="newSchedulerGroup.firstStr">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-3"
                         *ngIf="newSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring">
                      <label for="last" class="block text-sm font-medium leading-6 text-gray-900">Ende</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input class="w-full px-2 py-1 border-gray-300 rounded-md border-2" type="datetime-local"
                                 id="last" [(ngModel)]="newSchedulerGroup.lastStr">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-6"
                         *ngIf="newSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring">
                      <label for="interval" class="block text-sm font-medium leading-6 text-gray-900">Interval (in
                        Minuten)</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input class="w-full px-2 py-1 border-gray-300 rounded-md border-2" type="number" step="1"
                                 id="interval" [(ngModel)]="newSchedulerGroup.interval">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="space-y-10 divide-y divide-gray-900/10" *ngIf="isVisible(NewGroupStep.Sorting) && !setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Sortiereinstellungen</h2>
                <ul class="mt-1 text-sm leading-6 text-gray-600 list-disc">
                  <li><span class="font-bold">Keine:</span> Elemente sind Werten zugeordnet.</li>
                  <li><span class="font-bold">Anzahl:</span> Sortiert nach der Anzahl der verkauften Getränke während
                    des Events
                  </li>
                </ul>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="typeOrder"
                             class="block text-sm font-medium leading-6 text-gray-900">Sortierung</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="typeOrder" class="w-full" [items]="typeOrder" bindLabel="name" bindValue="id"
                                     [(ngModel)]="newSchedulerGroup.typeOrder">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="space-y-10 divide-y divide-gray-900/10" *ngIf="isVisible(NewGroupStep.Target) && !setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Zielobjekteinstellungen</h2>
                <ul class="mt-1 text-sm leading-6 text-gray-600 list-disc">
                  <li><span class="font-bold">Artikel:</span> Getränke, Speisen, ...</li>
                </ul>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="targetType"
                             class="block text-sm font-medium leading-6 text-gray-900">Zielobjekt</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="targetType" class="w-full" [items]="calculationTargetType" bindLabel="name"
                                     bindValue="id" [(ngModel)]="newSchedulerGroup.calculationTargetType">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="space-y-10 divide-y divide-gray-900/10"
               *ngIf="isVisible(NewGroupStep.Calculations) && !setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Berechnungseinstellungen</h2>
                <ul class="mt-1 text-sm leading-6 text-gray-600 list-disc">
                  <li><span class="font-bold">Berechnungsziel:</span> Speicherort des berechneten Wertes</li>
                  <li><span class="font-bold">Berechnungsoperation:</span> Berechnungsart des Werts. Wenn eine
                    Sortierung angegeben wurde sind die Werte losgelöst vom Objekt.
                  </li>
                  <li><span class="font-bold">Rundungseinstellungen:</span> Runden auf ganze 10ct. oder volle Euro.</li>
                </ul>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                      <label for="calculationType" class="block text-sm font-medium leading-6 text-gray-900">Berechnungsziel</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="calculationType" class="w-full" [items]="calculationType" bindLabel="name"
                                     bindValue="id" [(ngModel)]="newSchedulerGroup.calculationType">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label for="calculationOperation" class="block text-sm font-medium leading-6 text-gray-900">Berechnungsoperation</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="calculationOperation" class="w-full" [items]="calculationOperation"
                                     bindLabel="name" bindValue="id"
                                     [(ngModel)]="newSchedulerGroup.calculationOperation">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-6">
                      <label for="roundingType" class="block text-sm font-medium leading-6 text-gray-900">Rundungsoptionen</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="roundingType" class="w-full" [items]="roundingType" bindLabel="name"
                                     bindValue="id" [(ngModel)]="newSchedulerGroup.roundingType">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-6 flex">
                      <button
                        class="ml-auto px-3 py-2 rounded-md bg-theme-primary-700 hover:bg-theme-primary-900 cursor-pointer select-none text-white"
                        *ngIf="setItems(false)" (click)="continue = true">Weiter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- set items -->
          <div class="space-y-10 divide-y divide-gray-900/10" *ngIf="setItems()">
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2
                  class="text-base font-semibold leading-7 text-theme-primary-700 hover:text-theme-primary-900 cursor-pointer select-none"
                  (click)="continue = false;">Zurück</h2>
                <p><span class="font-bold">Event:</span> {{ eventName(newSchedulerGroup.additionalIds["EventId"]!) }}
                </p>
                <p><span
                  class="font-bold">Preisprofil:</span> {{ configurationName(newSchedulerGroup.additionalIds["ConfigurationId"]!) }}
                </p>
                <p><span class="font-bold">Start:</span> {{ newSchedulerGroup.first | date: "dd.MM.yyyy HH:mm" }} Uhr
                </p>
                <p *ngIf="newSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring"><span
                  class="font-bold">Ende:</span> {{ newSchedulerGroup.last | date: "dd.MM.yyyy HH:mm" }} Uhr</p>
                <p *ngIf="newSchedulerGroup.schedulerReoccurrence == SchedulerReoccurrence.Reoccurring"><span
                  class="font-bold">Intervall:</span> {{ newSchedulerGroup.interval }} Minute(n)</p>
                <p><span class="font-bold">Sortierung:</span> {{ getTypeOrderName(newSchedulerGroup.typeOrder!) }}</p>
                <p><span
                  class="font-bold">Objekttyp:</span> {{ getCalculationTargetTypeName(newSchedulerGroup.calculationTargetType!) }}
                </p>
                <p><span
                  class="font-bold">Berechnugnsziel:</span> {{ getCalculationTypeName(newSchedulerGroup.calculationType!) }}
                </p>
                <p><span
                  class="font-bold">Berechnung:</span> {{ getCalculationOperationName(newSchedulerGroup.calculationOperation!) }}
                </p>
                <p><span class="font-bold">Runden:</span> {{ getRoundingType(newSchedulerGroup.roundingType) }}</p>
              </div>

              <div class="max-w-2xl bg-white shadow-2xl ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div class="px-4 py-2 space-y-2"
                     *ngIf="newSchedulerGroup.calculationTargetType == CalculationTargetType.Composition && compositions">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                       *ngFor="let composition of newSchedulerGroup.targetIds;let index = index;">
                    <div class="sm:col-span-3">
                      <label for="target_{{ composition }}" class="block text-sm font-medium leading-6 text-gray-900">Objekt</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input id="target_{{ composition }}" type="text" disabled
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="compositionName(composition)">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="value_{{ composition }}" class="block text-sm font-medium leading-6 text-gray-900">Zuordnungswert</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input id="value_{{ composition }}" type="number"
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="compositionName(composition)"
                                 [(ngModel)]="newSchedulerGroup.calculationValues[index]">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-1">
                      <span class="block text-sm font-medium leading-6 text-gray-900">&nbsp;</span>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <span class="m-auto text-red-600 hover:text-red-800 cursor-pointer select-none p-1"
                                (click)="removeComposition(index)">Entfernen</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="addComposition" class="block text-sm font-medium leading-6 text-gray-900">Objekt
                        hinzufügen</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="addComposition" class="w-full" [items]="compositions" bindLabel="name"
                                     bindValue="id" [(ngModel)]="selectedComposition"
                                     (ngModelChange)="addComposition($event)">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-6 flex">
                      <button
                        class="ml-auto flex py-1 px-2 bg-theme-primary-700 hover:bg-theme-primary-900 select-none cursor-pointer text-white rounded-2xl"
                        (click)="sendSchedulerGroup()">Speichern
                      </button>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-2 space-y-2"
                     *ngIf="newSchedulerGroup.calculationTargetType == CalculationTargetType.CompositionType && compositionTypes">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                       *ngFor="let compositionType of newSchedulerGroup.targetIds;let index = index;">
                    <div class="sm:col-span-3">
                      <label for="target_{{ compositionType }}"
                             class="block text-sm font-medium leading-6 text-gray-900">Artikel-Kategorie</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input id="target_{{ compositionType }}" type="text" disabled
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="compositionTypeName(compositionType)">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="value_{{ compositionType }}"
                             class="block text-sm font-medium leading-6 text-gray-900">Zuordnungswert</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <input id="value_{{ compositionType }}" type="number"
                                 class="w-full p-1 border-gray-300 border-2 rounded-md"
                                 [value]="compositionTypeName(compositionType)"
                                 [(ngModel)]="newSchedulerGroup.calculationValues[index]">
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-1">
                      <span class="block text-sm font-medium leading-6 text-gray-900">&nbsp;</span>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <span class="m-auto text-red-600 hover:text-red-800 cursor-pointer select-none p-1"
                                (click)="removeComposition(index)">Entfernen</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label for="addComposition" class="block text-sm font-medium leading-6 text-gray-900">Artikel-Kategorie
                        hinzufügen</label>
                      <div class="">
                        <div
                          class="w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-theme-primary-900">
                          <ng-select id="addCompositionType" class="w-full" [items]="compositionTypes" bindLabel="name"
                                     bindValue="id" [(ngModel)]="selectedCompositionType"
                                     (ngModelChange)="addCompositionType($event)">

                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="sm:col-span-6 flex">
                      <button
                        class="ml-auto flex py-1 px-2 bg-theme-primary-700 hover:bg-theme-primary-900 select-none cursor-pointer text-white rounded-2xl"
                        (click)="sendSchedulerGroup()">Speichern
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
