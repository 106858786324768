import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {LocationDto} from "../models/location-dto";
import {ConfigurationDto} from "../models/configuration-dto";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationHttpService {

  private _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  list(): Observable<ConfigurationDto[]> {
    return this.http.get<ConfigurationDto[]>(this._baseUrl + ServerEndpoints.Inventory_Configuration_List);
  }

  add(name: string): Observable<ConfigurationDto> {
    return this.http.post<any>(this._baseUrl + ServerEndpoints.Inventory_Configuration_Add, {name: name});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + ServerEndpoints.Inventory_Configuration_Delete + "?id=" + id);
  }
}
