import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {ShardNl} from "../../../models/admin_owner/shard/shard-nl";
import {ServerEndpoints} from "../../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class CompanyHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  GetCompanyShards(): Observable<ShardNl[]> {
    return this.httpClient.get<ShardNl[]>(this.baseUrl + ServerEndpoints.Company_Company_GetCompanyShards);
  }
}
