/**
 * Enum for all routes in the application
 *
 * @export
 * @enum {string}
 * @description Routing paths for the application
 */
export enum Routes {
  // Home
  Home = '',

  // Login
  Login = 'login',

  // Setup
  SetupIndex = 'setup/index',

  // Events
  // Events = 'events',
  EventSettings = 'location/event/settings',

  // Authentication
  AuthenticationProfile = 'authentication/profile',

  // Configurations
  Configurations = 'configurations',
  //ConfigurationSettings = 'configurations/settings',

  // Locations
  Locations = 'locations',
  LocationSettings = 'locations/settings',

  // Compositions
  Compositions = 'compositions',
  CompositionSettings = 'compositions/settings',
  CompositionTypes = 'compositions/type',
  CompositionTypeSettings = 'compositions/type/settings',

  // Tags
  Tags = 'tags',
  TagSettings = 'tags/settings',

  // Categories
  Categories = 'categories',
  CategorySettings = 'categories/settings',

  // Items
  ItemSettings = "items/settings",
  Items = 'items',

  // Orders
  Orders = 'orders',

  // Analytics
  Analytics = 'analytics',

  // Devices
  DeviceOverview = 'devices/overview',
  DeviceSettings = 'devices/settings',

  // Company
  CompanyModule = 'company',
  CompanySupplier = 'company/supplier',
  CompanySupplierSettings = 'company/supplier/settings',
  CompanySettings = 'company/settings',
  CompanyTelegram = 'company/telegram',
  CompanyEmployee = 'company/employee',
  CompanyEmployeeDownload = 'company/employee/download',
  CompanyEmployeeDetails = 'company/employee/details',
  CompanyEmployeeSettings = 'company/employee/settings',

  // Automation Module
  AutomationModule = 'automation',

  // Location Module
  LocationModule = 'location',

  // Legal Module
  LegalModule = 'legal',

  // owner module
  OwnerModule = 'owner',

  // selfservice module
  SelfServiceModule = 'selfservice',

  // Admin
  AdminUsers = 'admin/users',
  AdminRoles = 'admin/roles',
  AdminPatches = 'admin/patches',


  // Owner
  OwnerCompanies = 'owner/companies',
  OwnerCompanySettings = 'owner/companies/settings',
  OwnerUsers = 'owner/users',
  OwnerEvents = 'owner/events',
  OwnerShards = 'owner/shards',
  OwnerShardSettings = 'owner/shards/settings',
  OwnerEnvironmentSettings = 'owner/environment',
  OwnerGeolocationTracking = 'owner/geolocation-tracking',

  Changelog = 'changelog',

  // EmployeePanel
  EmployeePanel = 'ep',
  EmployeePanelLogin = 'ep/login',

  // Feedback
  Feedback = 'feedback',

  // Table Reservation Module
  TablePlanner = 'table-planner',
}



