import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {TagDto} from "../models/tag-dto";
import {CompositionDto} from "../models/composition-dto";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class TagHttpService {
  private _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  list(): Observable<TagDto[]> {
    return this.http.get<TagDto[]>(this._baseUrl + ServerEndpoints.Inventory_Tag_List);
  }

  add(name: string, color: string, other: boolean = false, disabled: boolean = false, allCompositions: boolean = false): Observable<TagDto> {
    return this.http.post<any>(this._baseUrl + ServerEndpoints.Inventory_Tag_Add, {
      name: name,
      color: color,
      other: other,
      disabled: disabled,
      allCompositions: allCompositions
    });
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + ServerEndpoints.Inventory_Tag_Delete + "?id=" + id);
  }

  setFilter(tag: string, filter: string): Observable<TagDto> {
    return this.http.put<TagDto>(this._baseUrl + ServerEndpoints.Inventory_Tag_SetFilter + "?tag=" + tag + "&filter=" + (filter == "none" ? "" : filter), {});
  }

  get(id: string, all: boolean = false): Observable<TagDto> {
    return this.http.get<TagDto>(this._baseUrl + ServerEndpoints.Inventory_Tag_Get + "?id=" + id + "&all=" + String(all));
  }

  changeName(id: string, name: string): Observable<any> {
    return this.http.put<any>(this._baseUrl + ServerEndpoints.Inventory_Tag_ChangeName, {
      Id: id,
      Name: name
    });
  }

  changeColor(id: string, color: string): Observable<any> {
    return this.http.put<any>(this._baseUrl + ServerEndpoints.Inventory_Tag_ChangeColor, {
      Id: id,
      Color: color
    });
  }

  setOrder(id: string, order: number): Observable<TagDto> {
    return this.http.put<TagDto>(this._baseUrl + ServerEndpoints.Inventory_Tag_SetOrder + "?id=" + id + "&order=" + order, {});
  }
}

