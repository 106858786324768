import {Input, Component, AfterViewInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter} from "@angular/core";
import {NavbarService} from "./navbar.service";


@Component({
  template: ''
})
export abstract class ItemBase implements AfterViewInit, OnDestroy {
  constructor(private navbarService: NavbarService) {
    this.navbarService.itemBases.push(this);
  }

  ngOnDestroy(): void {
    this.navbarService.itemBases.splice(this.navbarService.itemBases.indexOf(this), 1);
  }

  ngAfterViewInit(): void {
    this.breadcrumbs.splice(0, 0, this.title);
    let ne = this.item.nativeElement;
    while (ne.parentElement != undefined && ne.nodeName != "APP-NAVBAR-OFFICE-ITEM") {
      if (ne.getAttribute("breadcrumb")) {
        this.breadcrumbs.splice(0, 0, ne.getAttribute("breadcrumb"));
      }
      ne = ne.parentElement;
    }
  }

  abstract item: ElementRef;

  @Input() title: string = "Button";
  @Input() link: any[] | string | null | undefined;
  @Input() params: any = {};
  @Input() onSelectedFunction: boolean = false;
  @Output() onSelected: EventEmitter<number> = new EventEmitter<number>();

  breadcrumbs: string[] = [];

  get id(): string {
    return this.breadcrumbs.join(" > ");
  }

  get value(): string {
    return this.breadcrumbs.slice(1).join(" > ");
  }

  contains(term: string): boolean {
    return this.id.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }

  execute() {
    this.onSelected.next(Math.random())
  }
}
