import {IModelBase, ModelBase} from "./model-base";
import {EmployeeEventResponseDto} from "../../../models/event/employee-event-response-dto";
import {UserInformation} from "../../../x-models/user-information";
import {EmployeeStatsDto} from "./employee-stats-dto";
import {EmployeeAttributeNl} from "./employee-attribute-nl";

export class EmployeeNl extends ModelBase implements IModelBase {
  firstName: string = "";
  lastName: string = "";
  joined: Date = new Date();
  left: Date = new Date();

  canLogin: boolean = false;

  telegram: UserInformation | undefined;

  // shift maker
  responses: EmployeeEventResponseDto[] = [];

  employeeStats: EmployeeStatsDto = new EmployeeStatsDto()

  employeeAttributes: EmployeeAttributeNl[] = [];
}
