import {Component, Inject, OnInit} from '@angular/core';
import {SignalRHttpService} from "../x-http-requests/signal-r-http.service";
import {LocationHttpService} from "../../../http-requests/location-http.service";
import {LocationDto} from "../../../models/location-dto";
import {SignalRGroup} from "../x-models/signal-r-group";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {BatteryState} from "../x-models/battery-state";
import * as signalR from '@microsoft/signalr';
import {HubConnectionState} from '@microsoft/signalr';
import {AuthenticationService} from "../../../_auth/authentication.service";
import {SignalRClient} from "../x-models/signal-r-client";
import {Device} from "../x-models/device";
import {PopUps} from "../../../enums/pop-ups";
import {Routes} from "../../../enums/routes";
import {Router} from "@angular/router";
import {PermissionService} from "../../../_auth/permission.service";
import {RequestedInformation} from "../x-models/requested-information";


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  locations: LocationDto[] = [];
  signalRGroup: SignalRGroup | undefined;
  BatteryState = BatteryState;
  initial: boolean = true;
  Devices: Device[] = [];
  DevicesFiltered: Device[] = [];

  constructor(private signalRHttpService: SignalRHttpService, private locationHttpService: LocationHttpService,
              private globalAlertService: GlobalAlertService, @Inject("BASE_URL") private baseUrl: string,
              private authenticationService: AuthenticationService, private router: Router,
              public PermissionService: PermissionService) {
  }

  toggleActive = true;
  toggleLocked = false;

  Filter(): void {
    this.DevicesFiltered = Object.assign([], this.Devices.filter(x => x.isLockedOut ? this.toggleLocked : this.toggleActive));
  }

  GetLocationName(id: string): string {
    let location = this.locations.find(x => x.id == id);
    return location == undefined ? "- Verkaufspunkt unbekannt... -" : location.name;
  }

  GetSignalRClient(id: string): SignalRClient | undefined {
    return this.signalRGroup?.all.find(x => x.deviceId == id);
  }

  sBacklog: any | undefined = undefined;

  RequestInformation(id: string, requestedInformation: RequestedInformation) {
    this.signalRHttpService.RequestInformation(id, requestedInformation).subscribe({
      next: x => {
        if (x[0]?.requestedInformation === undefined) {
          this.globalAlertService.createAlertBannerModel("Fehler", "Der Backlog konnte nicht abgerufen werden.", AlertLevel.error, 2000);
          this.globalAlertService.show();
          return;
        }

        let information: any = {};

        Object.keys(x[0]!.requestedInformation!).forEach(k => {
          information[k] = JSON.parse(x[0]!.requestedInformation![Number.parseInt(k)]);
        })

        this.sBacklog = information;

        let device = this.Devices.find(c => c.id == x[0]?.deviceId);
        if (device != undefined && x[0]?.deviceInformation != undefined) {
          device.deviceInformation = x[0]?.deviceInformation;
        }
      },
      error: error => {
        console.error(error);
        this.globalAlertService.createAlertBannerModel("Fehler", "Beim Anfordern der Informationen ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
        this.globalAlertService.show();
      }
    })
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.DeviceSettings], {queryParams: {id: id}});
  }

  TrackByIndex(index: number, obj: any): any {
    return index;
  }

  SortDevices() {
    this.Devices.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
      return 0;
    })
    this.Filter();
  }

  LoadData() {
    this.signalRHttpService.List().subscribe(x => {
      this.Devices = x.item1 ?? [];
      this.signalRGroup = x.item2;
      this.SortDevices();
      this.initial = false;
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Beim Laden der Geräte ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
    this.locationHttpService.list().subscribe(x => {
      this.locations = x;
    }, error => {
      console.error(error);
    })
  }

  ngOnInit(): void {
    this.LoadData();
    if (this.Connection == undefined) this.SignalRConnect();
  }

  ngOnDestroy(): void {
    if (this.Connection != undefined) {
      this.Connection.stop().then(r => {
        console.log(`SignalR Disconnected: ${r}`);
      }, error => {
        console.error(error)
      });
    }
  }


  public Connection: signalR.HubConnection | undefined;

  SignalRConnect(): void {
    this.Connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this.baseUrl + "/hubs/devices", {
        withCredentials: true,
        //headers: {
        //  "Authorization": `Bearer ${this.authenticationService.userValue!.jwt}`
        //},
        accessTokenFactory: () => {
          return this.authenticationService.userValue!.jwt;
        }
      })
      .withAutomaticReconnect()
      .build();

    this.Connection.start().then(function () {
      console.log("SignalR connected");
    }).catch(function (err) {
      return console.error(err);
    });

    const interval = setInterval(() => {
      if (this.Connection?.state == HubConnectionState.Connected) {
        this.Connection!.invoke("JoinListeners").then(() => {
          console.log("Joined Device Listeners...");
        }).catch((err) => {
          console.error(err)
        });
        clearInterval(interval);
      }
    }, 150);

    this.Connection.on("RemoveDevice", (id: string) => {
      if (this.signalRGroup == undefined) {
        return;
      }
      let client = this.signalRGroup.all.find(x => x.id == id);
      let index = this.signalRGroup.all.findIndex(x => x.id == id);
      if (index == -1 || client == undefined) {
        return;
      }
      let device = this.Devices.find(x => x.client != undefined && x.client.id == client?.id);
      if (device == undefined) {
        return;
      }
      this.globalAlertService.createAlertBannerModel("Verbindung verloren", `Die Verbindung ${device.name} ${device.location == undefined ? "" : " am Verkaufspunkt " + device.location.name} wurde unterbrochen.`, AlertLevel.warning, 2500);
      this.globalAlertService.show();
      this.signalRGroup.all.splice(index, 1);
    })

    this.Connection.on("AddDevice", (device: Device) => {
      let client = device.client!;

      let dIndex = this.Devices.findIndex(x => x.id == device.id);
      if (dIndex == -1) {
        this.Devices.push(device);
        this.SortDevices();
      } else {
        this.Devices.splice(dIndex, 1, device);
      }

      if (this.signalRGroup == undefined) return;
      let index = this.signalRGroup.all.findIndex(x => x.id == client.id);
      if (index == -1) {
        this.signalRGroup.all.push(client);
        this.globalAlertService.createAlertBannerModel("Verbindung hergestellt", `Die Verbindung zu ${device.name} ${device.location == undefined ? "" : " am Verkaufspunkt " + device.location.name} wurde hergestellt.`, AlertLevel.success, 2500);
        this.globalAlertService.show();
      } else this.signalRGroup.all.splice(index, 1, client);
      this.SortDevices();
    })

    this.Connection.onreconnected(() => {
      this.LoadData();
    })
  }

  protected readonly PopUps = PopUps;
  protected readonly HubConnectionState = HubConnectionState;
  protected readonly RequestedInformation = RequestedInformation;
}
