<app-navbar-container breadcrumb="Hilfe">
  <app-navbar-item-large title="Changelog" [link]="'/' + Routes.Changelog"
                         *ngIf="PSInstance.CheckPermission(PermissionService.Permissions_ChangelogComponent())">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
        <path d="M5 10L8 13L14 7"/>
        <path d="M5 24L8 27L14 21"/>
        <path d="M5 38L8 41L14 35"/>
        <path d="M21 24H43"/>
        <path d="M21 38H43"/>
        <path d="M21 10H43"/>
      </g>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Impressum"
                         [onSelectedFunction]="true"
                         (onSelected)="window.open('/static/legal.html', '_blank')"
                         (click)="window.open('/static/legal.html', '_blank')">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path fill="currentColor"
            d="M15 14s1 0 1-1s-1-4-5-4s-5 3-5 4s1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276c.593.69.758 1.457.76 1.72l-.008.002l-.014.002zM11 7a2 2 0 1 0 0-4a2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0a3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904c.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724c.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0a3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4a2 2 0 0 0 0-4"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Datenschutz"
                         [onSelectedFunction]="true"
                         (onSelected)="window.open('/static/privacy.html', '_blank')"
                         (click)="window.open('/static/privacy.html', '_blank')">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path fill="currentColor"
            d="M10 9.6c-.6 0-1 .4-1 1c0 .4.3.7.6.8l-.3 1.4h1.3l-.3-1.4c.4-.1.6-.4.6-.8c.1-.6-.3-1-.9-1m.1-4.3c-.7 0-1.4.5-1.4 1.2V8h2.7V6.5c-.1-.7-.6-1.2-1.3-1.2M10 2L3 5v3c.1 4.4 2.9 8.3 7 9.9c4.1-1.6 6.9-5.5 7-9.9V5zm4 11c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1h.3V6.5C7.4 5.1 8.6 4 10 4c1.4 0 2.6 1.1 2.7 2.5V8h.3c.6 0 1 .4 1 1z"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Cookie Einstellungen"
                         [onSelectedFunction]="true"
                         (onSelected)="CookieService.showSettings()"
                         (click)="CookieService.showSettings()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <circle cx="10" cy="21" r="2" fill="currentColor"/>
      <circle cx="23" cy="20" r="2" fill="currentColor"/>
      <circle cx="13" cy="10" r="2" fill="currentColor"/>
      <circle cx="14" cy="15" r="1" fill="currentColor"/>
      <circle cx="23" cy="5" r="2" fill="currentColor"/>
      <circle cx="29" cy="3" r="1" fill="currentColor"/>
      <circle cx="16" cy="23" r="1" fill="currentColor"/>
      <path fill="currentColor"
            d="M16 30C8.3 30 2 23.7 2 16S8.3 2 16 2h.3l1.4.1l-.3 1.2c-.1.4-.2.9-.2 1.3c0 2.8 2.2 5 5 5c1 0 2-.3 2.9-.9l1.3 1.5c-.4.4-.6.9-.6 1.4c0 1.3 1.3 2.4 2.7 1.9l1.2-.5l.2 1.3c.1.6.1 1.2.1 1.7c0 7.7-6.3 14-14 14m-.7-26C9 4.4 4 9.6 4 16c0 6.6 5.4 12 12 12s12-5.4 12-12v-.4c-2.3.1-4.2-1.7-4.2-4v-.2c-.5.1-1 .2-1.6.2c-3.9 0-7-3.1-7-7c0-.2 0-.4.1-.6"/>
    </svg>
  </app-navbar-item-large>
  <app-navbar-item-large title="Anleitung"
                         class="ml-auto"
                         [onSelectedFunction]="true"
                         (onSelected)="window.open('/static/manual/index.html', '_blank')"
                         (click)="window.open('/static/manual/index.html', '_blank')">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path d="M5.5 4.054a1.5 1.5 0 1 1 1.5 1.5v.5m0 2.062a.25.25 0 0 1 0-.5m0 .5a.25.25 0 0 0 0-.5"/><path d="M12.5 13.5H3a1.5 1.5 0 1 1 0-3h8.5a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H3a1.5 1.5 0 0 0-1.5 1.46v10m10-1.46v3"/></g></svg>
  </app-navbar-item-large>
</app-navbar-container>
