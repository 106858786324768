import {Inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {EnvironmentSetting} from "../x-models/environment-setting";
import {Observable} from "rxjs";
import {ServerEndpoints} from "../../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentSettingHttpService {

  constructor(@Inject("BASE_URL") private baseUrl: string, private httpClient: HttpClient) {
  }

  Upsert(variable: EnvironmentSetting, empty: boolean): Observable<EnvironmentSetting> {
    return this.httpClient.post<EnvironmentSetting>(this.baseUrl + (empty ? ServerEndpoints.Management_Environment_Add : ServerEndpoints.Management_Environment_Upsert), variable);
  }


  List(): Observable<EnvironmentSetting[]> {
    return this.httpClient.get<EnvironmentSetting[]>(this.baseUrl + ServerEndpoints.Management_Environment_List);
  }

  Delete(id: string) {
    return this.httpClient.delete(this.baseUrl + ServerEndpoints.Management_Environment_Delete + "?id=" + id);
  }

  get(id: string): Observable<EnvironmentSetting> {
    return this.httpClient.get<EnvironmentSetting>(this.baseUrl + ServerEndpoints.Management_Environment_Get + "?id=" + id);
  }
}
