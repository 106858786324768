export enum BookingType {
  Cash,
  Card,
  Employee,
  BookingItem,
  BookingEnd
}

export const BookingTypeLabels: { [key: number]: string } = {
  [BookingType.Cash]: "Bar",
  [BookingType.Card]: "Karte",
  [BookingType.Employee]: "Mitarbeiter",
  [BookingType.BookingItem]: "Buchung",
  [BookingType.BookingEnd]: "Buchung"
};

export class N5PaymentMapper {
  GetString(type: BookingType): string {
    return ["Bar", "Karte (SumUp)", "Mitarbeiter", "Buchung"][type]
  }
}
