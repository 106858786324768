import {Component} from "@angular/core";
import {ComponentType} from "@angular/cdk/overlay";
import {ContractType} from "../../navbar-base/_models/contract-type";

export class NavbarItem {
  title: string = "Title";
  component: ComponentType<any>;
  available: boolean = false;
  isSelected: boolean = false;
  isExpanded: boolean = false;
  inNavBar: boolean = true;
  typeFlags: number = 0;

  constructor(title: string, component: ComponentType<any>, permission: boolean, inNavBar: boolean = true, typeFlags: number = 0) {
    this.title = title;
    this.component = component;
    this.available = permission;
    this.inNavBar = inNavBar;
    this.typeFlags = typeFlags;
  }

  showing() {
    return this.isSelected && this.isExpanded;
  }

  isDefault(type: ContractType): boolean {
    // check if typeFlags has the bit set type
    return (this.typeFlags & type) === type;
  }
}
