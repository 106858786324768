<div class="absolute inset-4 md:inset-6 xl:inset-8 flex flex-col gap-y-4">
  <div class="flex flex-col" #employee>
    <div class="sm:flex sm:items-center">
      <div class="flex space-x-1">
        <h1 class="font-semibold leading-6 text-gray-900 !text-3xl pointer-events-none">
          {{ title }}
        </h1>
      </div>
    </div>
  </div>
  <div class="h-full w-full relative overflow-y-auto">
    <ng-content></ng-content>
  </div>
</div>
