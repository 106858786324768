import {ShiftLocation} from "./shift-location";
import {EventNl} from "../../../models/event/event-nl";

export class ShiftStorage {
  event: EventNl;
  shiftLocations: ShiftLocation[] = [];

  constructor(event: EventNl) {
    this.event = event;
  }
}
