import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {CompositionDto} from "../models/composition-dto";
import {ComponentNl} from "../models/component-nl";
import {TagNl} from "../models/tag-nl";
import {ItemDto} from "../models/item-dto";
import {ServerEndpoints} from "../server.endpoints";

@Injectable({
  providedIn: 'root'
})
export class CompositionHttpService {
  private _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject('BASE_HEADERS') baseHeaders: HttpHeaders) {
    this._baseUrl = baseUrl;

  }

  list(item: string = "", tag: string = "", notype: boolean = false, excludeSpecial: boolean = false): Observable<CompositionDto[]> {
    return this.http.get<CompositionDto[]>(this._baseUrl + ServerEndpoints.Inventory_Composition_List + "?item=" + item + "&tag=" + tag + "&notype=" + notype + "&excludeSpecial=" + excludeSpecial);
  }

  add(name: string, price: number, components: ComponentNl[], tags: string[], special: boolean = false) {
    return this.http.post<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_Create, {
      name: name,
      price: price,
      tags: tags,
      components: components,
      special: special
    })
  }

  delete(id: string) {
    return this.http.delete(this._baseUrl + ServerEndpoints.Inventory_Composition_Delete + "?id=" + id);
  }

  toggle(composition: string, tag: string): Observable<CompositionDto> {
    return this.http.put<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_ToggleTag + "?composition=" + composition + "&tag=" + tag, {});
  }

  changePrice(composition: string, price: number) {
    return this.http.put<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_PriceChange, {
      id: composition,
      price: price
    })
  }

  changeName(composition: string, name: string) {
    return this.http.put<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_ChangeName, {
      Id: composition,
      Name: name
    })
  }

  get(id: string, all: boolean = false): Observable<CompositionDto> {
    return this.http.get<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_Get + "?id=" + id + "&all=" + String(all));
  }

  download() {
    return this.http.get(this._baseUrl + ServerEndpoints.Inventory_Composition_Download, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

  setOrder(id: string, order: number): Observable<CompositionDto> {
    return this.http.put<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_SetOrder + "?id=" + id + "&order=" + order, {});
  }

  changePublicProperty(id: string, field: string, value: string | undefined): Observable<CompositionDto> {
    return this.http.put<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_ChangePublicProperty + "?id=" + id, {
      name: field,
      value: value
    })
  }

  changeComponents(compositionId: string, components: ComponentNl[]): Observable<CompositionDto> {
    return this.http.put<CompositionDto>(this._baseUrl + ServerEndpoints.Inventory_Composition_ComponentChange, {
      compositionId: compositionId,
      components: components
    })
  }
}
