export enum Unit {
  Piece,
  Ml,
  L,
  G,
  Kg
}

export class UnitConverter {
  public static readonly Strings: string[] = [
    "St.",
    "ml",
    "l",
    "g",
    "kg"
  ]

  static GetString(unit: Unit): string {
    return this.Strings[unit];
  }
}
