import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {NavbarItem} from "./models/navbar-item";
import {NavbarService} from "../navbar-base/navbar.service";
import {ItemBase} from "../navbar-base/item-base";
import {Router} from "@angular/router";
import {drop} from "@syncfusion/ej2-angular-richtexteditor";
import {WindowStateService} from "../../_services/window-state.service";
import {WindowState} from "../../x-models/window-state";
import {PlatformScannerService} from "../../_services/platform-scanner.service";
import {Platform} from "../../enums/platform";
import {AuthenticationService} from "../../_auth/authentication.service";
import {Routes} from "../../enums/routes";
import {ContractType} from "../navbar-base/_models/contract-type";

@Component({
  selector: 'app-navbar-office',
  templateUrl: './navbar-office.component.html',
  styleUrls: ['./navbar-office.component.scss']
})
export class NavbarOfficeComponent {
  @ViewChild("searchElement") searchElement: ElementRef | undefined;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
      event.preventDefault();
      // @ts-ignore
      this.searchElement?.focus();
    }
  }

  constructor(public NavbarService: NavbarService, private router: Router,
              private windowStateService: WindowStateService, public PlatformService: PlatformScannerService,
              public AuthenticationService: AuthenticationService) {
    // this.select(this.NavbarService.navbarItems[0])
    this.handleWindowState(this.windowStateService.state);
    this.windowStateService.tabLoaded.subscribe({
      next: (x: WindowState | undefined) => {
        this.handleWindowState(x);
      }
    })
  }

  handleWindowState(state: WindowState | undefined) {
    if (!state) return;
    let item = this.NavbarService.getAvailable().find(i => i.title == state.tab);
    if (item) {
      this.last = item;
      if (state.expanded) {
        this.select(item)
      }
    }
  }

  last: NavbarItem | undefined;

  select(item: NavbarItem) {
    this.last = item;
    this.NavbarService.navbarItems.forEach(i => {
      if (i.isSelected && i.title == item.title) {
        item.isExpanded = !item.isExpanded;
        item.isSelected = !item.isSelected;
        this.windowStateService.setTab(item.title, item.isExpanded);
      } else {
        i.isSelected = i.title == item.title;
        if (i.isSelected) {
          i.isExpanded = true;
          this.windowStateService.setTab(item.title, item.isExpanded);
        } else {
          i.isExpanded = false;
        }
        this.menuClose();
      }
    });
  }

  close() {
    this.NavbarService.navbarItems.forEach(i => {
      i.isSelected = false;
      i.isExpanded = false;
    });
  }

  protected readonly console = console;

  loadItems() {
    this.focus = true;
    this.NavbarService.itemBases = [...this.NavbarService.itemBases.sort((a, b) => a.id.localeCompare(b.id))];
  }

  selected: ItemBase | null | undefined;

  groupBy(item: ItemBase) {
    return item.breadcrumbs[0];
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async navigate(item: ItemBase) {
    if (item.onSelectedFunction) {
      item.execute();
    } else if (typeof item.link == "object" && item.link != null) {
      this.router.navigate(item.link as any[], {queryParams: item.params}).then(_ => {
      });
    } else {
      this.router.navigate([item.link], {queryParams: item.params}).then(_ => {
      });
    }

    await this.delay(0);
    this.selected = undefined;
  }

  @ViewChild("dropdown") dropdown!: ElementRef;
  protected readonly drop = drop;

  focus: boolean = false;

  focusout() {
    this.focus = false;
  }

  search(term: string, item: ItemBase) {
    return item.contains(term);
  }

  menuVisible = false;

  menuToggle() {
    if (!this.menuVisible) this.menuOpen();
    else this.menuClose();
  }

  menuClose() {

    this.menuVisible = false;
  }

  menuOpen() {

    this.menuVisible = true;
  }

  protected readonly Platform = Platform;
  protected readonly Routes = Routes;

  identify(index: any, item: ItemBase) {
    return item.id;
  }

  protected readonly ContractType = ContractType;
}
