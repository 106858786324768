import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CalendarDay} from "../../x-components/small-calendar/x-models/calendar-day";
import {CalendarEntry} from "../../x-components/small-calendar/x-models/calendar-entry";
import {EventHttpService} from "../../../management/x-http-requests/event-http.service";
import {EventNl} from "../../../models/event/event-nl";
import {Tuple} from "../../../models/_generic/tuple";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {
  EmployeeEventResponseDto,
  EmployeeEventResponseDtoMinimal
} from "../../../models/event/employee-event-response-dto";
import {LocationHttpService} from "../../../http-requests/location-http.service";
import {LocationNl} from "../../../models/location-nl";
import {ShiftStorage} from "../x-models/shift-storage";
import {ShiftLocation} from "../x-models/shift-location";
import {CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {TimeUtilities} from "../../../utils/time-utilities";
import {EmployeeNl} from "../x-models/employee-nl";
import {Side} from "../x-models/x-enums/side";
import * as signalR from '@microsoft/signalr';
import {HubConnection, HubConnectionState} from '@microsoft/signalr';
import {AuthenticationService} from "../../../_auth/authentication.service";
import {EmployeeHttpService} from "../x-http-requests/employee-http.service";
import {ShiftMakerResponse} from "../x-models/shift-maker-response";
import {PlatformScannerService} from "../../../_services/platform-scanner.service";
import {Platform} from "../../../enums/platform";
import {filter} from "rxjs";
import {Routes} from "../../../enums/routes";
import {CompanyRoutes} from "../x-models/x-enums/company-routes";
import {DownloadInformation} from "../../x-components/x-models/download-information";
import {DateHelperService} from "../../x-components/x-services/date-helper.service";
import {ResponseState} from "../x-models/x-enums/response-state";
import {PermissionService} from "../../../_auth/permission.service";
import {LocationDto} from "../../../models/location-dto";
import {ActivatedRoute, Router} from "@angular/router";
import {EmployeeStatsDto, EmployeeStatsHelper} from "../x-models/employee-stats-dto";
import {ServerEndpoints} from "../../../server.endpoints";
import {LocationRoutes} from "../../location/_models/_enums/location-routes";
import {SettingsHttpService} from "../_services/_http/settings-http.service";
import {ShiftMakerSettings} from "../_models/settings/shift-maker-settings";
import {CalculationType} from "../../../_models/_general/calculation-type";

@Component({
  selector: 'app-shift-maker',
  templateUrl: './shift-maker.component.html',
  styleUrls: ['./shift-maker.component.scss']
})
export class ShiftMakerComponent implements OnInit, OnDestroy {
  constructor(private eventHttpService: EventHttpService, private locationHttpService: LocationHttpService,
              private globalAlertService: GlobalAlertService, public authenticationService: AuthenticationService,
              private employeeHttpService: EmployeeHttpService, public platformScannerService: PlatformScannerService,
              private settingsHttpService: SettingsHttpService,
              public PermissionService: PermissionService, private router: Router, private route: ActivatedRoute,
              @Inject("BASE_URL") public baseUrl: string, private dateHelperService: DateHelperService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  @ViewChild("employeeHeader") employeeHeader!: ElementRef<HTMLSpanElement>;
  @ViewChild("employeeModal") employeeModal!: ElementRef<HTMLDivElement>;

  downloadInformation: DownloadInformation = new DownloadInformation();
  downloadParams: string = "";
  downloadExtended: boolean = false;
  downloadPng: boolean = false;
  downloadStart: Date = new Date();
  downloadEnd: Date = new Date();

  events: EventNl[] = [];
  filteredEvents: EventNl[] = [];
  showingEvent: EventNl | undefined;
  shifts: ShiftStorage[] = [];
  responses: EmployeeEventResponseDto[] = [];
  responseList: any = {};

  companyLocations: any | undefined = undefined;

  selectedDay: CalendarDay = new CalendarDay(new Date(), false);
  calendarEntries: CalendarEntry[] = [];
  calendarRange: Tuple<Date, Date> = new Tuple<Date, Date>();

  edit: boolean = false;
  protected now = new Date();

  canEditPredicate(event: EventNl, permissionService: PermissionService) {
    return function (drag: CdkDrag, drop: CdkDropList) {
      if (permissionService.CheckPermission(permissionService.Hubs_ShiftMakerHub_PushGeneralUpdate())) return true;
      return permissionService.CheckPermission(permissionService.Hubs_ShiftMakerHub_PushLiveUpdate()) &&
        event.start <= new Date() && event.end > new Date();
    }
  }

  canEdit(event: EventNl | undefined = undefined): boolean {
    if (this.PermissionService.CheckPermission(this.PermissionService.Hubs_ShiftMakerHub_PushGeneralUpdate())) return true;
    if (!event) return this.PermissionService.CheckPermission(this.PermissionService.Hubs_ShiftMakerHub_PushLiveUpdate()) &&
      this.filteredEvents.findIndex(x => x.start <= new Date() && x.end > new Date()) > -1
    return this.PermissionService.CheckPermission(this.PermissionService.Hubs_ShiftMakerHub_PushLiveUpdate()) &&
      event.start <= new Date() && event.end > new Date();
  }

  nextSelectedDate: CalendarDay | undefined;
  nextSelectedEvent: string | undefined;

  companyLocationsLoaded: boolean = false;

  ngOnInit(): void {
    let width = window.innerWidth;
    if (width >= 1536 && !this.large) {
      this.large = true;
    } else if (width < 1536 && this.large) {
      this.large = false;
    }

    this.route.queryParams.subscribe(params => {
      if (params["event"]) {
        this.nextSelectedEvent = params["event"]
      }
    })


    this.setDownloadTimespan(new Date())

    if (this.PermissionService.CheckPermission(PermissionService.hubs_shiftmakerhub_pushgeneralupdate)) {
      this.endpoint = "PushGeneralUpdate";
    } else if (this.PermissionService.CheckPermission(PermissionService.hubs_shiftmakerhub_pushliveupdate)) {
      this.endpoint = "PushLiveUpdate";
    }

    this.edit = !this.platformScannerService.isMobile()

    this.loadSettings();
  }

  protected settings: ShiftMakerSettings = new ShiftMakerSettings();

  private loadSettings() {
    this.settingsHttpService.getShiftMakerSettings()
      .withLoadingIndicator()
      .subscribe({
        next: settings => {
          this.settings = settings;
        },
        error: err => {
          this.globalAlertService.createAndShow("Fehler", "Einstellungen konnten nicht geladen werden, bitte Rundung bei Endzeiten prüfen.", AlertLevel.warning);
          console.error(err);
          this.settings = new ShiftMakerSettings();
        }
      })
  }

  setDownloadParams() {
    this.downloadParams = "start=" + this.downloadStart.toISOString() + "&end=" + this.downloadEnd.toISOString() + "&extended=" + this.downloadExtended + "&png=" + this.downloadPng;
  }

  setDownloadTimespan(date: Date) {
    this.downloadStart = TimeUtilities.toFullDate(date)
    this.downloadEnd = new Date(this.downloadStart.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.setDownloadParams();
  }

  setDownloadExtended(extended: boolean) {
    this.downloadExtended = extended;
    this.setDownloadParams();
  }

  setDownloadPng(png: boolean) {
    this.downloadPng = png;
    this.setDownloadParams();
  }

  setDownloadStart(start: string) {
    this.downloadStart = new Date(start);
    this.downloadStart.setHours(0, 0, 0, 0);
    this.setDownloadParams();
  }

  setDownloadEnd(end: string) {
    this.downloadEnd = new Date(end);
    this.downloadEnd.setHours(0, 0, 0, 0);
    this.setDownloadParams();
  }

  ngOnDestroy(): void {
    Object.keys(this.Connections).forEach(x => {
      this.Connections[x].stop().then((r: any) => {
        console.log("SignalR disconnected: " + r)
      }, (error: any) => {
        console.error(error)
      })
    })
  }

  public Connections: any = {};

  SignalRConnect(event: string): void {
    if (this.Connections[event] == undefined) {
      this.Connections[event] = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(this.baseUrl + "/hubs/shiftmaker?event=" + event, {
          accessTokenFactory: () => this.authenticationService.userValue!.jwt
        })
        .withAutomaticReconnect()
        .build()
      let connection: HubConnection = this.Connections[event];
      connection.onreconnected(x => {
        this.selectedDateChangedWrapper(true)
      })

      this.Connections[event].on("ReceiveChange", (response: EmployeeEventResponseDto) => {
        if (response.start)
          response.start = new Date((response.start as any as string).endsWith("Z") ? (response.start as any as string) : (response.start as any as string) + "Z");
        if (response.end)
          response.end = new Date((response.end as any as string).endsWith("Z") ? (response.end as any as string) : (response.end as any as string) + "Z");
        if (response.employee == undefined) {
          response.employee = new EmployeeNl();
          // @ts-ignore
          response.employee.id = null;
        }

        this.dateHelperService.convertDates(response)

        // check if in responseList
        let old = this.responseList[response.id] as EmployeeEventResponseDto;

        if (old == undefined) {
          this.responseList[response.id] = response;

          // if no employee assigned
          if (response.employee.id == null) {
          }
          // if employee is assigned
          else {
            let employee = this.employees[response.employee.id];
            if (employee == undefined) {
              employee = this.employeeList.find(x => x.id == response.employee.id);
              if (employee != undefined) {
                employee.responses = [];
                this.employees[employee.id] = employee;
              } else {
                throw new Error();
              }
            }
            response.employee = employee;
            response.employee.responses.push(response);
          }

          old = response;

          if (old.manuallyCreated) {
            old.shardName = "Manuell hinzugefügt"
            old.shardId = "manually_added"
          }

          let target = response.locationId == "drop_base" ? this.responses : this.getShiftLocationResponseList(response.locationId.split("_")[1], response.locationId.split("_")[2]);
          old.responses = target;
          target.push(old);
          moveItemInArray(
            target,
            target.indexOf(old),
            old.index
          )

          if (response.locationId != "drop_base") {
            let qEvent = this.events.find(x => x.id == response.locationId.split("_")[1]);
            if (!qEvent) throw new Error();
            old.e_start = qEvent.start;
            old.e_end = qEvent.end;
          }
        }

        old.expanded = false;

        // if location was changed
        if (old.locationId != response.locationId || old.index != response.index) {
          let origin = old.locationId == "drop_base" ? this.responses : this.getShiftLocationResponseList(old.locationId.split("_")[1], old.locationId.split("_")[2]);
          let target = response.locationId == "drop_base" ? this.responses : this.getShiftLocationResponseList(response.locationId.split("_")[1], response.locationId.split("_")[2]);

          if (old.locationId == response.locationId) {
            moveItemInArray(
              origin,
              origin.indexOf(old),
              response.index
            )
          } else {
            transferArrayItem(
              origin,
              target,
              origin.indexOf(old),
              response.index
            )

            old.responses = target;
            old.locationId = response.locationId;

            if (response.locationId != "drop_base") {
              let qEvent = this.events.find(x => x.id == response.locationId.split("_")[0]);
              if (!qEvent) throw new Error();
              old.e_start = qEvent.start;
              old.e_end = qEvent.end;
            }
          }

          old.index = response.index;

          // remove custom created drops when in drop_base
          if (old.locationId == "drop_base" && old.manuallyCreated) {
            target.splice(target.indexOf(old), 1);
            old.employee.responses.splice(old.employee.responses.indexOf(old), 1);
          }
        }

        // if employee was changed
        if (old.employee.id != response.employee.id) {
          if (Object.keys(response.updatedEmployeeStats).length > 0) {
            Object.keys(response.updatedEmployeeStats).forEach(employeeId => {
              if (old.employee.id == employeeId) {
                old.employee.employeeStats = response.updatedEmployeeStats[employeeId];
              }
            })
          }


          old.employee.responses.splice(old.employee.responses.indexOf(old), 1);

          if (response.employee.id != null) {
            let employee = this.employees[response.employee.id];
            if (employee == undefined) {
              employee = this.employeeList.find(x => x.id == response.employee.id);
              if (employee != undefined) {
                employee.responses = [];
                this.employees[employee.id] = employee;
              } else {
                throw new Error();
              }
            }

            old.employee = employee;
            old.employee.responses.push(old);
          } else {
            old.employee = response.employee;
          }
        }

        // if set inactive
        if (!response.active) {
          old.employee.responses.splice(old.employee.responses.indexOf(old), 1);
          old.responses?.splice(old.responses?.indexOf(old), 1);
          delete this.responseList[old.id];
        }


        // adapt start and end time
        old.start = response.start;
        old.end = response.end;

        if (old.employee.id != null) {
          old.employee.employeeStats = response.employee.employeeStats;
          old.employee.employeeStats.firstShift = !response.employee.employeeStats.firstShift ? undefined : new Date(response.employee.employeeStats.firstShift);
        }
      })
    }

    if (this.Connections[event].state != HubConnectionState.Disconnected) {

    } else {
      this.Connections[event].start().then(() => {
        console.log("SignalR connected: " + event);
      }).catch((err: any) => {
        return console.error(err);
      });
    }
  }

  reconnect(eventId: string) {
    let connection: HubConnection | undefined = this.Connections[eventId];
    if (connection == undefined) {
      this.globalAlertService.createAlertBannerModel("False", "Ein Fehler ist aufgetreten, bitte Seite neuladen.");
      this.globalAlertService.show();
    } else if (connection.state != HubConnectionState.Disconnected) {

    } else {
      connection.start().then(x => {
        this.selectedDateChangedWrapper()
      }, error => {
        this.globalAlertService.createAlertBannerModel("Fehler", "Verbindung konnte nicht wiederhergestellt werden.", AlertLevel.error, 2000);
        this.globalAlertService.show()
      })
    }
  }

  restoreChanges(previous: EmployeeEventResponseDto, next: EmployeeEventResponseDtoMinimal) {
    // if employee changed
    if (previous.employee.id != next.employeeId) {
      if (previous.employee.id != null) {
        previous.employee.responses.splice(previous.employee.responses.indexOf(previous), 1);
      }

      if (next.employeeId != null) {
        let employee = this.employees[next.employeeId];
        if (employee == undefined) {
          employee = this.employeeList.find(x => x.id == next.employeeId);
          if (employee != undefined) {
            this.employees[employee.id] = employee;
          } else {
            this.globalAlertService.createAlertBannerModel("Fehler", "Ein unbekannter Fehler ist aufgetreten, bitte Seite neu laden.", AlertLevel.error, 3000);
            this.globalAlertService.show();
            return;
          }
        }
        previous.employee = employee;
        previous.employee.responses.push(previous);
      }
    }

    // if location changed
    if (previous.locationId != next.locationId) {
      transferArrayItem(
        previous.locationId == "drop_base" ? this.responses : this.getShiftLocationResponseList(previous.locationId.split("_")[1], previous.locationId.split("_")[2]),
        next.locationId == "drop_base" ? this.responses : this.getShiftLocationResponseList(next.locationId.split("_")[1], next.locationId.split("_")[2]),
        previous.index,
        next.index
      )
      previous.locationId = next.locationId;
      previous.index = next.index;
    }
    // if index changed
    else if (previous.index != next.index && previous.locationId != "drop_base") {
      moveItemInArray(
        previous.locationId == "drop_base" ? this.responses : this.getShiftLocationResponseList(previous.locationId.split("_")[1], previous.locationId.split("_")[2]),
        previous.index,
        next.index
      )
      previous.index = next.index;
    }

    // set basic properties
    previous.start = next.start;
    previous.end = next.end;
    previous.e_start = next.e_start;
    previous.e_end = next.e_end;
    previous.active = next.active;
  }

  endpoint: string = "";

  async SendChange(response: EmployeeEventResponseDto, previous: EmployeeEventResponseDtoMinimal): Promise<ShiftMakerResponse> {
    try {
      return await this.Connections[response.eventId]
        .invoke(this.endpoint, new EmployeeEventResponseDtoMinimal(response), previous.employeeId);

    } catch (e) {
      console.error(e)
      this.restoreChanges(response, previous);
      return new ShiftMakerResponse();
    }
  }

  _large: boolean = false;
  get large(): boolean {
    return this._large;
  }

  set large(value: boolean) {
    this._large = value;
    this.assignEvents(this._large);
  }

  onResize(event: any) {
    let width: number = event.target.innerWidth;
    if (width >= 1536 && !this.large) {
      this.large = true;
    } else if (width < 1536 && this.large) {
      this.large = false;
    }
  }

  height: number = 10;

  onScroll(event: any) {
    if (event.target.innerWidth < 768) return;

    const {x, y} = this.employeeHeader.nativeElement.getBoundingClientRect();
    this.height = event.target.lastChild.clientHeight - y;
  }

  assignEvents(large: boolean) {
    if (large) {
      this.filteredEvents = Object.assign([], this.events);
    } else {
      this.filteredEvents = this.showingEvent ? [this.showingEvent] : [];
    }
  }

  employees: any = {};
  latestDisabledLocations: any = {};
  isUpdating: boolean = false;

  selectedDateChangedWrapper(fromReconnect: boolean = false) {
    let calls: Function[] = [];
    if (this.companyLocations == undefined) {
      calls.push((functions: Function[], index: number, fromReconnect: boolean) => this.loadCompanyLocations(functions, index, fromReconnect))
    }
    if (!fromReconnect) this.employeeList = [];
    if (this.employeeList.length == 0) {
      calls.push((functions: Function[], index: number, fromReconnect: boolean) => this.loadEmployees(functions, index, fromReconnect));
    }
    calls.push((functions: Function[], index: number, fromReconnect: boolean) => this.selectedDateChanged(functions, index, fromReconnect))

    calls[0](calls, 0, fromReconnect);
  }

  selectedDateChanged(functions: Function[], index: number, fromReconnect: boolean) {
    if (this.isUpdating) return;
    else this.isUpdating = true;

    this.setDownloadTimespan(this.selectedDay.date)
    this.edit = !this.platformScannerService.isMobile()

    this.eventHttpService.getDisabledLocations(this.selectedDay.date).subscribe({
      next: x => {
        if (this.companyLocations) {
          Object.keys(this.latestDisabledLocations).forEach(c => {
            if (this.companyLocations[c]) {
              let locationIds = this.latestDisabledLocations[c].map((x: LocationDto) => x.id);
              let locations = this.companyLocations[c].filter((x: LocationDto) => locationIds.indexOf(x.id) > -1);
              locations.forEach((x: LocationDto) => {
                this.companyLocations[c].splice(this.companyLocations[c].indexOf(x), 1);
              })
            }
          })
        }

        this.latestDisabledLocations = {};

        Object.keys(x).forEach(c => {
          let locations: LocationDto[] = x[c];
          this.latestDisabledLocations[c] = locations.filter(x => !x.active)
          locations.forEach(v => {
            if (!this.companyLocations[c] || this.companyLocations[c] == undefined) this.companyLocations[c] = [];
            if (this.companyLocations[c].findIndex((b: LocationDto) => b.id == v.id) > -1) return;
            this.companyLocations[c].push(v)
          })
        })

        // reset everything
        this.employees = {};
        this.events = [];
        this.filteredEvents = [];
        this.responses = [];
        this.shifts = [];
        this.responseList = {};

        if (!this.selectedDay) return;

        // signalr disconnect
        if (!fromReconnect) {
          Object.keys(this.Connections).forEach(x => {
            this.Connections[x].stop().then((r: any) => {
              console.log("SignalR disconnected: " + r)
            }, (error: any) => {
              console.error(error)
            })
          })
        }

        this.eventHttpService.getEmployeeAvailability(this.selectedDay.date).subscribe(x => {
          this.events = x.item1!;
          if (this.events.length > 0) this.showingEvent = this.events[0];
          else this.showingEvent = undefined;
          this.assignEvents(this.large)

          // create rooms
          this.shifts = [];
          this.events.forEach(x => {
            let storage = new ShiftStorage(x);
            this.companyLocations[x.shardId].forEach((l: LocationNl) => {
              storage.shiftLocations.push(new ShiftLocation(l))
            })
            this.shifts.push(storage);
          })

          if (!fromReconnect) {
            // signalr connect
            this.events.forEach(e => {
              this.SignalRConnect(e.id)
            })
          }

          //this.responses = x.item2!;
          x.item2!.forEach(r => {
            r.expanded = false;
            this.responseList[r.id] = r;

            let qEvent = this.events.find(x => x.id == r.locationId.split("_")[1]);
            if (qEvent == undefined && r.locationId != "drop_base") throw new Error();
            else if (qEvent != undefined) {
              r.e_start = qEvent.start;
              r.e_end = qEvent.end;
            }

            if (r.manuallyCreated && !r.employee) {
              r.employee = new EmployeeNl();
              // @ts-ignore
              r.employee.id = null;
            }

            if (r.manuallyCreated) {
              r.shardName = "Manuell hinzugefügt"
              r.shardId = "manually_added"
            }

            // putting the responses in the right location
            if (r.locationId == "drop_base") {
              this.responses.push(r);
              r.responses = this.responses;
            } else {
              let split = r.locationId.split("_");
              let responses = this.getShiftLocationResponseList(split[1], split[2]);
              responses.push(r);
              r.responses = responses;
            }

            if (r.employee.id != null) {
              // creating one-object employees
              if (!this.employees[r.employee.id]) {
                this.employees[r.employee.id] = r.employee;
                r.employee.responses = [];
              } else r.employee = this.employees[r.employee.id] as EmployeeNl;
            }

            r.employee.responses.push(r);
          })

          if (this.nextSelectedEvent) {
            let event = this.events.find(x => x.id == this.nextSelectedEvent);
            if (event != undefined) {
              this.showingEvent = event;
              this.assignEvents(this.large);
            }
          }

          this.isUpdating = false;
        }, error => {
          this.globalAlertService.createAlertBannerModel("Fehler", "Tag konnte nicht geladen werden.", AlertLevel.error, 2000);
          this.globalAlertService.show();

          this.isUpdating = false;
        })

      }, error: error => {
        this.globalAlertService.createAlertBannerModel("Fehler", "Beim Laden ist ein Fehler aufgetreten, bitte Seite aktualisieren.", AlertLevel.error, 2000);
        this.globalAlertService.show()

        this.isUpdating = false;
      }
    })
  }

  dateRangeChanged() {
    this.eventHttpService.getPrivatePublicEvents(this.calendarRange!.item1!, this.calendarRange!.item2!).subscribe(x => {
      this.calendarEntries = x.map(x => new CalendarEntry(x.start, x.end, x.color, x.id))
    }, error => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Events konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  getEventName(id: string): string | undefined {
    return this.events.find(x => x.id == id)?.name
  }

  getEmployeeName(id: string): string | undefined {
    let employee = this.employeeList.find(x => x.id == id);
    if (employee == undefined) return undefined;
    return (employee.firstName + " " + employee.lastName).trim()
  }

  loadCompanyLocations(functions: Function[], index: number, fromReconnect: boolean) {
    this.locationHttpService.getCompanyLocations().subscribe({
      next: x => {
        this.companyLocations = x;
        console.log(x)
        if (functions.length - 1 > index) functions[index + 1](functions, index + 1, fromReconnect)
      },
      error: err => {
        this.globalAlertService.createAlertBannerModel("Fehler", "Beim Laden der Verkaufspunktkonfigurationen ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
        this.globalAlertService.show();
        console.error(err)
      }
    })
  }

  createShift() {
    let shift = this.responses.find(x => x.shardId == "manually_added")
    if (shift != undefined) {
      this.globalAlertService.createAlertBannerModel("Warnung", "Es darf sich nur ein freier Mitarbeiterplatz in der Auswahl befinden.", AlertLevel.warning, 2000);
      this.globalAlertService.show()
      return;
    }


    shift = new EmployeeEventResponseDto();
    shift.index = 0;
    shift.responses = this.responses;
    shift.locationId = "drop_base";
    shift.shardName = "Manuell hinzugefügt"
    shift.shardId = "manually_added"
    shift.active = true;
    shift.employee = new EmployeeNl();
    // @ts-ignore
    shift.employee.id = null;
    shift.manuallyCreated = true;
    this.responses.splice(0, 0, shift);
  }

  getEmployees(): EmployeeNl[] {
    return Object.values(this.employees);
  }

  employeeList: EmployeeNl[] = [];

  loadEmployees(functions: Function[], index: number, fromReconnect: boolean) {
    this.employeeHttpService.listNl(this.selectedDay.date).subscribe(x => {
      this.employeeList = x;
      this.employeeList.forEach(x => {
        x.name = x.firstName + " " + x.lastName
      })
      if (functions.length - 1 > index) functions[index + 1](functions, index + 1, fromReconnect)
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiter konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show()
    })
  }

  setEmployee(response: EmployeeEventResponseDto, employeeId: string) {
    let previous: EmployeeEventResponseDtoMinimal = new EmployeeEventResponseDtoMinimal(response);
    let pEmployee = response.employee
    if (employeeId == null) {
      response.employee.responses.splice(response.employee.responses.indexOf(response), 1);
      response.employee = new EmployeeNl();
      response.employee.id = employeeId;
    } else {
      let lEmployee: EmployeeNl | undefined = this.employees[employeeId];
      if (!lEmployee) {
        lEmployee = this.employeeList.find(x => x.id == employeeId);
        if (lEmployee) {
          lEmployee.responses = [];
          this.employees[employeeId] = lEmployee;
        }
      }
      if (!lEmployee) {
        this.globalAlertService.createAlertBannerModel("Fehler", "Mitarbeiter konnte nicht gespeichert werden.", AlertLevel.error, 2000);
        this.globalAlertService.show();
        return;
      }

      response.employee.responses.splice(response.employee.responses.indexOf(response), 1);
      response.employee = lEmployee;
      response.employee.responses.push(response);
    }

    if (response.locationId != "drop_base") this.SendChange(response, previous).then(r => {
      if (r.success) {
        this.dateHelperService.convertDates(r);
        if (response.employee.id != null) {
          if (r.employeeEventResponse != undefined) {
            response.employee.employeeStats = r.employeeEventResponse.employee.employeeStats;
            response.employee.employeeStats.firstShift = r.employeeEventResponse.employee.employeeStats.firstShift == undefined ? undefined : new Date(r.employeeEventResponse.employee.employeeStats.firstShift);
          }
        }

        if (r.employeeEventResponse && Object.keys(r.employeeEventResponse.updatedEmployeeStats).length > 0) {
          Object.keys(r.employeeEventResponse.updatedEmployeeStats).forEach(employeeId => {
            let employee: EmployeeNl = this.employees[employeeId];
            if (employee != undefined) {
              employee.employeeStats = r.employeeEventResponse!.updatedEmployeeStats[employeeId];
            }
          })
        }
      }
    })

    if (this.platformScannerService.getPlatform() == Platform.Safari) {
      let element: HTMLElement = document.getElementById(response.locationId) as HTMLElement;
      element.click();
    }
  }

  disableResponse(response: EmployeeEventResponseDto) {
    let c = confirm(`Bitte das Deaktivieren der Verfügbarkeit für ${response.employee.firstName} ${response.employee.lastName} bestätigen.`);
    if (!c) return;

    let previous: EmployeeEventResponseDtoMinimal = new EmployeeEventResponseDtoMinimal(response);
    if (response.locationId != "drop_base") {
      this.globalAlertService.createAlertBannerModel("Fehler", "Es können nur nicht genutzte Mitarbeiter-Verfügbarkeiten entfernt werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
      return;
    }
    if (response.manuallyCreated) {
      this.globalAlertService.createAlertBannerModel("Fehler", "Um eine manuell erstellte Verfügbarkeit zu löschen bitte erneut in die verfügbare Liste ziehen.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    }

    response.active = false;

    this.SendChange(response, previous).then(r => {
      if (r.success) {
        this.globalAlertService.createAlertBannerModel("Erfolg", "Verfügbarkeit wurde deaktiviert.", AlertLevel.success, 2000);
        this.globalAlertService.show()
      } else {
        this.globalAlertService.createAlertBannerModel("Fehler", "Beim Speichern ist ein Fehler aufgetreten.", AlertLevel.error, 2000);
        this.globalAlertService.show();
      }
    })
  }


  getShardLocations(shard: string): LocationNl[] | undefined {
    if (!this.companyLocations || !this.companyLocations[shard]) return;
    return (this.companyLocations[shard] as LocationNl[])
    //.sort((a,b) => {
    //if (a.order - b.order != 0) return a.order - b.order;
    //if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    //if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
    //return 0;
    //});
  }

  getEventColor(id: string): string {
    return this.events.find(x => x.id == id)?.color ?? "#000000";
  }

  getShiftLocationResponseList(eventId: string, locationId: string): EmployeeEventResponseDto[] {
    let location = this.shifts.find(x => x.event.id == eventId)?.shiftLocations.find(x => x.location.id == locationId)?.responses;
    if (location == undefined) {
      throw new Error();
    }
    return location;
  }

  availabilityDropped(event: CdkDragDrop<EmployeeEventResponseDto[]>) {
    let previous: EmployeeEventResponseDtoMinimal = new EmployeeEventResponseDtoMinimal(event.previousContainer.data[event.previousIndex]);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // update response dto for new index
      event.container.data[event.currentIndex].index = event.currentIndex;
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      )

      // update response dto for new location
      event.container.data[event.currentIndex].locationId = event.container.id;
      event.container.data[event.currentIndex].index = event.currentIndex;

      event.container.data[event.currentIndex].responses = event.container.data;
      if (event.container.id != "drop_base" && event.container.data[event.currentIndex].shardId != "manually_added" && event.container.id.split("_")[3] != event.container.data[event.currentIndex].shardId) {
        this.globalAlertService.createAlertBannerModel("Warnung", "Mitarbeiter hat Verfügbarkeit für einen anderen Ort angegeben!", AlertLevel.warning, 2000);
        this.globalAlertService.show();
      } else if (event.container.id != "drop_base" && event.container.data[event.currentIndex].shardId != "manually_added" && event.container.id.split("_")[1] != event.container.data[event.currentIndex].eventId) {
        this.globalAlertService.createAlertBannerModel("Warnung", "Mitarbeiter hat Verfügbarkeit für eine andere Veranstaltung angegeben!", AlertLevel.warning, 2000);
        this.globalAlertService.show();
      }
    }


    if (event.container.id == "drop_base" && event.container.data[event.currentIndex]) {
      event.container.data[event.currentIndex].start = undefined;
      event.container.data[event.currentIndex].end = undefined;
    } else {
      let qEvent = this.events.find(x => x.id == event.container.id.split("_")[1]);

      if (qEvent == null) return;

      if (event.container.data[event.currentIndex] && !event.container.data[event.currentIndex].start) {
        event.container.data[event.currentIndex].start = qEvent.start;
        event.container.data[event.currentIndex].e_start = qEvent.start;
      }
      if (event.container.data[event.currentIndex] && !event.container.data[event.currentIndex].end) {
        // event.container.data[event.currentIndex].end = qEvent.end;
        event.container.data[event.currentIndex].e_end = qEvent.end;
      }
    }

    if (event.container.data[event.currentIndex].shardId == "manually_added" && event.container.id != "drop_base") {
      event.container.data[event.currentIndex].eventId = event.container.id.split("_")[1];
    }

    let old = event.container.data[event.currentIndex];
    this.SendChange(event.container.data[event.currentIndex], previous).then(r => {
      if (r.success) {
        if (old.manuallyCreated && old.locationId == "drop_base") {
          event.container.data.splice(event.container.data.indexOf(old), 1);
          old.employee.responses.splice(old.employee.responses.indexOf(old), 1);
        } else if (old.manuallyCreated && old.locationId != "drop_base" && old.id == "") {
          if (old.employee && old.employee.id != null) {
            old.employee.responses.splice(old.employee.responses.indexOf(old), 1);
          }
          event.container.data.splice(event.container.data.indexOf(old), 1);
        }
      }

      if (r.success) {
        this.dateHelperService.convertDates(r);
        if (old.employee.id != null) {
          if (r.employeeEventResponse != undefined) {
            old.employee.employeeStats = r.employeeEventResponse.employee.employeeStats;
            old.employee.employeeStats.firstShift = r.employeeEventResponse.employee.employeeStats.firstShift == undefined ? undefined : new Date(r.employeeEventResponse.employee.employeeStats.firstShift);
          }
        }

        if (!old.responses) return;
        old.responses.forEach(x => {
          let previous: EmployeeEventResponseDtoMinimal = new EmployeeEventResponseDtoMinimal(x);
          x.index = old.responses!.indexOf(x);
          this.SendChange(x, previous).then(r => {
            if (r.success) {
              this.dateHelperService.convertDates(r);
              if (x.employee.id != null) {
                if (r.employeeEventResponse != undefined) {
                  x.employee.employeeStats = r.employeeEventResponse.employee.employeeStats;
                  x.employee.employeeStats.firstShift = r.employeeEventResponse.employee.employeeStats.firstShift == undefined ? undefined : new Date(r.employeeEventResponse.employee.employeeStats.firstShift);
                }
              }
            }
          });
        })
      }
    });


  }

  getDropArray(): string[] {
    let dropIds: string[] = [];
    this.shifts.forEach(storage => {
      storage.shiftLocations.forEach(location => {
        dropIds.push("drop_" + storage.event.id + "_" + location.location.id + "_" + storage.event.shardId)
      })
    })
    dropIds.push("drop_base");
    return dropIds;
  }

  getEventIndex(event: EventNl): number {
    return this.events.indexOf(event);
  }

  getPreviousEvent(event: EventNl): EventNl {
    return this.events[(this.getEventIndex(event) + (this.events.length - 1)) % this.events.length];
  }

  getNextEvent(event: EventNl): EventNl {
    return this.events[(this.getEventIndex(event) + 1) % this.events.length]
  }

  isColliding(response: EmployeeEventResponseDto): boolean {
    return (response.responses?.filter(x => x.start?.toISOString() == response.start?.toISOString() && x != response).length ?? 0) > 0;
  }

  // if time is over 24 hours
  timeWarning(response: EmployeeEventResponseDto): boolean {
    if (response.start == undefined || response.end == undefined) return false;
    return response.end.getTime() - response.start.getTime() >= 86400000;
  }

  firstShift(response: EmployeeEventResponseDto): boolean {
    if (response.employee.id == null) return false;
    if (response.employee.employeeStats.firstShift == undefined) return true;

    if (response.start == undefined) {
      let event = this.events.find(x => x.id == response.eventId);
      if (event == undefined) return false;

      return event.start <= response.employee.employeeStats.firstShift;
    }
    return response.start <= response.employee.employeeStats.firstShift;
  }

  hasRelationship(employee: EmployeeNl): boolean {
    return employee.employeeStats.employmentRelationship != undefined;
  }

  isOverLimit(stats: EmployeeStatsDto): boolean {
    if (!stats.employmentRelationship?.limit || !stats.hoursWorked) return false;
    return stats.employmentRelationship.limit < EmployeeStatsHelper.getTotal(stats)
  }

  alreadyPlanned(response: EmployeeEventResponseDto): boolean {
    return response.employee.responses.find(x => x.locationId != "drop_base") != undefined;
  }

  isDoubleEmployee(response: EmployeeEventResponseDto): boolean {
    if (!response.employee) return false;
    response.employee.responses = response.employee.responses.sort((a, b) => {
      if (!a.start || !b.start) return 0;
      if (a.start < b.start) return -1;
      if (a.start > b.start) return 1;
      return 0;
    })

    if (response.employee.responses.length < 2) return false;
    for (let i = 1; i < response.employee.responses.length; i++) {
      if (!response.employee.responses[i].start || !response.employee.responses[i - 1].end) continue;
      if (response.employee.responses[i].start! < response.employee.responses[i - 1].end!) return true;
    }
    return false;
  }

  isOverlapping(response: EmployeeEventResponseDto): boolean {
    if (!response.employee) return false;

    let overlapping = false;
    let comparable = response.employee.responses.filter(x => x.locationId != "drop_base");
    comparable = comparable.sort((a, b) => {
      if ((a.start ?? a.e_start!) < (b.start ?? b.e_start!)) return -1;
      if ((a.start ?? a.e_start!) > (b.start ?? b.e_start!)) return 1;
      if ((a.end ?? a.e_end!) < (b.end ?? b.e_end!)) return -1;
      if ((a.end ?? a.e_end!) > (b.end ?? b.e_end!)) return 1;
      if (a.locationId != "drop_base" && b.locationId != "drop_base") overlapping = true;
      return 0;
    })
    if (overlapping) return true;

    if (comparable.length < 2) return false;
    for (let i = 1; i < comparable.length; i++) {
      if ((comparable[i].start ?? comparable[i].e_start!) < (comparable[i - 1].end ?? comparable[i - 1].e_end!)) return true;
    }
    return false;
  }

  isEndBeforeStart(response: EmployeeEventResponseDto): boolean {
    if (!response.start || !response.end) return false;
    return response.start >= response.end;
  }

  isTimeNotSet(response: EmployeeEventResponseDto, key: Side): boolean {
    if (key == Side.Left) return response.start == undefined;
    if (key == Side.Right) return response.end == undefined;
    return false;
  }

  sort(responses: EmployeeEventResponseDto[]) {
    responses.sort((a, b) => {
      if (!a.start || !b.start) return 0;
      if (a.start < b.start) return -1;
      if (a.start > b.start) return 1;
      return 0;
    })
    responses.forEach(x => {
      let previous: EmployeeEventResponseDtoMinimal = new EmployeeEventResponseDtoMinimal(x);
      x.index = responses.indexOf(x);
      this.SendChange(x, previous).then(r => {
        if (r.success) {
          this.dateHelperService.convertDates(r);
          if (x.employee.id != null) {
            if (r.employeeEventResponse != undefined) {
              x.employee.employeeStats = r.employeeEventResponse.employee.employeeStats;
              x.employee.employeeStats.firstShift = r.employeeEventResponse.employee.employeeStats.firstShift == undefined ? undefined : new Date(r.employeeEventResponse.employee.employeeStats.firstShift);
            }
          }
        }
      });
    })
  }

  stringToDate(response: EmployeeEventResponseDto, string: string, key: Side) {
    let previous: EmployeeEventResponseDtoMinimal = new EmployeeEventResponseDtoMinimal(response);

    switch (key) {
      case Side.Left:
        response.start = string == "" ? undefined : new Date(string)
        break;
      case Side.Right:
        response.end = string == "" ? undefined : new Date(string)
        break;
    }

    this.SendChange(response, previous).then(r => {
      if (r.success) {
        this.dateHelperService.convertDates(r);
        if (response.employee.id != null) {
          if (r.employeeEventResponse != undefined) {
            response.employee.employeeStats = r.employeeEventResponse.employee.employeeStats;
            response.employee.employeeStats.firstShift = r.employeeEventResponse.employee.employeeStats.firstShift == undefined ? undefined : new Date(r.employeeEventResponse.employee.employeeStats.firstShift);
          }
        }
      }
    });
  }

  setEventStartDate(response: EmployeeEventResponseDto) {
    let event = response.locationId.split("_")[1];
    let qEvent = this.events.find(x => x.id == event);
    if (qEvent == undefined) return;

    this.stringToDate(response, TimeUtilities.dateToString(qEvent.start), Side.Left);
  }

  setEventEndDate(response: EmployeeEventResponseDto) {
    let event = response.locationId.split("_")[1];
    let qEvent = this.events.find(x => x.id == event);
    if (qEvent == undefined) return;

    let now = new Date();

    if (qEvent.start <= now && qEvent.end > now) {
      let minutes = now.getMinutes()
      const segment = 60 / this.settings.segmentsPerHour;
      let factor = Math.floor(minutes / segment);
      let left = minutes % segment;
      if (left >= this.settings.segmentRoundingPoint) factor++;
      now.setMinutes(factor * segment, 0, 0);
      this.stringToDate(response, TimeUtilities.dateToString(now), Side.Right);
    } else {
      this.stringToDate(response, TimeUtilities.dateToString(qEvent.end), Side.Right);
    }
  }

  selectChanged(event: any) {
    let target = event.target as HTMLElement;
    target.parentElement!.parentElement!.parentElement!.attributes.getNamedItem("cdkDragDisabled")
  }


  employeesPerResponse(event: EventNl, state: ResponseState) {
    event.state = state;
    event.employeesPerResponse = this.employeeList.filter(
      x => (state == ResponseState.Declined ? event.deniedIds : state == ResponseState.Accepted ? event.acceptedIds : event.pendingIds).indexOf(x.id) > -1
    ).sort((a, b) => {
      if ((a.firstName + a.lastName).toLowerCase() < (b.firstName + b.lastName).toLowerCase()) return -1;
      if ((a.firstName + a.lastName).toLowerCase() > (b.firstName + b.lastName).toLowerCase()) return 1;
      return 0;
    })
  }

  closeEmployeesPerResponse(event: EventNl) {
    event.employeesPerResponse = undefined;
  }

  mouseEvent?: MouseEvent;
  visible: boolean = false;
  eVisible: any = {};
  popupEmployee: EmployeeNl | undefined;

  redirectPath: any = {};
  queryParams: any = {};

  openPopup(e: any, employee: EmployeeNl, response: EmployeeEventResponseDto) {
    this.redirectPath = ['/' + Routes.CompanyModule + "/" + CompanyRoutes.ShiftMaker]
    this.queryParams = {'event': response.locationId == "drop_base" ? response.eventId : response.locationId.split("_")[1]}
    this.queryParams['date'] = this.filteredEvents.find(x => x.id == this.queryParams['event'])?.start.toISOString();

    this.popupEmployee = employee;
    this.mouseEvent = e;

    this.eVisible = {}
    this.eVisible[this.queryParams["event"] ?? "drop_base"] = true;
    //this.visible = true;
  }

  getRelative(): boolean {
    return window.innerWidth >= 768;
  }

  lock(event: EventNl) {
    if (!confirm("Möchten Sie die Anmeldefrist wirklich beenden?")) return;

    this.eventHttpService.changePublicResponseTime(event.id, new Date(), event.shardId)
      .subscribe({
        next: value => {
          event.publicResponseEnd = value.publicResponseEnd;
          this.now = new Date();
        },
        error: err => {
          console.error(err);
          this.globalAlertService.createAndShow("Fehler", "Fehler beim Sperren des Events.", AlertLevel.error);
        }
      })
  }

  protected readonly TimeUtilities = TimeUtilities;
  protected readonly Side = Side;
  protected readonly Object = Object;
  protected readonly Platform = Platform;
  protected readonly HubConnectionState = HubConnectionState;
  protected readonly filter = filter;
  protected readonly Routes = Routes;
  protected readonly CompanyRoutes = CompanyRoutes;
  protected readonly ResponseState = ResponseState;
  protected readonly location = location;
  protected readonly JSON = JSON;
  protected readonly ServerEndpoints = ServerEndpoints;
  protected readonly LocationRoutes = LocationRoutes;
  protected readonly CalculationType = CalculationType;


  protected readonly EmployeeStatsHelper = EmployeeStatsHelper;
}
