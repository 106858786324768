import { Component } from '@angular/core';

@Component({
  selector: 'layout-page-container',
  standalone: true,
  imports: [],
  templateUrl: './page-container.component.html',
  styleUrl: './page-container.component.scss'
})
export class PageContainerComponent {

}
