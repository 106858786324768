import {finalize, Observable, of, switchMap, tap} from "rxjs";
import {LoadingIndicatorService} from "./loading-indicator.service";

// Define startWithTap
export function startWithTap<T>(callback: () => void) {
  return (source: Observable<T>) =>
    of({}).pipe(
      tap(callback),
      switchMap(() => source)
    );
}

// Define withLoadingIndicator
export function withLoadingIndicator<T>(this: Observable<T>): Observable<T> {
  return this.pipe(
    startWithTap(() => LoadingIndicatorService.count++),
    finalize(() => LoadingIndicatorService.count--)
  );
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    withLoadingIndicator: typeof withLoadingIndicator;
  }
}

(Observable.prototype as any).withLoadingIndicator = withLoadingIndicator;
