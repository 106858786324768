import {ModelBase} from "./model-base";
import {ChangeableProperty} from "../x-components/settings/changeable-property";
import {PermissionSet} from "../_auth/Models/PermissionSet";
import {Tuple} from "./_generic/tuple";

export class SettingsPageModel {
  public changeableProperties: ChangeableProperty = new ChangeableProperty();
  public originalPropertyValue: any;
  public propertyTitle: string = "";
  public propertyTitleFunction: (() => string) | undefined;
  public requiredPermission: PermissionSet | undefined;
  public inputType: string | undefined;
  public order: number = Infinity;
  public items: Tuple<any, any>[] = [];
  public isClearable: boolean = false;
}
