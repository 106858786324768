import {EventEmitter, Injectable} from '@angular/core';
import {WindowState} from "../x-models/window-state";
import {CookieService} from "./cookie.service";
import {CookieType} from "../x-models/x-enums/cookie-type";

@Injectable({
  providedIn: 'root'
})
export class WindowStateService {
  constructor(private cookieService: CookieService) {
    let last = localStorage.getItem('w_state');
    if (last == null) {
      this.state = new WindowState();
      // this.setWindowState();
    }
    else {
      this.state = JSON.parse(last!);
      this.tabLoaded.emit(this.state);
    }
  }

  setWindowState() {
    this.cookieService.setLocalStorage(CookieType.Functional, 'w_state', JSON.stringify(this.state));
  }

  setTab(tab: string, expand: boolean) {
    this.state.tab = tab;
    this.state.expanded = expand;
    this.setWindowState();
  }

  tabLoaded: EventEmitter<WindowState | undefined> = new EventEmitter<WindowState | undefined>();

  state!: WindowState;
}
