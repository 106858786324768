import {Injectable} from '@angular/core';
import {DtoBaseService} from "../../../../_services/dto-services/dto-base.service";
import {SupplierDto} from "../../x-models/supplier-dto";
import {SupplierHttpService} from "../../x-http-requests/supplier-http.service";
import {Observable} from 'rxjs';
import {DtoServices} from 'src/app/_services/dto-services/_enums/dto-services';
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {DtoSignalRService} from "../../../../_services/dto-services/dto-signal-r.service";

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends DtoBaseService<SupplierDto, SupplierHttpService> {
  public Identifier: DtoServices = DtoServices.SupplierService;
  FetchAll(all: boolean): Observable<SupplierDto[]> {
      return this.HttpService.list();
  }
  FetchById(id: string, all: boolean): Observable<SupplierDto> {
      return this.HttpService.get(id);
  }
  Add(payload: any): Observable<SupplierDto> {
      return this.HttpService.add(payload.Name);
  }
  GetId(entity: SupplierDto): string {
      return entity.id;
  }

  constructor(supplierHttpService: SupplierHttpService,
              globalAlertService: GlobalAlertService,
              dtoSignalRService: DtoSignalRService) {
    super(supplierHttpService, globalAlertService, dtoSignalRService);
    this.Initialize();
  }
}
