import {Component, Input} from '@angular/core';
import {LocationDto} from "../../../../models/location-dto";
import {EmploymentRelationshipDto} from "../../x-models/employment-relationship-dto";
import {AlertLevel} from "../../../../enums/alert-level";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../../../_auth/permission.service";
import {EmploymentRelationshipHttpService} from "../../x-http-requests/employment-relationship-http.service";
import {GlobalAlertService} from "../../../../_services/global-alert.service";
import {SettingsPageComponent} from "../../../../x-components/settings/settings-page/settings-page.component";
import {Routes} from "../../../../enums/routes";
import {SettingsInputElementModel} from "../../../../models/settings-input-element-model";
import {EmployeeDto} from "../../x-models/employee-dto";
import {HttpPropertyChangeStatus} from "../../../../enums/http-property-change-status";
import {ObservableQueueElement} from "../../../../models/observable-queue-element";
import {CompanyRoutes} from "../../x-models/x-enums/company-routes";
import {EventDto} from "../../../../models/event/event-dto";

@Component({
  selector: 'app-employment-relationship-settings',
  templateUrl: '../../../../x-components/settings/settings-page/settings-page.component.html',
  styleUrls: ['./employment-relationship-settings.component.scss']
})
export class EmploymentRelationshipSettingsComponent extends SettingsPageComponent {
  public redirectRoute: string = Routes.CompanyModule + "/" + CompanyRoutes.EmployeeRelationships;
  public settingsInputElementModel: { [key: string]: SettingsInputElementModel } = {
    newEmploymentRelationshipName: new SettingsInputElementModel(),
    newEmploymentRelationshipSalary: new SettingsInputElementModel(),
    newEmploymentRelationshipLimit: new SettingsInputElementModel(),
    newEmploymentRelationshipMultiplier: new SettingsInputElementModel()
  };

  constructor(route: ActivatedRoute,
              PermissionService: PermissionService,
              public employmentRelationshipHttpService: EmploymentRelationshipHttpService,
              router: Router,
              globalAlertService: GlobalAlertService) {
    super(route, PermissionService, router, globalAlertService);

    this.settingsInputElementModel.newEmploymentRelationshipName.propertyTitle = "Name";
    this.settingsInputElementModel.newEmploymentRelationshipName.requiredPermission = PermissionService.Company_EmploymentRelationship_ChangeName()
    this.settingsInputElementModel.newEmploymentRelationshipName.order = 1;

    this.settingsInputElementModel.newEmploymentRelationshipSalary.propertyTitle = "Stundenlohn (Euro / h)";
    this.settingsInputElementModel.newEmploymentRelationshipSalary.requiredPermission = PermissionService.Company_EmploymentRelationship_SetValues()
    this.settingsInputElementModel.newEmploymentRelationshipSalary.order = 2;
    this.settingsInputElementModel.newEmploymentRelationshipSalary.inputType = "number";

    this.settingsInputElementModel.newEmploymentRelationshipLimit.propertyTitle = "Grenze (Euro)";
    this.settingsInputElementModel.newEmploymentRelationshipLimit.requiredPermission = PermissionService.Company_EmploymentRelationship_SetValues()
    this.settingsInputElementModel.newEmploymentRelationshipLimit.order = 3;
    this.settingsInputElementModel.newEmploymentRelationshipLimit.inputType = "number";

    this.settingsInputElementModel.newEmploymentRelationshipMultiplier.propertyTitle = "Multiplikator";
    this.settingsInputElementModel.newEmploymentRelationshipMultiplier.requiredPermission = PermissionService.Company_EmploymentRelationship_SetValues()
    this.settingsInputElementModel.newEmploymentRelationshipMultiplier.order = 4;
    this.settingsInputElementModel.newEmploymentRelationshipMultiplier.inputType = "number";

    this.deletePermission = PermissionService.CheckPermission(PermissionService.Company_EmploymentRelationship_Delete());
  }

  public model?: EmploymentRelationshipDto;
  public timeoutClick: boolean = false;

  deleteModel = () => {
    if (this.model === undefined || !this.deletePermission) return;
    this.employmentRelationshipHttpService.delete(this.model.id).subscribe(() => {
      this.globalAlertService.createAlertBannerModel("Verhältnis gelöscht", "Verhältnis wurde erfolgreich gelöscht", AlertLevel.success, 5000)
      this.globalAlertService.show();
    });
  };

  @Input() loadInputModel = (id: string) => {
    this.employmentRelationshipHttpService.get(id).subscribe((employmentRelationship: EmploymentRelationshipDto) => {
        this.model = employmentRelationship;
        this.settingsInputElementModel.newEmploymentRelationshipName.originalPropertyValue = this.settingsInputElementModel.newEmploymentRelationshipName.changeableProperties.newPropertyValue = employmentRelationship.name;
        this.settingsInputElementModel.newEmploymentRelationshipSalary.originalPropertyValue = this.settingsInputElementModel.newEmploymentRelationshipSalary.changeableProperties.newPropertyValue = employmentRelationship.salary;
        this.settingsInputElementModel.newEmploymentRelationshipLimit.originalPropertyValue = this.settingsInputElementModel.newEmploymentRelationshipLimit.changeableProperties.newPropertyValue = employmentRelationship.limit;
        this.settingsInputElementModel.newEmploymentRelationshipMultiplier.originalPropertyValue = this.settingsInputElementModel.newEmploymentRelationshipMultiplier.changeableProperties.newPropertyValue = employmentRelationship.multiplier;

        this.updateElements();
      }
    )
  }

  @Input() saveChanges: Function = () => {
    if (this.model === undefined) return;

    this.requiresChanges.push(this.saveName());
    this.requiresChanges.push(this.savePublicProperty("Salary", this.settingsInputElementModel.newEmploymentRelationshipSalary))
    this.requiresChanges.push(this.savePublicProperty("Limit", this.settingsInputElementModel.newEmploymentRelationshipLimit))
    this.requiresChanges.push(this.savePublicProperty("Multiplier", this.settingsInputElementModel.newEmploymentRelationshipMultiplier))

    if (!this.changeRequestRequired()) return;

    this.executeQueueWrapper()
  }

  saveName(): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEmploymentRelationshipName.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (this.settingsInputElementModel.newEmploymentRelationshipName.changeableProperties.newPropertyValue === this.settingsInputElementModel.newEmploymentRelationshipName.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;


    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employmentRelationshipHttpService.changeName(this.model.id, this.settingsInputElementModel.newEmploymentRelationshipName.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EmploymentRelationshipDto) => {
      this.model = model;
      this.settingsInputElementModel.newEmploymentRelationshipName.originalPropertyValue = this.settingsInputElementModel.newEmploymentRelationshipName.changeableProperties.newPropertyValue = model.name;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    }
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Namens.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;
    }

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }

  savePublicProperty(name: string, cModel: SettingsInputElementModel): HttpPropertyChangeStatus {
    if (this.model === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === undefined) return HttpPropertyChangeStatus.NotChanged;
    if (cModel.changeableProperties.newPropertyValue === cModel.originalPropertyValue) return HttpPropertyChangeStatus.NotChanged;

    let observableQueueElement: ObservableQueueElement = new ObservableQueueElement();
    observableQueueElement.observable = this.employmentRelationshipHttpService.setValues(this.model.id, name, cModel.changeableProperties.newPropertyValue);
    observableQueueElement.callback = (model: EmploymentRelationshipDto) => {
      this.model = model;
      cModel.originalPropertyValue = cModel.changeableProperties.newPropertyValue;
      observableQueueElement.successIndicator = true;
      this.updateElements();
    };
    observableQueueElement.callbackError = (error: any) => {
      this.globalAlertService.createAlertBannerModel("Fehler", "Fehler beim Speichern des Werts.", AlertLevel.error, 5000);
      this.globalAlertService.show();
      observableQueueElement.successIndicator = false;

    };

    this.observableQueue.push(observableQueueElement);

    return HttpPropertyChangeStatus.Changed;
  }
}
