<div class="fixed inset-0 z-[51] bg-gray-700 opacity-70 cursor-pointer" *ngIf="CookieService.settingsVisible" (click)="CookieService.closeSettings()"></div>
<div class="fixed inset-0 z-[51] pointer-events-none flex" *ngIf="CookieService.settingsVisible">
  <div class="m-auto max-w-xl bg-white rounded-md pointer-events-auto p-4">
    <div class="flex w-full pb-3">
      <h2 class="text-lg font-bold">Cookie Einstellungen</h2>
      <a href="/static/privacy.html" target="_blank" class="cursor-pointer text-theme-primary-700 hover:text-theme-primary-900 ml-auto place-self-end">Datenschutzbestimmungen</a>
    </div>
    <fieldset>
      <legend class="sr-only">Cookie Settings</legend>
      <div class="space-y-5">
        <div class="relative flex items-start" *ngFor="let cookie of CookieService.cookies">
          <div class="flex h-6 items-center">
            <app-switch-medium [disabled]="cookie.forced" [(isOn)]="cookie.enabled"></app-switch-medium>
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="required-cookies" class="text-lg font-light text-gray-900">{{ cookie.name }}</label>
            <p id="required-cookies" class="text-gray-500">{{ cookie.description }}</p>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="ml-auto flex flex-row-reverse pt-3 gap-x-3">
      <button (click)="CookieService.acceptAll()" type="button" class="rounded-md bg-theme-primary-700 hover:bg-theme-primary-900 px-3.5 py-2.5 text-sm font-semibold text-white">Alle akzeptieren</button>
      <button (click)="CookieService.denyAll()" type="button" class="rounded-md border-2 border-theme-primary-700 hover:border-theme-primary-900 px-3.5 py-2.5 text-sm font-semibold text-theme-primary-700 hover:text-theme-primary-900">Alle ablehnen</button>
      <button (click)="CookieService.saveSettings()" type="button" class="rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-700 hover:text-gray-900">Speichern</button>
    </div>
  </div>
</div>
