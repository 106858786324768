<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<div class="mx-auto max-w-md sm:max-w-3xl">
  <div>
    <div class="text-center grid place-content-center">
      <div class="grid place-content-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
          <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
        </svg>
      </div>
      <h2 (click)="creator = !creator" class="select-none mt-2 text-base font-semibold leading-6 text-gray-900 hover:text-theme-primary-700 cursor-pointer">
        Add Users
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" [class.rotate-180]="creator" class="mt-2 transition-all mx-auto bi bi-arrow-bar-down" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
        </svg>
      </h2>
      <p class="mt-1 text-sm text-gray-500" *ngIf="users != undefined && users.length < 1">You haven’t added any users yet.</p>
    </div>
    <div class="border-b border-gray-900/10 p-2 rounded-md pb-12 bg-gray-100 mt-4" *ngIf="creator">
      <div class="grid grid-cols-1 gap-x-6 sm:grid-cols-6" [class.gap-y-8]="creator">
        <div class="sm:col-span-3" *ngIf="creator">
          <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Username</label>
          <div class="mt-2">
            <input [(ngModel)]="name" type="text" name="name" id="name" class="block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="sm:col-span-3" *ngIf="creator">
          <label [class.opacity-50]="!login" for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
          <div class="mt-2">
            <input [disabled]="!login" [(ngModel)]="password" type="password" name="password" id="password" class="block w-full rounded-md border-0 px-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="hidden sm:block col-span-3" *ngIf="creator"></div>

        <div class="sm:col-span-3" *ngIf="creator">
          <label [class.opacity-50]="!login" for="c-password" class="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
          <div class="mt-2">
            <input [disabled]="!login" [(ngModel)]="cPassword" type="password" name="c-password" id="c-password" class="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div class="flex items-center justify-center sm:col-span-3 w-full">
          <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
          <button (click)="admin = !admin" [class.!bg-theme-primary-700]="admin"  type="button" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span aria-hidden="true" [class.!translate-x-5]="admin" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
          </button>
          <span class="ml-3 text-sm" id="AdminToggle">
            <span class="font-medium text-gray-900">Admin</span>
          </span>
        </div>
        <div class="opacity-50 pointer-events-none flex items-center justify-center sm:col-span-3 w-full">
          <!-- Enabled: "bg-theme-primary-700", Not Enabled: "bg-gray-200" -->
          <button (click)="login = !login" [class.!bg-theme-primary-700]="login"  type="button" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-theme-primary-700 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span aria-hidden="true" [class.!translate-x-5]="login" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
          </button>
          <span class="ml-3 text-sm" id="LoginToggle">
            <span class="font-medium text-gray-900">Login möglich?</span>
          </span>
        </div>

        <button *ngIf="creator" (click)="AddUser()" type="submit" class="col-span-full block w-full rounded-md bg-theme-primary-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-700">Add User</button>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <h3 class="text-sm font-medium text-gray-500">List of Login-Users</h3>
    <app-loading *ngIf="users == undefined"></app-loading>
    <ul role="list" class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
      <li *ngFor="let user of GetLoginUsers(true)">
        <button type="button" class="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-theme-primary-900 focus:ring-offset-2">
          <span class="flex min-w-0 flex-1 items-center space-x-3">
            <span class="block flex-shrink-0">
              <img class="h-10 w-10 rounded-full" src="./assets/images/icon_nobackground_md.png" alt="">
            </span>
            <span class="block min-w-0 flex-1">
              <span class="block truncate text-sm font-medium text-gray-900">{{ user.name }}</span>
              <span class="block truncate text-xs font-medium text-gray-500">{{ user.shard.name }}</span>
              <span class="block truncate text-sm font-medium text-gray-900">{{ user.company.name }}</span>
            </span>
          </span>
          <span (click)="RemoveUser(user)" class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </span>
        </button>
      </li>
    </ul>
  </div>
  <div class="mt-10">
    <h3 class="text-sm font-medium text-gray-500">List of Non-Login-Users</h3>
    <app-loading *ngIf="users == undefined"></app-loading>
    <ul role="list" class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
      <li *ngFor="let user of GetLoginUsers(false)">
        <button type="button" class="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-theme-primary-900 focus:ring-offset-2">
          <span class="flex min-w-0 flex-1 items-center space-x-3">
            <span class="block flex-shrink-0">
              <img class="saturate-0 h-10 w-10 rounded-full" src="./assets/images/icon_nobackground_md.png" alt="">
            </span>
            <span class="block min-w-0 flex-1">
              <span class="block truncate text-sm font-medium text-gray-900">{{ user.name }}</span>
              <span class="block truncate text-xs font-medium text-gray-500">{{ user.shard.name }}</span>
              <span class="block truncate text-sm font-medium text-gray-900">{{ user.company.name }}</span>
            </span>
          </span>
          <span (click)="RemoveUser(user)" class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </span>
        </button>
      </li>
    </ul>
  </div>
  <!--
  Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
  your content from being obscured when the user scrolls to the bottom of the page.
-->
  <app-error-banner [(error)]="error"></app-error-banner>
</div>
