/**
 * Enum for the alert level.
 * @export
 * @enum {string}
 * - info
 * - warning
 * - error
 * - success
 */
export enum AlertLevel {
  info = "info",
  warning = "warning",
  error = "error",
  success = "success"
}
