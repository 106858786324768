<div class="px-4 sm:px-6 lg:px-8 flex flex-col space-y-4">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="font-semibold leading-6 text-gray-900 !text-3xl">Changelog</h1>
    </div>
  </div>
  <div class="flex flex-col bg-white shadow-2xl px-4 py-2 rounded-lg space-y-2 w-full"
       *ngIf="PermissionService.CheckPermission(PermissionService.Owner())">
    <div class="flex flex-col md:flex-row w-full space-x-3">
      <div class="flex w-full place-self-center">
        <label for="title" class="sr-only"></label>
        <input name="title" id="title" placeholder="Title" class="w-full text-2xl border-2 px-1.5 place-self-center"
               [(ngModel)]="editorTitle"/>
      </div>
      <div class="flex" *ngIf="editingItem">
        <input type="datetime-local" [ngModel]="TimeUtilities.dateToString(editingItem.created)"
               (ngModelChange)="setDate($event)" class="place-self-center">
      </div>
    </div>
    <ejs-richtexteditor [enableResize]="true" [(value)]="editorContent" (valueChange)="fixHtmlReplacement()"
                        id='changelogRTE' #changelogRTE editorMode="Markdown" [enableHtmlSanitizer]="false"
                        [formatter]="formatter" [toolbarSettings]="tools" (created)="onCreate()">
      <ng-template #valueTemplate>
        #
      </ng-template>
    </ejs-richtexteditor>
    <div class="inline-flex w-full space-x-4" *ngIf="!editingItem">
      <span class="text-gray-600 hover:text-gray-800 cursor-pointer select-none ml-auto place-self-center"
            (click)="clearEdit()">Clear</span>
      <button
        class="rounded-md bg-theme-primary-700 hover:text-theme-primary-900 text-white cursor-pointer select-none py-1 px-3 place-self-center"
        (click)="post()">Save
      </button>
    </div>
    <div class="inline-flex w-full space-x-4" *ngIf="editingItem">
      <span class="text-red-600 hover:text-red-800 cursor-pointer select-none place-self-center mr-auto"
            (click)="deleteEdit()" *ngIf="editingItem.active">Deactivate</span>
      <span class="text-green-600 hover:text-green-800 cursor-pointer select-none place-self-center mr-auto"
            (click)="reactivateEdit()" *ngIf="!editingItem.active">Reactivate</span>
      <span class="text-gray-600 hover:text-gray-800 cursor-pointer select-none ml-auto place-self-center"
            (click)="clearEdit()">Cancel</span>
      <button
        class="rounded-md bg-theme-primary-700 hover:text-theme-primary-900 text-white cursor-pointer select-none py-1 px-3 place-self-center"
        (click)="update(editingItem.id, editorTitle, editorContent, editingItemNewDate)">Update
      </button>
    </div>
  </div>
  <div class="bg-white rounded-lg shadow-2xl flex flex-col p-4 space-y-8">
    <div *ngFor="let item of changelogFiltered;let index = index"
         class="w-full space-y-3 text-xl font-light border-gray-500 pb-4" [class.opacity-50]="!item.active"
         [class.border-b-2]="changelogFiltered && changelogFiltered.length > index + 1">
      <div class="w-full inline-flex">
        <h1 class="place-self-center">
          <span class="text-red-700" *ngIf="!item.active">[Inactive]</span>
          {{ item.name }}
        </h1>
        <div *ngIf="PermissionService.CheckPermission(PermissionService.Owner())"
             class="ml-auto place-self-center text-theme-primary-700 hover:text-theme-primary-900 select-none cursor-pointer"
             (click)="edit(item)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pencil-square"
               viewBox="0 0 16 16">
            <path
              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
        </div>
      </div>
      <markdown
        class="block max-w-none prose prose-sm prose-h1:my-0 prose-h2:my-0 prose-h3:my-0 prose-h4:my-0 prose-li:m-0 prose-code:text-theme-primary-400 prose-code:before:content-none prose-code:after:content-none prose-p:before:content-none prose-p:after:content-none prose-blockquote:font-light"
        [data]="item.content"></markdown>
      <div class="flex">
        <span class="ml-auto text-sm font-normal text-gray-500">{{ item.created | date: "dd.MM.yyyy HH:mm" }} Uhr</span>
      </div>
    </div>
  </div>
</div>
