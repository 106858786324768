import {Component} from '@angular/core';
import {Routes} from "../../../../../../enums/routes";
import {AnalyticsRouting} from "../../../routes/analytics-routing";
import {PermissionService} from "../../../../../../_auth/permission.service";

@Component({
  selector: 'app-navbar-analysis-component',
  templateUrl: './navbar-analysis.component.html',
  styleUrls: ['./navbar-analysis.component.css']
})
export class NavbarAnalysisComponent {
  constructor(public PermissionService: PermissionService) {
  }

  public outletPaths = {
    [AnalyticsRouting.TimeSelectorView]: [Routes.Analytics, {"outlets": {"analytics": AnalyticsRouting.MultiEventAnalysis + "/" + AnalyticsRouting.TimeSelectorView}}],
    [AnalyticsRouting.EventPicker]: [Routes.Analytics, {"outlets": {"analytics": AnalyticsRouting.SingleEventAnalysis + "/" + AnalyticsRouting.EventPicker}}],
    [AnalyticsRouting.Index]: [Routes.Analytics, {"outlets": {"analysis": AnalyticsRouting.EventPicker}}],
  }
  protected readonly AnalysisRouting = AnalyticsRouting;

}
