import {Injectable} from '@angular/core';
import {CompositionTypeNl} from "../models/composition-type/composition-type-nl";

@Injectable({
  providedIn: 'root'
})
export class CompositionTypeService {
  public CompositionTypes: CompositionTypeNl[] = [];

  constructor() {
  }
}
