import {Component, EventEmitter, HostListener, Input} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionService} from "../../../_auth/permission.service";
import {ModelBase} from "../../../models/model-base";
import {SettingsPageModel} from "../../../models/settings-page-model";
import {GlobalAlertService} from "../../../_services/global-alert.service";
import {AlertLevel} from "../../../enums/alert-level";
import {Routes} from "../../../enums/routes";
import {ObservableQueueElement} from "../../../models/observable-queue-element";
import {concatMap, finalize, from} from "rxjs";
import {HttpPropertyChangeStatus} from "../../../enums/http-property-change-status";


@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent {
  public model?: ModelBase;
  public modelChanged = new EventEmitter<ModelBase>();
  public timeoutClick: boolean = false;
  observableQueue: ObservableQueueElement[] = [];
  redirectRoute: string = Routes.Home;
  public requiresChanges: HttpPropertyChangeStatus[] = [];

  @Input() loadInputModel: Function = () => {
  };
  @Input() saveChanges: Function = () => {
  };
  @Input() redirect: Function = (redirectRoute: string | undefined) => {
    if (redirectRoute !== undefined)
      this.redirectRoute = redirectRoute;
    window.location.href = this.redirectRoute;
  };

  deleteModel: Function | undefined = undefined;
  deletePermission: boolean = false;

  deleteModelWrapper: Function = () => {
    if (this.deleteModel === undefined) return;
    this.deleteModel();
    setTimeout(() => {
        this.redirect();
      }
      , 2000);

  };


  @Input() settingsInputElementModel: { [key: string]: SettingsPageModel } = {};
  public settingsInputElementModelChanged = new EventEmitter<{ [key: string]: SettingsPageModel }>();


  public get sortedInputElements(): SettingsPageModel[] {
    let result: SettingsPageModel[] = [];
    for (let key in this.settingsInputElementModel) {
      result.push(this.settingsInputElementModel[key]);
    }
    return result.sort((a, b) => a.order - b.order);

  }

  public isVisible: boolean = true;


  constructor(public route: ActivatedRoute,
              public PermissionService: PermissionService,
              public router: Router,
              public globalAlertService: GlobalAlertService) {
  }

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if ((event.target as any).type == "textarea") return; // to prevent saving on multiline textareas
    this.saveChangesWrapper();
  }

  saveChangesWrapper() {
    this.observableQueue = [];
    this.saveChanges()
  }


  loadModelDataWrapper(id: string) {
    this.loadInputModel(id);
  }

  executeQueueWrapper() {
    let index = 0;
    from(this.observableQueue).pipe(
      concatMap((observable, observableIndex) => {
        index = observableIndex;
        return observable.observable
      }),
      finalize(() => {

        if (this.observableQueue.every(x => x.successIndicator)) {
          this.globalAlertService.createAlertBannerModel("Erfolgreich", "Die Änderungen wurden erfolgreich gespeichert.", AlertLevel.success, 5000);
          this.globalAlertService.show();
          setTimeout(() => {
              this.redirect();
            }
            , 2000);
        }
      })
    ).subscribe(result => {
      this.observableQueue[index].callback(result);
    }, error => {
      this.observableQueue[index].callbackError(error);
    });
  }

  parseToNumber(value: string | undefined): number | undefined {
    if (value === undefined || value === "") return undefined;
    value = value.toString();
    value = value.replace(",", ".");
    let result = Number(value);
    return isNaN(result) ? undefined : result
  }

  isValidNumber(value: string | undefined): boolean {

    if (value === undefined || value === "") {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein", AlertLevel.error, 5000);
      this.globalAlertService.show();
      return false;
    }
    try {
      let result = Number(value);
      return result !== undefined;
    } catch (e) {
      this.globalAlertService.createAlertBannerModel("Ungültige Eingabe", "Bitte geben Sie eine Zahl ein. Fehlerbehaftete Eingabe: " + value, AlertLevel.error, 5000);
      this.globalAlertService.show();
      return false;
    }
  }

  abortChangesWrapper() {
    this.redirect()
  }


  updateElements() {
    this.isVisible = false;
    setTimeout(() => {
      this.isVisible = true;
    }, 0);
  }

  changeRequestRequired(): boolean {
    if (this.requiresChanges.every(x => x === HttpPropertyChangeStatus.NotChanged)) {
      this.globalAlertService.createAlertBannerModel("Keine Änderungen", "Es wurden keine Änderungen vorgenommen.", AlertLevel.info, 5000);
      this.globalAlertService.show();
      return false;
    }
    return true;
  }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        this.loadModelDataWrapper(params["id"]);
      }
    );
  }
}

