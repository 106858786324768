import {Component, OnInit} from '@angular/core';
import {RoleHttpService} from "../x-http-requests/role-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionHttpService} from "../x-http-requests/permission-http.service";
import {UserHttpService} from "../x-http-requests/user-http.service";
import {PermissionDto} from "../../models/permission/permission-dto";
import {RoleDto} from "../../models/role/role-dto";
import {UserDto} from "../../models/admin_owner/user/user-dto";
import { HttpErrorResponse } from "@angular/common/http";
import {PermissionService} from "../../_auth/permission.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {filter} from "rxjs";
import {Q} from "@angular/cdk/keycodes";
import {VisibilityState} from "../../x-models/x-enums/visibility-state";
import {AuthenticationService} from "../../_auth/authentication.service";
import {UserJwt} from "../../models/admin_owner/user/user-jwt";

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class AdminRoleComponent implements OnInit {
  roles?: RoleDto[];
  role?: RoleDto;
  permissions?: PermissionDto[];
  permissions_filtered?: PermissionDto[];
  users?: UserDto[];
  name: string = "";
  searchText: string = "";
  error?: HttpErrorResponse;

  constructor(private roleHttpService: RoleHttpService,
              private permissionHttpService: PermissionHttpService,
              private userHttpService: UserHttpService,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService,
              private globalAlertService: GlobalAlertService,
              private authenticationService: AuthenticationService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.user = this.authenticationService.userValue;
  }

  user: UserJwt | null;

  AssignCopy() {
    this.permissions_filtered = Object.assign([], this.permissions);
  }
  Filter(text: string) {
    if (!this.permissions) return;
    this.searchText = text;

    if (this.searchText == "") {
      this.AssignCopy()
    }
    else {
      this.permissions_filtered = Object.assign([], this.permissions?.filter(x => x.id.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1));
    }
  }

  LoadData() {
    this.roleHttpService.list().subscribe(x => {
      this.roles = x;
      if (this.roles.length > 0) this.role = this.roles[0];
    }, error => console.error(error));
    this.permissionHttpService.list().subscribe(x => {
      this.permissions = x;
      this.AssignCopy();
    }, error => console.error(error));
    this.userHttpService.list().subscribe(x => {
      this.users = x;
    }, error => console.error(error));
  }

  RPContain(role: RoleDto | undefined, permission: PermissionDto): boolean {
    if (role == undefined) return false;
    return role.permissions.findIndex(x => x.id == permission.id) > -1;
  }
  RUContain(role: RoleDto, user: UserDto): boolean {
    return role.users.findIndex(x => x.id == user.id) > -1;
  }

  togglePublic(role: RoleDto) {
    this.roleHttpService.togglePublic(role.id, role.visibilityState != VisibilityState.Public).subscribe(x => {
      if (!this.roles) this.roles = [];
      this.roles.splice(this.roles.indexOf(role), 1, x);
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Rolle konnte nicht öffentlich geschaltet werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  TogglePermission(role: RoleDto | undefined, permission: PermissionDto, state: boolean) {
    if (role == undefined) {
      this.error = new HttpErrorResponse({
        statusText: "No role selected!"
      });
      return;
    }
    this.roleHttpService.togglePermission(role.id, permission.id, state).subscribe(x => {
      this.error = undefined;
      this.roles?.splice(this.roles?.indexOf(role), 1, x)
      this.role = x;
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  ToggleUser(role: RoleDto | undefined, user: UserDto, state: boolean) {
    if (role == undefined) {
      this.error = new HttpErrorResponse({
        statusText: "No role selected!"
      });
      return;
    }
    if (state) {
      this.roleHttpService.addUser(role.id, user.id).subscribe(x => {
        this.error = undefined;
        this.roles?.splice(this.roles?.indexOf(role), 1, x);
        this.role = x;
      }, error => {
        this.error = error;
        console.error(error);
      });
    } else {
      this.roleHttpService.removeUser(role.id, user.id).subscribe(x => {
        this.error = undefined;
        this.roles?.splice(this.roles?.indexOf(role), 1, x);
        this.role = x;
      }, error => {
        this.error = error;
        console.error(error);
      });
    }

  }

  AddRole() {
    this.roleHttpService.add(this.name).subscribe(x => {
      if (this.roles == undefined) this.roles = [];
      this.error = undefined;
      this.roles.splice(0,0,x);
      this.role = this.roles[0];
      this.name = "";
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  RemoveRole(role: RoleDto) {
    let confirmation = confirm("Are you sure you want to delete the following role:\n" + role.name + "\n" + role.id);
    if (!confirmation) return;
    this.roleHttpService.delete(role.id).subscribe(x => {
      this.error = undefined;
      this.roles?.splice(this.roles?.indexOf(role), 1);
      if (this.roles!.length > 0 && this.role == role) this.role = this.roles![0];
      else if (this.roles!.length == 0) this.role = undefined;
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  ngOnInit(): void {
    this.LoadData();
  }

  protected readonly filter = filter;
    protected readonly Q = Q;
  protected readonly VisibilityState = VisibilityState;
}
