<div class="flex py-4 gap-x-4 -mx-4 pointer-events-none">
  <div class="grid grid-cols-1 gap-x-8 gap-y-8 w-full">
    <form class="bg-gray-100 shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl relative">
      <div class="px-3 pt-2 pb-3 pointer-events-auto" *ngIf="!isCollapsed">
        <ng-content></ng-content>
      </div>
      <div [class.border-t]="!isCollapsed"
           class="pointer-events-auto flex items-center justify-end gap-x-6 border-gray-900/10 py-2 px-3">
        <button *ngIf="!isCollapsed" type="button"
                class="text-sm font-semibold leading-6 text-gray-900 px-1.5 rounded-md"
                (click)="clear.emit()">
          {{ clearText }}
        </button>
        <input #search type="text" name="search" id="search" *ngIf="isCollapsed && hasSearch" [ngModel]="searchValue"
               (ngModelChange)="onSearchChange($event)"
               class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-primary-700 sm:text-sm sm:leading-6"
               placeholder="Suchen...">
        <button type="submit" (click)="onSubmit($event)" *ngIf="hasPermission()"
                class="inline-flex whitespace-nowrap rounded-md bg-theme-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-theme-primary-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-theme-primary-900">
          {{ submitText }}
        </button>
        <button type="submit" *ngIf="!hasPermission()" (click)="preventDefault($event)" class="hidden"></button>
      </div>
      <button class="absolute h-6 w-6 right-3 top-2 text-gray-600 pointer-events-auto" (click)="collapse($event)"
              *ngIf="!isCollapsed">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <path fill="currentColor"
                d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z"/>
        </svg>
      </button>
    </form>
  </div>
</div>
