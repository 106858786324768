<nav class="fixed inset-x-0 top-0 bg-gray-800 h-12 z-50">
  <div class="mx-auto max-w-10xl px-2 sm:px-4 lg:px-8">
    <div class="relative flex items-center justify-between">
      <div class="flex px-2 lg:px-0 ml-0 transition-all xl:!ml-0 xl:!mr-0" [ngClass]="{'lg:-ml-48 lg:mr-48': focus}">
        <div class="flex-shrink-0 flex items-center h-12 select-none cursor-pointer" [routerLink]="['/']">
          <img class="h-8 w-auto rounded-full border-2 border-white" src="./assets/images/icon.png" alt="N5CM">
        </div>
        <div class="hidden lg:ml-2 lg:block h-10 pt-2">
          <div class="flex">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <app-navbar-office-item *ngFor="let navbarItem of NavbarService.getAvailable()" [navbarItem]="navbarItem"
                                    (click)="select(navbarItem)"></app-navbar-office-item>
            <div *ngIf="AuthenticationService.contractType != ContractType.Full"
                 (click)="AuthenticationService.contractType = ContractType.Full"
                 class="text-white pb-2 flex px-3 cursor-pointer hover:!text-theme-primary-700">
              <span class="my-auto text-xs font-light">mehr anzeigen</span>
              <svg class="my-auto h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                   viewBox="0 0 256 256">
                <path fill="currentColor"
                      d="m184.49 136.49l-80 80a12 12 0 0 1-17-17L159 128L87.51 56.49a12 12 0 1 1 17-17l80 80a12 12 0 0 1-.02 17"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-1 justify-center lg:justify-end lg:focus-within:-ml-48 xl:!ml-6 transition-all">
        <div class="w-full max-w-lg lg:max-w-xl">
          <label for="search" class="sr-only">Search</label>
          <div class="relative ml-4">
            <ng-select #searchElement name="search" id="search" class="custom-dropdown-dark"
                       [groupBy]="groupBy" [searchFn]="search"
                       (change)="navigate($event);searchElement.blur()" [(ngModel)]="selected"
                       [items]="NavbarService.itemBases" (focusin)="loadItems()" (focusout)="focusout()"
                       notFoundText="Keine Ergebnisse" bindLabel="value" bindValue="id">
              <ng-option class="custom-dropdown-dark" *ngFor="let item of NavbarService.itemBases; trackBy: identify"
                         [value]="item.id">
                Test {{ item.value }}
              </ng-option>
            </ng-select>
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
              <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="flex lg:hidden">
        <!-- Mobile menu button -->
        <button (click)="select(NavbarService.getHelpItem())"
                class="mx-2 h-10 w-10 p-2 place-self-center text-gray-400 hover:bg-gray-700 hover:text-white rounded-md">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path fill="currentColor"
                  d="M10 9.6c-.6 0-1 .4-1 1c0 .4.3.7.6.8l-.3 1.4h1.3l-.3-1.4c.4-.1.6-.4.6-.8c.1-.6-.3-1-.9-1m.1-4.3c-.7 0-1.4.5-1.4 1.2V8h2.7V6.5c-.1-.7-.6-1.2-1.3-1.2M10 2L3 5v3c.1 4.4 2.9 8.3 7 9.9c4.1-1.6 6.9-5.5 7-9.9V5zm4 11c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1h.3V6.5C7.4 5.1 8.6 4 10 4c1.4 0 2.6 1.1 2.7 2.5V8h.3c.6 0 1 .4 1 1z"/>
          </svg>
        </button>
        <button (click)="menuToggle()" type="button"
                class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu" aria-expanded="false">
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
          </svg>
          <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
      <div class="hidden lg:ml-2 lg:block">
        <div class="flex items-center">
          <!-- Profile dropdown -->
          <div class="relative ml-2 flex-shrink-0">
            <div>
              <button type="button" (click)="select(NavbarService.userNavbar)"
                      class="relative flex rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-person-circle h-6 w-6"
                     viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                  <path fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div (click)="menuClose()"
       class="lg:hidden cursor-pointer fixed z-[48] top-12 inset-x-0 bottom-0 bg-gray-700 opacity-0 pointer-events-none transition-all"
       [ngClass]="{'!opacity-90 !pointer-events-auto': menuVisible}"></div>
  <div
    class="translate-x-full transition-all fixed top-12 right-0 bottom-0 left-0 sm:left-2/3 lg:hidden z-[48] bg-gray-700"
    id="mobile-menu" [ngClass]="{'!translate-x-0': menuVisible}">
    <div class="space-y-1 px-2 pb-3 pt-2">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <app-navbar-office-item [mobile]="true" *ngFor="let navbarItem of NavbarService.getAvailable()"
                              [navbarItem]="navbarItem" (click)="select(navbarItem)"></app-navbar-office-item>
      <div class="flex">
        <div *ngIf="AuthenticationService.contractType != ContractType.Full"
             (click)="AuthenticationService.contractType = ContractType.Full"
             class="mx-auto text-white pb-2 flex flex-col px-3 cursor-pointer hover:!text-theme-primary-700">
          <span class="my-auto text-xs font-light">mehr anzeigen</span>
          <svg class="mx-auto h-4 w-4 rotate-90" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
               viewBox="0 0 256 256">
            <path fill="currentColor"
                  d="m184.49 136.49l-80 80a12 12 0 0 1-17-17L159 128L87.51 56.49a12 12 0 1 1 17-17l80 80a12 12 0 0 1-.02 17"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="border-t border-gray-700 pb-3 pt-4">
      <div class="mt-3 space-y-1 px-2 flex flex-col">
        <a class="inline-flex space-x-1" (click)="select(NavbarService.userNavbar)">
          <div class="place-self-center ml-auto">
            <div class="text-base font-medium text-white fw-light">{{ AuthenticationService.userValue?.name }}</div>
          </div>
          <div class="text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="h-8 w-8 place-self-center mr-3">
              <path fill="currentColor"
                    d="M10.561 8.073a6 6 0 0 1 3.432 5.142a.75.75 0 1 1-1.498.07a4.5 4.5 0 0 0-8.99 0a.75.75 0 0 1-1.498-.07a6 6 0 0 1 3.431-5.142a3.999 3.999 0 1 1 5.123 0M10.5 5a2.5 2.5 0 1 0-5 0a2.5 2.5 0 0 0 5 0"/>
            </svg>
          </div>
        </a>
        <a
          class="ml-auto block rounded-md px-3 pt-3 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
          [routerLink]="['/' + Routes.AuthenticationProfile]" (click)="menuClose()">Profil</a>
        <a
          class="ml-auto block rounded-md px-3 pt-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
          (click)="AuthenticationService.logout()">Abmelden</a>
      </div>
    </div>
  </div>
</nav>
<div class="w-full fixed top-12 inset-x-0 z-[49]">
  <div class="w-full absolute h-24 bg-gray-300 transition-all -translate-y-24"
       [ngClass]="{'!translate-y-0': NavbarService.hasExpanded()}"></div>
  <app-navbar-office-item *ngFor="let navbarItem of NavbarService.getAvailable(false)" [navbarItem]="navbarItem"
                          [actions]="true">
    <ng-container *ngComponentOutlet="navbarItem.component"></ng-container>
  </app-navbar-office-item>
  <!--<app-navbar-office-item [navbarItem]="NavbarService.navbarItems[0]" [actions]="true"><app-navbar-inventory></app-navbar-inventory></app-navbar-office-item>
  <app-navbar-office-item [navbarItem]="NavbarService.navbarItems[0]" [actions]="true"><app-navbar-analytic-tools></app-navbar-analytic-tools></app-navbar-office-item>-->
  <!--<app-navbar-office-item *ngFor="let navbarItem of navbarItems" [navbarItem]="navbarItem" [actions]="true"></app-navbar-office-item>-->
  <div *ngIf="NavbarService.getAvailable().length > 0"
       class="lg:hidden fixed h-5 mt-0 w-full -mb-5 pointer-events-none transition-all"
       [ngClass]="{'!mt-24': NavbarService.hasExpanded()}">
    <div (click)="select(last ?? NavbarService.getAvailable()[0])"
         class="cursor-pointer select-none flex pointer-events-auto mx-auto w-1/5 h-full bg-gray-800 rounded-b-lg relative custom-top-rounding">
      <svg class="m-auto h-4 w-auto transition-all rotate-90" [ngClass]="{'!-rotate-90': NavbarService.hasExpanded()}"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="#ffffff"
              d="m20.08 11.42l-4.04-5.65c-.34-.48-.89-.77-1.48-.77c-1.49 0-2.35 1.68-1.49 2.89L16 12l-2.93 4.11c-.87 1.21 0 2.89 1.49 2.89c.59 0 1.15-.29 1.49-.77l4.04-5.65c.24-.35.24-.81-.01-1.16"/>
        <path fill="#ffffff"
              d="M13.08 11.42L9.05 5.77C8.7 5.29 8.15 5 7.56 5C6.07 5 5.2 6.68 6.07 7.89L9 12l-2.93 4.11C5.2 17.32 6.07 19 7.56 19c.59 0 1.15-.29 1.49-.77l4.04-5.65c.24-.35.24-.81-.01-1.16"/>
      </svg>
    </div>
  </div>
</div>
