import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {

  constructor() {
  }

  static count = 0;

  static get loading(): boolean {
    return this.count > 0;
  }
}

