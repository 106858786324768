import {Component, OnInit} from '@angular/core';
import {ShardHttpService} from "../x-http-requests/shard-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ShardDto} from "../../models/admin_owner/shard/shard-dto";
import { HttpErrorResponse } from "@angular/common/http";
import {CompanyDto} from "../../models/admin_owner/company/company-dto";
import {CompanyHttpService} from "../x-http-requests/company-http.service";
import {Routes} from "../../enums/routes";
import {PermissionService} from "../../_auth/permission.service";
import {IntegrationProvider} from "../../models/integrations/integration-provider";
import {FiskalyHttpService} from "../../_modules/owner/_services/fiskaly-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";
import {AlertLevel} from "../../enums/alert-level";
import {finalize, tap} from "rxjs";

@Component({
  selector: 'app-shard',
  templateUrl: './shard.component.html',
  styleUrls: ['./shard.component.scss']
})
export class OwnerShardComponent implements OnInit {
  shards?: ShardDto[];
  filtered_shards?: ShardDto[];
  companies?: CompanyDto[];
  company?: CompanyDto;
  searchText: string = "";
  error?: HttpErrorResponse;
  name: string = "";
  company_filter: string = "";

  loading: boolean = false;

  constructor(private shardHttpService: ShardHttpService,
              private companyHttpService: CompanyHttpService,
              private route: ActivatedRoute, private router: Router,
              public PermissionService: PermissionService,
              private fiskalyHttpService: FiskalyHttpService,
              private gas: GlobalAlertService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  redirectToSettings(id: string) {
    this.router.navigate([Routes.OwnerShardSettings], {queryParams: {id: id}});
  }

  AssignCopy() {
    this.filtered_shards = Object.assign([], this.shards);
  }

  FilterItems(value: string) {
    this.searchText = value;
    if (!value) {
      this.AssignCopy();
    } else {
      this.filtered_shards = Object.assign([], this.shards?.filter(shard =>
        shard.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ));
    }
  }

  LoadData() {
    this.shardHttpService.list(this.company_filter).subscribe(x => {
      this.shards = x;
      this.AssignCopy();
    }, error => console.error(error));
    if (this.company_filter != "") {
      this.companyHttpService.get(this.company_filter).subscribe(x => {
        this.companies = [x];
        this.company = x;
      }, error => console.error(error));
    } else {
      this.companyHttpService.list().subscribe(x => {
        this.companies = x;
        if (this.companies.length > 0)
          this.company = this.companies[0];
      }, error => console.error(error));
    }
  }

  AddShard(): void {
    this.shardHttpService.add(this.name, this.company == undefined ? "" : this.company.id).subscribe(x => {
      if (this.shards == undefined) this.shards = [];
      this.error = undefined;
      this.shards.splice(0, 0, x);
      this.FilterItems(this.searchText);
      this.name = "";
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  RemoveShard(shard: ShardDto): void {
    let confirmation = confirm("Are you sure you want to delete the following shard:\n" + shard.name + "\n" + shard.id + "\nOwned by " + shard.company.name);
    if (!confirmation) return;
    this.shardHttpService.delete(shard.id).subscribe(x => {
      this.error = undefined;
      this.shards!.splice(this.shards!.indexOf(shard), 1);
      this.FilterItems(this.searchText);
    }, error => {
      this.error = error;
      console.error(error);
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.company_filter = params.company == undefined ? "" : params.company;
    })
    this.LoadData();
  }

  hasIntegrationReference(shard: ShardDto, provider: IntegrationProvider): boolean {
    return shard.integrationReferences.some(x => x.integrationProvider == provider && x.externalId != undefined);
  }

  fiskalyRegister(shard: ShardDto) {
    this.loading = true;
    this.fiskalyHttpService.upsertShard(shard.id)
      .withLoadingIndicator()
      .subscribe({
        next: s => {
          this.gas.createAndShow("Success", "Fiskaly registration successful", AlertLevel.success);
          this.shards?.splice(this.shards?.indexOf(shard), 1, s);
          this.FilterItems(this.searchText);
        },
        error: err => {
          console.error(err);
          this.gas.createAndShow("Error", "Fiskaly registration failed", AlertLevel.error);
        }
      })
  }

  protected readonly IntegrationProvider = IntegrationProvider;
}
