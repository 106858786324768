/**
 * Enum that represents the status if a property changed.
 * @export
 * @enum {number}
 * - NotChanged
 * - Changed
 * - Error
 */
export enum HttpPropertyChangeStatus {
  NotChanged = 0,
  Changed = 1,
  Error = 2
}
