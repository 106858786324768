import {Component, OnInit} from '@angular/core';
import {Routes} from "../../enums/routes";
import {AlertLevel} from "../../enums/alert-level";
import {PermissionService} from "../../_auth/permission.service";
import {CompositionTypeService} from "../../_services/composition-type.service";
import {CompositionTypeHttpService} from "../../http-requests/composition-type-http.service";
import {GlobalAlertService} from "../../_services/global-alert.service";

@Component({
  selector: 'app-navbar-inventory',
  templateUrl: './navbar-inventory.component.html',
  styleUrls: ['./navbar-inventory.component.scss']
})
export class NavbarInventoryComponent implements OnInit {
  constructor(public PermissionService: PermissionService, public CompositionTypeService: CompositionTypeService,
              protected compositionTypeHttpService: CompositionTypeHttpService, private globalAlertService: GlobalAlertService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    if (!this.PermissionService.CheckPermission(this.PermissionService.Inventory_CompositionType_ListNl())) {
      this.CompositionTypeService.CompositionTypes = [];
      return;
    }
    this.compositionTypeHttpService.ListNl().subscribe(x => {
      this.CompositionTypeService.CompositionTypes = x;
    }, error => {
      console.error(error);
      this.globalAlertService.createAlertBannerModel("Fehler", "Artikel-Kategorien konnten nicht geladen werden.", AlertLevel.error, 2000);
      this.globalAlertService.show();
    })
  }

  protected readonly Routes = Routes;
}
