export interface IModelBase {
  id: string;
  name: string;
  created: Date;
  modified: Date;
  active: boolean;
}

export class ModelBase implements IModelBase {
  active: boolean = false;
  modified: Date = new Date;
  created: Date = new Date;
  id: string = "";
  name: string = "";
  publicName: string | undefined = undefined;
}
